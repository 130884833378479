import "./TutorHubFeedback.scss";
import TutorHubSessionImg from "../../Assets/TutorHub/TutorHubSession.svg";
import TutorImg from "../../Assets/Tutor/TutorImg.svg";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from "react";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS } from "../../Constants/Constants";
import { GetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import toast from "react-hot-toast";
import activitiesIcon from "../../Assets/Activities/activitiesIcon.svg";
import spacemen from "../../Assets/Global/spacemen.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const TutorHubFeedback = ({ getData, type }: any) => {

    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [FeedbackList, setFeedbackList] = useState<any>([]);
    const [PassDilogData, setPassDilogData] = useState<any>([]);
    const [FeedbackInput, setFeedbackInput] = useState<any>("");
    const [RatingData, setRatingData] = useState<any>(0);
    const [EmptyData, setEmptyData] = useState<any>([]);
    const [StatusFeedback, setStatusFeedback] = useState<any>(false);

    /**Loader state*/
    const Loader: any = useSelector((state: any) => {
        return state.loaderReducer.loader;
    });

    useEffect(() => {
        getTutorFeedbackData();
    }, [])

    /**Get Tutor Hub Feedback List*/
    const getTutorFeedbackData = () => {
        axiosInstance
            .get(`${API_END_POINTS.HUB_FEEDBACK_LIST + getData?.id}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setFeedbackList(Response?.data?.result?.data[0]);
                    setEmptyData(Response?.data?.result?.data)
                }
            })
            .catch(() => { });
    };

    /**Tutor get List Api*/
    const addRatingApi = () => {
        var payload = {
            service_provider_type: getData?.teach_service_provider_id,
            service_provider: PassDilogData?.session_creator_id,
            rating: RatingData,
            feedback: FeedbackInput,
            session_id: PassDilogData?.session_id,
            booking_id: parseInt(FeedbackList?.booking_id),
            session_time_slot_id: PassDilogData?.session_time_slot_id,
            is_rating: true
        }
        axiosInstance
            .post(`${API_END_POINTS.ADD_RATING}`, payload)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    toast.success(Response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                    handleClose();
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message, {
                    position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                });
            });
    };

    const handleClickOpen = (value: any) => {
        setPassDilogData(value);
        setOpen(true);
        if (value?.rating || value?.feedback) {
            setRatingData(value?.rating);
            setFeedbackInput(value?.feedback);
            setStatusFeedback(true);
        } else {
            setStatusFeedback(false);
        }

    };
    const handleClose = () => {
        setOpen(false);
        setPassDilogData([])
        setRatingData(0);
        setFeedbackInput("");
        setStatusFeedback(false);
    };
    return (
        <>
            <div className="TutorHubFeedback__container">

                {EmptyData?.length > 0 && (
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="Header__wapper">
                                <img src={TutorHubSessionImg} alt="" />
                                <div className="title">{t("Sessions")}</div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="Profile_contant">
                                <div className="tutor__contant">
                                    <img src={nullUndefinedEmptyCheck(FeedbackList?.profile) ? FeedbackList?.profile : tutor_img} className="profile__Img"
                                        onError={(e: any) => { e.target.src = tutor_img; }} alt="" />
                                    <div className="d-flex flex-column">
                                        <div className="Black__text">{FeedbackList?.tutor_name}</div>
                                        <div className="name__text">{FeedbackList?.type}</div>
                                    </div>
                                </div>
                                <div className="right_options">
                                    <div className="d-flex flex-row align-items-center">
                                        <StarIcon className="rating__starIcon me-1" />
                                        <div className="rating__value">{FeedbackList?.overall_rating}</div>
                                    </div>
                                    {/* <div className="editBtn">Edit</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row feedback__card__conatiner">
                    {type === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT && (
                        <>
                            {FeedbackList?.data?.map((data: any, i: any) => {
                                return (
                                    <>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                                            <div className="feedback__card__wapper">
                                                <div className="top__contant__wapper">
                                                    <div className="tutor__contant">
                                                        <div className='tutor__img__wapper'>
                                                            <img src={TutorImg} alt="" />
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <div className="Black__text">{data?.session_title}</div>
                                                            <div className="name__text">{data?.session_creator_name}</div>
                                                        </div>
                                                    </div>
                                                    {data?.is_completed && (<button className="completedBtn">{t("Completed")}</button>)}
                                                </div>
                                                <div className="row__contant">
                                                    <div className="left__light__text">{t("No. of Sessions")}</div>
                                                    <div className="right__black__text">{data?.slot_no}/{data?.total_count}</div>
                                                </div>
                                                <div className="row__contant">
                                                    <div className="left__light__text">{t("Date")}</div>
                                                    <div className="right__black__text">{data?.date}</div>
                                                </div>
                                                {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                                                    <button className="feedbackBtn" onClick={() => handleClickOpen(data)}>{data?.rating || data?.feedback ? 'View feedback' : 'Give feedback'}</button>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                            {EmptyData?.length === 0 && !Loader && (
                                <>
                                    <div className="No__data__contant">
                                        <img src={spacemen} alt="" />
                                        <div className="text">{t("No Data Found")}</div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {type !== GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT && (
                        <>
                            {FeedbackList?.data?.map((data: any, i: any) => {
                                return (
                                    <>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                                            <div className="feedback__card__wapper">
                                                <div className="top__contant__wapper">
                                                    <div className="tutor__contant">
                                                        <div className='activity__img__wapper'>
                                                            <img src={activitiesIcon} alt="" />
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <div className="Black__text">{data?.session_title}</div>
                                                            <div className="name__text">{data?.session_creator_name}</div>
                                                        </div>
                                                    </div>
                                                    {data?.is_completed && (<button className="completedBtn">{t("Completed")}</button>)}
                                                </div>
                                                <div className="row__contant">
                                                    <div className="left__light__text">{t("No. of Sessions")}</div>
                                                    <div className="right__black__text">{data?.slot_no}/{data?.total_count}</div>
                                                </div>
                                                <div className="row__contant">
                                                    <div className="left__light__text">{t("Date")}</div>
                                                    <div className="right__black__text">{data?.date}</div>
                                                </div>
                                                {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                                                    <button className="feedbackBtn" onClick={() => handleClickOpen(data)}>{data?.rating || data?.feedback ? 'View feedback' : 'Give feedback'}</button>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                            {EmptyData?.length === 0 && !Loader && (
                                <>
                                    <div className="No__data__contant">
                                        <img src={spacemen} alt="" />
                                        <div className="text">{t("No Data Found")}</div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>

            {open && (
                <div className="overlay-ModelBackround">
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        className="FeedBack__dailog__box"
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            <div className="DialogTitle__wapper">
                                <div className="title">{t("Feedback")}</div>
                                <div className="closeBtn" onClick={handleClose}>{t("Close")}</div>
                            </div>
                        </DialogTitle>
                        <DialogContent dividers>

                            <div className="DialogContent___wapper">
                                <div className="tutor__contant">
                                    {type === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT && (
                                        <>
                                            <div className='tutor__img__wapper'>
                                                <img src={TutorImg} alt="" />
                                            </div>
                                        </>
                                    )}
                                    {type === GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT && (
                                        <>
                                            <div className='activity__img__wapper'>
                                                <img src={activitiesIcon} alt="" />
                                            </div>
                                        </>
                                    )}
                                    <div className="d-flex flex-column">
                                        <div className="Black__text">{PassDilogData?.session_title}</div>
                                        <div className="name__text">{PassDilogData?.session_creator_name}</div>
                                    </div>
                                </div>
                                <div className="row__contant">
                                    <div className="left__light__text">{t("No. of Sessions")}</div>
                                    <div className="right__black__text">{PassDilogData?.slot_no}/{PassDilogData?.total_count}</div>
                                </div>

                                <div className="rating__container">
                                    <Rating name="half-rating" value={RatingData}
                                        disabled={StatusFeedback}
                                        onChange={(event, newValue) => {
                                            setRatingData(newValue);
                                        }}
                                        precision={1} className="rating__icon" size="large" />

                                    {StatusFeedback ? (
                                        <div className="lable__text">{t("Thank you for your feedback! Your feedback is now visible below.")}</div>
                                    ) : (
                                        <div className="lable__text">{t("Do you have any thoughts you'd like to share?")}</div>
                                    )}

                                    <textarea className="textarea__input" placeholder="Write your message here..." value={FeedbackInput}
                                        onChange={(e) => setFeedbackInput(e.target.value)} cols={30} rows={3} disabled={StatusFeedback} ></textarea>

                                    {!StatusFeedback && (
                                        <>
                                            {RatingData !== 0 || nullUndefinedEmptyCheck(FeedbackInput) ? (
                                                <button className="submitBtn" onClick={addRatingApi} >{t("Submit")}</button>
                                            ) : (
                                                <button className="submitBtn__disable">{t("Submit")}</button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>

                        </DialogContent>

                    </BootstrapDialog>
                </div>
            )}

        </>
    )
}

export default TutorHubFeedback;