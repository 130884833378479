import { Dialog, DialogContent } from "@mui/material";
import "./TutorTrialSession.scss";
import { useEffect, useState } from "react";
import freeTrial_img from "../../Assets/Tutor/freeTrial_img.png";
import MemberBlue from "../../Assets/Tutor/oneMemberBlue.svg";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import Radio from "@mui/material/Radio";
import { GetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TutorTrialSession = ({ TrialOpen, onClose, tutor_id }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [trialSessionList, setTrialSessionList] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [userId, setUserId] = useState<any>(GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID));

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  useEffect(() => {
    getListData();
    setSelectedValue(null);
  }, [TrialOpen]);

  /**Get Tutor trial session List*/
  const getListData = () => {
    if (nullUndefinedEmptyCheck(tutor_id)) {
      axiosInstance
        .get(
          `${API_END_POINTS.TUTOR_FREE_SESSION +
          "?tutor_id=" +
          tutor_id +
          "&teach_service_provider_id=1"
          }`
        )
        .then((Response: any) => {
          if (Response?.data?.status) {
            const currentDateTime = new Date();
            var findDate = Response?.data?.result?.data?.filter(
              (item: any) => new Date(item?.start) > currentDateTime
            );
            setTrialSessionList(findDate);
          }
        })
        .catch(() => { });
    }
  };

  const handleChange = (value: any) => {
    setSelectedValue(value);
  };

  const ConfirmSession = () => {
    var transformedData: any = [
      {
        start_time: selectedValue?.start_time,
        end_time: selectedValue?.end_time,
        start_date: moment(selectedValue?.start).format(
          GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT
        ),
      }
    ];
    let payload = {
      is_primary: true,
      parent_id: userId,
      child_id: null,
      booked_slot_detail: transformedData,
      teach_service_provider_id: selectedValue?.teach_service_provider_id?.toString(),
      sp_user_id: selectedValue?.user_id,
      free_session: true,
    }
    axiosInstance
      .post(`${API_END_POINTS.TUTOR_BOOKING_VALIDATION}`, payload)
      .then((Response: any) => {
        if (Response?.data?.status) {
          navigate(ROUTES.TUTORS_CHECKOUT, {
            state: {
              free_trial_data: selectedValue,
              free_trial: true,
            },
          });
        }
      })
      .catch((error) => { });
    onClose();
  };

  return (
    <>
      <Dialog open={TrialOpen}>
        <DialogContent dir={
          language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
            ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
            : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
        } >
          <div className="TutorTrialSession_wrapper">
            <div onClick={onClose} className="skip_button">
              <CloseIcon className="close__icon" />
            </div>
            <div className="row mt-2">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="leftside__contant">
                  <img src={freeTrial_img} className="freeTrial_img" alt="" />
                  <div className="title">{t("Book an introductory session")}</div>
                  <div className="descrption">
                    {t("tutor_free_session_description")}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="rightSide__Card">
                  <div className="lable_title">
                    {t("Session with")} {trialSessionList[0]?.name}
                  </div>
                  <div className="scrool__conatiner">
                    {trialSessionList.map((item: any, i: any) => {
                      return (
                        <>
                          <div className="trial_card" key={i}>
                            <img
                              src={MemberBlue}
                              className="MemberBlueImg"
                              alt=""
                            />
                            <div className="date_time_wapper">
                              <div className="date">
                                {moment(item?.start).format("dddd")}{" "}
                                {moment(item?.start).format("DD/MM/YYYY")}
                              </div>
                              <div className="time">
                                {moment(item?.start).format("LT")}&nbsp;to&nbsp;
                                {moment(item?.end).format("LT")}
                              </div>
                            </div>
                            <Radio
                              checked={selectedValue == item}
                              id={`session${item.id}`}
                              onChange={() => handleChange(item)}
                              value={item?.id}
                              name="radio-buttons"
                            />
                          </div>
                        </>
                      );
                    })}
                    {trialSessionList.length === 0 && (
                      <div className="trial_card">
                        <div className="date">{t("No Session here!")}</div>
                      </div>
                    )}
                  </div>
                </div>
                {selectedValue ? (
                  <button
                    className="ConfirmSessionBtn"
                    onClick={ConfirmSession}
                  >
                    {t("Confirm Session")}
                  </button>
                ) : (
                  <button className="ConfirmSessionBtn Btn__disable">
                    {t("Confirm Session")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TutorTrialSession;
