import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useEffect, useRef, useState, createRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import defaultProfile from "../../Assets/Authentication/defaultProfile.svg";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import swimingActivities from "../../Assets/Activities/swimingActivities.svg";
import ActivitiesCamp from "../../Assets/Activities/camp_icon.svg";
import ActivitiesTriner from "../../Assets/Activities/cap.svg";
import universityEducation from "../../Assets/University/universityEducation.svg";
import TutorImg from "../../Assets/Tutor/TutorImg.svg";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import "./ChildCalender.scss";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
} from "../../Constants/Constants";
import video_call_icon from "../../Assets/TutorHub/video_call_icon.svg";
import { nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function renderEventContent(eventInfo: any) {
  return (
    <>
      {/* <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i> */}
      {/* {eventInfo?.event?.id == 1 ? (
                <div className="tutor__EventContent">
                    {eventInfo?.event?.title}
                </div>
            ) : eventInfo?.event?.id == 2 ? (
                <div className="activity__EventContent">
                    {eventInfo?.event?.title}
                </div>
            ) : eventInfo?.event?.id == 3 ? (
                <div className="activity__EventContent">
                    {eventInfo?.event?.title}
                </div>
            ) : eventInfo?.event?.id == 4 ? (
                <div className="tutor__EventContent">
                    {eventInfo?.event?.title}
                </div>
            ) : eventInfo?.event?.id == 5 ? (
                <div className="university__EventContent">
                    {eventInfo.event.title}
                </div>
            ) : ""} */}
    </>
  );
}

const ChildCalender = () => {
  const { t } = useTranslation();
  const route = useLocation();
  const [slotSelectDpt, setSlotSelectDpt] = useState<any>(0);
  const [childCalenderData, setChildCalenderData] = useState<any>([]);
  const [PassClickData, setPassClickData] = useState<any>([]);
  const [DynamicDate, setDynamicDate] = useState<any>(new Date());
  const [childProfileData, setChildProfileData] = useState<any>([]);
  const [parentProfileData, setParentProfileData] = useState<any>([]);
  const [MeetOpen, setMeetOpen] = useState(false);
  const [MeetData, setMeetData] = useState<any>("");
  const [events, setEvents] = useState<any>(null);
  const [ChangeView, setChangeView] = useState<any>("");
  const [selectedOption, setSelectedOption] = useState<any>(
    GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL
  );

  useEffect(() => {
    getParentView("", "");
    setSelectedOption(route?.state?.is_parent ? route?.state?.is_parent : GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL);
    getProfileList();
  }, []);

  const handleClose = () => {
    setMeetData("");
    setMeetOpen(false);
  };

  /**Get profile data */
  const getProfileList = () => {
    axiosInstance
      .get(`${API_END_POINTS.PROFILE_LIST}`)
      .then((profileListResponse: any) => {
        if (
          profileListResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          setParentProfileData(profileListResponse.data.result.data.parent);
          setChildProfileData(profileListResponse?.data?.result?.data?.child);
        }
      })
      .catch(() => { });
  };

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
    // if (event.target.value === GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL) {
    //   getParentView("");
    // } else {
    //   getParentView(event.target.value);
    // }
  };

  /**onclick Function to get particular calender data*/
  const handleEventClick = (eventInfo: any) => {
    setDynamicDate(new Date(eventInfo?.event?.start));
    const filteredEvents = childCalenderData?.filter((item: any) => {
      return (
        moment(item?.start).format(
          GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT
        ) ===
        moment(eventInfo?.event?.start).format(
          GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT
        )
      );
    });
    setPassClickData(filteredEvents);
  };

  // Handle the date selection event here
  const handleDateSelect = (selectInfo: any) => {
    setDynamicDate(new Date(selectInfo.startStr));
    const filteredEvents = childCalenderData?.filter((item: any) => {
      return (
        moment(item?.start).format(
          GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT
        ) ===
        moment(selectInfo.startStr).format(
          GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT
        )
      );
    });
    const sortedSessionSlots = filteredEvents.slice().sort((a: any, b: any) => {
      const startTimeA: any = new Date(`1970-01-01T${a.start_time}`);
      const startTimeB: any = new Date(`1970-01-01T${b.start_time}`);
      return startTimeA - startTimeB;
    });
    setPassClickData(filteredEvents);
  };

  /**Get Parent view for all child*/
  const getParentView = (type: any, value: any) => {
    setPassClickData([]);
    setEvents([]);
    axiosInstance
      .get(`${API_END_POINTS.MY_CALENDAR_VIEW + type + value}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          // let res = Response?.data?.result?.data[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT]?.child_01
          // let mergeSingleArr = [...res?.camp, ...res?.personal, ...res?.tutor]
          // setChildCalenderData(mergeSingleArr)

          const flattenedArray = Response?.data?.result?.data?.flatMap(
            (child: any) => {
              const childKey =
                Object?.keys(child)[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT];
              const childData = child[childKey];

              return [
                ...childData?.tutor?.map((tutor: any) => ({
                  ...tutor,
                  child: childKey,
                  type: "tutor",
                })),
                ...childData?.personal?.map((personal: any) => ({
                  ...personal,
                  child: childKey,
                  type: "personal",
                })),
                ...childData?.camp?.map((camp: any) => ({
                  ...camp,
                  child: childKey,
                  type: "camp",
                })),
              ];
            }
          );

          const currentDateTime = new Date();
          var findDate = flattenedArray?.filter(
            (item: any) => new Date(item?.start) > currentDateTime
          );
          var sorting = findDate?.sort((a: any, b: any) => {
            try {
              const dateA = new Date(a.start);
              const dateB = new Date(b.start);
              return dateA.getTime() - dateB.getTime(); // Ensure date values are used in arithmetic operations
            } catch (error) {
              return 0; // Return 0 if there's an error to avoid breaking the sorting
            }
          });
          // Output the flattened array
          if (flattenedArray?.length > 0) {
            setChildCalenderData(flattenedArray);
            /**Calendar Data binding */
            const eventsData = flattenedArray?.map((data: any) => {
              return {
                start: new Date(data?.start),
                end: new Date(data?.end),
                id: data?.id,
                display: "background",
                // color: '#178FFF',
                // backgroundColor: "#178FFF",
                title: data.title?.split("_")[0],
                // start_time: data?.start_time,
                // end_time: data?.end_time,
              };
            });
            setEvents(eventsData);
          } else {
            setPassClickData([]);
            setChildCalenderData([]);
          }
        }
      })
      .catch(() => { });
  };

  const dayCellClassName = (info: any) => {
    let paymentcheck = events?.find(
      (values: any) =>
        moment(values.start).format("YYYY-MM-DD") ===
        moment(info.date).format("YYYY-MM-DD")
    );
    let selecedcheck = moment(DynamicDate).format("YYYY-MM-DD") === moment(info.date).format("YYYY-MM-DD")
    if (selecedcheck) {
      return ["custom-background_selected_mycalendar"];
    } else if (paymentcheck) {
      return ["custom-background_child_calender"];
    }
    return ["disabled-date"];
  };

  const dayCellContent = (arg: any) => {
    // Check if the current day belongs to the custom date's column
    // if (arg?.date?.getDate() == DynamicDate?.getDate()) {
    //   return (
    //     <div className="dayCellContent__selected">{arg.dayNumberText}</div>
    //   );
    // }

    return (
      <div className="dayCellContent__Unselected">{arg.dayNumberText}</div>
    );
  };

  const dayHeaderContent = (arg: any) => {
    return (
      <div translate="no" className='child-custom-day-header'>
        {arg.text}
      </div>
    );
  };

  const handleMeetOpen = (data: any) => {
    setMeetData(data);
    setMeetOpen(true);
  }

  const handleOpenNewTab = (data: any) => {
    var payload = {
      session_slot_id: data?.session_slot,
      session_time_slot_id: data?.id,
      is_attendance: true
    };

    axiosInstance
      .put(`${API_END_POINTS.TUTOR_ATTENDANCE}`, payload)
      .then((Response: any) => {
        if (Response?.data?.status) {
          // Open a new tab/window
          window.open(data?.meet_link, "_blank");
        }
      })
      .catch(() => { });
  };

  const checkEnableTime = (timing: any) => {
    const currentTime = new Date();
    const startTime = new Date(timing);

    // Calculate the timestamp for 15 minutes before the start time
    const fiveMinutesBeforeStartTime = new Date(startTime.getTime() - 15 * 60 * 1000);

    // Check if the current time is less than 15 minutes before the start time
    if (currentTime >= fiveMinutesBeforeStartTime) {
      return true;
    } else {
      return false;
    }
  };

  const formatTime = (time: any) => {
    return time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const handleSelectAllow = (arg: any) => {
    let SelectAllow = events?.find(
      (values: any) =>
        moment(values.start).format("YYYY-MM-DD") ===
        moment(arg.start).format("YYYY-MM-DD")
    );
    if (SelectAllow) {
      return SelectAllow?.start;
    }
  };

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const ChildCalenderContainerLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "childCalender_container_eng" : "childCalender_container_arab";
  const SlotWapperLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "slot_wapper_eng" : "slot_wapper_arab";

  return (
    <>
      <div className={`${ChildCalenderContainerLang} childCalender__container`}>
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 my-claender-view">
            <div className={`${SlotWapperLang} slot_wapper`}>
              <div className='slot__item'>
                <div className="today_slot"></div>
                <span>{t("Current Day")}</span>
              </div>
              <div className='slot__item'>
                <div className="selected_slot"></div>
                <span>{t("Selected Day")}</span>
              </div>
              <div className='slot__item'>
                <div className="avilableSlot"></div>
                <span>{t("Available Bookings")}</span>
              </div>
              <div className='slot__item'>
                <div className="notAvilableSlot"></div>
                <span>{t("No Bookings")}</span>
              </div>
            </div>
            <div translate='no'>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                // validRange={{
                //   start: new Date(),
                // }}
                viewClassNames="ChildFulCalender"
                events={events}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                select={handleDateSelect}
                dayCellClassNames={dayCellClassName}
                dayCellContent={dayCellContent}
                firstDay={1}
                // unselectAuto={false}
                // displayEventTime={true}
                selectAllow={handleSelectAllow}
                dayHeaderContent={dayHeaderContent}
                selectable={true}
                slotEventOverlap={false}
                dayCellDidMount={(info: any) => {
                  // Ensure events are visible after initial load
                  info?.dayEl?.querySelectorAll('.fc-event')?.forEach((eventEl: any) => {
                    eventEl.style.visibility = 'block';
                  });
                }}
                // showNonCurrentDates={false}
                fixedWeekCount={true}
                dayHeaderFormat={{ weekday: "short" }}
                headerToolbar={{
                  left: "title,prev,next",
                  center: "",
                  right: "timeGridDay,timeGridWeek,dayGridMonth,",
                }}
              />
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 childCalender__rightSide">
            <div className="slot__select__dropdown_wapper">
              <Box sx={{ minWidth: 120 }}>
                <FormControl>
                  <Select
                    labelId="custom-select-label"
                    id="custom-select"
                    className="child_select__dropdown"
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    <MenuItem value={"all"} onClick={() => getParentView("", "")}>{t("show only booked slot")}</MenuItem>
                    {parentProfileData?.map(
                      (parentProfileElements: any, i: any) => {
                        return (
                          <MenuItem key={i} value={parentProfileElements?.user} onClick={() => getParentView("?parent_id=", parentProfileElements?.user)}>
                            <div className="avatar_name_wrapper">
                              <div className="avatar_name_active_container">
                                <img
                                  src={
                                    nullUndefinedEmptyCheck(
                                      parentProfileElements.profile_image
                                    )
                                      ? parentProfileElements.profile_image
                                      : defaultProfile
                                  }
                                  onError={(e: any) => { e.target.src = defaultProfile; }}
                                  alt="Learn and Play"
                                  className="avatar_pic"
                                />
                                <div className="profile_name_role">
                                  <div className="profile_name">
                                    {parentProfileElements?.name}
                                  </div>
                                  <div className="role">{t("Parent")}</div>
                                </div>
                              </div>
                            </div>
                          </MenuItem>
                        );
                      }
                    )}
                    {childProfileData?.map(
                      (childProfileElements: any, i: any) => {
                        return (
                          <MenuItem key={i} value={childProfileElements?.child} onClick={() => getParentView("?child_id=", childProfileElements?.child)}>
                            <div className="avatar_name_wrapper">
                              <div className="avatar_name_active_container">
                                <img
                                  src={
                                    nullUndefinedEmptyCheck(
                                      childProfileElements.profile_image
                                    )
                                      ? childProfileElements.profile_image
                                      : defaultProfile
                                  }
                                  alt="Learn and Play" onError={(e: any) => { e.target.src = defaultProfile; }}
                                  className="avatar_pic"
                                />
                                <div className="profile_name_role">
                                  <div className="profile_name">
                                    {childProfileElements?.name}
                                  </div>
                                  <div className="role">{t("Kid")}</div>
                                </div>
                              </div>
                            </div>
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="slot__detailes__container">
              <div className="title">
                {moment(DynamicDate).format("dddd Do MMMM, YYYY")}
              </div>

              <div className="detailes__wapper">
                {PassClickData.map((item: any, i: any) => {
                  return (
                    <>
                      {" "}
                      {item.teach_service_provider_id === 1 ? (
                        <div className="Tutor__container" key={i}>
                          <div className="img__wapper">
                            <img src={TutorImg} alt="" />
                          </div>
                          <div className="right_container">
                            <div className="name__detailes">
                              <div className="name">
                                {item.title?.split("_")[0]}
                              </div>
                              {/* <div className='name'>({item?.remaining_slots_count}/{item?.total_slots_count})</div> */}
                              {item?.is_online && (
                                <>
                                  {item?.is_meet_link ? (
                                    <div
                                      className="video_call__wapeer"
                                      onClick={() => handleMeetOpen(item)}
                                    >
                                      <div className="badgeIcon badeg__pos"></div>
                                      <img src={video_call_icon} alt="" />
                                    </div>
                                  ) : (
                                    <div className="video_call__wapeer disable_style">
                                      <div className="badgeIcon badeg__pos"></div>
                                      <img src={video_call_icon} alt="" />
                                    </div>
                                  )}
                                </>)}
                            </div>
                            <div className="name__detailes">
                              <div className="createdBy">
                                {item?.service_provider_name}
                              </div>
                              <div className="createdBy">
                                {formatTime(new Date(item?.start))} to{" "}
                                {formatTime(new Date(item?.end))}
                              </div>
                            </div>

                            <div className="name__detailes">
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    nullUndefinedEmptyCheck(item?.profile_image)
                                      ? item?.profile_image
                                      : tutor_img
                                  } onError={(e: any) => { e.target.src = tutor_img; }}
                                  className="child_profile_img"
                                  alt=""
                                />
                                <div className="createdBy ms-2">
                                  {item?.username}
                                </div>
                              </div>
                              <div className="createdBy">
                                {item?.course_mode == 'Offline' ? t("On-premises") : item?.course_mode}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : item.teach_service_provider_id === 2 ? (
                        <div className="Activitie__container">
                          <div className="img__wapper">
                            <img src={ActivitiesTriner} alt="" />
                          </div>
                          <div className="right_container">
                            <div className="name__detailes">
                              <div className="name">
                                {item.title?.split("_")[0]}
                              </div>
                              {/* <div className='name'>({item?.remaining_slots_count}/{item?.total_slots_count})</div> */}
                              {/* {item?.is_online && (
                                <>
                                  {checkEnableTime(
                                    item?.start,
                                  ) ? (
                                    <div
                                      className="video_call__wapeer"
                                      onClick={() => handleMeetOpen(item)}
                                    >
                                      <div className="badgeIcon badeg__pos"></div>
                                      <img src={video_call_icon} alt="" />
                                    </div>
                                  ) : (
                                    <div className="video_call__wapeer disable_style">
                                      <div className="badgeIcon badeg__pos"></div>
                                      <img src={video_call_icon} alt="" />
                                    </div>
                                  )}
                                </>
                              )} */}
                            </div>
                            <div className="name__detailes">
                              <div className="createdBy">
                                {item?.service_provider_name}
                              </div>
                              <div className="createdBy">
                                {formatTime(new Date(item?.start))} {t("to")}{" "}
                                {formatTime(new Date(item?.end))}
                              </div>
                            </div>

                            <div className="name__detailes">
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    nullUndefinedEmptyCheck(item?.profile_image)
                                      ? item?.profile_image
                                      : tutor_img
                                  } onError={(e: any) => { e.target.src = tutor_img; }}
                                  className="child_profile_img"
                                  alt=""
                                />
                                <div className="createdBy ms-2">
                                  {item?.username}
                                </div>
                              </div>
                              <div className="createdBy">
                                {item?.course_mode == 'Offline' ? t("On-premises") : item?.course_mode}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : item.teach_service_provider_id === 3 ? (
                        <>
                          <div className="Activitie__container">
                            <div className="img__wapper">
                              <img src={ActivitiesCamp} alt="" />
                            </div>
                            <div className="right_container">
                              <div className="name__detailes">
                                <div className="name">
                                  {item.title?.split("_")[0]}
                                </div>
                                {/* <div className='name'>({item?.remaining_slots_count}/{item?.total_slots_count})</div> */}
                                {/* {item?.is_online && (
                                  <>
                                    {checkEnableTime(
                                      item?.start,
                                    ) ? (
                                      <div
                                        className="video_call__wapeer"
                                        onClick={() => handleMeetOpen(item)}
                                      >
                                        <div className="badgeIcon badeg__pos"></div>
                                        <img src={video_call_icon} alt="" />
                                      </div>
                                    ) : (
                                      <div className="video_call__wapeer disable_style">
                                        <div className="badgeIcon badeg__pos"></div>
                                        <img src={video_call_icon} alt="" />
                                      </div>
                                    )}
                                  </>
                                )} */}
                              </div>

                              <div className="name__detailes">
                                <div className="createdBy">
                                  {item?.service_provider_name}
                                </div>
                                <div className="createdBy">
                                  {formatTime(new Date(item?.start))} to{" "}
                                  {formatTime(new Date(item?.end))}
                                </div>
                              </div>

                              <div className="name__detailes">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      nullUndefinedEmptyCheck(
                                        item?.profile_image
                                      )
                                        ? item?.profile_image
                                        : tutor_img
                                    } onError={(e: any) => { e.target.src = tutor_img; }}
                                    className="child_profile_img"
                                    alt=""
                                  />
                                  <div className="createdBy ms-2">
                                    {item?.username}
                                  </div>
                                </div>
                                <div className="createdBy">
                                  {item?.course_mode == 'Offline' ? t("On-premises") : item?.course_mode}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : item.teach_service_provider_id === 4 ? (
                        <></>
                      ) : item.teach_service_provider_id === 5 ? (
                        <>
                          <div className="University__container">
                            <div className="img__wapper">
                              <img src={universityEducation} alt="" />
                            </div>
                            <div className="right_container">
                              <div className="name__detailes">
                                <div className="name">
                                  {item.title?.split("_")[0]}
                                </div>
                                <div className="name">
                                  ({item?.remaining_slots_count}/
                                  {item?.total_slots_count})
                                </div>
                              </div>

                              <div className="createdBy">
                                {item?.service_provider_name}
                              </div>

                              <div className="name__detailes">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={tutor_img}
                                    className="child_profile_img"
                                    alt=""
                                  />
                                  <div className="createdBy ms-2">
                                    {item?.username}
                                  </div>
                                </div>
                                <div className="createdBy">{t("Online")}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {MeetOpen && (
        <div className="overlay-ModelBackround">
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={MeetOpen}
            className="FeedBack__dailog__box"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              <div className="DialogTitle__wapper">
                <div className="title">{t("Attend Online class")}</div>
                <div className="closeBtn" onClick={handleClose}>{t("Close")}</div>
              </div>
            </DialogTitle>
            <DialogContent dividers>
              <div className="DialogContent___wapper">
                <div className="tutor__contant mt-2 mb-3">
                  {t("Hi")} <b>{MeetData?.username}</b>
                </div>
                <div className="tutor__contant  mb-3">
                  {t("Do you want to attend the online class!")}
                </div>
                <div className="rating__container">
                  <button className="submitBtn" onClick={() => handleOpenNewTab(MeetData)}>{t("Sure")}</button>
                </div>
              </div>
            </DialogContent>
          </BootstrapDialog>
        </div>
      )}
    </>
  );
};

export default ChildCalender;
