import { Dialog, DialogContent } from "@mui/material";
import "./PersonalDetailsDialog.scss";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
} from "../../Constants/Constants";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import es from 'react-phone-input-2/lang/es.json';
import ar from 'react-phone-input-2/lang/ar.json';
import { useEffect, useRef, useState } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { toast } from "react-hot-toast";
import { format } from "date-fns";
import moment from "moment";
import {
  SetSessionStorageData,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PersonalDetailsDialog: any = (PersonalDetailsDialogProps: any) => {
  const { t } = useTranslation();
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /**Initial states */
  const [validNumber, setValidNumber] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
  );
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberTouched, setMobileNumberTouched] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const personalDetailsRef = useRef<any>();

  /**Personal details form initial values */
  const personalDetailsValue = () => {
    return {
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME]: "",
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME]: "",
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL]: "",
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE]: "",
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]: "",
    };
  };

  /**Google SingUp details form initial values */
  const googleSingUpDetailsValue = () => {
    return {
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME]: PersonalDetailsDialogProps?.googlepassData?.given_name ? PersonalDetailsDialogProps?.googlepassData?.given_name : "",
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME]: PersonalDetailsDialogProps?.googlepassData?.family_name ? PersonalDetailsDialogProps?.googlepassData?.family_name : "",
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL]: PersonalDetailsDialogProps?.googlepassData?.email ? PersonalDetailsDialogProps?.googlepassData?.email : "",
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE]: "",
      [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]: "",
    };
  };

  const [personalDetailsInitialValue, setPersonalDetailsInitialValue] =
    useState(personalDetailsValue());

  useEffect(() => {
    if (PersonalDetailsDialogProps.isEmail) {
      setPersonalDetailsInitialValue({
        ...personalDetailsInitialValue,
        ...{
          [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL]:
            PersonalDetailsDialogProps.userName,
        },
        ...{
          [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE]: "",
        },
      });
      setMobileNumber("");
    } else if (PersonalDetailsDialogProps?.googlepassData) {
      setPersonalDetailsInitialValue(googleSingUpDetailsValue());
      setMobileNumber("");
    }
    else {
      setPersonalDetailsInitialValue({
        ...personalDetailsInitialValue,
        ...{
          [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE]:
            PersonalDetailsDialogProps.userName,
        },
        ...{
          [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL]: "",
        },
      });
      setMobileNumber(PersonalDetailsDialogProps.userName);
    }
  }, [PersonalDetailsDialogProps.isEmail, PersonalDetailsDialogProps.userName, PersonalDetailsDialogProps?.googlepassData]);

  /**Personal details validation schema */
  const personalDetailsValidationSchema: any = yup.object({
    [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME]: yup
      .string()
      .trim(AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.SPACE)
      .strict(true)
      .required(
        AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES
          .FIRST_NAME_REQUIRED
      ),
    [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME]: yup
      .string()
      .trim(AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.SPACE)
      .strict(true)
      .required(
        AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.LAST_NAME_REQUIRED
      ),
    // [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL]: yup
    //   .string()
    //   .required(
    //     AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.EMAIL_REQUIRED
    //   )
    //   .matches(
    //     GLOBAL_CONSTANTS.REGEX.EMAIL,
    //     AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.INVALID_EMAIL
    //   )
    //   .trim(),
    [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE]: yup
      .string()
      .required(
        AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.PHONE_NUMBER
      )
      .test('isValidPhoneNumber', 'Invalid phone number', (value) =>
        isValidPhoneNumber(value || '')
      ),
    [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]: yup
      .string()
      .required(
        AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES
          .DATE_OF_BIRTH_REQUIRED
      ),
  });

  /**Go to login */
  const goToLogin = () => {
    PersonalDetailsDialogProps.handlePersonalDetailsDialog();
    PersonalDetailsDialogProps.setIsLogin(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
    PersonalDetailsDialogProps.setOpenEmailPhoneOtpLoginSignupDialog(
      GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    );
  };

  /**Personal details payload */
  const personalDetailsPayload = (values: any) => {
    return {
      firstname: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME],
      lastname: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME],
      email: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL],
      phone_number: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE],
      roles: AUTHENTICATION.SIGNUP_SIGNIN_API_PAYLOADS.ROLES,
      dob: moment(
        values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]
      ).format(AUTHENTICATION.DATE_FORMAT.DATE_OF_BIRTH),
      is_social: PersonalDetailsDialogProps.isGoogle ? true : false,
      social_id: PersonalDetailsDialogProps.isGoogle ? PersonalDetailsDialogProps?.googlepassData?.sub : "",
      is_email: PersonalDetailsDialogProps.isEmail,
      is_phone: !PersonalDetailsDialogProps.isEmail,
    };
  };

  /**Submit personal details */
  const handleSubmitPersonalDetails = (values: any, actions: any) => {
    axiosInstance
      .post(
        `${API_END_POINTS.PERSONAL_DETAILS}`,
        personalDetailsPayload(values)
      )
      .then((phoneSignUpResponse: any) => {
        if (
          phoneSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          SetSessionStorageData(
            AUTHENTICATION.SHARED.ACCESS_TOKEN,
            phoneSignUpResponse.data.result.data.access_token
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.ACCOUNT_TYPE,
            AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.REFRESH_TOKEN,
            phoneSignUpResponse.data.result.data.refresh_token
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.NAME,
            `${values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME]}` +
            " " +
            `${values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME]}`
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.EMAIL,
            phoneSignUpResponse.data.result.data.user_info.email
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.MOBILE,
            phoneSignUpResponse.data.result.data.user_info.phone_number
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.PARENT_DOB,
            phoneSignUpResponse.data.result.data.user_info.dob
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.USER_ID,
            phoneSignUpResponse.data.result.data.user_info.id
          );
          if (
            nullUndefinedEmptyCheck(
              phoneSignUpResponse.data.result.data.user_info.image
            )
          ) {
            SetSessionStorageData(
              AUTHENTICATION.SHARED.PROFILE_PICTURE,
              phoneSignUpResponse.data.result.data.user_info.image
            );
          }
          PersonalDetailsDialogProps.setOpenAvatarPickerDialog(
            GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
          );
          PersonalDetailsDialogProps.handlePersonalDetailsDialog();
          toast.success(phoneSignUpResponse.data.message);
        }
      })
      .catch(() => { });
  };

  return (
    <>
      {" "}
      <Dialog
        className={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "personal_details_dialog_wrapper" : "personal_details_dialog_wrapper arab_input"}
        dir={
          language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
            ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
            : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
        }
        open={PersonalDetailsDialogProps.openPersonalDetailsDialog}
      >
        <DialogContent >
          <div className="background_color_wrapper_left"></div>
          <div className="background_color_wrapper_right"></div>
          <div className="personal_details_title_wrapper">
            <div className="personal_details_title">{t("Sign up")}</div>
            <div className="personal_details_description">
              {t("signUpDesc")}
            </div>
          </div>
          <Formik
            initialValues={personalDetailsInitialValue}
            validationSchema={personalDetailsValidationSchema}
            onSubmit={(values, actions) => {
              if (validNumber) {
                handleSubmitPersonalDetails(values, actions)
              } else {
                setMobileNumberTouched(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
              }
            }}
            enableReinitialize={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
            innerRef={personalDetailsRef}
          >
            {(emailFormikProps) => {
              const handleChange = (value: string) => {
                const phoneNumber = `+${value}`;
                emailFormikProps.setFieldValue('mobile', phoneNumber);
                /* Mark field as touched on change */
                emailFormikProps.setFieldTouched('mobile', true);
                /* Validate field on change */
                emailFormikProps.validateField('mobile');
              };
      
              const handleBlur = () => {
                emailFormikProps.setFieldTouched('mobile', true);
              };
              return (
                <Form className="email_login_form_wrapper">
                  <div className="fields_row_container">
                    <div className="label_field_error_messages_container">
                      <label className="field_label">{t("First name")}</label>
                      <Field
                        placeholder={t("Enter your first name")}
                        name={AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME}
                      />
                      {emailFormikProps.touched[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME
                      ] &&
                        emailFormikProps.errors[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME
                        ] ? (
                        <div className="errorMessage">
                          {
                            t(`${emailFormikProps.errors[
                              AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME
                            ]}`)
                          }
                        </div>
                      ) : null}
                    </div>
                    <div className="label_field_error_messages_container">
                      <label className="field_label">{t("Last name")}</label>
                      <Field
                        placeholder={t("Enter your last name")}
                        name={AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME}
                      />
                      {emailFormikProps.touched[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME
                      ] &&
                        emailFormikProps.errors[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME
                        ] ? (
                        <div className="errorMessage">
                          {
                            t(`${emailFormikProps.errors[
                              AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME
                            ]}`)
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_row_container">
                    <div className="label_field_error_messages_container">
                      <label className="field_label">{t("Email")}</label>
                      <Field
                        disabled={PersonalDetailsDialogProps.isEmail || PersonalDetailsDialogProps.isGoogle}
                        placeholder={t("Enter your Email")}
                        name={AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL}
                      />
                      {/* {emailFormikProps.touched[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL
                      ] &&
                        emailFormikProps.errors[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL
                        ] ? (
                        <div className="errorMessage">
                          {
                            emailFormikProps.errors[
                            AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL
                            ]
                          }
                        </div>
                      ) : null} */}
                    </div>
                    <div className="label_field_error_messages_container phone_input_wrapper">
                      <label className="field_label">{t("Phone number")}</label>
                      <PhoneInput
                        localization={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? es : ar}
                        disabled={!PersonalDetailsDialogProps.isEmail && !PersonalDetailsDialogProps.isGoogle}
                        value={mobileNumber}
                        inputProps={{
                          className: `${language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "login-phone-input" : "login-phone-input-ar"}`,
                          name: `${AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE}`,
                        }}
                        country={"ae"}
                        countryCodeEditable={
                          GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
                        }
                        enableSearch={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                        placeholder={t("Enter your Phone number")}
                        onBlur={() => {
                          handleBlur();
                          setMobileNumberTouched(
                            GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
                          );
                        }}
                        onChange={(e:any) => {
                          handleChange(e);
                          emailFormikProps.setFieldValue(
                            AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE,
                            `+${e}`
                          );
                          setTimeout(() => {
                            emailFormikProps.validateField(
                              AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                            );
                            setValidNumber(isValidPhoneNumber(`+${e}`));
                          }, GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);
                        }}
                      />
                      {validNumber === GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE &&
                        emailFormikProps.touched[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                        ] &&
                        emailFormikProps.values[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                        ] !== "" ? (
                        <div className="errorMessage">
                          {
                            AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES
                              .INVALID_PHONE_NUMBER
                          }
                        </div>
                      ) : null}
                      {mobileNumberTouched &&
                        validNumber === GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE ? (
                        <div className="errorMessage">
                          {
                            emailFormikProps.errors[
                            AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                            ]
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_row_container">
                    <div className="label_date_picker_field_error_messages_container">
                      <label className="field_label">{t("Date of birth")}</label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className=""
                          disableFuture
                          format="dd-MM-yyyy"
                          value={
                            new Date(
                              emailFormikProps.values[
                              AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                              ]
                            )
                          }
                          onChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : "";
                            emailFormikProps.setFieldValue(
                              AUTHENTICATION.PERSONAL_DETAILS_FIELDS
                                .DATE_OF_BIRTH,
                              new Date(formattedDate)
                            );
                          }}
                        />
                      </LocalizationProvider>
                      {emailFormikProps.touched[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                      ] &&
                        emailFormikProps.errors[
                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                        ] ? (
                        <div className="errorMessage">
                          {
                            t(`${emailFormikProps.errors[
                              AUTHENTICATION.PERSONAL_DETAILS_FIELDS
                                .DATE_OF_BIRTH
                            ]}`)
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="signup-account-text-wrap">
                    <button type="submit" className="sign_up_button">
                      {t("Sign up")}
                    </button>
                    <div className="login_navigation">
                      {t("Already have an account?")}{" "}
                      <span
                        className="login_navigation_button"
                        onClick={goToLogin}
                      >
                        {t("login")}
                      </span>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog >
    </>
  );
};

export default PersonalDetailsDialog;
