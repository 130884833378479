/**Assets import */
import carouselFirst from "../Assets/Authentication/carouselFirst.png";
import carouselSecond from "../Assets/Authentication/carouselSecond.png";
import carouselThird from "../Assets/Authentication/carouselThird.png";
import carouselFourth from "../Assets/Authentication/carouselFourth.png";
import tickets from "../Assets/Accounts/SideNav/tickets.svg";
import axios from "axios";
import aboutus from "../Assets/ProfileSideBar/aboutus.svg";
import calenderActive from "../Assets/ProfileSideBar/calenderActive.svg";
import favourties from "../Assets/ProfileSideBar/favourties.svg";
import legalsecurity from "../Assets/ProfileSideBar/legal&security.svg";
import mysession from "../Assets/ProfileSideBar/mysession.svg";
import mywallet from "../Assets/ProfileSideBar/mywallet.svg";
import paymenthistory from "../Assets/ProfileSideBar/paymenthistory.svg";
import supports from "../Assets/ProfileSideBar/supports.svg";
import themPark from "../Assets/ProfileSideBar/themPark.svg";
import notification from "../Assets/ProfileSideBar/notification.svg";
import calender from "../Assets/ProfileSideBar/calendar.svg";
import aboutsActive from "../Assets/ProfileSideBar/aboutsActive.svg";
import favoutiesActive from "../Assets/ProfileSideBar/favoutiesActive.svg";
import legalsecurityActive from "../Assets/ProfileSideBar/legal&securityActive.svg";
import mysessionActive from "../Assets/ProfileSideBar/mysessionActive.svg";
import mywalletActive from "../Assets/ProfileSideBar/mywalletActive.svg";
import notificationActive from "../Assets/ProfileSideBar/notificationActive.svg";
import paymentHistoryActive from "../Assets/ProfileSideBar/paymentHistoryActive.svg";
import supportActive from "../Assets/ProfileSideBar/supportActive.svg";
import themeparkActive from "../Assets/ProfileSideBar/themeparkActive.svg";
import tutorHubActive from "../Assets/ProfileSideBar/tutorHubActive.svg";
import tutor_Hub from "../Assets/ProfileSideBar/tutor_Hub.svg";
import TutorHubChat from "../Assets/TutorHub/TutorChatActive.svg";
import TutorHubDocument from "../Assets/TutorHub/TutorDocumentActive.svg";
import TutorHubSession from "../Assets/TutorHub/TutorSessionActive.svg";
import TutorFeedback from "../Assets/TutorHub/TutorFeedback.svg";
import TutorDocumentInActive from "../Assets/TutorHub/TutorDocumentInActive.svg";
import TutorChatInActive from "../Assets/TutorHub/TutorChatInActive.svg";
import TutorFeedbackInActive from "../Assets/TutorHub/TutorFeedbackInActive.svg";
import TutorSessionInActive from "../Assets/TutorHub/TutorSessionInActive.svg";
import PreferenceActive from "../Assets/ProfileSideBar/preferenceActive.svg";
import PreferenceInActive from "../Assets/ProfileSideBar/preference.svg";
import { setChildLoginRestriction, setGuestSignupOpen, setLocation, setLocationName, setLoginSignUpOpen } from "../Redux/Reducer/Reducer";
import { useSelector } from "react-redux";
import { GetSessionStorageData } from "../Utilities/Helpers/Helper";




export const ROUTES: any = {
  /**Routes */
  HOMEPAGE: "/home",
  THEMEPARK__HOMEPAGE: "/themePark-home",
  /**Profile setting path */
  ACCOUNTS: "/accounts",
  ADD_PROFILE: "/accounts/add-profile",
  EDIT_PROFILE: "/accounts/edit-profile",
  CHILDCALENDER: "/accounts/child-calender",
  FAVOURTIES: "/accounts/favourties",
  MYSESSION: "/accounts/my-orders",
  NOTIFICATION: "/accounts/notification",
  LEGALANDSECURITY: "/accounts/legal&security",
  ABOUTUS: "/accounts/about-us",
  PREFERENCESCREEN: "/accounts/preference",
  ABOUTUS_WITHOUT_LOGIN: "/about-us",
  SUPPORT: "/accounts/support",
  PAYMENTHISTORY: "/accounts/payment-history",
  MYWALLET: "/accounts/my-wallet",
  TUTOR_HUB: "/accounts/my-sessions",
  TUTOR_HUB_DETAILES: "/accounts/my-sessions/list",
  MYTICKETS: "/accounts/my-tickets",
  MYTICKETS_DETAIL: "/accounts/my-tickets/:id",
  ADMIN_CHAT: "/accounts/support/chat",
  /**Tutors path */
  TUTORS: "/tutors",
  TUTORS_MAPS_VIEW: "/tutors/map-view",
  TUTORS_DETAILE_VIEW: "/tutors/detaile-view",
  TUTORS_LIST_VIEW: "/tutors/list-view",
  TUTORS_CALENDAR_VIEW: "/tutors/calendar-view",
  TUTORS_CHECKOUT: "/tutors/checkout",
  TUTORS_PAYMENT_SUCCESS: "/tutors/payment-successs",
  /**Activities path */
  MAP_VIEW: "/map-view",
  ACTIVITYLAYOUT: "/activites",
  ACTIVITY_MAPS_VIEW: "/activites/map-view",
  ACTIVITY_DETAILE_VIEW: "/activites/detaile-view",
  ACTIVITY_LIST_VIEW: "/activites/list-view",
  ACTIVITY_CALENDAR_VIEW: "/activites/calendar-view",
  ACTIVITY_CHECKOUT: "/activites/checkout",
  ACTIVITY_PAYMENT_SUCCESS: "/activites/payment-successs",
  /** University path */
  UNIVERSITYLAYOUT: "/university",
  UNIVERSITY_MAPS_VIEW: "/university/map-view",
  UNIVERSITY_DETAILE_VIEW: "/university/detaile-view",
  UNIVERSITY_LIST_VIEW: "/university/list-view",
  UNIVERSITY_CHAT_VIEW: "/university/chat-view",
  UNIVERSITY_CHECKOUT: "/university/checkout",
  UNIVERSITY_PAYMENT_SUCCESS: "/university/payment-successs",
  /** University path */
  SCHOOLLAYOUT: "/school",
  SCHOOL_MAPS_VIEW: "/school/map-view",
  SCHOOL_DETAILE_VIEW: "/school/detaile-view",
  SCHOOL_LIST_VIEW: "/school/list-view",
  SCHOOL_CHAT_VIEW: "/school/chat-view",
  /** Theme Park path */
  THEMEPARK_LAYOUT: "/themePark",
  THEMEPARK_LIST_VIEW: "/themePark/list-view",
  THEMEPARK_DETAILE_VIEW: "/themePark/detaile-view",
  THEMEPARK_CHECKOUT: "/themePark/checkout",
  THEMEPARK_PAYMENT_SUCCESS: "/themePark/payment-successs",
  // THEMEPARK__HOMEPAGE_LISTVIEW: "/themePark-home/list-view",
};

/**API end points */
export const API_END_POINTS: any = {
  TEMP_OTP: "/api/user/tempotp/",
  S3_DATA_KEY: "api/user/s3credentials/",
  S3_STATIC_UPLOAD: "/api/user/staticupload/",
  OTP_VERIFICATION: "/api/user/verifytempotp/",
  PERSONAL_DETAILS: "/api/user/register/",
  PROFILE_PICTURE_UPDATE: "/api/user/parent/",
  TEMP_OTP_LOGIN: "/api/user/loginotp/",
  OTP_VERIFICATION_LOGIN: "/api/user/verifyloginotp/",
  AVATAR_IMAGES: "/api/account/avatarimage/",
  EDIT_PROFILE: "/api/user/profile/",
  CHILD_EDIT_PROFILE: "/api/user/child_profile/",
  ADD_PROFILE: "/api/user/parent_child_mapping/",
  NOTIFICATION_LIST: "api/user/notification_list/",
  NOTIFICATION_SEEN: "api/user/notification/?id=",
  GOOGLE_LOGIN: "api/user/verifyloginotp/?login_type=2",
  GOOGLE_SINGUP: "api/user/register/",
  PROFILE_LIST: "/api/user/parentchildlist/",
  PROFILE_SWITCH: "/api/user/account_switch/",
  HOMEPAGE: "/api/user/webuserhomepage/",
  USER_WALLET: "/api/user/user_wallet/",
  USER_PAYMENT_HISTORY: "/api/user/user_payment_history/",
  CHAT_HISTORY: "/api/user/chat_history/?room_id=",
  UNIVERSITY_CHAT_HISTORY: "/api/user/university_chat_histroy/?room_id=",
  CHAT_ROOM: "/api/user/chat_room/",
  SESSION_CHECK: "/api/user/sessions_booking_check/",
  SUPPORT_CHAT: "/api/user/admin_user_chat/",
  SOCIAL_SIGNUP_VALIDATE: "api/user/social_signup_validate/?social_id=",
  VALIDATE_DISCOUNT: "api/user/validate_discount/?discount_id=",
  PREFERENCE_LIST: "api/user/preference_list/",
  PREFERENCE_SELECTED_LIST: "api/user/user_preference_mapping/",
  CANCEL_REQUEST: "api/user/user_cancel/",
  CHANGE_EMAIL_UPDATE: "api/user/email_update/?login_type=",
  CHANGE_EMAIL_UPDATE_OTP: "api/user/verify_update/?login_type=",
  CAMP_DISCOUNT_LIST: "api/user/user_camp_discount_list/?camp_id=",

  /**Tutor Hub Api's */
  TUTOR_HUB: "/api/user/tutor_hub/?sort=",
  HUB_SESSION_VIEW: "/api/user/hub_session_view/?booking_id=",
  HUB_DOCUMENT_LIST: "/api/user/document_list/?session_id=",
  HUB_FEEDBACK_LIST: "/api/user/feedback_list/?booking_id=",
  ADD_RATING: "/api/user/add_rating/",
  TUTOR_RESCHEDULE: "/api/user/user_reschedule/",
  /**Activity Hub Api's */
  Activity_HUB: "/api/user/recreational_hub/?sort=",
  /**Tutor Api's */
  TUTOR_COURSE_CATEGORIES: "/api/user/tutorcoursecategories/",
  POPULAR_TUTOR_LIST: "/api/user/populartutorlist/",
  TUTOR_KEY_WORD_SEARCH: "/api/user/tutorkeywordsearch/",
  TUTOR_DETAIL: "/api/user/tutor_detail/",
  TUTORLIST: "/api/user/tutorlists/",
  TUTORFILTERLIST: "/api/user/tutorfilterlist/",
  TUTORSESSIONLIST: "/api/user/tutorsessionlist/?tutor_subject_id=",
  PROMOTIONAL_CONTENT_LIST: "/api/user/service_provider_mainpage/",
  TUTOR_BOOKING_VALIDATION: "/api/user/booking_validation/",


  SLOT_BOOKING: "/api/user/slot_booking/",
  CAMPS_BOOKING: "api/user/camp_booking/",
  DISCOUNT_LIST: "api/user/user_discount_list/",
  TUTOR_FREE_SESSION: "api/user/freesession_list/",
  TUTOR_ATTENDANCE: "api/user/attendance/",

  /**Activities Api's */
  ACTIVITIESLIST: "/api/user/activitieslist/",
  POPULAR_ACTIVITIES_LIST: "/api/user/popularactiviteslist/",
  ACTIVITIES_FILTERLIST: "/api/user/activitesfilterlist/",
  ACTIVITIES_KEY_WORD_SEARCH: "/api/user/activitieskeyword/",
  ACTIVITIES_CATEGORIES: "/api/user/activitiescategories/",
  ACTIVITIES_SESSIONLIST_CAMP:
    "/api/user/campsessionlist/?trainer_activity_id=",
  ACTIVITIES_SESSIONLIST_PERSONAL:
    "/api/user/personaltrainersessionlist/?trainer_activity_id=",
  ACTIVITIES_DEATAILES: "/api/user/activity_detail/",
  /**University Api's */
  UNIVERSITYLIST: "/api/user/universitylist/",
  UNIVERSITYFILTERLIST: "/api/user/universityfilterlist/",
  UNIVERSITY_CHAT_CONSULTANT: "api/user/university_expert/",
  UNIVERSITY_KEY_WORD_SEARCH: "api/user/universitykeywordsearch/",
  /**School Api's */
  SCHOOLLIST: "/api/user/schoollist/",
  SCHOOLFILTERLIST: "/api/user/schoolfilterlist/",
  SCHOOL_KEY_WORD_SEARCH: "api/user/schoolkeywordsearch/",
  SCHOOL_CONTACT_NOTIFICATION: "api/user/school_notification/",
  SCHOOL_EXPERT: "api/user/school_expert/",
  /**childe calender view Api's */
  PARENT_VIEW_FOR_ALL_CHILD: "/api/user/bookings_list/?child_id=",
  PARENT_VIEW_FOR_SPECFIC_CHILD: "/api/user/bookings_list/?child_id=",
  MY_CALENDAR_VIEW: "/api/user/bookings_list/",
  FAVOURITE__ADD: "/api/user/add_fav/",
  FAVOURITE__LIST: "/api/user/fav_list/?search=",
  MY_SESSIONS: "/api/user/my_sessions/?sort=",
  HOME_SEARCH_USER: "/api/user/userhomesearch/?search_text=",
  /**Theme Park Api's */
  THEMEPARKLIST: "/api/user/userthemeparklist/",
  THEMEPARK_SEARCH_KEYWORDS: "/api/user/themepark_search/?search=",
  THEMEPARK_DETAILED_LIST: "/api/user/themeparkuser/?id=",
  THEMEPARK_CHECKOUT_LIST: "/api/user/userticket/?event_id=",
  THEMEPARK_HOMEPAGE: "/api/user/themepark_homepage/",
  THEMEPARK_BOOKING: "/api/user/themepark_ticket_booking/",
  THEMEPARK_GUESTUSER: "/api/user/themepark_guestuser/",
  THEMEPARK_GUESTUSER_VERIFY: "/api/user/themepark_guestuser_verify/",

  /* My tickets */
  MYTICKETS_LIST: "/api/user/themepark_ticket_booking_list/"
};

/**global constants */
export const GLOBAL_CONSTANTS: any = {
  LANGUAGES: {
    ENGLISH: "English",
    ARABIC: "Arabic",
    ENGLISH_ABBRIVATION: "en",
    ARABIC_ABBRIVATION: "ar",
    LANGUAGE: "language",
  },
  LAYOUT_DIRECTION: {
    LTR: "ltr",
    RTL: "rtl",
  },
  REGEX: {
    EMAIL: new RegExp(
      "^[_a-z0-9-]+(\\.[_a-z0-9-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,4})$"
    ),
  },
  INITIAL_VALUES: {
    LEFT: "Left",
    RIGHT: "Right",
    ENTER: "Enter",
    TOAST_POSITION: "top-right",
    DATE_FORMAT: "YYYY-MM-DD",
    ZERO_INT: 0,
    ONE_INT: 1,
    TRUE: true,
    FALSE: false,
    TWO_INT: 2,
    THREE_INT: 3,
    FOUR_INT: 4,
    FIVE_INT: 5,
    SIX_INT: 6,
    FIFTY_INT: 50,
    STRING_ALL: "all",
    NEAR_ME: "near me",
    UP_ARROW: "ArrowUp",
    DOWN_ARROW: "ArrowDown",
    AND_SEARCH: "&search=",
    AND_ISTODAY: "&is_today=",
    ONLINE: "online",
    CARD: "card",
    NA: "N/A",
  },
};

/**Authentication pages constants*/
export const AUTHENTICATION: any = {
  SHARED: {
    ACCESS_TOKEN: "accessToken",
    REFRESH_TOKEN: "refreshToken",
    PROFILE_PICTURE: "profilePicture",
    NAME: "name",
    EMAIL: "email",
    MOBILE: "mobile",
    USER_ID: "userId",
    ACCOUNT_TYPE: "accountType",
    STATUS: "status",
    PARENT_DOB: "dob",
    TRUE: true,
    PROFILE_HEADER: "profile_header",
    PROFILE_SETTING: "Profile & Settings",
    PROFILE_ADD: "Add Profile",
    PROFILE_EDIT: "Edit Profile",
    MY_PORTAL: "My Sessions",
    MY_PORTAL_NAME: "MyPortalName",
    GUEST_DATA: 'guest_data',
    GUEST_ACCESS_TOKEN: 'guest_accessToken',
    GUEST_REFRESH_TOKEN: 'guest_refreshToken',
    CURRENT_LOCATION: 'current_location',
    LANGUAGE_SWICTH: 'switch_language',
    COUNTRY_NAME: 'country_name',
    REGION_NAME: 'region_name',
    UNITED_ARAB: 'United Arab Emirates'
  },
  CAROUSEL_CONSTANTS: {
    arrows: false,
    arrowsBlock: false,
    autoplay: true,
    autoplaySpeed: 5000,
    centerPadding: 10,
    duration: 300,
    AUTO_PLAY_INTERVAL: 2000,
  },
  // CAROUSEL_IMAGES: [
  //   <img
  //     src={carouselFirst}
  //     alt="Learn and Play"
  //     className="LoginCarousel__img LoginCarousel__img_bg1"
  //   />,
  //   <img
  //     src={carouselSecond}
  //     alt="Learn and Play"
  //     className="LoginCarousel__img LoginCarousel__img_bg2"
  //   />,
  //   <img
  //     src={carouselThird}
  //     alt="Learn and Play"
  //     className="LoginCarousel__img LoginCarousel__img_bg3"
  //   />,
  //   <img
  //     src={carouselFourth}
  //     alt="Learn and Play"
  //     className="LoginCarousel__img LoginCarousel__img_bg4"
  //   />,
  // ],
  CAROUSEL_IMAGE_SWITCH_ANIMATION_CLASSES: {
    STEP_ONE: "image_one_container",
    STEP_TWO: "image_two_container",
    STEP_THREE: "image_three_container",
    STEP_FOUR: "image_four_container",
  },
  LOGIN_SIGNUP_FIELDS: {
    EMAIL: "email",
    MOBILE: "mobile",
  },
  LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES: {
    EMAIL_REQUIRED: "Please enter the E-Mail ID",
    INVALID_EMAIL: "Please enter a valid E-Mail ID",
    PHONE_NUMBER: "Phone Number is required",
    INVALID_PHONE_NUMBER: "Please enter a valid phone number",
    OTP_REQUIRED: "Please enter the OTP",
  },
  PERSONAL_DETAILS_FIELDS: {
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    EMAIL: "email",
    MOBILE: "mobile",
    DATE_OF_BIRTH: "dataOfBirth",
  },
  PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES: {
    FIRST_NAME_REQUIRED: "Please enter your First Name",
    LAST_NAME_REQUIRED: "Please enter your Last Name",
    EMAIL_REQUIRED: "Please enter the E-Mail ID",
    INVALID_EMAIL: "Please enter a valid E-Mail ID",
    PHONE_NUMBER: "Phone Number is required",
    INVALID_PHONE_NUMBER: "Please enter a valid phone number",
    DATE_OF_BIRTH_REQUIRED: "Please select your Date of Birth",
    SPACE: "Should not allow empty spaces",
  },
  OTP_VERIFICATION_TIMER: {
    TIMER_INITIAL_VALUE: 100,
    OTP_VERIFICATION_TIMEOUT: 1000,
  },
  SIGNUP_SIGNIN_API_PAYLOADS: {
    ROLES: "8",
  },
  DATE_FORMAT: {
    DATE_OF_BIRTH: "YYYY-MM-DD",
  },
  PROFILE_ACCOUNT_TYPE: {
    PARENT: "1",
    CHILD: "2",
  },
};

/**socket detailes constants */
export const SOCKET: any = {
  URL_WEB_SOCKET: "wss://phygital.way2smile.ae:8001/ws/notification/",
  METHOD: "SUBSCRIBE",
  CHAT_SOCKET: "wss://phygital.way2smile.ae:8001/ws/chat/",
};

/**Tutor Pages google map constants */
export const TUTORMAPCONSTANT: any = {
  LIBRARIES: "places",
  MAP__ID: "google-map-script",
};

/**Tutor dashboard  filter nested menu constants */
export const NESTEDMENUITEM: any = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  ARROWRIGHT: "ArrowRight",
  ARROWLEFT: "ArrowLeft",
  ESCAPE: "Escape",
  MAP__ID: "google-map-script",
  KEYDOWN: "keydown",
};

/**Accounts Pages constatnts */

export const ACCOUNTS: any = {
  SIDE_NAV_CONTENTS: [
    { ICON: tickets, ICON_ACTIVE: tickets, LABEL: "My Tickets" },
    { ICON: tickets, ICON_ACTIVE: tickets, LABEL: "My Tickets" },
  ],
  PERSONAL_DETAILS_FIELDS: {
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    EMAIL: "email",
    TYPE: "type",
    DATE_OF_BIRTH: "dataOfBirth",
  },
  PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES: {
    FIRST_NAME_REQUIRED: "Please enter your First Name",
    LAST_NAME_REQUIRED: "Please enter your Last Name",
    EMAIL_REQUIRED: "Please enter the E-Mail ID",
    INVALID_EMAIL: "Please enter a valid E-Mail ID",
    TYPE_REQUIRED: "Please select the profile type",
    DATE_OF_BIRTH_REQUIRED: "Please select your Date of Birth",
  },
  ADD_PROFILE_ROLE: {
    PARENT: "8",
    CHILD: "10",
  },
};

export const HOMEPAGE: any = {
  SERVICE_TYPE: {
    SCHOOL: "Schools",
    UNIVERSITY: "Universities",
    ACTIVITIES: "Activities",
    THEME_PARK: "Theme park",
    TUTOR: "Tutors",
    PARKS: "Parks & Events",
  },

  CAROUSEL_TYPE: {
    SCHOOL: "school",
    UNIVERSITY: "university",
    ACTIVITIES: "activity",
    THEME_PARK: "themepark",
    CAMP: "camp",
    TRAINER: "trainer",
    TUTOR: "tutor",
    PARKS: "Parks & Events",
  },
};

export const areaArray = [
  {
    lat: 25.2048493,
    lng: 55.2707828,
    locationName: "Dubai",
    id: 1,
  },
  {
    lat: 25.800694,
    lng: 55.976200,
    locationName: "Ras Al-Khaimah",
    id: 2,
  },
  {
    lat: 25.553579889224252,
    lng: 55.55311006347656,
    locationName: "Umm Al-Quwain",
    id: 3,
  },
  {
    lat: 24.453884,
    lng: 54.3773438,
    locationName: "Abu Dhabi",
    id: 4,
  },
  {
    lat: 25.362774045016405,
    lng: 55.42616224365235,
    locationName: "Sharjah",
    id: 5,
  },
  {
    lat: 25.1223,
    lng: 56.3342,
    locationName: "Fujayrah",
    id: 6,
  },
  {
    lat: 25.409249343651254,
    lng: 55.51297426513673,
    locationName: "Ajman",
    id: 7,
  },
];

export const DayTotalHours = [
  {
    time: "12 am",
  },
  {
    time: "1 am",
  },
  {
    time: "2 am",
  },
  {
    time: "3 am",
  },
  {
    time: "4 am",
  },
  {
    time: "5 am",
  },
  {
    time: "6 am",
  },
  {
    time: "7 am",
  },
  {
    time: "8 am",
  },
  {
    time: "9 am",
  },
  {
    time: "10 am",
  },
  {
    time: "11 am",
  },
  {
    time: "12 pm",
  },
  {
    time: "1 pm",
  },
  {
    time: "2 pm",
  },
  {
    time: "3 pm",
  },
  {
    time: "4 pm",
  },
  {
    time: "5 pm",
  },
  {
    time: "6 pm",
  },
  {
    time: "7 pm",
  },
  {
    time: "8 pm",
  },
  {
    time: "9 pm",
  },
  {
    time: "10 pm",
  },
  {
    time: "11 pm",
  },
];

/**Radius Km filter tag  Array*/

export const RadiusMapKm = [
  {
    lable: "15 km",
    value: 15,
  },
  {
    icon: "",
    lable: "50 km",
    value: 50,
  },
  {
    lable: "100 km",
    value: 100,
  },
];

export const onInputChangeGoogleApi = (value: any) => {
  var map_key = process.env.REACT_APP_GOOGLE_MAP_KEY || "";
  return axios
    .request({
      method: "get",
      url: `https://maps.googleapis.com/maps/api/place/textsearch/json?key=${map_key}&query=${value}`,
    })
    .then((response) => {
      return response;
    })
    .catch((e) => { });

  // return axios
  //   .get(`https://maps.googleapis.com/maps/api/place/textsearch/json?key=${map_key}&query=${value}`,
  //     {
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Headers': '*',
  //         // 'Access-Control-Allow-Credentials': 'true',
  //         // "Sec-Fetch-Mode": "no-cors",
  //         // 'Sec-Fetch-Site': 'same-origin'
  //       },
  //     }
  //   )
  //   .then((response: any) => {
  //     return response
  //   })
  //   .catch(() => { });
};

export const S3DataGetApi = async (event: any) => {
  const file = event.target.files[0];
  const formData = new FormData();
  formData.append('file', file);
  const url = `${process.env.REACT_APP_BASE_BACKEND_API_URL}${API_END_POINTS.S3_STATIC_UPLOAD}`;
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response?.data?.result?.data[0];
  } catch (error) { }
};

export const ProfileSiderBarArray = [
  {
    title: "My Sessions",
    activeImg: tutorHubActive,
    InactiveImg: tutor_Hub,
    path: ROUTES.TUTOR_HUB,
    accountType: 1
  },
  {
    title: "My Orders",
    activeImg: mysessionActive,
    InactiveImg: mysession,
    path: ROUTES.MYSESSION,
    accountType: 1
  },
  {
    title: "My Tickets",
    activeImg: themeparkActive,
    InactiveImg: themPark,
    path: ROUTES.MYTICKETS,
    accountType: 1
  },
  // {
  //   title: "Tickets",
  //   activeImg: themeparkActive,
  //   InactiveImg: themPark,
  //   path: ROUTES.MYTICKETS_DETAIL,
  // },
  {
    title: "Calendar",
    activeImg: calenderActive,
    InactiveImg: calender,
    path: ROUTES.CHILDCALENDER,
    accountType: 1
  },
  {
    title: "Notifications",
    activeImg: notificationActive,
    InactiveImg: notification,
    path: ROUTES.NOTIFICATION,
    accountType: 1
  },
  {
    title: "Favourites",
    activeImg: favoutiesActive,
    InactiveImg: favourties,
    path: ROUTES.FAVOURTIES,
    accountType: 1
  },
  {
    title: "My Wallet",
    activeImg: mywalletActive,
    InactiveImg: mywallet,
    path: ROUTES.MYWALLET,
    accountType: 2
  },
  {
    title: "Payment History",
    activeImg: paymentHistoryActive,
    InactiveImg: paymenthistory,
    path: ROUTES.PAYMENTHISTORY,
    accountType: 2
  },
  {
    title: "My Preferences",
    activeImg: PreferenceActive,
    InactiveImg: PreferenceInActive,
    path: ROUTES.PREFERENCESCREEN,
    accountType: 2
  },
  {
    title: "About Us",
    activeImg: aboutsActive,
    InactiveImg: aboutus,
    path: ROUTES.ABOUTUS,
    accountType: 1
  },
  {
    title: "Legal & Security",
    activeImg: legalsecurityActive,
    InactiveImg: legalsecurity,
    path: ROUTES.LEGALANDSECURITY,
    accountType: 1
  },
  {
    title: "Support",
    activeImg: supportActive,
    InactiveImg: supports,
    path: ROUTES.SUPPORT,
    accountType: 1
  },
];

export const PreferenceDilogArray = [
  {
    id: 1,
    lable: "University",
  },
  {
    id: 2,
    lable: "Tutors",
  },
  {
    id: 3,
    lable: "Swimming",
  },
  {
    id: 4,
    lable: "CBSE",
  },
  {
    id: 5,
    lable: "British Curriculum",
  },
  {
    id: 6,
    lable: "Football",
  },
  {
    id: 7,
    lable: "UK 12Y",
  },
  {
    id: 8,
    lable: "Mathematics",
  },
  {
    id: 9,
    lable: "Physics",
  },
  {
    id: 10,
    lable: "Chemistry",
  },
];

export const TutorHubArray = [
  {
    title: "Sessions",
    activeImg: TutorHubSession,
    InactiveImg: TutorSessionInActive,
    id: 1,
  },
  {
    title: "Documents",
    activeImg: TutorHubDocument,
    InactiveImg: TutorDocumentInActive,
    id: 2,
  },
  {
    title: "Chats",
    activeImg: TutorHubChat,
    InactiveImg: TutorChatInActive,
    id: 3,
  },
  {
    title: "Feedback",
    activeImg: TutorFeedback,
    InactiveImg: TutorFeedbackInActive,
    id: 4,
  },
];

// const currentLocation: any = useSelector((state: any) => {
//   return state.locationReducer.location;
// });


export const getGoogleMapLink = (themepark: any) => {
  var clientLocation: any = {
    lat: 25.2048493,
    lng: 55.2707828,
  }

  let locationData = GetSessionStorageData(AUTHENTICATION.SHARED.CURRENT_LOCATION);

  if (locationData) {
    let currentLocation = JSON.parse(locationData);
    let location = currentLocation ? currentLocation : clientLocation;
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${location.lat},${location.lng}&destination=${themepark.lat},${themepark.lng}`;
    return mapsUrl;
  } else {
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${themepark.lat},${themepark.lng}&travelmode=driving`;
    return mapsUrl;
  }

  return null



}


export const GradeViewArray = [
  {
    id: 0,
    label: 'KG1 / FS2'
  },
  {
    id: 1,
    label: 'Pre primary / FS1'
  },
  {
    id: 2,
    label: 'KG2 / YEAR 1'
  },
  {
    id: 3,
    label: 'GRADE 1 / YEAR 2'
  },
  {
    id: 4,
    label: 'GRADE 2 / YEAR 3'
  },
  {
    id: 5,
    label: 'GRADE 3 / YEAR 4'
  },
  {
    id: 6,
    label: 'GRADE 4 / YEAR 5'
  },
  {
    id: 7,
    label: 'GRADE 5 / YEAR 6'
  },
  {
    id: 8,
    label: 'GRADE 6 / YEAR 7'
  },
  {
    id: 9,
    label: 'GRADE 7 / YEAR 8'
  },
  {
    id: 10,
    label: 'GRADE 8 / YEAR 9'
  },
  {
    id: 11,
    label: 'GRADE 9 / YEAR 10'
  },
  {
    id: 12,
    label: 'GRADE 10 / YEAR 11'
  },
  {
    id: 13,
    label: 'GRADE 11 / YEAR 12'
  },
  {
    id: 14,
    label: 'GRADE 12 / YEAR 13'
  },
  {
    id: 15,
    label: 'GRADE 13 / YEAR 14'
  }
];