import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../../Assets/Tutor/share.svg";
import LikeIcon from "../../../Assets/Tutor/LikeIcon.svg";
import comments from "../../../Assets/Tutor/commentIcon.svg";
import locationIcon from "../../../Assets/Tutor/location.svg";
import experienceIcon from "../../../Assets/Tutor/experience.svg";
import BadgeIcon from "../../../Assets/Tutor/BadgeIcon.svg";
import right from "../../../Assets/Tutor/right.svg";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import curriculum from "../../../Assets/Tutor/curriculumBlue.svg";
import teaching_mode from "../../../Assets/Tutor/bookBlue.svg";
import StarIcon from "@mui/icons-material/Star";
import AliceCarousel from "react-alice-carousel";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullSharpIcon from "@mui/icons-material/OpenInFullSharp";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import NavigateBeforeSharpIcon from "@mui/icons-material/NavigateBeforeSharp";
import { useLocation, useNavigate } from "react-router-dom";
import "./TutorsModelDilogView.scss";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
} from "../../../Constants/Constants";
import { Tooltip } from "@mui/material";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import toast from "react-hot-toast";
import Tutorfavourites from "../../../Assets/HeartFill/TutorFill.svg";
import Private_session from "../../../Assets/Tutor/twomemberBlue.svg";
import mode_of_teaching from "../../../Assets/Tutor/oneMemberBlue.svg";
import syllabus from "../../../Assets/Tutor/syllabusBlue.svg";
import pdfIcon from "../../../Assets/Tutor/pdfIcon.svg";
import experienceBlue from "../../../Assets/Tutor/experienceBlue.svg";
import {
  GetSessionStorageData,
  encryptPassData,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import { useDispatch, useSelector } from "react-redux";
import { setLoader, setLoginSignUpOpen, setChildLoginRestriction } from "../../../Redux/Reducer/Reducer";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import { store } from "../../../Redux/Store/Store";
import TutorTrialSession from "../../../Components/TutorTrialSession/TutorTrialSession";
import CalendarIcon from "../../../Assets/Tutor/CALENDAR.svg";
import PdfDownloadFile from "../../../Components/PdfDownload";
import tutor_icon from "../../../Assets/Tutor/TutorImg.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return (
    <>
      {" "}
      <Slide direction="up" ref={ref} {...props} />
    </>
  );
});

const TutorsModelDilogView = ({
  triggerModel,
  close,
  Detailes,
  tutorList,
  getLatAndLong,
}: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const carouselRef = useRef<any>(null);
  const [toturDetailes, setToturDetailes] = useState<any>([]);
  const [courseIndex, setCourseIndex] = useState<any>([]);
  const [courseSelectIndex, setCourseSelectIndex] = useState<any>([0]);
  const [AuthenticationOpen, setAuthenticationOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [FreeTrialOpen, setFreeTrialOpen] = useState(false);
  const [PdfOpen, setPdfOpen] = useState(false);
  const [PdfData, setPdfData] = useState([]);
  const [CourseOpen, setCourseOpen] = useState(true);

  /** Onchange Function to Prev Button*/
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      tutorList?.map((value: any, i: any) => {
        if (currentIndex - 1 === i) {
          setToturDetailes(value);
          setCourseIndex(value?.tutor_subjects[0]);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  /** Onchange Function to Next Button*/
  const handleNext = () => {
    if (currentIndex < tutorList?.length - 1) {
      setCurrentIndex(currentIndex + 1);
      tutorList?.map((value: any, i: any) => {
        if (currentIndex + 1 === i) {
          setToturDetailes(value);
          setCourseIndex(value?.tutor_subjects[0]);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  /**Slide previous card */
  const slidePrev = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slidePrev();
    }
  };

  /**Slide next card */
  const slideNext = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slideNext();
    }
  };

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };

  /**Sync card index on slide */
  const navigateTutorDetailedView = () => {
    navigate(ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(toturDetailes?.id), {
      state: { tutor_Detailes: toturDetailes },
    });
  };

  const navigateBookSlot = () => {
    if (nullUndefinedEmptyCheck(courseIndex?.id)) {
      navigate(ROUTES.TUTORS_CALENDAR_VIEW, {
        state: {
          tutor_sesstion_id: courseIndex?.id,
          tutor_del: toturDetailes,
          courseIndex: courseIndex,
          is_reschedule: false,
          tutor_name:
            toturDetailes?.profile_details?.firstname +
            " " +
            toturDetailes?.profile_details?.lastname,
        },
      });
    }
  };

  useEffect(() => {
    if (nullUndefinedEmptyCheck(Detailes?.id)) {
      setToturDetailes([]);
      getOneList();
    }
  }, [Detailes]);

  /**Get One List*/
  const getOneList = () => {
    var id: any = "?tutor_id=" + Detailes?.id;
    axiosInstance
      .get(`${API_END_POINTS.TUTOR_DETAIL + id}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setToturDetailes(Response?.data?.result?.data);
          setCourseIndex(
            Response?.data?.result?.data?.tutor_subjects?.length > 0
              ? Response?.data?.result?.data?.tutor_subjects[0]
              : []
          );
        }
      })
      .catch(() => { });
  };

  const CoverImg = toturDetailes?.cover_images?.map((item: any) => {
    return (
      <>
        {/* {toturDetailes?.cover_images?.blob_type === "image" ? (
                    <img src={toturDetailes?.cover_images ? toturDetailes?.cover_images : card_bg} alt="card_bg" className='card_bg_img' />
                ) : ""} */}

        {/* {toturDetailes?.cover_images?.blob_type === "video" ? (
                    <video id="my-video" width="250" controls >
                        <source src={toturDetailes?.cover_images} type="video/mp4" />
                    </video>
                ) : ""} */}
        <img
          src={item ? item : card_bg}
          alt="card_bg"
          className="card_bg_img"
        />
      </>
    );
  });

  useEffect(() => {
    Introvideo();
  }, [toturDetailes?.intro_video, courseIndex, carouselRef])

  const Introvideo = () => {
    if (nullUndefinedEmptyCheck(toturDetailes?.intro_video)) {
      CoverImg?.push(
        <video controls width="270" height="150">
          <source src={toturDetailes?.intro_video} type="video/mp4" />
        </video>
      )
    }
  }

  const CoverImgEmpty = [0, 1, 2]?.map((item: any) => {
    return (
      <>
        <img src={card_bg} alt="card_bg" className="card_bg_img" />
      </>
    );
  });

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
            getOneList();
          }
        })
        .catch((error) => { });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const handleAuthentication = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD) {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      } else {
        navigateBookSlot()
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const handleAuthenticationFreeTrial = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD) {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      } else {
        onOpenFreeTrial();
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const DownloadPdf = (data: any) => {
    if (nullUndefinedEmptyCheck(data)) {
      store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      fetch(data, {
        method: "GET",
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          const urlParts = data?.split('/');
          const fileName = urlParts[urlParts?.length - 1];
          a.style.display = "none";
          a.href = url;
          a.download = fileName; // the filename you want
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setTimeout(() => {
            toast.success("Download Pdf Successfully", {
              position: "top-right",
            });
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
          }, 500);
        });
    } else {
      toast.error("No data Found", {
        position: "top-right",
      });
    }
  };

  const onOpenFreeTrial = () => {
    setFreeTrialOpen(true);
  };

  const onCloseFreeTrial = () => {
    setFreeTrialOpen(false);
  };

  const onOpenPdfDownload = (data: any) => {
    setPdfData(data)
    setPdfOpen(true);
  };

  const onClosePdfDownload = () => {
    setPdfOpen(false);
    setPdfData([])
  };

  const checkString = (data: any) => {

    // Convert array to a string
    var dataAsString = JSON.stringify(data);

    // Check if the string contains square brackets
    if (dataAsString?.includes('[') && dataAsString?.includes(']')) {
      const match = data?.match(/\['([^']+)'\]/);
      return match ? match[1] : null;
    } else {
      return data;
    }
  }


  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const top_cover_img_wrapper = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top_cover_img_wrapper" : "top_cover_img_wrapper_arb";


  return (
    <>
      <div className="Model__Dialog__container">
        <Dialog
          open={triggerModel}
          onClose={close}
          TransitionComponent={Transition}
          keepMounted
          PaperProps={{
            style: {
              position: "absolute",
              right: 0,
              top: 100,
              bottom: -25,
              minWidth: 300,
              backgroundColor: "transparent", // Set the background color to transparent
              boxShadow: "none", // Optional:
            },
          }}
        >
          {/* <div className="background_circle_left__Tutor_model"></div> */}
          <div className="background_circle_right__Tutor_model"></div>
          <DialogContent sx={{ zIndex: 1 }} dir={
            language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
              ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
              : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
          }>
            <div className="top__contant__wapper">
              <div className="top__PageBtn__icons">
                {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                  <NavigateBeforeSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                ) : (
                  <NavigateNextSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                )}
                <div
                  className={currentIndex > 0 ? "prev__text" : "text__disable"}
                  onClick={handlePrev}
                >
                  {t("Prev")}
                </div>
                <div
                  className={
                    currentIndex < tutorList?.length - 1
                      ? "prev__text"
                      : "text__disable"
                  }
                  onClick={handleNext}
                >
                  {t("Next")}
                </div>
                {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                  <NavigateNextSharpIcon className={currentIndex < tutorList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                ) : (
                  <NavigateBeforeSharpIcon className={currentIndex < tutorList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                )}
              </div>
              <div className="top__close__icons">
                <Tooltip title="Expanded">
                  <OpenInFullSharpIcon
                    className="expanded__icons"
                    onClick={navigateTutorDetailedView}
                  />
                </Tooltip>
                <Tooltip title="close">
                  <CloseIcon className="close__icons" onClick={close} />
                </Tooltip>
              </div>
            </div>
            <div className={top_cover_img_wrapper}>
              <div className="arrow_navs">
                <NavigateBeforeSharpIcon
                  onClick={slidePrev}
                  className="left_arrow"
                />
                <NavigateNextSharpIcon
                  onClick={slideNext}
                  className="right_arrow"
                />
              </div>
              {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                <div
                  className="viewCalendar"
                  onClick={() => navigateBookSlot()}
                >
                  <img src={CalendarIcon} alt="ExpandMap" />
                  <span>{t("View on calendar")}</span>
                </div>
              ) : (
                <div className="viewCalendar" onClick={handleAuthentication}>
                  <img src={CalendarIcon} alt="ExpandMap" />
                  <span>{t("View on calendar")}</span>
                </div>
              )}
              <AliceCarousel
                // infinite
                mouseTracking
                autoWidth
                disableButtonsControls
                items={
                  toturDetailes?.cover_images?.length > 0 || toturDetailes?.intro_video
                    ? CoverImg
                    : CoverImgEmpty
                }
                disableDotsControls
                activeIndex={activeIndex}
                onSlideChanged={syncActiveIndex}
                ref={carouselRef}
              />
              <img
                src={
                  toturDetailes?.profile_image
                    ? toturDetailes?.profile_image
                    : tutor_img
                }
                alt="tutor_img" onError={(e: any) => { e.target.src = tutor_img; }}
                className="profileImg"
              />
            </div>

            <div className="card__container tutor__model_pop_width">
              <div className="w-100">
                <div className="user__Detailes">
                  <div className="user__name">
                    {toturDetailes?.profile_details?.firstname}
                  </div>
                  <div className="user__icon__wrapper">
                    {/* <img src={comments} alt="comments" /> */}
                    <WebShareComponent
                      params={
                        ROUTES.TUTORS_DETAILE_VIEW +
                        "?id=" +
                        encryptPassData(toturDetailes?.id)
                      }
                    >
                      <img src={shareIcon} alt="shareIcon" className="mb-1" />
                    </WebShareComponent>
                    {toturDetailes.is_favourite ? (
                      <img
                        src={Tutorfavourites}
                        alt="Learn and Play"
                        onClick={() =>
                          handleChangeFavourites(
                            toturDetailes.is_favourite,
                            toturDetailes?.user
                          )
                        }
                      />
                    ) : (
                      <img
                        src={LikeIcon}
                        alt="Learn and Play"
                        onClick={() =>
                          handleChangeFavourites(
                            toturDetailes.is_favourite,
                            toturDetailes?.user
                          )
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="description__contant">
                  {toturDetailes?.description}
                </div>

                <div className="row tutor__user__Detailes__Education">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img
                        src={teaching_mode}
                        alt="teaching_mode"
                        className="grayIcons"
                      />
                      <div className="graytext">{t("Education")}</div>
                    </div>
                    <div className="Bluetext" title={toturDetailes?.degree ? toturDetailes?.degree : toturDetailes?.high_education}>
                      {toturDetailes?.degree ? toturDetailes?.degree : toturDetailes?.high_education}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <StarIcon className="StarIcon" />
                      <div className="graytext">{t("Rating")}</div>
                    </div>
                    <div className="Bluetext">{toturDetailes?.rating}</div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv mt-3">
                    <div className="detailed__container__wapper">
                      <img src={BadgeIcon} alt="Grade" className="grayIcons" />
                      <div className="lable">{t("Grade")}</div>
                    </div>
                    {courseIndex?.courses ? (
                      <div className="Bluetext" title={courseIndex?.grade_name}>
                        {courseIndex?.grade_name}
                      </div>
                    ) : (
                      <div
                        className="Bluetext"
                        title={
                          toturDetailes?.tutor_subjects?.length > 0
                            ? toturDetailes?.tutor_subjects[0]?.grade_name
                            : ""
                        }
                      >
                        {toturDetailes?.tutor_subjects?.length > 0
                          ? toturDetailes?.tutor_subjects[0]?.grade_name
                          : ""}
                      </div>
                    )}
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv mt-3">
                    <div className="detailed__container__wapper">
                      <img
                        src={curriculum}
                        alt="curriculum"
                        className="grayIcons"
                      />
                      <div className="lable">{t("Curriculum")}</div>
                    </div>
                    <div className="Bluetext">{toturDetailes?.curriculum}</div>
                  </div>
                </div>
                <div className="user__Detailes__fess mt-3">
                  <div className="Avg__Fees">{t("Fees Per Session (1hr)")}</div>
                  <h1 className="title">{t("AED")} {courseIndex?.final_fee}</h1>
                </div>
                <div className="user__Detailes__Courses">
                  <div className="tutorList_selectcourse-div">
                    <div className="title">{t("Courses")}</div>
                    <KeyboardArrowDownIcon
                      className={CourseOpen ? "Icon" : "IconInActive"}
                      onClick={() => setCourseOpen(!CourseOpen)}
                    />
                  </div>
                  {CourseOpen && (
                    <div className="display-container2">
                      {toturDetailes?.tutor_subjects?.map((sub: any, i: any) => {
                        return (
                          <>
                            <div
                              className={
                                courseSelectIndex == i
                                  ? "courses-Active"
                                  : "courses-div"
                              }
                              onClick={() => {
                                setCourseSelectIndex(i);
                                setCourseIndex(sub);
                              }}
                            >
                              <img src={sub?.coursecategory_icon ? sub?.coursecategory_icon : tutor_icon}
                                onError={(e: any) => { e.target.src = tutor_icon; }} alt="" />
                              <div className="coursetxt">
                                {sub.courses} - {sub.grade_name}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="Description_contant_tutor">
                  <label className="title">{t("About Me")}</label>
                  <div className="para-content">{toturDetailes?.about_me}</div>
                </div>
                <div className="Description_contant_tutor mt-3">
                  <label className="title">{t("Additional notes")}</label>
                  <div className="para-content">
                    {checkString(courseIndex?.additional_notes)}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 bottom__contant__wapper">
                    <div className="bottom__contant__left">
                      <img src={experienceBlue} alt="" />
                      <div>{t("Experience")}</div>
                    </div>
                    <div>{courseIndex?.experience} {t("Years")}</div>
                  </div>
                  <div className="col-md-12 bottom__contant__wapper">
                    <div className="bottom__contant__left">
                      <img src={Private_session} alt="" />
                      <div>{t("Private 1-1 sessions")}</div>
                    </div>
                    <div>{courseIndex?.course_mode == 'Offline' ? t("On-premises") : courseIndex?.course_mode}</div>
                  </div>
                  <div className="col-md-12 bottom__contant__wapper">
                    <div className="bottom__contant__left">
                      <img src={mode_of_teaching} alt="" />
                      <div>{t("Mode Of Teaching")}</div>
                    </div>
                    <div>{courseIndex?.course_mode == 'Offline' ? t("On-premises") : courseIndex?.course_mode}</div>
                  </div>
                  <div className="col-md-12 bottom__contant__wapper">
                    <div className="bottom__contant__left">
                      <img src={syllabus} alt="" />
                      <div>{t("Syllabus Covers")}</div>
                    </div>
                    {courseIndex?.syllabus_covers?.length > 0 && (
                      <img
                        src={pdfIcon}
                        alt=""
                        className="pdfIcon"
                        onClick={() =>
                          onOpenPdfDownload(courseIndex?.syllabus_covers)
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="model__diloge__bookSlot_container mt-4">
                  {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                    <button
                      className="free_session_Btn"
                      onClick={() => onOpenFreeTrial()}
                    >
                      {t("Book an introduction session (Free)")}
                    </button>
                  ) : (
                    <button
                      className="free_session_Btn"
                      onClick={handleAuthenticationFreeTrial}
                    >
                      {t("Book an introduction session (Free)")}
                    </button>
                  )}
                </div>
                <div className="model__diloge__bookSlot_container mt-4">
                  {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                    <button
                      className="Book-Slots-btn"
                      onClick={() => navigateBookSlot()}
                    >
                      {t("Book Slots")}
                    </button>
                  ) : (
                    <button
                      className="Book-Slots-btn"
                      onClick={handleAuthentication}
                    >
                      {t("Book Slots")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>

      <TutorTrialSession
        TrialOpen={FreeTrialOpen}
        onClose={onCloseFreeTrial}
        tutor_id={toturDetailes?.id}
      />
      <PdfDownloadFile open={PdfOpen} handleClose={onClosePdfDownload} data={PdfData} />

      {AuthenticationOpen && (
        <div className="overlay-ModelBackround">
          <Dialog open={AuthenticationOpen}>
            <DialogContent>
              <div className="ChildSelect__container">
                <div className="text__contant">
                  {t("Parent only allowed to Book slot")}
                </div>
                <div className="ChildSelectBtn__wapper__Tutor">
                  <button
                    className="BackBtn"
                    onClick={() => setAuthenticationOpen(false)}
                  >
                    {t("Back")}
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default TutorsModelDilogView;
