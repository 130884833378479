import "./EmailPhoneOtpLoginSignup.scss";
import { Dialog, DialogContent, Tab, Tabs } from "@mui/material";
import AuthScreenCarousel from "../AuthScreenCarousel/AuthScreenCarousel";
import { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
} from "../../Constants/Constants";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import es from 'react-phone-input-2/lang/es.json';
import ar from 'react-phone-input-2/lang/ar.json';
/**Assets */
import googleIcon from "../../Assets/Authentication/google_icon.svg";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import toast from 'react-hot-toast';
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import { SetSessionStorageData } from "../../Utilities/Helpers/Helper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const EmailPhoneOtpLoginSignup = (EmailPhoneOtpLoginSignupProps: any) => {
  const { t } = useTranslation();
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /**Initial states */
  const [mobileEmailTab, setMobileEmailTab] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT
  );
  const [validNumber, setValidNumber] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
  );

  const [mobileNumberTouched, setMobileNumberTouched] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );

  const personalDetailsRef = useRef<any>();

  useEffect(() => {
    setMobileEmailTab(GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);
  }, [EmailPhoneOtpLoginSignupProps.openEmailPhoneOtpLoginSignupDialog]);

  /**Login signup initial values */
  let emailFormInitialValue = {
    [AUTHENTICATION.LOGIN_SIGNUP_FIELDS.EMAIL]: EmailPhoneOtpLoginSignupProps?.userName ? EmailPhoneOtpLoginSignupProps?.userName : "",
  };

  let phoneFormInitialValue = {
    [AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE]: "",
  };

  /**Handle tab change */
  const handleMobileEmailswitch = (
    event: React.SyntheticEvent,
    tabIndex: number
  ) => {
    setMobileEmailTab(tabIndex);
  };

  /**Login signup validation schema */
  const emailFormValidationSchema = yup.object({
    [AUTHENTICATION.LOGIN_SIGNUP_FIELDS.EMAIL]: yup
    .string()
    .required(AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES.EMAIL_REQUIRED) // Custom error message for required field
    .email(AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES.INVALID_EMAIL) // Custom error message for invalid email
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, // Custom regex for email validation
      AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES.INVALID_EMAIL
    )
    .test(
      'is-valid-email',
      AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES.INVALID_EMAIL, // Show on change if invalid
      (value) => !value || yup.string().email().isValidSync(value)
    )
    .trim(), // Trim whitespace
});

  const phoneFormValidationSchema = yup.object({
    [AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE]: yup
      .string()
      .required(
        // AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES.PHONE_NUMBER
        AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.PHONE_NUMBER
      )
      .test('isValidPhoneNumber', 'Invalid phone number', (value) =>
        isValidPhoneNumber(value || '')
      ),
  });

  /**Email Sign up payload */
  const emailSignUpPayload = (values: any) => {
    return {
      email: values[AUTHENTICATION.LOGIN_SIGNUP_FIELDS.EMAIL],
      roles: AUTHENTICATION.SIGNUP_SIGNIN_API_PAYLOADS.ROLES,
    };
  };

  /**Email Login Signup */
  const emailLoginSignUp = (values: any, actions: any) => {
    EmailPhoneOtpLoginSignupProps.setUserName(
      values[AUTHENTICATION.LOGIN_SIGNUP_FIELDS.EMAIL]
    );
    EmailPhoneOtpLoginSignupProps.setIsEmail(
      GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    );
    EmailPhoneOtpLoginSignupProps.setIsGoogle(
      GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
    );
    if (EmailPhoneOtpLoginSignupProps.isLogin) {
      emailLogin(emailSignUpPayload(values));
    } else {
      emailSignUp(emailSignUpPayload(values));
    }
  };

  /**Email sign up */
  const emailSignUp = (payload: any) => {
    axiosInstance
      .post(`${API_END_POINTS.TEMP_OTP}?login_type=1`, payload)
      .then((emailSignUpResponse: any) => {
        if (
          emailSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(emailSignUpResponse.data.message, {
            position: "top-right"
          });
          EmailPhoneOtpLoginSignupProps.handleCloseEmailPohneOtpLoginSignupDialog();
          EmailPhoneOtpLoginSignupProps.setOpenOtpVerificationDialog(
            GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
          );
        }
      })
      .catch(() => { });
  };

  /**Email login */
  const emailLogin = (payload: any) => {
    axiosInstance
      .post(`${API_END_POINTS.TEMP_OTP_LOGIN}?login_type=1`, payload)
      .then((emailSignUpResponse: any) => {
        if (
          emailSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(emailSignUpResponse?.data?.message, {
            position: "top-right"
          });
          EmailPhoneOtpLoginSignupProps.handleCloseEmailPohneOtpLoginSignupDialog();
          EmailPhoneOtpLoginSignupProps.setOpenOtpVerificationDialog(
            GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
          );
        }
      })
      .catch(() => { });
  };

  /**Phone signup payload */
  const phoneSignUpPayload = (values: any) => {
    return {
      phone_number: values[AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE],
      roles: AUTHENTICATION.SIGNUP_SIGNIN_API_PAYLOADS.ROLES,
    };
  };

  /**Phone login signup */
  const phoneLoginSignUp = (values: any, actions: any) => {
    if (validNumber) {
      EmailPhoneOtpLoginSignupProps.setUserName(
        values[AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE]
      );
      EmailPhoneOtpLoginSignupProps.setIsEmail(
        GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
      );
      EmailPhoneOtpLoginSignupProps.setIsGoogle(
        GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
      );
      if (EmailPhoneOtpLoginSignupProps.isLogin) {
        phoneLogin(phoneSignUpPayload(values));
      } else {
        phoneSignUp(phoneSignUpPayload(values));
      }
    }
  };

  /**Phone sign up */
  const phoneSignUp = (payload: any) => {
    axiosInstance
      .post(`${API_END_POINTS.TEMP_OTP}?login_type=0`, payload)
      .then((phoneSignUpResponse: any) => {
        if (
          phoneSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(phoneSignUpResponse.data.message, {
            position: "top-right"
          });
          EmailPhoneOtpLoginSignupProps.handleCloseEmailPohneOtpLoginSignupDialog();
          EmailPhoneOtpLoginSignupProps.setOpenOtpVerificationDialog(
            GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
          );
        }
      })
      .catch(() => { });
  };

  /**Phone login */
  const phoneLogin = (payload: any) => {
    axiosInstance
      .post(`${API_END_POINTS.TEMP_OTP_LOGIN}?login_type=0`, payload)
      .then((phoneSignUpResponse: any) => {
        if (
          phoneSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(phoneSignUpResponse.data.message, {
            position: "top-right"
          });
          EmailPhoneOtpLoginSignupProps.handleCloseEmailPohneOtpLoginSignupDialog();
          EmailPhoneOtpLoginSignupProps.setOpenOtpVerificationDialog(
            GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
          );
        }
      })
      .catch(() => { });
  };

  /**toggle login signup */
  const toggleLoginSignUp = () => {
    EmailPhoneOtpLoginSignupProps.setIsLogin(
      !EmailPhoneOtpLoginSignupProps.isLogin
    );
  };

  /**Social Singup Validation */
  const validatedSocialSingup = (data: any) => {
    axiosInstance.get(`${API_END_POINTS.SOCIAL_SIGNUP_VALIDATE + data?.sub}`)
      .then((Response: any) => {
        googleLoginApi(data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
        });
      });
  }

  /**Google Autherization */
  const googleOAuth = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo: any = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );
      EmailPhoneOtpLoginSignupProps.isLogin ? validatedSocialSingup(userInfo.data) : googleSingUpApi(userInfo.data);
    },
    onError: (errorResponse) => { },
  });

  const googleSingUpApi = (value: any) => {
    EmailPhoneOtpLoginSignupProps.googleSingUpPass(value)
    EmailPhoneOtpLoginSignupProps.handleCloseEmailPohneOtpLoginSignupDialog();
    EmailPhoneOtpLoginSignupProps.setOpenPersonalDetailsDialog(
      GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    );
    EmailPhoneOtpLoginSignupProps.setIsEmail(
      GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
    );
    EmailPhoneOtpLoginSignupProps.setIsGoogle(
      GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    );
  }

  const googleLoginApi = (value: any) => {
    var payload = {
      email: value?.email,
      social_id: value?.sub
    }
    axiosInstance
      .post(`${API_END_POINTS.GOOGLE_LOGIN}`, payload)
      .then((Response: any) => {
        if (Response?.data?.status) {
          EmailPhoneOtpLoginSignupProps.handleCloseEmailPohneOtpLoginSignupDialog();
          SetSessionStorageData(
            AUTHENTICATION.SHARED.ACCESS_TOKEN,
            Response.data.result.data.access_token
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.REFRESH_TOKEN,
            Response.data.result.data.refresh_token
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.PROFILE_PICTURE,
            Response?.data?.result?.data?.user_info?.image
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.NAME,
            Response.data.result.data.user_info.name
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.ACCOUNT_TYPE,
            AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.EMAIL,
            Response.data.result.data.user_info.email
          );
          toast.success(Response?.data?.message, {
            position: "top-right"
          });
        }
      })
      .catch(() => { });
  }

  return (
    <>
      <Dialog
        open={EmailPhoneOtpLoginSignupProps.openEmailPhoneOtpLoginSignupDialog}
        className="overal-signup-modal-container"
        dir={
          language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
            ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
            : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
        }
      >
        <DialogContent>
          <div className={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "carousel_login_navigations_wrapper" : "carousel_login_navigations_wrapper arab_input"}>
            <div
              onClick={
                EmailPhoneOtpLoginSignupProps.handleCloseEmailPohneOtpLoginSignupDialog
              }
              className={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "skip_button" : "skip_button_ar"}
            >
              <CloseIcon className="close__icon" />
            </div>
            <div className="carousel_wrapper">
              <AuthScreenCarousel />
            </div>
            <div className="login_signup_navigations_wrapper">
              <div className="signin_signup_container">
                <div className="signin_signup_title">
                  {EmailPhoneOtpLoginSignupProps.isLogin
                    ? t("Sign In")
                    : t("Sign Up")}
                </div>
                <div className="signin_signup_description">
                  {EmailPhoneOtpLoginSignupProps.isLogin
                    ? t("signInDesc")
                    : t("signUpDesc")}
                </div>
                <Tabs
                  value={mobileEmailTab}
                  onChange={handleMobileEmailswitch}
                  aria-label="basic tabs example"
                >
                  <Tab label={t("Email")} />
                  <Tab label={t("Phone")} />
                </Tabs>
                {mobileEmailTab ===
                  GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT && (
                    <Formik
                      initialValues={emailFormInitialValue}
                      validationSchema={emailFormValidationSchema}
                      onSubmit={(values, actions) => {
                        emailLoginSignUp(values, actions);
                      }}
                      enableReinitialize={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                      validateOnChange={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                      innerRef={personalDetailsRef}
                    >
                      {(emailFormikProps: any) =>{
                      const emailHandleChange = (value: any) => {
                        const email = value;
                        emailFormikProps.setFieldValue('email', email);
                        /* Mark field as touched on change */
                        emailFormikProps.setFieldTouched('email', true);
                        /* Validate field on change */
                        emailFormikProps.validateField('email');
                      };

                      const emailHandleBlur = () => {
                        emailFormikProps.setFieldTouched('email', true);
                      };
                        return (
                        <Form className="email_login_form_wrapper">
                          <label className="fields_label">{t("Email")}</label>
                          <div className="email_field_wrapper">
                            <Field
                              className={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "login-email-input" :"login-email-input-ar"}
                              placeholder="Ex: ronaldo@gmail.com"
                              name={AUTHENTICATION.LOGIN_SIGNUP_FIELDS.EMAIL}
                              onBlur={emailHandleBlur}
                              onChange={(e: any) => emailHandleChange(e.target.value)}
                            />
                            <button type="submit" className={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "send_otp_button" :"send_otp_button_ar"}>
                              {t("Send OTP")}
                            </button>
                          </div>
                            {emailFormikProps.touched[AUTHENTICATION.LOGIN_SIGNUP_FIELDS.EMAIL] &&
                              emailFormikProps.errors[AUTHENTICATION.LOGIN_SIGNUP_FIELDS.EMAIL] && (
                                <div className="errorMessage">
                                  {emailFormikProps.errors[AUTHENTICATION.LOGIN_SIGNUP_FIELDS.EMAIL]}
                                </div>
                              )}
                        </Form>
                      )}}
                    </Formik>
                  )}
                {mobileEmailTab === 1 && (
                  <Formik
                    initialValues={phoneFormInitialValue}
                    validationSchema={phoneFormValidationSchema}
                    onSubmit={(values, actions) => {
                      phoneLoginSignUp(values, actions);
                    }}
                    enableReinitialize={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                  >
                    {(phoneFormikProps) => {
                                    const handleChange = (value: string) => {
                                      const phoneNumber = `+${value}`;
                                      phoneFormikProps.setFieldValue('mobile', phoneNumber);
                                      /* Mark field as touched on change */
                                      phoneFormikProps.setFieldTouched('mobile', true);
                                      /* Validate field on change */
                                      phoneFormikProps.validateField('mobile');
                                    };
                            
                                    const handleBlur = () => {
                                      phoneFormikProps.setFieldTouched('mobile', true);
                                    };
                     return(
                      <Form className="email_login_form_wrapper">
                        <label className="fields_label">{t("Phone Number")}</label>
                        <div className="phone_field_wrapper">
                          <PhoneInput
                           localization={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? es : ar}
                           containerStyle={{ direction: language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? 'ltr' : 'rtl' }}
                            inputProps={{
                              className: `${language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "login-phone-input" :"login-phone-input-ar"}`,
                              name: `${AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE}`,
                            }}
                            country={"ae"}
                            countryCodeEditable={
                              GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
                            }
                            enableSearch={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                            placeholder={t("Enter your Phone number")}
                            onBlur={() => {
                              handleBlur();
                              setMobileNumberTouched(
                                GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
                              );
                            }}
                            onChange={(e) => {
                              handleChange(e);
                              phoneFormikProps.setFieldValue(
                                AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE,
                                `+${e}`
                              );
                              setTimeout(() => {
                                phoneFormikProps.validateField(
                                  AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE
                                );
                                setValidNumber(isValidPhoneNumber(`+${e}`));
                              }, GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);
                            }}
                          />
                          <button type="submit"className={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "send_otp_button" :"send_otp_button_ar"}>
                            {t("Send OTP")}
                          </button>
                        </div>
                        {/* <div className="errorMessage"> */}

                        {validNumber === GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE &&
                        phoneFormikProps.touched[
                          AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE
                        ] &&
                        phoneFormikProps.values[
                          AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE
                        ] !== "" ? (
                        <div className="errorMessage">
                          {
                            AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES
                              .INVALID_PHONE_NUMBER
                          }
                        </div>
                      ) : null}

                         {mobileNumberTouched &&
                        validNumber === GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE ? (
                        <div className="errorMessage">
                          {
                            phoneFormikProps.errors[
                              AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE
                            ]
                          }
                        </div>
                      ) : null}

                          {/* <ErrorMessage name="mobile" /> */}
                        {/* </div> */}

                      </Form>
                    )}}
                  </Formik>
                )}
                <div className="seperator_for_social_login">{t("OR")}</div>
                <button
                  className="google_login_wrapper"
                  onClick={() => {
                    googleOAuth();
                  }}
                >
                  <img
                    className="google_icon"
                    src={googleIcon}
                    alt="Learn and Play"
                  ></img>
                  <div className="google_login_label">{t("Continue with Google")}</div>
                </button>
                <div className="signup_login_navigation_wrapper">
                  <div className="signup_login_question">
                    {EmailPhoneOtpLoginSignupProps.isLogin
                      ? t("noAcc")
                      : t("Already have an account?")}
                  </div>
                  <button
                    className="signup_login_navigation_button"
                    onClick={toggleLoginSignUp}
                  >
                    {" "}
                    {EmailPhoneOtpLoginSignupProps.isLogin
                      ? t("Sign up here")
                      : t("Sign in here")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailPhoneOtpLoginSignup;
