import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../../Assets/University/universityShareIcon.svg";
import LikeIcon from "../../../Assets/University/UniversityLikeIcon.svg";
import comments from "../../../Assets/University/universityCommentIcon.svg";
import CalenderIcon from "../../../Assets/University/universityCalenderIcon.svg";
import viewOnMap from "../../../Assets/University/universityViewMapIcon.svg";
import globalRank from "../../../Assets/University/universityGlobalRanking.svg";
import shangaiRank from "../../../Assets/University/universityShangai.svg";
import courseIocn from "../../../Assets/University/universityCourse.svg";
import noOfStudent from "../../../Assets/University/universityNoStud.svg";
import locationIcon from "../../../Assets/Tutor/location.svg";
import experienceIcon from "../../../Assets/Tutor/experience.svg";
import BadgeIcon from "../../../Assets/Tutor/BadgeIcon.svg";
import right from "../../../Assets/Tutor/right.svg";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import curriculum from "../../../Assets/Tutor/curriculumBlue.svg";
import teaching_mode from "../../../Assets/Tutor/bookBlue.svg";
import StarIcon from "@mui/icons-material/Star";
import AliceCarousel from "react-alice-carousel";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullSharpIcon from "@mui/icons-material/OpenInFullSharp";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import NavigateBeforeSharpIcon from "@mui/icons-material/NavigateBeforeSharp";
import { useLocation, useNavigate } from "react-router-dom";
import "./UniversityModelDilogView.scss";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
  getGoogleMapLink,
} from "../../../Constants/Constants";
import { Tooltip } from "@mui/material";
import {
  GetSessionStorageData,
  encryptPassData,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import toast from "react-hot-toast";
import { setChildLoginRestriction, setLoginSignUpOpen } from "../../../Redux/Reducer/Reducer";
import UniversityFill from "../../../Assets/HeartFill/UniversityFill.svg";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import emailIcon from "../../../Assets/School/emailIcon.svg";
import websiteIcon from "../../../Assets/School/websiteIcon.svg";
import callIcon from "../../../Assets/School/callIcon.svg";
import PersonIcon from "../../../Assets/School/PersonIcon.svg";
import location_icon from "../../../Assets/Header/header_location_icon.svg";
import chat_consultant from "../../../Assets/University/chat_consultant.svg";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import UniversityChatRequest from "../../../Components/UniversityChatRequest/UniversityChatRequest";
import ViewMoreLess from "../../../Components/ViewMoreCommon";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return (
    <>
      {" "}
      <Slide direction="up" ref={ref} {...props} />
    </>
  );
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UniversityModelDilogView = ({
  triggerModel,
  close,
  Detailes,
  tutorList,
  getLatAndLong,
}: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const carouselRef = useRef<any>(null);
  const [ChatRequestOpen, setChatRequestOpen] = useState(false);
  const [ChatRequestShowData, setChatRequestShowData] = useState(false);
  const [ChatRequestMeg, setChatRequestMeg] = useState("");
  const [toturDetailes, setToturDetailes] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  /** Onchange Function to Prev Button*/
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      tutorList?.map((value: any, i: any) => {
        if (currentIndex - 1 === i) {
          setToturDetailes(value);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  /** Onchange Function to Next Button*/
  const handleNext = () => {
    if (currentIndex < tutorList.length - 1) {
      setCurrentIndex(currentIndex + 1);
      tutorList?.map((value: any, i: any) => {
        if (currentIndex + 1 === i) {
          setToturDetailes(value);
          getLatAndLong(value?.latitude, value?.longitude);
        }
      });
    }
  };

  /**Slide previous card */
  const slidePrev = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slidePrev();
    }
  };

  /**Slide next card */
  const slideNext = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slideNext();
    }
  };

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };

  /**Sync card index on slide */
  const navigateTutorDetailedView = () => {
    navigate(
      ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(toturDetailes?.id),
      { state: { tutor_Detailes: toturDetailes } }
    );
  };

  const CoverImg = toturDetailes?.images?.map((item: any) => {
    return (
      <>
        <img
          src={item ? item : card_bg}
          alt="card_bg" onError={(e: any) => { e.target.src = tutor_img; }}
          className="card_bg_img"
        />
      </>
    );
  });

  const CoverImgEmpty = [0, 1, 2]?.map((item: any) => {
    return (
      <>
        <img src={card_bg} alt="card_bg" className="card_bg_img" />
      </>
    );
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(Detailes?.id)) {
      setToturDetailes([]);
      getOneList();
    }
  }, [Detailes]);

  /**Get One List*/
  const getOneList = () => {
    var id: any = "?id=" + Detailes?.id;
    axiosInstance
      .get(`${API_END_POINTS.UNIVERSITYLIST + id}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setToturDetailes(Response?.data?.result?.data);
        }
      })
      .catch(() => { });
  };

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            getOneList();
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const handleChatRequestClose = () => {
    setChatRequestOpen(false);
    setChatRequestShowData(false);
    setChatRequestMeg("");
  };

  const handleChatRequestOpen = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
        axiosInstance
          .get(`${API_END_POINTS.UNIVERSITY_CHAT_CONSULTANT + '?university_id=' + toturDetailes?.id}`)
          .then((Response: any) => {
            if (Response?.data?.status) {
              var status = Response?.data?.result?.data[0];
              if (status?.room_id === 0) {
                let payload = {
                  university_id: toturDetailes?.id,
                }
                axiosInstance
                  .post(`${API_END_POINTS.UNIVERSITY_CHAT_CONSULTANT}`, payload)
                  .then((Response: any) => {
                    if (Response?.data?.status) {
                      var status_new = Response?.data?.result?.data[0];
                      navigate(ROUTES.UNIVERSITY_CHAT_VIEW, {
                        state: { roomData: status_new, is_notification: false },
                      });
                    }
                  })
                  .catch(() => { });
              } else {
                navigate(ROUTES.UNIVERSITY_CHAT_VIEW, {
                  state: { roomData: status, is_notification: false },
                });
              }
              // if (status?.is_expert === false && status?.user_exist === false) {
              //   setChatRequestOpen(true);
              //   setChatRequestShowData(true);
              //   setChatRequestMeg(Response?.data?.message);
              // } else if (
              //   status?.is_expert === true &&
              //   status?.user_exist === true
              // ) {
              //   navigate(ROUTES.UNIVERSITY_CHAT_VIEW, {
              //     state: { roomData: status },
              //   });
              // } else {
              //   setChatRequestOpen(true);
              //   setChatRequestShowData(false);
              //   setChatRequestMeg(Response?.data?.message);
              // }
            }
          })
          .catch(() => { });
      } else {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const NavigateCalendar = (url: any) => {
    // Open a new tab/window
    window.open(url, "_blank");
  };

  const handleOpenMap = (data: any) => {
    let getDestination = { lat: data.latitude, lng: data.longitude }
    const mapsUrl = getGoogleMapLink(getDestination);
    if (mapsUrl) {
      window.open(mapsUrl, '_blank');
    }
  };


  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const university__tap__contant = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "university__tap__contant" : "university__tap__contant_arb";
  const top_cover_img_wrapper = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top_cover_img_wrapper" : "top_cover_img_wrapper_arb";

  return (
    <>
      {ChatRequestOpen && (
        <div className="overlay-ModelBackround">
          <UniversityChatRequest
            open={ChatRequestOpen}
            handleClose={handleChatRequestClose}
            data={ChatRequestShowData}
            message={ChatRequestMeg}
          />
        </div>
      )}
      <div className="Model__Dialog__container">
        <Dialog
          open={triggerModel}
          onClose={close}
          TransitionComponent={Transition}
          keepMounted
          PaperProps={{
            style: {
              position: "absolute",
              right: 0,
              top: 100,
              bottom: -25,
              minWidth: 300,
              backgroundColor: "transparent", // Set the background color to transparent
              boxShadow: "none", // Optional:
            },
          }}
        >
          {/* <div className="background_circle_left__University_model"></div> */}
          <div className="background_circle_right__University_model"></div>
          <DialogContent sx={{ zIndex: 1 }} dir={
            language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
              ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
              : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
          }>
            <div className="top__contant__wapper__university">
              <div className="top__PageBtn__icons">
                {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                  <NavigateBeforeSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                ) : (
                  <NavigateNextSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                )}
                <div
                  className={currentIndex > 0 ? "prev__text" : "text__disable"}
                  onClick={handlePrev}
                >
                  {t("Prev")}
                </div>
                <div
                  className={
                    currentIndex < tutorList?.length - 1
                      ? "prev__text"
                      : "text__disable"
                  }
                  onClick={handleNext}
                >
                  {t("Next")}
                </div>
                {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                  <NavigateNextSharpIcon className={currentIndex < tutorList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                ) : (
                  <NavigateBeforeSharpIcon className={currentIndex < tutorList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                )}
              </div>
              <div className="top__close__icons">
                <Tooltip title="Expanded">
                  <OpenInFullSharpIcon
                    className="expanded__icons"
                    onClick={navigateTutorDetailedView}
                  />
                </Tooltip>
                <Tooltip title="close">
                  <CloseIcon className="close__icons" onClick={close} />
                </Tooltip>
              </div>
            </div>
            <div className={top_cover_img_wrapper}>
              <div className="arrow_navs">
                <NavigateBeforeSharpIcon
                  onClick={slidePrev}
                  className="left_arrow"
                />
                <NavigateNextSharpIcon
                  onClick={slideNext}
                  className="right_arrow"
                />
              </div>
              <AliceCarousel
                // infinite
                mouseTracking
                autoWidth
                disableButtonsControls
                items={
                  toturDetailes?.images?.length > 0
                    ? CoverImg
                    : CoverImgEmpty
                }
                disableDotsControls
                activeIndex={activeIndex}
                onSlideChanged={syncActiveIndex}
                ref={carouselRef}
              />
              <img
                src={toturDetailes?.images?.length > 0 ? toturDetailes?.images[0] : tutor_img}
                alt="tutor_img" onError={(e: any) => { e.target.src = tutor_img; }}
                className="profileImg"
              />
            </div>

            <div className="card__container">
              <div className="w-100">
                <div className="user__Detailes">
                  <div className="user__name">
                    {toturDetailes?.university_name}
                  </div>
                  <div className="user__icon__wrapper">
                    {/* <img src={comments} alt="comments" /> */}
                    <WebShareComponent
                      params={
                        ROUTES.UNIVERSITY_DETAILE_VIEW +
                        "?id=" +
                        encryptPassData(toturDetailes?.id)
                      }
                    >
                      <img src={shareIcon} alt="shareIcon" className="mb-1" />
                    </WebShareComponent>
                    <img
                      src={
                        toturDetailes?.is_favourite ? UniversityFill : LikeIcon
                      }
                      alt=""
                      onClick={() =>
                        handleChangeFavourites(
                          toturDetailes?.is_favourite,
                          toturDetailes?.user
                        )
                      }
                    />
                  </div>
                </div>

                <div className="View__on__map__container">
                  {nullUndefinedEmptyCheck(
                    toturDetailes?.academic_calendar
                  ) && (
                      <div
                        className="wapperDiv"
                        onClick={() =>
                          NavigateCalendar(toturDetailes?.academic_calendar)
                        }
                      >
                        <img src={CalenderIcon} alt="" />
                        <span>{t("Academic Calendar")}</span>
                      </div>
                    )}
                  <div
                    className="wapperDiv"
                    onClick={() => {
                      handleOpenMap(toturDetailes)
                    }}
                  >
                    <img src={viewOnMap} alt="" />
                    <span>{t("View on Map")}</span>
                  </div>
                </div>

                <div className="row University__detailed__container">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img src={globalRank} alt="" />
                      <span>{t("QS world Rank")}</span>
                    </div>
                    <div className="number">
                      {toturDetailes?.QS_ranking ? toturDetailes?.QS_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img src={shangaiRank} alt="" />
                      <span>{t("The Higher Education Rank")}</span>
                    </div>
                    <div className="number">
                      {toturDetailes?.THE_ranking ? toturDetailes?.THE_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img src={shangaiRank} alt="" />
                      <span>{t("Shanghai World Rank")}</span>
                    </div>
                    <div className="number">
                      {toturDetailes?.SH_ranking ? toturDetailes?.SH_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img src={noOfStudent} alt="" />
                      <span>{t("No. of students")}</span>
                    </div>
                    <div className="number">
                      {toturDetailes?.students_count ? toturDetailes?.students_count : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                    <div className="detailed__container__wapper">
                      <img src={courseIocn} alt="" />
                      <span>{t("Courses")}</span>
                    </div>
                    <div className="number">{toturDetailes?.courses}</div>
                  </div>
                </div>

                {toturDetailes?.description && (
                  <div className="university__description__container">
                    <div className="title">{t("Description")}</div>
                    <ViewMoreLess
                      text={toturDetailes?.description}
                      maxLength={100}
                    />
                  </div>
                )}

                <div className="university__bottom__contant">
                  <div className="bottom__contant__wapper">
                    <span>{t("Founded")}</span>
                    <div>{toturDetailes?.founded_year ? toturDetailes?.founded_year : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                  </div>
                  <div className="bottom__contant__wapper mt-2">
                    <span>{t("Language of Instruction")}</span>
                    <div>{toturDetailes?.available_mediums == 'Both' ? t('Online & On-premises') : toturDetailes?.available_mediums}</div>
                  </div>
                </div>

                {(toturDetailes?.application_process?.length > 0 || toturDetailes?.eligibility_criteria?.length > 0) && (
                  <div className="school__Taps__container__width">
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Tabs
                        className="Uiversity__Taps"
                        value={value}
                        centered
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        textColor="secondary"
                        indicatorColor="secondary"
                      >
                        <Tab label={t("Application Process")} {...a11yProps(0)} />
                        <Tab label={t("Eligibility Criteria")} {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <div className={university__tap__contant}>
                        <ul>
                          {toturDetailes?.application_process?.map(
                            (data: any) => {
                              return <li>{data}</li>;
                            }
                          )}
                        </ul>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className={university__tap__contant}>
                        <ul>
                          {toturDetailes?.eligibility_criteria?.map(
                            (data: any) => {
                              return <li>{data}</li>;
                            }
                          )}
                        </ul>
                      </div>
                    </TabPanel>
                  </div>
                )}

                {toturDetailes?.university_program?.length > 0 && (
                  <div className="Uiversity__Programs__text">{t("Programs")}</div>
                )}
                {toturDetailes?.university_program?.map((data: any, i: any) => {
                  return (
                    <>
                      <div className="Uiversity__accordien">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                              // style={{ transform: "rotate(180deg)" }}
                              />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className="title">
                              {data?.programs_offered}
                            </Typography>
                          </AccordionSummary>
                          {data?.program_classification?.map((sub: any) => {
                            return (
                              <AccordionDetails>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                      // style={{ transform: "rotate(180deg)" }}
                                      />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography className="title">
                                      {sub?.programs_name}
                                    </Typography>
                                  </AccordionSummary>
                                  {sub?.subprogram_classifiction?.map(
                                    (subChild: any) => {
                                      return (
                                        <AccordionDetails className="detailes__wapper">
                                          <Typography className="text">
                                            {subChild?.sub_program_name}
                                          </Typography>
                                          <div className="d-flex gap-2 align-items-center">
                                            <Typography className="text">
                                              {t("AED")}
                                            </Typography>
                                            <Typography className="green__text">
                                              {subChild?.price}
                                            </Typography>
                                          </div>
                                        </AccordionDetails>
                                      );
                                    }
                                  )}
                                </Accordion>
                              </AccordionDetails>
                            );
                          })}
                        </Accordion>
                      </div>
                    </>
                  );
                })}
                <div className="School__personal__detailes__wapper mt-3">
                  <div className="detailes__wapper">
                    <div className="left__side">
                      <img src={websiteIcon} alt="" />
                      <span>{t("Website")}</span>
                    </div>
                    <a
                      className="link"
                      href={toturDetailes?.web_link}
                      target="_blank"
                    >
                      {toturDetailes?.web_link}
                    </a>
                  </div>
                  <div className="detailes__wapper">
                    <div className="left__side">
                      <img src={emailIcon} alt="" />
                      <span>{t("Email")}</span>
                    </div>
                    <div className="number">{toturDetailes?.email}</div>
                  </div>
                  <div className="detailes__wapper">
                    <div className="left__side">
                      <img src={callIcon} alt="" />
                      <span>{t("Call")}</span>
                    </div>
                    <div className="number">{toturDetailes?.phone_number}</div>
                  </div>
                  {/* <div className="detailes__wapper">
                    <div className="left__side">
                      <img src={PersonIcon} alt="" />
                      <span>{t("Principal")}</span>
                    </div>
                    <div className="text">{toturDetailes?.principal}</div>
                  </div> */}
                  <div className="detailes__wapper">
                    <div className="left__side">
                      <img src={location_icon} alt="" />
                      <span>{t("Location")}</span>
                    </div>
                    <div className="text" title={toturDetailes?.address}>
                      {toturDetailes?.address}
                    </div>
                  </div>
                </div>
                <div className="chat__consultant__btn__wapper">
                  <button
                    className="chat__consultant__btn"
                    onClick={handleChatRequestOpen}
                  >
                    <img src={chat_consultant} alt="" />
                    <span>{t("Chat with our Consultant")}</span>
                  </button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default UniversityModelDilogView;
