import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./Favourites.scss";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import { encryptPassData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import universityImg from "../../Assets/University/universityImg.png";
import UniversityLikeIcon from "../../Assets/University/UniversityLikeIcon.svg";
import universityShareIcon from "../../Assets/University/universityShareIcon.svg";
import locationIcon from "../../Assets/Tutor/location.svg";
import StarIcon from "@mui/icons-material/Star";
import TutorImg from "../../Assets/Tutor/mapTutorIcon.svg";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import TutorshareIcon from "../../Assets/Tutor/share.svg";
import TutorLikeIcon from "../../Assets/Tutor/LikeIcon.svg";
import experienceIcon from "../../Assets/Tutor/experience.svg";
import teaching_mode from "../../Assets/Tutor/teaching_mode.svg";
import grades from "../../Assets/Tutor/grades.svg";
import curriculum from "../../Assets/Tutor/curriculum.svg";
import SchoolshareIcon from "../../Assets/School/shareIcon.svg";
import SchoolLikeIcon from "../../Assets/School/schoolLikeIcon.svg";
import ActivityFill from "../../Assets/HeartFill/ActivityFill.svg";
import SchoolFill from "../../Assets/HeartFill/SchoolFill.svg";
import TutorFill from "../../Assets/HeartFill/TutorFill.svg";
import UniversityFill from "../../Assets/HeartFill/UniversityFill.svg";
import activityshareIcon from "../../Assets/Activities/share_activity.svg";
import activityLikeIcon from "../../Assets/Activities/like_activity.svg";
import toast from "react-hot-toast";
import spacemen from "../../Assets/Global/spacemen.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThemeParShare from "../../Assets/ThemePark/shareIcon.svg";
import ThemeParkFilled from "../../Assets/ThemePark/HeartFilled.svg";
import ThemeParkOutline from "../../Assets/ThemePark/Heartoutline.svg";
import { useTranslation } from "react-i18next";
import WebShareComponent from "../WebShare/WebShare";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="body-tabpanel-divtag">
          <Typography className="body-tabpanel-ptag">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Favourites = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [FavouritesList, setFavouritesList] = useState<any>([]);
  const [TutorList, setTutorList] = useState<any>([]);
  const [SchoolList, setSchoolList] = useState<any>([]);
  const [UniversityList, setUniversityList] = useState<any>([]);
  const [ThemeParkList, setThemeParkList] = useState<any>([]);
  const [CampList, setCampList] = useState<any>([]);
  const [PersonalList, setPersonalList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  /**Loader state*/
  const Loader: any = useSelector((state: any) => {
    return state.loaderReducer.loader;
  });

  useEffect(() => {
    getFavouritesList(searchValue);
  }, [searchValue]);

  /**Get Parent view for all child*/
  const getFavouritesList = (value: any) => {
    axiosInstance
      .get(`${API_END_POINTS.FAVOURITE__LIST + value}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setFavouritesList(Response?.data?.result?.data);
          setTutorList(
            Response?.data?.result?.data?.filter(
              (item: any) =>
                item.service_provider_type ===
                GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT
            )
          );
          setSchoolList(
            Response?.data?.result?.data?.filter(
              (item: any) =>
                item.service_provider_type ===
                GLOBAL_CONSTANTS.INITIAL_VALUES.FOUR_INT
            )
          );
          setUniversityList(
            Response?.data?.result?.data?.filter(
              (item: any) =>
                item.service_provider_type ===
                GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT
            )
          );
          setCampList(
            Response?.data?.result?.data?.filter(
              (item: any) =>
                item.service_provider_type ===
                GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT
            )
          );
          setPersonalList(
            Response?.data?.result?.data?.filter(
              (item: any) =>
                item.service_provider_type ===
                GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT
            )
          );
          setThemeParkList(
            Response?.data?.result?.data?.filter(
              (item: any) =>
                item.service_provider_type ===
                GLOBAL_CONSTANTS.INITIAL_VALUES.SIX_INT
            )
          );
        }
      })
      .catch(() => { });
  };

  /**Handle change Favourites */
  const handleChangeFavourites = (val: any, id: any, type: any, campId: any) => {

    var payload = {};
    if (type == 3) {
      payload = {
        service_provider_type: type,
        service_provider: id,
        is_favourite: !val,
        camp_session: campId
      };
    } else {
      payload = {
        service_provider_type: type,
        service_provider: id,
        is_favourite: !val,
      };
    }
    axiosInstance
      .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
      .then((Response: any) => {
        if (Response?.data?.status) {
          getFavouritesList(searchValue);
          toast.success(Response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
        });
      });
  };


  const NavigateRoute = (type: any, data: any) => {
    if (type == GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT) {
      navigate(ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(data?.tutor_id));
    } else if (type == GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT) {
      navigate(ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(data?.universtiy_id));
    } else if (type == GLOBAL_CONSTANTS.INITIAL_VALUES.FOUR_INT) {
      navigate(ROUTES.SCHOOL_DETAILE_VIEW + "?id=" + encryptPassData(data?.school_id));
    } else if (type == GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT) {
      navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(data?.camp_session_id) + '&type=' + encryptPassData('camp'));
    } else if (type == GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT) {
      navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(data?.personal_id) + '&type=' + encryptPassData('personal'));
    } else if (type == GLOBAL_CONSTANTS.INITIAL_VALUES.SIX_INT) {
      navigate(ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + encryptPassData(data?.themepark_id));
    }
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const FavouritesTaps_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "Favourites_Taps_eng" : "Favourites_Taps_arab";
  const RightSideSearchLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "RightSideSearchLang_Eng" : "RightSideSearchLang_Arab";

  return (
    <>
      <div className="FavouritesList__container">

        <div className="header__container">
          <Box
            sx={{
              width: "70%",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              className={`${FavouritesTaps_Lang} Favourites__Taps`}
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              // aria-label="visible arrows tabs example"
              aria-label="scrollable force tabs example"
            >
              <Tab label={t("All")} {...a11yProps(0)} />
              <Tab label={t("Schools")} {...a11yProps(1)} />
              <Tab label={t("Tutors")} {...a11yProps(2)} />
              <Tab label={t("Personal Trainers")} {...a11yProps(3)} />
              <Tab label={t("Camps")} {...a11yProps(4)} />
              <Tab label={t("Parks & Events")} {...a11yProps(5)} />
              <Tab label={t("Universities")} {...a11yProps(6)} />
            </Tabs>
          </Box>
          <div className={`${RightSideSearchLang} rightSide__search`}>
            <input
              type="text"
              className="SettingSearch"
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(e: any) => {
                if (e.code === "Enter") {
                  if (searchValue.trim() === "") {
                    e.preventDefault();
                    getFavouritesList(searchValue);
                  } else {
                    getFavouritesList(searchValue);
                    e.preventDefault();
                  }
                }
              }}
              placeholder={t("search")}
            />
          </div>
        </div>


        <TabPanel value={value} index={0}>
          <div className="row FavouritesList__Scroll_wapper">
            {UniversityList?.map((item: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="university__list__wapper" onClick={() => {
                      NavigateRoute(item?.service_provider_type, item)
                    }}>
                      <div className="university__leftside">
                        <img
                          src={
                            nullUndefinedEmptyCheck(item?.profile_image)
                              ? item?.profile_image
                              : universityImg
                          }
                          alt=""
                        />
                      </div>
                      <div className="university__rightside">
                        <div className="university__name">
                          <span className="name">{item?.name}</span>
                          <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={
                                ROUTES.UNIVERSITY_DETAILE_VIEW +
                                "?id=" +
                                encryptPassData(item?.universtiy_id)
                              }
                            >
                              <img src={universityShareIcon} alt="" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(item?.is_favourite)
                                  ? UniversityFill
                                  : UniversityLikeIcon
                              }
                              alt=""
                              onClick={() =>
                                handleChangeFavourites(
                                  item?.is_favourite,
                                  item?.user,
                                  item?.service_provider_type,
                                  item.id
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="university__location">
                          <img src={locationIcon} alt="" />
                          <span className="location">{item?.city}</span>
                          <div className="location">
                            {t("Founded")} : {item?.founded_year}
                          </div>
                        </div>
                        <div className="university__rating">
                          <div className="university__ranking">
                            <div className="text__black">{t("QS")} : </div>
                            <div className="text__green">
                              {item?.QS_ranking}
                            </div>
                            <div className="text__black">{t("THE")} : </div>
                            <div className="text__green">
                              {item?.THE_ranking}
                            </div>
                            <div className="text__black">{t("SH")} : </div>
                            <div className="text__green">
                              {item?.SH_ranking}
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            {item?.overall_rating && (
                              <StarIcon className="StarIcon" />
                            )}
                            <span className="points">
                              {item?.overall_rating}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(item?.image)
                            ? item?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{item.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {TutorList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="Tutor__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.profile_image)
                            ? data?.profile_image
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.name}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(data?.tutor_id)}
                            >
                              <img src={TutorshareIcon} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? TutorFill
                                  : TutorLikeIcon
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="user__Detailes__location">
                          <img
                            src={locationIcon}
                            alt="locationIcon"
                            className="grayIcons"
                          />
                          <div className="graytext">{data.city}</div>
                          <img
                            src={experienceIcon}
                            alt="experienceIcon"
                            className="grayIcons"
                          />
                          <div className="graytext">{data?.experience}</div>
                          <img
                            src={teaching_mode}
                            alt="teaching_mode"
                            className="grayIcons"
                          />
                          <div className="graytext">{data?.course_mode}</div>
                        </div>

                        <div className="user__Detailes__Course">
                          <img src={grades} alt="shareIcon" />
                          <div className="lable">{data?.courses}</div>
                          <img src={curriculum} alt="curriculum" />
                          <div className="lable">{data?.curriculum}</div>
                        </div>

                        <div className="user__Detailes__rating">
                          <h1 className="title">
                            {data?.final_fee > 0
                              ? "AED " + data?.final_fee
                              : ""}
                          </h1>
                          <div className="d-flex gap-2 align-items-center">
                            {data.rating && <StarIcon className="StarIcon" />}
                            <div className="card__title">{data.rating}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {SchoolList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="School__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.profile_image)
                            ? data?.profile_image
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.name}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.SCHOOL_DETAILE_VIEW + "?id=" + encryptPassData(data?.school_id)}
                            >
                              <img src={SchoolshareIcon} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? SchoolFill
                                  : SchoolLikeIcon
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="user__Detailes__location">
                          <div className="School_infowindow__text">
                            {data?.ds_rating}
                          </div>
                        </div>
                        <div className="user__Detailes__location">
                          <img src={curriculum} alt="curriculum" />
                          <div className="lable">{data?.curriculam}</div>
                          <img
                            src={locationIcon}
                            alt="locationIcon"
                            className="grayIcons"
                          />
                          <div className="lable">{data?.city}</div>
                          <img src={grades} alt="shareIcon" />
                          <div className="lable">
                            {data?.grade ? data?.grade : "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {CampList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="Acrivity__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          data?.camp_images.length > 0
                            ? data?.camp_images[0]
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.camp_session_title}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(data?.camp_session_id) + '&type=' + encryptPassData('camp')}
                            >
                              <img src={activityshareIcon} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? ActivityFill
                                  : activityLikeIcon
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.camp_session_id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="user__Detailes__Slots_activities">
                          <div className="lable">{data?.activites}</div>
                        </div>

                        <div className="user__Detailes__rating_Activities">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="card__title">{t("Price")} : </div>
                            <div className="title">
                              {data?.final_fee ? "AED " + data?.final_fee : ""}
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            {data.rating && <StarIcon className="StarIcon" />}
                            <div className="card__title">{data?.rating}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {PersonalList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="Acrivity__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.profile_image)
                            ? data?.profile_image
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.name}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(data?.personal_id) + '&type=' + encryptPassData('personal')}
                            >
                              <img src={activityshareIcon} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? ActivityFill
                                  : activityLikeIcon
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="user__Detailes__Slots_activities">
                          <div className="lable">{data?.activites}</div>
                          <div className="Slots">{t("Slots")}:</div>
                          <div className="SlotsYes">{t("Yes")}</div>
                        </div>

                        <div className="user__Detailes__rating_Activities">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="card__title">{t("Price")} : </div>
                            <div className="title">
                              {data?.final_fee ? "AED " + data?.final_fee : ""}
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            {data.rating && <StarIcon className="StarIcon" />}
                            <div className="card__title">{data?.rating}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {ThemeParkList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="Acrivity__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          data?.themepark_image.length > 0
                            ? data?.themepark_image[0]
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.themepark_name}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + encryptPassData(data?.themepark_id)}
                            >
                              <img src={ThemeParShare} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? ThemeParkFilled
                                  : ThemeParkOutline
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.id
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="user__Detailes__Slots_activities">
                          <div className="lable">{data?.activites}</div>
                          <div className="Slots">Slots:</div>
                          <div className="SlotsYes">Yes</div>
                        </div> */}
                        <div className="user__Detailes__location">
                          <img
                            src={locationIcon}
                            alt="locationIcon"
                            className="grayIcons"
                          />
                          <div className="graytext">{data.city}</div>
                        </div>

                        <div className="user__Detailes__rating_themePark">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="card__title">{t("Price")} : </div>
                            <div className="title">
                              {t("AED")} {data?.min_price}{" "}{t("AED")} {data?.max_price}
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            {data.rating && <StarIcon className="StarIcon" />}
                            <div className="card__title">{data?.rating}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {FavouritesList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="row FavouritesList__Scroll_wapper">
            {SchoolList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="School__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.profile_image)
                            ? data?.profile_image
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.name}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.SCHOOL_DETAILE_VIEW + "?id=" + encryptPassData(data?.school_id)}
                            >
                              <img src={SchoolshareIcon} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? SchoolFill
                                  : SchoolLikeIcon
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="user__Detailes__location">
                          <div className="School_infowindow__text">
                            {data?.ds_rating}
                          </div>
                        </div>
                        <div className="user__Detailes__location">
                          <img src={curriculum} alt="curriculum" />
                          <div className="lable">{data?.curriculam}</div>
                          <img
                            src={locationIcon}
                            alt="locationIcon"
                            className="grayIcons"
                          />
                          <div className="lable">{data?.city}</div>
                          <img src={grades} alt="shareIcon" />
                          <div className="lable">
                            {data?.grade ? data?.grade : "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {SchoolList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="row FavouritesList__Scroll_wapper">
            {TutorList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="Tutor__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.profile_image)
                            ? data?.profile_image
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.name}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(data?.tutor_id)}
                            >
                              <img src={TutorshareIcon} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? TutorFill
                                  : TutorLikeIcon
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="user__Detailes__location">
                          <img
                            src={locationIcon}
                            alt="locationIcon"
                            className="grayIcons"
                          />
                          <div className="graytext">{data.city}</div>
                          <img
                            src={experienceIcon}
                            alt="experienceIcon"
                            className="grayIcons"
                          />
                          <div className="graytext">{data?.experience}</div>
                          <img
                            src={teaching_mode}
                            alt="teaching_mode"
                            className="grayIcons"
                          />
                          <div className="graytext">{data?.course_mode}</div>
                        </div>

                        <div className="user__Detailes__Course">
                          <img src={grades} alt="shareIcon" />
                          <div className="lable">{data?.courses}</div>
                          <img src={curriculum} alt="curriculum" />
                          <div className="lable">{data?.curriculum}</div>
                        </div>

                        <div className="user__Detailes__rating">
                          {/* <h1 className="title">
                            {data?.final_fee > 0
                              ? "AED " + data?.final_fee
                              : ""}
                          </h1> */}
                          <h1 className="title">
                            {data?.final_fee > 0
                              ? `${t("AED")} ${data?.final_fee}`
                              : ""}
                          </h1>
                          <div className="d-flex gap-2 align-items-center">
                            {data.rating && <StarIcon className="StarIcon" />}
                            <div className="card__title">{data.rating}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {TutorList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="row FavouritesList__Scroll_wapper">
            {PersonalList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="Acrivity__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.profile_image)
                            ? data?.profile_image
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.name}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(data?.personal_id) + '&type=' + encryptPassData('personal')}
                            >
                              <img src={activityshareIcon} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? ActivityFill
                                  : activityLikeIcon
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="user__Detailes__Slots_activities">
                          <div className="lable">{data?.activites}</div>
                          <div className="Slots">{t("Slots")}:</div>
                          <div className="SlotsYes">{t("Yes")}</div>
                        </div>

                        <div className="user__Detailes__rating_Activities">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="card__title">{t("Price")} : </div>
                            <div className="title">
                              {data?.final_fee ? "AED " + data?.final_fee : ""}
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            {data.rating && <StarIcon className="StarIcon" />}
                            <div className="card__title">{data?.rating}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {PersonalList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className="row FavouritesList__Scroll_wapper">
            {CampList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="Acrivity__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          data?.camp_images.length > 0
                            ? data?.camp_images[0]
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.camp_session_title}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(data?.camp_session_id) + '&type=' + encryptPassData('camp')}
                            >
                              <img src={activityshareIcon} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? ActivityFill
                                  : activityLikeIcon
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.camp_session_id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="user__Detailes__Slots_activities">
                          <div className="lable">{data?.activites}</div>
                        </div>

                        <div className="user__Detailes__rating_Activities">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="card__title">{t("Price")} : </div>
                            <div className="title">
                              {data?.final_fee ? `${t("AED")}` + data?.final_fee : ""}
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            {data.rating && <StarIcon className="StarIcon" />}
                            <div className="card__title">{data?.rating}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {CampList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <div className="row FavouritesList__Scroll_wapper">
            {ThemeParkList?.map((data: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="Acrivity__Favourites__Card" onClick={() => {
                      NavigateRoute(data?.service_provider_type, data)
                    }}>
                      <img
                        src={
                          data?.themepark_image.length > 0
                            ? data?.themepark_image[0]
                            : tutor_img
                        }
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image"
                      />
                      <div className="rightside_contant">
                        <div className="user__Detailes">
                          <div className="user__name">{data?.themepark_name}</div>
                          <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + encryptPassData(data?.themepark_id)}
                            >
                              <img src={ThemeParShare} alt="shareIcon" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.is_favourite)
                                  ? ThemeParkFilled
                                  : ThemeParkOutline
                              }
                              alt="LikeIcon"
                              onClick={() =>
                                handleChangeFavourites(
                                  data?.is_favourite,
                                  data?.user,
                                  data?.service_provider_type,
                                  data.id
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="user__Detailes__Slots_activities">
                          <div className="lable">{data?.activites}</div>
                          <div className="Slots">Slots:</div>
                          <div className="SlotsYes">Yes</div>
                        </div> */}
                        <div className="user__Detailes__location">
                          <img
                            src={locationIcon}
                            alt="locationIcon"
                            className="grayIcons"
                          />
                          <div className="graytext">{data.city}</div>
                        </div>

                        <div className="user__Detailes__rating_themePark">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="card__title">{t("Price")} : </div>
                            <div className="title">
                              {"AED " + data?.min_price}{" "}{"AED " + data?.max_price}
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            {data.rating && <StarIcon className="StarIcon" />}
                            <div className="card__title">{data?.rating}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(data?.image)
                            ? data?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{data?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {ThemeParkList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div className="row FavouritesList__Scroll_wapper">
            {UniversityList?.map((item: any, i: any) => {
              return (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                    <div className="university__list__wapper" onClick={() => {
                      NavigateRoute(item?.service_provider_type, item)
                    }}>
                      <div className="university__leftside">
                        <img
                          src={
                            nullUndefinedEmptyCheck(item?.profile_image)
                              ? item?.profile_image
                              : universityImg
                          }
                          alt=""
                        />
                      </div>
                      <div className="university__rightside">
                        <div className="university__name">
                          <span className="name">{item?.name}</span>
                          <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                            <WebShareComponent
                              params={ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(item?.universtiy_id)}
                            >
                              <img src={universityShareIcon} alt="" />
                            </WebShareComponent>
                            <img
                              src={
                                nullUndefinedEmptyCheck(item?.is_favourite)
                                  ? UniversityFill
                                  : UniversityLikeIcon
                              }
                              alt=""
                              onClick={() =>
                                handleChangeFavourites(
                                  item?.is_favourite,
                                  item?.user,
                                  item?.service_provider_type,
                                  item.id
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="university__location">
                          <img src={locationIcon} alt="" />
                          <span className="location">{item?.city}</span>
                          <div className="location">
                            {t("Founded")} : {item?.founded_year}
                          </div>
                        </div>
                        <div className="university__rating">
                          <div className="university__ranking">
                            <div className="text__black">{t("QS")} : </div>
                            <div className="text__green">
                              {item?.QS_ranking}
                            </div>
                            <div className="text__black">{t("THE")} : </div>
                            <div className="text__green">
                              {item?.THE_ranking}
                            </div>
                            <div className="text__black">{t("SH")} : </div>
                            <div className="text__green">
                              {item?.SH_ranking}
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            {item?.overall_rating && (
                              <StarIcon className="StarIcon" />
                            )}
                            <span className="points">
                              {item?.overall_rating}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parent__contant__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(item?.image)
                            ? item?.image
                            : tutor_img
                        }
                        alt=""
                      />
                      <span>{item?.username}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {UniversityList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </TabPanel>
      </div>
    </>
  );
};

export default Favourites;
