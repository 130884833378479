import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './ThemeParkGuestUserLogin.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
    API_END_POINTS,
    AUTHENTICATION,
    GLOBAL_CONSTANTS,
} from "../../../Constants/Constants";
import CloseIcon from '@mui/icons-material/Close';
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import toast from 'react-hot-toast';
import { SetSessionStorageData } from '../../../Utilities/Helpers/Helper';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";


interface ThemeParkGuestUserLoginProps {
    openGuestSignupDialog: any;
    handleGuestSignUpClose: (isSignupNavigation: any) => void;
    setOpenGuestOtpDialog: any;
    handleOpenLoginSignUpDialogClose: any;
    setOpenOtpVerificationDialog: any;
    setIsEmail:any;
    setUserName:any;
    setIsLogin:any;
    isLogin:any;
}

const ThemeParkGuestUserLogin: React.FC<ThemeParkGuestUserLoginProps> = (props: ThemeParkGuestUserLoginProps) => {
    const { setIsLogin,isLogin, setIsEmail,setUserName, openGuestSignupDialog, handleGuestSignUpClose, setOpenGuestOtpDialog, handleOpenLoginSignUpDialogClose, setOpenOtpVerificationDialog } = props;
    // const [activeButton, setActiveButton] = useState<string>('phone');
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        // phone: activeButton === 'phone' || activeButton === '' ? Yup.string().required('Phone is required') : Yup.string()
    });

    const guestUserSignUp = (payload: any, actions: any) => {
        // setIsEmail( GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
        // setUserName(payload[AUTHENTICATION.LOGIN_SIGNUP_FIELDS.EMAIL]);
        // setIsLogin(!isLogin);

        let data = {
            login_type: 1,
            email: payload.email
        }
        axiosInstance
            .post(`${API_END_POINTS.THEMEPARK_GUESTUSER}`, data)
            .then((res: any) => {
                console.log(res, "res")
                if (res.data[AUTHENTICATION.SHARED.STATUS] === AUTHENTICATION.SHARED.TRUE) {
                    SetSessionStorageData(AUTHENTICATION.SHARED.GUEST_DATA, JSON.stringify(payload))
                    toast.success(res.data.message, {
                        position: "top-right"
                    });

                    handleGuestSignUpClose(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
                    setOpenGuestOtpDialog(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
                    actions.resetForm();
                }
            })
            .catch((err) => {
                console.log(err, "err")
                if (err?.response?.data?.result?.registered_user === GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE) {
                    console.log("already registered")
                    handleGuestSignUpClose(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
                    
                    // setOpenGuestOtpDialog(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);

                    // User login page
                    handleOpenLoginSignUpDialogClose(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);

                    // User login verification page
                    // setOpenOtpVerificationDialog( GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
                }
                actions.resetForm();

            });
    };

        /**get selected or current language from redux */
        const language: any = useSelector((state: any) => {
            return state.languageReducer.language;
          });

       const GuestuUserLoginModel= language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "guest-user-login-model-english" : "guest-user-login-model-arabic";

    return (
        <Dialog
            className={`${GuestuUserLoginModel} guest-user-login-model`}
            open={openGuestSignupDialog}
            keepMounted
            hideBackdrop={true}
            onClose={() => handleGuestSignUpClose(GLOBAL_CONSTANTS.INITIAL_VALUES.FLASE)}
            aria-describedby="alert-dialog-slide-description"
            dir={
                language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
                  ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
                  : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
              }
        >
            <div
                onClick={() => handleGuestSignUpClose(GLOBAL_CONSTANTS.INITIAL_VALUES.FLASE)}
                className="skip_button" style={{ top: '15px' }}
            >
                <CloseIcon className="close__icon" />
            </div>
            <DialogTitle className="title">{t("Add Info")}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        // phone: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        console.log(values);
                        guestUserSignUp(values, actions);
                        // setSubmitting(false);
                        // handleGuestSignUpClose();
                        // resetForm();
                    }}
                >
                    {({ isSubmitting, resetForm }) => (
                        <Form>

                            <div className="custom-form-group">
                                <label htmlFor="firstName">{t("First Name")}</label>
                                <Field placeholder="Enter your first name" name="firstName" as="input" type="text" />
                                <ErrorMessage name="firstName" component="div" className="error-message" />
                            </div>

                            <div className="custom-form-group">
                                <label htmlFor="lastName">{t("Last Name")}</label>
                                <Field placeholder="Enter your last name" name="lastName" as="input" type="text" />
                                <ErrorMessage name="lastName" component="div" className="error-message" />
                            </div>

                            <div className="custom-form-group">
                                <label>{t("Email")}</label>
                                <Field
                                    className="email-phone-input-field"
                                    name='email'
                                    as="input"
                                    type="text"
                                    placeholder='Email'
                                />
                                <ErrorMessage
                                    name='email'
                                    component="div"
                                    className="error-message"
                                />
                            </div>

                            <div>
                                <button className='submit-button' type="submit" disabled={isSubmitting}>
                                {t("Send OTP")}
                                </button>
                                <div className='text-center'>
                                 {t("Don't have an account")}  <button className='signup-button' onClick={() => handleGuestSignUpClose(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE)}>{t("Create")}</button>
                                </div>

                            </div>


                            {/* <DialogActions className='guest-user-action-button'>
                               
                            </DialogActions> */}
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default ThemeParkGuestUserLogin;
