import { useEffect, useState } from "react";
import "./TutorsDashboardLayout.scss";
import TutorMaps from "../../Components/TutorMaps/TutorMaps";
import TutorCardsCarousel from "../../Components/TutorCardsCarousel/TutorCardsCarousel";
import TopTutors from "../../Components/TopTutors/TopTutors";
import search from "../../Assets/Tutor/searchIconGray.svg";
import TutorImg from "../../Assets/Tutor/TutorImg.svg";
import FilterIcon from "../../Assets/Tutor/FilterIcon.svg";
import Chemistry from "../../Assets/Tutor_Subjects/Chemistry.svg";
import tutorImg1 from "../../Assets/Tutor/tutorImg1.png";
import tutorImg2 from "../../Assets/Tutor/tutorImg2.png";
import tutorImg3 from "../../Assets/Tutor/tutorImg3.png";
import tutorImg4 from "../../Assets/Tutor/tutorImg4.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  HOMEPAGE,
  ROUTES,
  onInputChangeGoogleApi,
} from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./Dropdown";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { store } from "../../Redux/Store/Store";
import { setLoader, setTutorFilter } from "../../Redux/Reducer/Reducer";
import axios from "axios";
import {
  GetSessionStorageData,
  encryptPassData,
  getCurrentLocation,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import Rating from "@mui/material/Rating";
import ExpandMap from "../../Assets/Activities/ExpandMap.svg";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useSelector } from "react-redux";
import Discount_Symbole from "../../Assets/Global/Discount_Symbole.svg";
import TutorFilterPopup from "./TutorFilterPopup/TutorFilterPop";
import TutorCategoryPopup from "./TutorCategoryPopup/TutorCategoryPopup";
import FilterPopup from "../../Components/FilterPopup/FilterPopup";
import AppFilter from "../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TutorsDashboardLayout = () => {
  const route = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [coursecategory, setCoursecategory] = useState([]);
  const [promotionalContent, setPromotionalContent] = useState([]);
  const [tutorList, setTutorList] = useState([]);
  const [searchTextList, setSearchTextList] = useState([]);
  const [currentLat, setCurrentLat] = useState<any>("");
  const [currentLang, setCurrentLang] = useState<any>("");
  const [IsTrigger, setIsTrigger] = useState<any>(false);
  const [nearSearchList, setNearSearchList] = useState<any>([]);
  const [ResetBoolean, setResetBoolean] = useState(false);
  const [itemsToShow, setItemsToShow] = useState<any>(7);
  const [DiscountList, setDiscountList] = useState<any>([]);
  const [navColour, updateNavbar] = useState(false);
  const [CategoryPopOpen, setCategoryPopOpen] = useState(false);
  const { t } = useTranslation();
  // const myfilter:any = GetSessionStorageData("tutor_filter");
  // console.log("filer from dashboard",JSON.parse(myfilter))

  const tutorfilter: any = useSelector((state: any) => {
    return state.filterReducer.tutorfilter
  })

  // Filter
  const init_filter = {
    courses: [],
    curriculum: [],
    price: [0, 0],
    mode_of_teaching: [],
    experience: [0, 0],
    rating: 0,
    gender: [],
    grade: [0, 0],
    radius: 10,

  }
  const [FilterPopOpen, setFilterPopOpen] = useState(false);
  const [FilterData, setFilterData] = useState<any>([]);
  const [initFilter, setInitFilter] = useState<any>(Object.values(tutorfilter)?.length > 0 ? tutorfilter : init_filter);
  const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
  const [initSort, setSort] = useState<any>({
    price: '',
    rating: ''
  });


  const onOpenFilterPop = () => {
    setFilterPopOpen(true);
  };

  const onCloseFilterPop = () => {
    setFilterPopOpen(false);
  };



  // function scrollHandler() {
  //   window.scrollY >= 100 ? updateNavbar(true) : updateNavbar(false);
  // }

  // window.addEventListener("scroll", scrollHandler);

  const handleShowMore = () => {
    // Increase the number of items to show when "Show More" is clicked
    // setItemsToShow(itemsToShow + 2); // You can change the number as needed
    //backup code inserBefore Error
    setCategoryPopOpen(true)
  };

  const handleShowLess = () => {
    setItemsToShow(6);
  };

  // Render the  Map view Page size
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  // Route to the Map view Page
  const handleRouteMap = (value: any) => {
    navigate(ROUTES.TUTORS_MAPS_VIEW, {
      state: {
        tutor_list: tutorList,
        search_text: value,
        redius: FilterApplyData.radius,
        lat: currentLat,
        lang: currentLang,
        filter: FilterApplyData,
        filterBoolean: IsTrigger,
      },
    });
  };



  const errorCallback = (error: any) => {
    // console.log("errorCallback", error);
  };

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng)) {
      setCurrentLat(HeaderLoction?.lat);
      setCurrentLang(HeaderLoction?.lng);
      if (tutorfilter && Object.values(tutorfilter)?.length > 0) {
        TutorListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, tutorfilter);
      } else {
        TutorListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
      }
    }
  }, [HeaderLoction, ResetBoolean, FilterApplyData]);

  useEffect(() => {
    // Get current user's location using the browser's Geolocation API
    // getCurrentLocation(successCallback, errorCallback);
    getFilterData();
    getPromotionalContentData();
    getCourse_Categories();
    // get_Discount_List();
  }, []);

  const payload = (latitude: any, longitude: any, value: string, filter: any) => {
    var payload;
    var priceValue: any = [];
    var experienceValue: any = [];
    var gradeValue: any = [];

    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }
    if (initFilter.experience[0] === filter.experience[0] && initFilter.experience[1] === filter.experience[1]) {
      experienceValue = []
    } else {
      experienceValue = {
        min: filter.experience[0],
        max: filter.experience[1],
      }
    }
    if (initFilter.grade[0] === filter.grade[0] && initFilter.grade[1] === filter.grade[1]) {
      gradeValue = []
    } else {
      gradeValue = {
        min: filter.grade[0],
        max: filter.grade[1],
      }
    }
    return payload = {
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      radius: filter.radius,
      search: value ? value : "",
      price: priceValue,
      experience: experienceValue,
      grade: gradeValue,
      courses: filter.courses,
      mode_of_teaching: filter.mode_of_teaching,
      gender: filter.gender,
      curriculum: filter.curriculum,
      rating: filter.rating,
    };
  };

  /**Tutor get List Api*/
  const TutorListApi = (latitude: any, longitude: any, value: string, filter: any) => {
    var pay = payload(latitude, longitude, value, filter);
    axiosInstance
      .post(`${API_END_POINTS.TUTORLIST}`, pay)
      .then((Response: any) => {
        setTutorList(Response?.data?.result?.data);
      })
      .catch(() => { });
  };

  /**Tutor Search Keyword Api*/
  const searchTutorKeyword = (search: any) => {
    var payload = {
      search_text: search.trim(),
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL +
        API_END_POINTS.TUTOR_KEY_WORD_SEARCH
        }`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          },
        }
      )
      .then((Response: any) => {
        setSearchTextList(Response?.data.result?.data);
      })
      .catch(() => { });
  };

  /**Get Course Categories List*/
  const getCourse_Categories = () => {
    axiosInstance
      .get(`${API_END_POINTS.TUTOR_COURSE_CATEGORIES}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setCoursecategory(Response?.data?.result?.data);
        }
      })
      .catch(() => { });
  };

  const getPromotionalContentData = () => {
    axiosInstance
      .get(`${API_END_POINTS.PROMOTIONAL_CONTENT_LIST}?service_provider_type=1`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          let data = Response?.data?.result?.data;
          data.sort((a: any, b: any) => a.id - b.id);
          setPromotionalContent(data);
        }
      })
      .catch(() => { });
  };

  /**Get Discount List*/
  const get_Discount_List = () => {
    axiosInstance
      .get(`${API_END_POINTS.DISCOUNT_LIST}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setDiscountList(Response?.data?.result?.data);
        }
      })
      .catch(() => { });
  };

  /**Get Filter Categories List*/
  const getFilterData = () => {
    axiosInstance
      .get(`${API_END_POINTS.TUTORFILTERLIST}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          let data = Response?.data?.result?.data;
          setFilterData(data);
          if (data) {
            let init = {
              courses: [],
              curriculum: [],
              price: [data.price?.min, data.price?.max],
              mode_of_teaching: [],
              experience: [data.experience?.min, data.experience?.max],
              rating: 0,
              gender: [],
              grade: [data.grade?.min, data.grade?.max],
              radius: 10,
            }
            setInitFilter(init);
            setFilterApplyData(Object.keys(tutorfilter)?.length > 0 ? tutorfilter : init);
          }
        }
      })
      .catch(() => { });
  };

  /**Filter Apply api call Function*/
  const FilterApply = (filter: any) => {
    setIsTrigger(true);
    // JSON.stringify(filter);
    // sessionStorage.setItem("tutor_filter", JSON.stringify(filter));
    // console.log("filter, stored",filter)
    store.dispatch(setTutorFilter(filter))
    setFilterApplyData(filter);
    navigate(ROUTES.TUTORS_LIST_VIEW, {
      state: {
        tutor_list: null,
        search_text: searchValue,
        redius: filter.radius,
        lat: currentLat,
        lang: currentLang,
        filter: filter,
        filterBoolean: true,
      },
    });
  };

  /**Filter Reset Function*/
  const FilterReset = () => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    setFilterApplyData(initFilter);
    setTimeout(() => {
      setResetBoolean(!ResetBoolean);
      store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    }, 500);
  };

  /**AutoComplete Onchange Function*/
  const onInputChange = (value: any) => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    if (nullUndefinedEmptyCheck(value) && value?.length > 2) {
      setSearchValue(value);
      searchTutorKeyword(value);
    } else if (value?.length == 0) {
      TutorListApi(currentLat, currentLang, searchValue, FilterApplyData);
    }
  };

  /**AutoComplete submit Function*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //  prevent page refresh
    event.preventDefault();
    TutorListApi(currentLat, currentLang, searchValue, FilterApplyData);
  };

  /**Coursecategory Based filter the Data*/
  const handlePassCoursecategory = (value: any) => {
    if (nullUndefinedEmptyCheck(value)) {
      if (value?.includes("near me")) {
        navigate(ROUTES.TUTORS_LIST_VIEW, {
          state: {
            tutor_list: "",
            search_text: value,
            redius: FilterApplyData.radius,
            lat: currentLat,
            lang: currentLang,
            map_show: true,
            filter: FilterApplyData,
            filterBoolean: IsTrigger,
          },
        });
      } else {
        navigate(ROUTES.TUTORS_LIST_VIEW, {
          state: {
            tutor_list: "",
            search_text: value,
            redius: FilterApplyData.radius,
            lat: currentLat,
            lang: currentLang,
            map_show: false,
            filter: FilterApplyData,
            filterBoolean: IsTrigger,
          },
        });
      }
    } else {
      TutorListApi(currentLat, currentLang, value, FilterApplyData);
    }
  };

  /**onInputChangeGoogle api Function*/
  const onInputChangeGoogle = async (value: any) => {
    var response = await onInputChangeGoogleApi(value);
    if (nullUndefinedEmptyCheck(response?.data)) {
      setNearSearchList(response?.data?.results);
    } else {
      setNearSearchList([]);
    }
  };



  const onCloseCategoryPop = () => {
    setCategoryPopOpen(false);
  };

  const onNavigate = (content: any) => {
    let provider_id = content.provider_id
    if (nullUndefinedEmptyCheck(provider_id)) {
      navigate(ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(provider_id));
    } else {
      navigate(ROUTES.TUTORS_LIST_VIEW, {
        state: {
          tutor_list: "",
          search_text: "",
          redius: FilterApplyData.radius,
          lat: currentLat,
          lang: currentLang,
          map_show: false,
          filter: FilterApplyData,
          filterBoolean: IsTrigger,
        },
      });
    }
  };

  const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
    if (checkedPrice == 'high') {
      const sortedData = [...tutorList].sort((a: any, b: any) => b?.least_price - a?.least_price);
      setTutorList(sortedData);
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedPrice == 'low') {
      const sortedData = [...tutorList].sort((a: any, b: any) => a?.least_price - b?.least_price);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'high') {
      const sortedData = [...tutorList].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'low') {
      const sortedData = [...tutorList].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    }
  }


  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const wrapperClassName_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "tutors-filter-wrap-english" : "tutors-filter-wrap_arabic";

  const TutorCard_Img_wapper_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "TutorCard_Img_wapper-english" : "TutorCard_Img_wapper_arabic";

  const search_button_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_button" : "search_button_arabic";

  const Tutor__text__contant_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "Tutor_text_contant_english" : "Tutor_text_contant_arabic";

  const TutorMaps__view_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "TutorMaps__view_english" : "TutorMaps__view_arabic";

  const top__tutors__container_list_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top__tutors__container_list_english" : "top__tutors__container_list_arabic";

  const tutors_scroll_content_two_Lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "tutors_scroll_content_two_english" : "tutors_scroll_content_two_arabic";

  const TutorSubject_Img_span = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "TutorSubject_Img_wapper_span_english" : "TutorSubject_Img_wapper_span_arabic";

  return (
    <>
      {CategoryPopOpen && (
        <TutorCategoryPopup
          PopOpen={CategoryPopOpen}
          onClosePop={onCloseCategoryPop}
          currentLat={currentLat}
          currentLang={currentLang}
          CourseData={coursecategory}
          FilterRadiusvalue={FilterApplyData.radius}
          FilterApplyData={FilterApplyData}
        />
      )}
      {/* {FilterPopOpen && (
        <FilterPopup
          FilterPopOpen={FilterPopOpen}
          onClosePop={onCloseFilterPop}
          FilterApply={FilterApply}
          FilterApplyData={FilterApplyData}
          initFilter={init_filter}
          type="tutor"
          color="#178fff"
        />
      )} */}
      <div className="row tutor__container">

        {/* <div className="TutorCard_Img_wapper"> */}
        <div className={`TutorCard_Img_wapper ${TutorCard_Img_wapper_Lang}`}>
          <img draggable="false" src={TutorImg} alt="TutorImg" className="" />
          {/* <div className="TutorImg__text">{HOMEPAGE.SERVICE_TYPE.TUTOR}</div> */}
          <div className="TutorImg__text">{t("Tutors")}</div>
        </div>
        <div className="mb-4 tutors-head-row">
          <div className="tutors-search-part">
            <div className="search_field_wrapper">
              <div className="search__autocomplete">
                <form onSubmit={handleSubmit}>
                  <Autocomplete
                    freeSolo
                    // popupIcon
                    popupIcon={<ExpandMoreIcon />}
                    id="free-solo-2-demo"
                    disableClearable={false}
                    clearIcon={false}
                    value={searchValue}
                    onChange={(e, value) => {
                      setSearchValue(value);
                      handlePassCoursecategory(value);
                      // TutorListApi(currentLat, currentLang, value)
                    }}
                    options={searchTextList?.map((option: any) => option?.tag)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("Search for tutor")}
                        onChange={(inputChangeEvent: any) => {
                          onInputChange(inputChangeEvent.target.value.trim());
                        }}
                        onKeyDown={(e: any) => {
                          if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                            if (searchValue.trim() === "") {
                              e.preventDefault();
                            } else {
                              handlePassCoursecategory(searchValue);
                              e.preventDefault();
                            }
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          type: "text",
                        }}
                      />
                    )}
                  />
                  <img
                    draggable="false"
                    src={search}
                    alt="search"
                    className={search_button_Lang}
                    onClick={() => handlePassCoursecategory(searchValue)}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className={`tutors-filter-wrap ${wrapperClassName_Lang}`}>
            <AppFilter
              FilterPopOpen={FilterPopOpen}
              onOpenPop={onOpenFilterPop}
              onClosePop={onCloseFilterPop}
              FilterData={FilterData}
              FilterApply={FilterApply}
              FilterApplyData={FilterApplyData}
              initFilter={initFilter}
              initSort={initSort}
              handleApplySort={handleProceedSort}
              type="tutor"
              color="#178fff" />
          </div>
        </div>



        <div className="TutorSubject__container">
          {coursecategory?.slice(0, itemsToShow).map((data: any, i: any) => {
            return (
              <div
                className="TutorSubject_Img_wapper"
                key={data.id || i}
                onClick={() =>
                  handlePassCoursecategory(data?.course_category_name)
                }
              >
                <img
                  draggable="false"
                  src={
                    nullUndefinedEmptyCheck(data?.display_image)
                      ? data?.display_image
                      : Chemistry
                  }
                  onError={(e: any) => { e.target.src = Chemistry; }}
                  alt="TutorImg"
                  className=""
                />
                <span className={TutorSubject_Img_span}>{data?.course_category_name}</span>
              </div>
            );
          })}
          {/* {coursecategory?.length > 6 ? (
            <>
              {coursecategory?.length > itemsToShow ? (
                <div
                  className="TutorSubject_Img_wapper Show__more"
                  onClick={handleShowMore}
                >
                  <div>Show more</div>
                </div>
              ) : (
                <div
                  className="TutorSubject_Img_wapper Show__more"
                  onClick={handleShowLess}
                >
                  <div>Show less</div>
                </div>
              )}
            </>
          ) : (
            ""
          )} */}

          {coursecategory?.length > itemsToShow ? (
            <div
              className="TutorSubject_Img_wapper Show__more"
              onClick={handleShowMore}
            >
              <div>
                {t("Show more")}
              </div>
            </div>
          ) : ("")}

        </div>
        {/* ********************************** For Scroll Container ************************************ */}
        <div className="row scroll__container mt-4 pt-2">

          <div className="tutors-scroll-content-one">

            <div className={`Tutor__text__contant ${Tutor__text__contant_Lang}`}>
              <h1 className="text_contant_h1">
                <span>{t("LEARN")}</span>
                <br />
                <span>{t("from")}</span>
                <br />
                <span>{t("the BEST")}</span>
              </h1>
              <p>
                {t("With 500+ tutors in our platform, find the best tutor for your kid")}

              </p>
            </div>



            <div className="Middle__leftside_wapper">

              {promotionalContent.length > 0 ? promotionalContent.map((content: any, index: number) => {
                if (index <= 1) {
                  return (
                    <div className="tutorImg4" key={index} onClick={() => onNavigate(content)}>
                      <img
                        src={content.image}
                        alt="tutorImg4"
                        className="Middle__leftside__img"
                        onError={(e: any) => { e.target.src = tutorImg1; }}
                      />
                      <div className="discount__wapper">
                        <div className="discount__flex__column">
                          <div className="discount__text__contant notranslate">
                            {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? content?.description : content?.description_ar}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

              }) : ""}




              {/* <div className="tutorImg4">
                <img
                  src={tutorImg4}
                  alt="tutorImg4"
                  className="Middle__leftside__img"
                />
                <div className="discount__wapper">
                  <div className="discount__flex__column">
                    <div className="discount__text__contant">
                      Acing Advanced Physics
                    </div>
                    <div className="discount__flex__row">
                      <div className="discount__text__contant">
                        Book now
                      </div>
                    </div>
                  </div>
                  <img
                    src={Discount_Symbole}
                    alt=""
                    className="Discount_Symbole"
                  />
                </div>
              </div>

              <div className="tutorImg3">
                <img
                  src={tutorImg3}
                  alt="tutorImg3"
                  className="Middle__leftside__img"
                />
                <div className="discount__wapper">
                  <div className="discount__flex__column">
                    <div className="discount__text__contant">
                      Buy {DiscountList[0]?.buy_slots} Classes
                    </div>
                    <div className="discount__flex__row">
                      <div className="discount__text__contant">
                        Get {DiscountList[0]?.free_slots}
                      </div>
                      <span className="free_badge">free</span>
                    </div>
                  </div>
                  <img
                    src={Discount_Symbole}
                    alt=""
                    className="Discount_Symbole"
                  />
                </div>
              </div> */}
            </div>


            {promotionalContent.length > 0 ? promotionalContent.map((content: any, index: number) => {
              if (index >= 2) {
                return (
                  <div className="tutorImg1" key={index} onClick={() => onNavigate(content)}>
                    <img
                      src={content.image}
                      alt="tutorImg1"
                      className="Middle__rightside_img"
                      onError={(e: any) => { e.target.src = tutorImg4; }}
                    />
                    <div className="discount__wapper">
                      <div className="discount__flex__column">
                        <div className="discount__flex__row">
                          <div className="discount__text__contant notranslate">
                            {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? content?.description : content?.description_ar}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                )
              }

            }) : ""}

            {/* <div className="tutorImg1">
              <img
                src={tutorImg1}
                alt="tutorImg1"
                className="Middle__rightside_img"
              />
              <div className="discount__wapper">
                <div className="discount__flex__column">
                  <div className="discount__flex__row">
                    <div className="discount__text__contant">
                      Buy {DiscountList[1]?.buy_slots} Classes,
                    </div>
                    <div className="discount__text__contant">
                      Get {DiscountList[1]?.free_slots}
                    </div>
                    <span className="free_badge">free</span>
                  </div>
                  <div className="discount__smalltext__contant">
                    Explore tutors near you
                  </div>
                </div>
                <img
                  src={Discount_Symbole}
                  alt=""
                  className="Discount_Symbole"
                />
              </div>
            </div>

            <div className="tutorImg2">
              <img
                src={tutorImg2}
                alt="tutorImg2"
                className="Middle__rightside_img"
              />
              <div className="discount__wapper">
                <div className="discount__flex__column">
                  <div className="discount__text__contant">
                    tutors for British curriculum
                  </div>
                  <div className="discount__smalltext__contant">
                    Book online/offline classess now
                  </div>
                </div>
              </div>
            </div> */}


            {/* Below is map */}
            <div className={`TutorMaps__view ${TutorMaps__view_Lang}`}>
              <TutorMaps
                zoom={12}
                radius={FilterApplyData.radius * 1000}
                lat={currentLat}
                lang={currentLang}
                containerStyle={containerStyle}
                disable={true}
                zoomControl={false}
                clickableIcons={false}
                draggable={false}
                tutorList={tutorList}
                getOneTutor={[]}
                filter={FilterApplyData}
                initFilter={initFilter}
                searchValue={searchValue}
                initSort={initSort}
              />
              <div
                className="tutor__viewMap"
                onClick={() => handleRouteMap(searchValue)}
              >
                <img src={ExpandMap} alt="ExpandMap" />
                <span> {t("View on Map")}</span>
              </div>
            </div>
          </div>

          <div className="tutors_scroll_content_two">
            <div className="top_tutors_container_scroll">
              <TutorCardsCarousel
                radius={FilterApplyData.radius * 1000}
                lat={currentLat}
                lang={currentLang}
              />
            </div>

            <div className="top__tutors__container_list">
              <TopTutors
                tutorList={tutorList}
                radius={FilterApplyData.radius * 1000}
                lat={currentLat}
                lang={currentLang}
                FilterApplyData={FilterApplyData}
              />
            </div>

          </div>

        </div>



      </div>
    </>
  );
};

export default TutorsDashboardLayout;
