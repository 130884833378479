import { Dialog, DialogContent } from "@mui/material";
import AuthScreenCarousel from "../AuthScreenCarousel/AuthScreenCarousel";
import "./LoginSignUpDialog.scss";

/**Assets */
import learnAndPlay from "../../Assets/Authentication/learnAndPlay.svg";
import { GLOBAL_CONSTANTS } from "../../Constants/Constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoginSignUpDialog = (LoginSignUpDialogProps: any) => {
  const { t } = useTranslation();
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const skip_button = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "skip_button" : "skip_button_ar";

  return (
    <>
      <Dialog
        className="loginSignup-dialog-container"
        dir={
          language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
            ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
            : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
        }
        open={LoginSignUpDialogProps.openLoginSignUpDialog}
      >
        <DialogContent>
          <div className="carousel_login_navigations_wrapper">
            <div onClick={() => { LoginSignUpDialogProps.handleOpenLoginSignUpDialogClose(null) }} className={skip_button}>Skip</div>
            <div className="carousel_wrapper">
              <AuthScreenCarousel />
            </div>
            <div className="login_signup_navigations_wrapper">
              <div className="login_signup_navigations_container">
                <img
                  className="brand_logo"
                  src={learnAndPlay}
                  alt="Learn and Play"
                />
                <button
                  className="create_account_button"
                  onClick={() => {
                    LoginSignUpDialogProps.handleOpenLoginSignUpDialogClose(
                      GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
                    );
                  }}
                >
                  {t("Create an account")}
                </button>
                <button
                  className="login_button"
                  onClick={() => {
                    LoginSignUpDialogProps.handleOpenLoginSignUpDialogClose(
                      GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
                    );
                  }}
                >
                  {t("Login")}
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginSignUpDialog;
