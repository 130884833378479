import { useNavigate } from "react-router-dom";
import learnAndPlay from "../../Assets/Global/LearnAndPlayLogo.svg";
import { AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import { GetSessionStorageData, SetSessionStorageData } from "../../Utilities/Helpers/Helper";
import './Footer.scss'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Footer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    /**Get access token from session storage */
    const token = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);

    // Create a new Date object
    var currentDate = new Date();

    // Get the current year
    var currentYear = currentDate.getFullYear();

    const navigateHome = () => {
        window.location.href = '/home'
        window.scrollTo(0, 0);
    }

        /**get selected or current language from redux */
        const language: any = useSelector((state: any) => {
            return state.languageReducer.language;
          });
        /*Below class name based on English Arabic*/
         const FooterTextLag = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "footer_text_lag" : "footer_text_arab";

    return (
        <>
            <div className="homePage__footer__container">
                <div className="footer__nav_links">
                    <img src={learnAndPlay} alt="Learn and Play" className="logo__icon" onClick={navigateHome} />
                    <div className={`${FooterTextLag} footer__text`}>© {t("Copyright")} {currentYear}  {t("learnandplay.com")}, {t("All Rights Reserved")}.</div>
                </div>
                <div className="footer__nav_links">
                    <span className="link_text" onClick={navigateHome}>{t("Home")}</span>
                    <span className="link_text" onClick={() => {
                        if (token) {
                            SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "About Us");
                            navigate(ROUTES.ABOUTUS);
                        } else {
                            navigate(ROUTES.ABOUTUS_WITHOUT_LOGIN);
                        }
                    }}>{t("About US")}</span>
                </div>
            </div>
        </>
    )
}

export default Footer;