import "./TutorHubSession.scss";
import TutorHubSessionImg from "../../Assets/TutorHub/TutorHubSession.svg";
import TutorImg from "../../Assets/Tutor/TutorImg.svg";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import { useEffect, useState } from "react";
import { GetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import activitiesIcon from "../../Assets/Activities/activitiesIcon.svg";
import spacemen from "../../Assets/Global/spacemen.svg";
import { useSelector } from "react-redux";
import video_call_icon from "../../Assets/TutorHub/video_call_icon.svg";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Email from "../../Assets/ProfileSettings/supportEmail.svg";
import Call from "../../Assets/ProfileSettings/supportCall.svg";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import PhoneIcon from '@mui/icons-material/Phone';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const TutorHubSession = ({ getData, type }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sessionList, setSessionList] = useState<any>([]);
  const [Cancelopen, setCancelOpen] = useState(false);
  const [SupportOpen, setSupportOpen] = useState(false);
  const [MeetOpen, setMeetOpen] = useState(false);
  const [MeetData, setMeetData] = useState<any>("");
  const [FeedbackInput, setFeedbackInput] = useState<any>("");
  const [CancelData, setCancelData] = useState<any>("");

  /**Loader state*/
  const Loader: any = useSelector((state: any) => {
    return state.loaderReducer.loader;
  });

  const handleClose = () => {
    setCancelData("");
    setFeedbackInput("");
    setMeetData("");
    setCancelOpen(false);
    setSupportOpen(false);
    setMeetOpen(false);
  };
  const handleCancelOpen = () => {
    const startTime = new Date(CancelData?.start);
    const currentTime = new Date();
    const timeDifference = startTime.getTime() - currentTime.getTime();
    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);
    if (timeDifferenceInHours > 24) {
      if (CancelData.is_cancel_request == false) {
        setSupportOpen(false);
        setCancelOpen(true);
      } else {
        toast.error('Request cancellation sent, awaiting confirmation.', {
          position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
        });
      }
    } else {
      toast.error("Sessions Less than 24hrs can't be canceled", {
        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
      });
    }
  };

  const handleMeetOpen = (data: any) => {
    setMeetData(data);
    setMeetOpen(true);
  }

  const handleSupportOpen = (data: any) => {
    setCancelData(data)
    setSupportOpen(true);
  };

  const navigateReschedule = (data: any) => {
    if (data?.is_cancel_request == false) {
      const startTime = new Date(data?.start);
      const currentTime = new Date();
      const timeDifference = startTime.getTime() - currentTime.getTime();
      const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);
      if (timeDifferenceInHours > 24) {
        if (data?.teach_service_provider_id == 1) {
          navigate(ROUTES.TUTORS_CALENDAR_VIEW, {
            state: {
              tutor_sesstion_id: data?.tutor_subject_id,
              tutor_del: data,
              tutor_name: data.parent_name,
              is_reschedule: true
            }
          });
        } else {
          navigate(ROUTES.ACTIVITY_CALENDAR_VIEW, {
            state: {
              activity_sesstion_id: data?.trainer_activity_id,
              camp: data.type_camp,
              person: data,
              is_reschedule: true,
              trainer_name: data.session_creator_name,
            }
          })
        }
      } else {
        toast.error("Sessions Less than 24hrs can't be Reschedule", {
          position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
        });
      }
    } else {
      toast.error('Rescheduling is temporarily disabled until the cancellation request is resolved.', {
        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
      });
    }
  }

  useEffect(() => {
    getTutorHuSessionbData();
    setCancelData("");
  }, []);

  /**Get Tutor Hub List*/
  const getTutorHuSessionbData = () => {
    var getId = getData?.booking_id ?? getData?.id;
    if (getId) {
      axiosInstance
        .get(`${API_END_POINTS.HUB_SESSION_VIEW + getId}`)
        .then((Response: any) => {
          if (Response?.data?.status) {
            setSessionList(Response?.data?.result?.data);
          }
        })
        .catch(() => { });
    }
  };

  /**Cancel Request Api*/
  const CancelReqestApi = () => {
    var payload = {
      booking_id: CancelData?.booking_id,
      booked_time_slot_id: CancelData?.id,
      reason: FeedbackInput,
    }
    axiosInstance
      .post(`${API_END_POINTS.CANCEL_REQUEST}`, payload)
      .then((Response: any) => {
        if (Response?.data?.status) {
          toast.success(Response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
          });
          getTutorHuSessionbData();
          handleClose();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
        });
      });
  };

  const handleOpenNewTab = (data: any) => {
    var payload = {
      session_slot_id: data?.session_slot_id,
      session_time_slot_id: data?.time_slot_id,
      is_attendance: true
    };

    axiosInstance
      .put(`${API_END_POINTS.TUTOR_ATTENDANCE}`, payload)
      .then((Response: any) => {
        if (Response?.data?.status) {
          // Open a new tab/window
          window.open(data?.meet_link, "_blank");
          handleClose();
        }
      })
      .catch(() => { });
  };

  function isDateTimeInRangeWithBuffer(
    dateRange: any,
    timeRange: any,
    bufferMinutes = 15
  ) {
    // Parse date range
    const [startDate, endDate] = dateRange
      .split(" to ")
      .map((dateStr: any) => new Date(dateStr));

    // Parse time range
    const [startTime, endTime] = timeRange
      .split(" to ")
      .map((timeStr: any) => new Date(`1970-01-01T${timeStr}Z`));

    // Get current system date and time
    const currentDateTime = new Date();

    // Calculate the date and time 15 minutes ago
    const bufferDateTime = new Date(currentDateTime);
    bufferDateTime.setMinutes(bufferDateTime.getMinutes() - bufferMinutes);

    // Check if current date is within the date range
    const isDateInRange =
      currentDateTime >= startDate && currentDateTime <= endDate;

    // Check if current time is within the time range
    const isTimeInRange =
      currentDateTime >= startTime && currentDateTime <= endTime;

    // Check if the current date and time are within the last 15 minutes
    const isWithinBuffer = currentDateTime >= bufferDateTime;

    // Return true if both date, time, and buffer conditions are met
    return isDateInRange && isTimeInRange && isWithinBuffer;
  }

  const checkEnableTime = (timing: any) => {
    const currentTime = new Date();
    const startTime = new Date(timing?.start);

    // Calculate the timestamp for 15 minutes before the start time
    const fiveMinutesBeforeStartTime = new Date(startTime.getTime() - 15 * 60 * 1000);

    // Check if the current time is less than 15 minutes before the start time
    if (currentTime >= fiveMinutesBeforeStartTime) {
      return true;
    } else {
      return false;
    }
  };

  const openCall = () => {
    navigator.clipboard.writeText('+123456789');
    toast.success("Copied!", {
      position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
    });
    // window.location.href = 'tel:' + '+123456789';
  };

  const handleEmaiClick = (value: any) => {
    const emailAddress = 'LearnAndplay@gmail.com';
    const subject = encodeURIComponent('Support request' + ", " + value?.session_title);
    window.open(`mailto:${emailAddress}?subject=${subject}`, '_blank');
  };

  const handleEmailClick = (value: any) => {
    const emailAddress = 'LearnAndplay@gmail.com';
    const subject = encodeURIComponent('Support request' + ", " + value?.session_title);
    const body = encodeURIComponent(`Dear ${value?.session_creator_name},\n\n     I have booked the class ${value?.session_title}
    on (${value?.date}). I am reaching out to request your support for this session.
    \n\n
    [Please insert any additional details or requests here.]
    \n\n
    Thank you,
    ${value?.name}
    `);
    window.open(`mailto:${emailAddress}?subject=${subject}&body=${body}`, '_blank');
  };

  const handleChangeDidNotAttend = (data: any) => {
    var payload = {
      session_slot_id: data?.session_slot_id,
      session_time_slot_id: data?.time_slot_id,
      is_attendance: false,
      is_didnot: data?.is_didnot ? false : true
    };

    axiosInstance
      .put(`${API_END_POINTS.TUTOR_ATTENDANCE}`, payload)
      .then((Response: any) => {
        if (Response?.data?.status) {
          getTutorHuSessionbData();
        }
      })
      .catch(() => { });
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const CreatedByLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ms-lang" : "me-arab";

  return (
    <>
      <div className="TutorHubSession__container">
        <div className="Header__wapper">
          <img src={TutorHubSessionImg} alt="" />
          <div className="title">{t("Sessions")}</div>
        </div>

        <div className="row session__scroll">
          {type == GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT && (
            <>
              {sessionList.map((data: any, i: any) => {
                return (
                  <>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                      <div className="session__wapper">
                        <div className="row__contant">
                          <div className="left__contant">
                            <div className="tutor__img__wapper">
                              <img src={TutorImg} alt="" />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="Black__text">
                                {data?.session_title}
                              </div>
                              <div className="name__text">
                                {data?.session_creator_name}
                              </div>
                            </div>
                          </div>
                          {data?.is_online && (
                            <div className="left__contant">
                              {data?.status === "completed" ? (
                                <>{!data?.user_attendance && (
                                  <Tooltip title="Did not attend">
                                    <Switch disabled={data?.user_attendance} checked={data?.is_didnot} onChange={() => handleChangeDidNotAttend(data)} />
                                  </Tooltip>
                                )}</>
                              ) : (
                                <>{!data?.is_didnot && (
                                  <>
                                    {data?.is_meet_link ? (
                                      <div
                                        className="video_call__wapeer"
                                        onClick={() => handleMeetOpen(data)}
                                      >
                                        <div className="badgeIcon badeg__pos"></div>
                                        <img src={video_call_icon} alt="" />
                                      </div>
                                    ) : (
                                      <div className="video_call__wapeer disable_style">
                                        <div className="badgeIcon badeg__pos"></div>
                                        <img src={video_call_icon} alt="" />
                                      </div>
                                    )}
                                  </>
                                )}</>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Total")}</div>
                          <div className="right__black__text">
                            {data?.total_count} {t("Sessions")}
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Completed")} </div>
                          <div className="right__black__text">
                            {data?.completed_count} {t("Sessions")}
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Balance")}</div>
                          <div className="right__black__text">
                            {data?.balance_count} {t("Sessions")}
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">
                            {t("No. of Sessions")}
                          </div>
                          <div className="right__black__text">
                            {data?.completed_count}/{data?.total_count}
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Date")}</div>
                          <div className="right__black__text">{data?.date}</div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Time")}</div>
                          <div className="right__black__text">{data?.time}</div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Booked by")}</div>
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.parent_profile)
                                  ? data?.parent_profile
                                  : tutor_img
                              } onError={(e: any) => { e.target.src = tutor_img; }}
                              className="child_profile_img"
                              alt=""
                            />
                            {/* <div className="createdBy ms-2"> */}
                            <div className={`createdBy ms-2 ${CreatedByLang}`}>
                              {data?.parent_name}
                            </div>
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="right__black__text">{t("Booking ID")}</div>
                          <div className="right__black__text">
                            {data?.booking_id}
                          </div>
                        </div>
                        <div className="row__contant">
                          {data?.status == "completed" ? (
                            <button
                              className={
                                data?.status == "completed"
                                  ? "CompletedBtn"
                                  : "inprogressBtn"
                              }
                            >
                              {data?.status}
                            </button>
                          ) : (
                            <div className="d-flex gap-2 align-items-center">
                              {!data.free_introduction_session && data.is_reschedule && GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                                <button className="inprogressBtn" onClick={() => navigateReschedule(data)}>
                                  {t("Reschedule")}
                                </button>
                              )}
                              {!data.free_introduction_session && GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                                <button className="inprogressBtn" onClick={() => handleSupportOpen(data)}>
                                  {t("Support")}
                                </button>
                              )}
                            </div>
                          )}
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.profile)
                                  ? data?.profile
                                  : tutor_img
                              } onError={(e: any) => { e.target.src = tutor_img; }}
                              className="child_profile_img"
                              alt=""
                            />
                            {/* <div className="createdBy ms-2"> */}
                            <div className={`createdBy ms-2 ${CreatedByLang}`}>
                              {data?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {sessionList?.length === 0 && !Loader && (
                <>
                  <div className="No__data__contant">
                    <img src={spacemen} alt="" />
                    <div className="text">{t("No Data Found")}</div>
                  </div>
                </>
              )}
            </>
          )}
          {type == GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT && (
            <>
              {sessionList.map((data: any, i: any) => {
                return (
                  <>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                      <div className="session__wapper">
                        <div className="row__contant">
                          <div className="left__contant">
                            <div className="activity__img__wapper">
                              <img src={activitiesIcon} alt="" />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="Black__text">
                                {data?.session_title}
                              </div>
                              <div className="name__text">
                                {data?.session_creator_name}
                              </div>
                            </div>
                          </div>
                          {/* {getData?.is_online && (
                             <div
                                  className="video_call__wapeer"
                                  onClick={() => handleMeetOpen(data)}
                                >
                            >
                              <div className="badgeIcon badeg__pos"></div>
                              <img src={video_call_icon} alt="" />
                            </div>
                          )} */}
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Total")}</div>
                          <div className="right__black__text">
                            {data?.total_count} {t("Sessions")}
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Completed")} </div>
                          <div className="right__black__text">
                            {data?.completed_count} {t("Sessions")}
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Balance")}</div>
                          <div className="right__black__text">
                            {data?.balance_count} {t("Sessions")}
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">
                            {t("No. of Sessions")}
                          </div>
                          <div className="right__black__text">
                            {data?.completed_count}/{data?.total_count}
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Date")}</div>
                          <div className="right__black__text">{data?.date}</div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Time")}</div>
                          <div className="right__black__text">{data?.time}</div>
                        </div>
                        <div className="row__contant">
                          <div className="left__light__text">{t("Booked by")}</div>
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.parent_profile)
                                  ? data?.parent_profile
                                  : tutor_img
                              } onError={(e: any) => { e.target.src = tutor_img; }}
                              className="child_profile_img"
                              alt=""
                            />
                            {/* <div className="createdBy ms-2"> */}
                            <div className={`createdBy ms-2 ${CreatedByLang}`}>
                              {data?.parent_name}
                            </div>
                          </div>
                        </div>
                        <div className="row__contant">
                          <div className="right__black__text">{t("Booking ID")}</div>
                          <div className="right__black__text">
                            {data?.booking_id}
                          </div>
                        </div>
                        <div className="row__contant">
                          {data?.status == "completed" ? (
                            <button
                              className={
                                data?.status == "completed"
                                  ? "CompletedBtn"
                                  : "inprogressBtn"
                              }
                            >
                              {data?.status}
                            </button>
                          ) : (
                            <>
                              {data?.teach_service_provider_id == 2 ? (
                                <div className="d-flex gap-2 align-items-center">
                                  {!data.free_introduction_session && data.is_reschedule && GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                                    <button className="inprogressBtn" onClick={() => navigateReschedule(data)}>
                                      {t("Reschedule")}
                                    </button>
                                  )}
                                  {!data.free_introduction_session && GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                                    <button className="inprogressBtn" onClick={() => handleSupportOpen(data)}>
                                      {t("Support")}
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <button className="inprogressBtn">  {data?.status}</button>
                              )}
                            </>
                          )}
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                nullUndefinedEmptyCheck(data?.profile)
                                  ? data?.profile
                                  : tutor_img
                              } onError={(e: any) => { e.target.src = tutor_img; }}
                              className="child_profile_img"
                              alt=""
                            />
                            {/* <div className="createdBy ms-2"> */}
                            <div className={`createdBy ms-2 ${CreatedByLang}`}>
                              {data?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {sessionList?.length === 0 && !Loader && (
                <>
                  <div className="No__data__contant">
                    <img src={spacemen} alt="" />
                    <div className="text">No Data Found</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {Cancelopen && (
          <div className="overlay-ModelBackround">
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={Cancelopen}
              className="FeedBack__dailog__box"
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <div className="DialogTitle__wapper">
                  <div className="title">Cancel Request</div>
                  <div className="closeBtn" onClick={() => {
                    setCancelOpen(false);
                    setSupportOpen(true);
                  }}>Close</div>
                </div>
              </DialogTitle>
              <DialogContent dividers>

                <div className="DialogContent___wapper">
                  <div className="tutor__contant">
                  </div>
                  <div className="rating__container">
                    <textarea className="textarea__input" placeholder="Write your message here..."
                      onChange={(e) => setFeedbackInput(e.target.value)} cols={30} rows={3}></textarea>
                    {nullUndefinedEmptyCheck(FeedbackInput) ? (
                      <button className="submitBtn" onClick={CancelReqestApi} >Submit</button>
                    ) : (
                      <button className="submitBtn__disable">Submit</button>
                    )}

                  </div>
                </div>

              </DialogContent>

            </BootstrapDialog>
          </div>
        )}

        {SupportOpen && (
          <div className="overlay-ModelBackround">
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={SupportOpen}
              className="FeedBack__dailog__box"
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <div className="DialogTitle__wapper">
                  <div className="title">Having Any Issuse?</div>
                  <div className="closeBtn" onClick={handleClose}>Close</div>
                </div>
              </DialogTitle>
              <DialogContent dividers>

                <div className="Support__DialogContent___wapper">
                  <div className="Button__wapper" onClick={() => handleEmaiClick(CancelData)}>
                    <div className="accordion__lable__wapper">
                      <MailOutlineIcon className="icon" />
                      <div className="text">Email</div>
                    </div>
                    <ExpandMoreIcon style={{ transform: 'rotate(270deg)' }} />
                  </div>
                  <div className="Button__wapper" onClick={openCall}>
                    <div className="accordion__lable__wapper">
                      <PhoneIcon className="icon" />
                      <div className="text">Call</div>
                    </div>
                    <ExpandMoreIcon style={{ transform: 'rotate(270deg)' }} />
                  </div>
                  <div className="Button__wapper" onClick={handleCancelOpen}>
                    <div className="accordion__lable__wapper">
                      <DoDisturbIcon className="icon" />
                      <div className="text">Cancel</div>
                    </div>
                    <ExpandMoreIcon style={{ transform: 'rotate(270deg)' }} />
                  </div>
                </div>

              </DialogContent>

            </BootstrapDialog>
          </div>
        )}

        {MeetOpen && (
          <div className="overlay-ModelBackround">
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={MeetOpen}
              className="FeedBack__dailog__box"
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <div className="DialogTitle__wapper">
                  <div className="title">Attend Online class</div>
                  <div className="closeBtn" onClick={handleClose}>Close</div>
                </div>
              </DialogTitle>
              <DialogContent dividers>
                <div className="DialogContent___wapper">
                  <div className="tutor__contant mt-2 mb-3">
                    Hi <b>{MeetData?.name}</b>
                  </div>
                  <div className="tutor__contant  mb-3">
                    Do you want to attend the online class!
                  </div>
                  <div className="rating__container">
                    <button className="submitBtn" onClick={() => handleOpenNewTab(MeetData)}>Sure</button>
                  </div>
                </div>
              </DialogContent>
            </BootstrapDialog>
          </div>
        )}

      </div>
    </>
  );
};

export default TutorHubSession;
