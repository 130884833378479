import { useEffect, useState } from "react";
import "./TopServiceCards.scss";
import AliceCarousel from "react-alice-carousel";
import { GLOBAL_CONSTANTS, HOMEPAGE, ROUTES } from "../../Constants/Constants";
import tutor_img from "../../Assets/Tutor/tutor_image.png";

/**Assets */
import starGroup from "../../Assets/home/starGroup.svg";
import starGroupHighlighted from "../../Assets/home/starGroupHighlighted.svg";
import { encryptPassData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { useSwipeable } from "react-swipeable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Rating from '@mui/material/Rating';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
// import required modules
import { Autoplay, Pagination, Navigation, Mousewheel, Keyboard, Grid } from 'swiper/modules';

const TopServiceCards = (topServiceCardsProps: any) => {
  /**Initial states */
  const [TopServiceCardsTemplate, setTopServiceCardsTemplate] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  const handleNavigate = (value: any) => {
    navigate(value, {
      state: {
        search_text: "",
        redius: 100, lat: HeaderLoction?.lat, lang: HeaderLoction?.lng,
      }
    });
  }

  const NavigateRoute = (name: any, type: any, id: any) => {

    if (type === HOMEPAGE.SERVICE_TYPE.TUTOR) {
      navigate(ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(id), { state: { DetailesID: id } });
    } else if (type === HOMEPAGE.SERVICE_TYPE.ACTIVITIES) {
      navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(id) + '&type=' + encryptPassData('personal'), { state: { DetailesID: id, type: 'personal' } });
    }
  }

  /**Top service cards */
  const getTopServiceCards = () => {
    var card = topServiceCardsProps.serviceData?.slice(0, 10)?.map(
      (serviceDataElements: any, index: any) => {
        return (
          <SwiperSlide>
            <div className="top_service_card" key={index} onClick={() => NavigateRoute(serviceDataElements?.name, topServiceCardsProps.serviceType, serviceDataElements?.id)}>
              <div className="top_service_card_container">
                <div className="type_content_profile_wrapper">
                  <div className="type_contents_container">
                    {topServiceCardsProps.serviceType ===
                      HOMEPAGE.SERVICE_TYPE.TUTOR && (
                        <div className="tutor_type_chip">
                          {serviceDataElements.is_service_type}
                        </div>
                      )}
                    {topServiceCardsProps.serviceType ===
                      HOMEPAGE.SERVICE_TYPE.ACTIVITIES && (
                        <div className="activities_type_chip">
                          {topServiceCardsProps.serviceType}
                        </div>
                      )}
                    <div className="top_service_name" title={serviceDataElements.name}>
                      {serviceDataElements.name?.length > 10 ? serviceDataElements?.name?.slice(0, 10) + '...' : serviceDataElements?.name}
                    </div>
                    <div className="top_service_description" title={serviceDataElements.description}>
                      {serviceDataElements.description?.length > 60 ? serviceDataElements?.description?.slice(0, 60) + '...' : serviceDataElements?.description}
                    </div>
                    <div className="star_group_wrapper">
                      <div className="star_group_highlighted_wrapper">
                        <Rating name="read-only" sx={{ fontSize: "18px" }} value={parseInt(serviceDataElements?.rating)} readOnly />
                      </div>
                      <div className="ratings">{nullUndefinedEmptyCheck(serviceDataElements?.rating) ? serviceDataElements?.rating : 0}/5</div>
                    </div>
                  </div>
                  <img
                    draggable="false"
                    src={serviceDataElements?.images}
                    onError={(e: any) => { e.target.src = tutor_img; }}
                    alt="Learn and Play"
                    className="service_profile"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>

        );
      }
    );

    topServiceCardsProps.serviceData.length > 10 && card.push(
      <>
        {topServiceCardsProps.serviceType ===
          HOMEPAGE.SERVICE_TYPE.TUTOR && (

            <SwiperSlide>
              <div className="tutor_explore-all__wapper" key={HOMEPAGE.SERVICE_TYPE.TUTOR}
                onClick={() => handleNavigate(ROUTES.TUTORS_LIST_VIEW)}>
                <div className="text">Explore all</div>
                <div className="title">Tutor</div>
              </div>
            </SwiperSlide>

          )}
        {topServiceCardsProps.serviceType ===
          HOMEPAGE.SERVICE_TYPE.ACTIVITIES && (
            <SwiperSlide>
              <div className="activity_find_all_explore-all__wapper" key={HOMEPAGE.SERVICE_TYPE.TUTOR}
                onClick={() => handleNavigate(ROUTES.ACTIVITY_LIST_VIEW)}>
                <div className="text">Explore all</div>
                <div className="title">Activities</div>
              </div>
            </SwiperSlide>
          )}
      </>
    );

    return card;
  };

  /**Update top service data when fetched from API */
  useEffect(() => {
    if (nullUndefinedEmptyCheck(topServiceCardsProps.serviceData))
      setTopServiceCardsTemplate(getTopServiceCards());
  }, [topServiceCardsProps.serviceData]);

  /**Handle slide change */
  const hangeSlideChange = ({ item }: any) => {
    setActiveIndex(item);
  };

  /**Rough */

  const handlers = useSwipeable({
    onSwiped: (eventData: any) => {
      if (
        eventData.dir === GLOBAL_CONSTANTS.INITIAL_VALUES.LEFT &&
        activeIndex === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT
      ) {
        topServiceCardsProps.setIsTutorContentOverlapping(
          GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
        );
      }
      if (
        eventData.dir === GLOBAL_CONSTANTS.INITIAL_VALUES.RIGHT &&
        activeIndex === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT
      ) {
        topServiceCardsProps.setIsTutorContentOverlapping(
          GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
        );
      }
    },
    preventScrollOnSwipe: GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE,
    trackMouse: GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE,
  });

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name for depend languge changing*/
  const home_swiper_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "home_swiper" : "home_swiper_arab";
  const SwiperButtonPrevLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "swiper-button-prev-eng" : "swiper-button-prev-arab";
  const SwiperButtonNextLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "swiper-button-next-eng" : "swiper-button-next-arab";

  return (
    <>
      {/* <div {...handlers} className="top_service_cards_wrapper">
        <AliceCarousel
          mouseTracking={topServiceCardsProps.isTutorContentOverlapping}
          autoWidth
          disableButtonsControls
          items={TopServiceCardsTemplate}
          disableDotsControls
          activeIndex={activeIndex}
          onSlideChanged={hangeSlideChange}
          animationDuration={2000} 
          animationType="fadeout"
        />
      </div> */}

      <div className="top_service_cards_wrapper">
        {/* <AliceCarousel
          mouseTracking={false}
          autoWidth
          // disableButtonsControls
          items={TopServiceCardsTemplate}
          disableDotsControls
          activeIndex={activeIndex}
          onSlideChanged={hangeSlideChange}
          // animationDuration={2000} 
          // animationType="fadeout"
        /> */}


        <Swiper
          dir={
            language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ltr" : "rtl"
          }
          key={language}
          mousewheel={false}
          slidesPerView={1}
          spaceBetween={30}
          grid={{
            rows: 1,
          }}
          keyboard={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation, Keyboard, Grid]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          // className="home_swiper"
          className={home_swiper_wrap}
          allowTouchMove={false}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {TopServiceCardsTemplate}

          <div className="swiper-control">
            <div className={`${SwiperButtonPrevLang} swiper-button-prev`}></div>
            <div className={`${SwiperButtonNextLang} swiper-button-next`} style={{ left: `${TopServiceCardsTemplate?.length * 14 + 16}px ` }}></div>
          </div>
        </Swiper>
      </div>
    </>
  );
};
export default TopServiceCards;
