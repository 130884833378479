import { useState, useEffect, useRef } from "react";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../../Assets/Tutor/share.svg";
import LikeIcon from "../../../Assets/Tutor/LikeIcon.svg";
import experienceBlue from "../../../Assets/Tutor/experienceBlue.svg";
import Private_session from "../../../Assets/Tutor/twomemberBlue.svg";
import mode_of_teaching from "../../../Assets/Tutor/oneMemberBlue.svg";
import syllabus from "../../../Assets/Tutor/syllabusBlue.svg";
import pdfIcon from "../../../Assets/Tutor/pdfIcon.svg";
import teaching_mode from "../../../Assets/Tutor/bookBlue.svg";
import curriculum from "../../../Assets/Tutor/curriculumBlue.svg";
import BadgeIcon from "../../../Assets/Tutor/BadgeIcon.svg";
import back_img from "../../../Assets/Tutor/back_img.svg";
import tutor_icon from "../../../Assets/Tutor/TutorImg.svg";
import AliceCarousel from "react-alice-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import "./TutorsDetaileView.scss";
import { Container, Rating } from "@mui/material";
import React from "react";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
} from "../../../Constants/Constants";
import {
  GetSessionStorageData,
  decrypPassData,
  encryptPassData,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import toast from "react-hot-toast";
import Tutorfavourites from "../../../Assets/HeartFill/TutorFill.svg";
import { setLoginSignUpOpen, setChildLoginRestriction, setLoader } from "../../../Redux/Reducer/Reducer";
import { useDispatch, useSelector } from "react-redux";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Dialog, DialogContent } from "@mui/material";
import TutorTrialSession from "../../../Components/TutorTrialSession/TutorTrialSession";
import PdfDownloadFile from "../../../Components/PdfDownload";
import locationIcon from "../../../Assets/Tutor/location.svg";
import { useTranslation } from "react-i18next";
import { store } from "../../../Redux/Store/Store";

const TutorsDetaileView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qureyId: any = searchParams.get("id");
  const [toturDetailes, setToturDetailes] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [courseIndex, setCourseIndex] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [courseSelectIndex, setCourseSelectIndex] = useState<any>([0]);
  const [AuthenticationOpen, setAuthenticationOpen] = useState(false);
  const [CourseOpen, setCourseOpen] = useState(true);
  const [FreeTrialOpen, setFreeTrialOpen] = useState(false);
  const [PdfOpen, setPdfOpen] = useState(false);
  const [PdfData, setPdfData] = useState([]);
  const TicketcarouselRef = useRef<any>(null);



  const handleSlideChange = (setIndex: (index: number) => void) => ({ item }: any) => setIndex(item);

  const navigateBookSlot = () => {
    if (nullUndefinedEmptyCheck(courseIndex?.id)) {
      navigate(ROUTES.TUTORS_CALENDAR_VIEW, {
        state: {
          tutor_sesstion_id: courseIndex?.id,
          tutor_del: toturDetailes,
          courseIndex: courseIndex,
          is_reschedule: false,
          tutor_name:
            toturDetailes?.profile_details?.firstname +
            " " +
            toturDetailes?.profile_details?.lastname,
        },
      });
    }
  };


  useEffect(() => {
    getOneList();
  }, [qureyId]);

  /**Get One List*/
  const getOneList = async () => {
    setLoading(true);
    try {
      const decryptedId = decrypPassData(qureyId);
      const id = `?tutor_id=${decryptedId}`;

      const response = await axiosInstance.get(`${API_END_POINTS.TUTOR_DETAIL + id}`);
      store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      if (response?.data?.status) {
        const tutorData = response.data.result.data;
        console.log('Fetched tutor data:', tutorData);

        if (tutorData) {
          setToturDetailes(tutorData);
          const initialCourseIndex = tutorData.tutor_subjects?.length > 0
            ? tutorData.tutor_subjects[0]
            : null;
          setCourseIndex(initialCourseIndex);
        }
      }
    } catch (error) {
      console.error('Error fetching tutor details:', error);
    } finally {
      setLoading(false);
      store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    }
  };

  useEffect(() => {
    if (!loading && (!toturDetailes || !courseIndex)) {
      setTimeout(() => navigate(-1), 3000);
    }
  }, [loading, toturDetailes, courseIndex, navigate]);


  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: 1,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            toast.success(Response?.data?.message, {
              position: "top-right",
            });
            getOneList();
          }
        })
        .catch((error) => { });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const handleAuthentication = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD) {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      } else {
        navigateBookSlot()
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const handleAuthenticationFreeTrial = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD) {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      } else {
        onOpenFreeTrial();
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const onOpenFreeTrial = () => {
    setFreeTrialOpen(true);
  };

  const onCloseFreeTrial = () => {
    setFreeTrialOpen(false);
  };
  const onOpenPdfDownload = (data: any) => {
    setPdfData(data)
    setPdfOpen(true);
  };

  const onClosePdfDownload = () => {
    setPdfOpen(false);
    setPdfData([])
  };

  const checkString = (data: any) => {

    // Convert array to a string
    var dataAsString = JSON.stringify(data);

    // Check if the string contains square brackets
    if (dataAsString?.includes('[') && dataAsString?.includes(']')) {
      const match = data?.match(/\['([^']+)'\]/);
      return match ? match[1] : null;
    } else {
      return data;
    }
  }
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const Detail_Tutor_Icon = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "dtutor-icon-english" : "dtutor-icon-arab";
  const Detail_Back_Img = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "dback-img-english" : "dback-img-arab";
  const DetailesScrollContainerLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "DetailesScrollContainerEng" : "DetailesScrollContainerArab";

  const renderItems = () => {
    const itemsArray = [];

    if (toturDetailes?.cover_images?.length > 0) {
      toturDetailes.cover_images.forEach((item: any, i: any) => {
        itemsArray.push(
          <img
            key={`cover_image_${i}`}
            src={item || card_bg}
            onError={(e: any) => { e.target.src = card_bg; }}
            alt="cover"
            className="card_bg_img"
          />
        );
      });
    }

    if (nullUndefinedEmptyCheck(toturDetailes?.intro_video)) {
      itemsArray.push(
        <video key="intro_video" controls width="270" height="150">
          <source src={toturDetailes.intro_video} type="video/mp4" />
        </video>
      );
    }

    if (itemsArray.length === 0) {
      [0, 1].forEach((_, i) => {
        itemsArray.push(
          <img key={`default_${i}`} src={card_bg} alt="card_bg" className="card_bg_img" />
        );
      });
    }

    return itemsArray;
  };

  console.log(toturDetailes, "toturDetailes");
  console.log(courseIndex, "courseIndex");


  if (!setToturDetailes || !courseIndex) {
    return <div className="tutor_detail_notfound_style">No data found</div>;
  }

  return (
    <div>
      <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
        <div className="Tutor__Detaile__view">
          <div className="tittle-div">
            <img
              src={back_img}
              alt=""
              className={`${Detail_Back_Img} back-img`}
              onClick={() => navigate(-1)}
            />
            <img src={tutor_icon} alt="" className={` ${Detail_Tutor_Icon} tutor-icon`} />
            <label className="text-tittle">{t("Tutors")}</label>
          </div>
          <div className="row mt-2 w-100">
            <div className={`col-xl-4 col-lg-4 tutorlist-conatiner ${DetailesScrollContainerLang} detailes__scroll__container`}>
              <div className="display-container">
                <img
                  src={toturDetailes?.profile_image || tutor_img}
                  alt="" onError={(e: any) => { e.target.src = tutor_img; }}
                  className="tutor-image"
                />
                <label className="text14">
                  {toturDetailes?.profile_details?.firstname}{" "}
                  {toturDetailes?.profile_details?.lastname}
                </label>
                <div className="text10">{toturDetailes?.description}</div>
                <div className="user__Detailes__rating_detailes">
                  {nullUndefinedEmptyCheck(toturDetailes?.rating) ? (
                    <div className="graytext">{toturDetailes?.rating}</div>
                  ) : (
                    ""
                  )}
                  <Rating
                    name="half-rating-read"
                    className="StarIcon"
                    value={parseInt(toturDetailes?.rating || 0)}
                    readOnly
                  />
                </div>
                <label className="text11">{courseIndex?.final_fee || "hi"}{t("AED")}</label>
                <div className="iconBtn__wapper">
                  <button
                    className="iconBtn"
                    onClick={() =>
                      handleChangeFavourites(
                        toturDetailes?.is_favourite,
                        toturDetailes?.user
                      )
                    }
                  >
                    <img
                      src={
                        toturDetailes?.is_favourite ? Tutorfavourites : LikeIcon
                      }
                      alt="Learn and Play"
                      className="img-size5"
                    />
                    <span>{t("Add favorite")}</span>
                  </button>
                  <WebShareComponent
                    params={
                      ROUTES.TUTORS_DETAILE_VIEW +
                      "?id=" +
                      encryptPassData(toturDetailes?.id)
                    }
                  >
                    <button className="iconBtn">
                      <img src={shareIcon} alt="" className="img-size5" />
                      <span>{t("Share Profile")}</span>
                    </button>
                  </WebShareComponent>
                </div>
                {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                  <button
                    className="free_session_Btn"
                    onClick={() => onOpenFreeTrial()}
                  >
                    {t("Book an introduction session (Free)")}
                  </button>
                ) : (
                  <button
                    className="free_session_Btn"
                    onClick={handleAuthenticationFreeTrial}
                  >
                    {t("Book an introduction session (Free)")}
                  </button>
                )}
                {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                  <button className="Book-Slots-btn" onClick={navigateBookSlot}>
                    {t("Book Slots")}
                  </button>
                ) : (
                  <button
                    className="Book-Slots-btn"
                    onClick={handleAuthentication}
                  >
                    {t("Book Slots")}
                  </button>
                )}
              </div>
            </div>
            <div className={`col-xl-7 col-lg-7 tutorlist-conatiner ${DetailesScrollContainerLang} detailes__scroll__container`}>
              <div className="display-container5">
                <AliceCarousel
                  mouseTracking={false}
                  keyboardNavigation={false}
                  disableButtonsControls
                  autoWidth
                  items={renderItems()}
                  disableDotsControls
                  ////
                  activeIndex={activeIndex}
                  onSlideChanged={handleSlideChange(setActiveIndex)}
                  autoPlay={false}
                  ref={TicketcarouselRef}
                />
              </div>
              <div className="user__Detailes__Grade">
                <img
                  src={teaching_mode}
                  alt="teaching_mode"
                  className="grayIcons"
                />
                <div className="lable">{t("Education")}:</div>
                <div className="Bluetext">{toturDetailes?.degree ? toturDetailes?.degree : toturDetailes?.high_education}</div>
                <img src={BadgeIcon} alt="shareIcon" />
                <div className="lable">{t("Grade")}:</div>
                <div className="Bluetext">
                  {courseIndex?.courses} - {courseIndex?.grade_name}
                </div>
                <img src={curriculum} alt="curriculum" />
                <div className="lable">{t("Curriculum")}:</div>
                {toturDetailes?.curriculum?.length > 0 ? (
                  <>
                    {toturDetailes?.curriculum?.map((item: any, i: any, arr: any[]) => {
                      return (
                        <div key={i} className="Bluetext">{item}{i !== arr?.length - 1 && ", "}</div>
                      )
                    })}
                  </>
                ) : (
                  <div className="Bluetext">{toturDetailes?.curriculum}</div>
                )}
              </div>
              <div className="user__Detailes__Grade">
                <img src={locationIcon} alt="shareIcon" />
                <div className="lable">{t("Location")}:</div>
                <div className="Bluetext">{toturDetailes?.address || t("Address not available")}</div>
              </div>
              <div className="selectcourse-div">
                <span>{t("Courses")}</span>
                <KeyboardArrowDownIcon
                  className={CourseOpen ? "Icon" : "IconInActive"}
                  onClick={() => setCourseOpen(!CourseOpen)}
                />
              </div>
              {CourseOpen && (
                <div className="display-container2">
                  {toturDetailes?.tutor_subjects?.map((sub: any, i: any) => (
                    <div
                      key={i}
                      className={
                        courseSelectIndex == i
                          ? "courses-Active"
                          : "courses-div"
                      }
                      onClick={() => {
                        setCourseSelectIndex(i);
                        setCourseIndex(sub);
                      }}
                    >
                      <img src={sub?.coursecategory_icon || tutor_icon}
                        onError={(e: any) => { e.target.src = tutor_icon; }} alt="" />
                      <div className="coursetxt">
                        {sub.courses} - {sub.grade_name}
                      </div>
                    </div>
                  ))
                  }
                </div>
              )}
              <div>
                <label className="title">{t("About Me")}</label>
                <div className="para-content">{toturDetailes?.about_me}</div>
              </div>
              <div className="mt-3">
                <label className="title">{t("Additional notes")}</label>
                <div className="para-content">
                  {checkString(courseIndex?.additional_notes)}
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-md-6 bottom__contant__wapper">
                  <div className="bottom__contant__left">
                    <img src={experienceBlue} alt="" />
                    <div>{t("Experience")}:</div>
                  </div>
                  <div>{courseIndex?.experience || 0}{t("Years")}</div>
                </div>
                <div className="col-md-6 bottom__contant__wapper">
                  <div className="bottom__contant__left">
                    <img src={Private_session} alt="" />
                    <div>{t("Private 1-1 sessions")}:</div>
                  </div>
                  <div>{courseIndex?.course_mode == 'Offline' ? t("On-premises") : courseIndex?.course_mode}</div>
                </div>
                <div className="col-md-6 bottom__contant__wapper">
                  <div className="bottom__contant__left">
                    <img src={mode_of_teaching} alt="" />
                    <div>{t("Mode Of Teaching")}:</div>
                  </div>
                  <div>{courseIndex?.course_mode == 'Offline' ? t("On-premises") : courseIndex?.course_mode}</div>
                </div>
                <div className="col-md-6 bottom__contant__wapper">
                  <div className="bottom__contant__left">
                    <img src={syllabus} alt="" />
                    <div>{t("Syllabus Covers")}</div>
                  </div>
                  {courseIndex?.syllabus_covers?.length > 0 && (
                    <img
                      src={pdfIcon}
                      alt=""
                      className="pdfIcon"
                      onClick={() =>
                        onOpenPdfDownload(courseIndex?.syllabus_covers)
                      }
                    />
                  )}
                </div>
                <div className="col-md-6 bottom__contant__wapper"></div>
              </div>
            </div>
          </div>
        </div>
        <TutorTrialSession
          TrialOpen={FreeTrialOpen}
          onClose={onCloseFreeTrial}
          tutor_id={toturDetailes?.id}
        />
        <PdfDownloadFile open={PdfOpen} handleClose={onClosePdfDownload} data={PdfData} />
        {AuthenticationOpen && (
          <div className="overlay-ModelBackround">
            <Dialog open={AuthenticationOpen}>
              <DialogContent>
                <div className="ChildSelect__container">
                  <div className="text__contant">
                    {t("Parent only allowed to Book slot")}
                  </div>
                  <div className="ChildSelectBtn__wapper__Tutor">
                    <button
                      className="BackBtn"
                      onClick={() => setAuthenticationOpen(false)}
                    >
                      {t("Back")}
                    </button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </Container>
    </div>
  );
};

export default TutorsDetaileView;


/////
////
//
