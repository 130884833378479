import Autocomplete from 'react-google-autocomplete';
import { setLocation, setLocationName } from '../../Redux/Reducer/Reducer';
import { useDispatch } from 'react-redux';
import { SetSessionStorageData } from '../../Utilities/Helpers/Helper';
import { AUTHENTICATION } from '../../Constants/Constants';
import { useLoadScript } from '@react-google-maps/api';
import { useState } from 'react';

const libraries: any = ['places'];

const GoogleCountryDropdown = ({ country, onChange }: any) => {

    const [ShowCountry, setShowCountry] = useState<string>(country);
    const dispatch = useDispatch();

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
        libraries, // Load the places library
    });

    if (loadError) {
        return <div>Search Not Fetching..</div>;
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    const geocodeByLatLng = (lat: any, lng: any) => {
        const geocoder = new window.google.maps.Geocoder();
        return new Promise((resolve, reject) => {
            geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
                if (status === 'OK') {
                    resolve(results);
                } else {
                    reject(status);
                }
            });
        });
    };

    const getFormattedAddress = async (lat: any, lng: any) => {
        try {
            const results: any = await geocodeByLatLng(lat, lng);
            if (results[0]) {
                var location: any = { lat, lng }
                let filterCountry = results[0].address_components.filter((res: any) => res.types.includes('country'));
                // dispatch(setLocation(location));
                // dispatch(setLocationName(filterCountry[0]?.long_name));
                // SetSessionStorageData(AUTHENTICATION.SHARED.CURRENT_LOCATION, JSON.stringify(location))
                // SetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME, filterCountry[0]?.long_name)
                setShowCountry(filterCountry[0]?.long_name)
                onChange(filterCountry[0]?.long_name || 'N/A');
            }
        } catch (error) {
            console.error('Geocode error:', error);
        }
    };

    return (
        <div>
            <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                onPlaceSelected={(place: any) => {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    getFormattedAddress(lat, lng);
                    // SetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME, place?.formatted_address)
                }}
                options={{
                    types: ['geocode'], // Show all results in the dropdown
                }}
                className="auto-complete my-country-dropdown-one"
                value={ShowCountry}
                onChange={(e: any) => setShowCountry(e.target.value)} // Handle input changes
                placeholder="Enter your country"
            />

        </div>
    );
};

export default GoogleCountryDropdown;
