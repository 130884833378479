import AliceCarousel from "react-alice-carousel";
import "./HomeServiceCards.scss";
import { useEffect, useRef, useState } from "react";
import { GLOBAL_CONSTANTS, HOMEPAGE, NESTEDMENUITEM, ROUTES } from "../../Constants/Constants";

/**Assets */
import navArrow from "../../Assets/home/navArrow.svg";
import star from "../../Assets/home/star.svg";
import { encryptPassData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { useNavigate } from "react-router-dom";
import left from "../../Assets/Tutor/left.svg";
import right from "../../Assets/Tutor/right.svg";
import { useSelector } from "react-redux";

const HomeServiceCards = (homeServiceCardsProps: any) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  let carousel: any;
  const carouselRef = useRef<any>(null);

  const NavigateRoute = (name: any, type: any, id: any) => {

    if (type === HOMEPAGE.SERVICE_TYPE.UNIVERSITY) {
      navigate(ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(id), { state: { DetailesID: id } });
    } else if (type === HOMEPAGE.SERVICE_TYPE.SCHOOL) {
      navigate(ROUTES.SCHOOL_DETAILE_VIEW + "?id=" + encryptPassData(id), { state: { DetailesID: id } });
    } else if (type === HOMEPAGE.SERVICE_TYPE.ACTIVITIES) {
      navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(id) + '&type=' + encryptPassData('camp'), { state: { DetailesID: id, type: 'camp' } });
    } else if (type === HOMEPAGE.SERVICE_TYPE.THEME_PARK) {
      navigate(ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + encryptPassData(id));
    }
  }

  /**Service cards template list */
  const getServiceCards = () => {

    var card: any = homeServiceCardsProps.serviceData?.slice(0, 10)?.map(
      (serviceDataElements: any, index: any) => {
        return (
          <div className="service_card" key={index} onClick={() => NavigateRoute(serviceDataElements?.name, homeServiceCardsProps.cardType, serviceDataElements?.id)}>
            <div className={`${ServiceCardContainerLang} service_card_container`}>
              <img
                draggable="false"
                src={serviceDataElements.images}
                alt="Learn and Play"
                className="offers_image"
                onDoubleClick={() => NavigateRoute(serviceDataElements?.name, homeServiceCardsProps.cardType, serviceDataElements?.id)}
              />
              <div className="sevice_card_contents_wrapper">
                {homeServiceCardsProps.cardType ===
                  HOMEPAGE.SERVICE_TYPE.SCHOOL && (
                    <div className="service_card_type school_service_card_type">
                      {homeServiceCardsProps.cardType}
                    </div>
                  )}
                {homeServiceCardsProps.cardType ===
                  HOMEPAGE.SERVICE_TYPE.UNIVERSITY && (
                    <div className="service_card_type university_service_card_type">
                      {homeServiceCardsProps.cardType}
                    </div>
                  )}
                {homeServiceCardsProps.cardType ===
                  HOMEPAGE.SERVICE_TYPE.ACTIVITIES && (
                    <div className="service_card_type activities_service_card_type">
                      {homeServiceCardsProps.cardType}
                    </div>
                  )}
                {homeServiceCardsProps.cardType ===
                  HOMEPAGE.SERVICE_TYPE.THEME_PARK && (
                    <div className="service_card_type theme_park_service_card_type">
                      {HOMEPAGE.SERVICE_TYPE.PARKS}
                    </div>
                  )}
                <div className="service_card_title" title={serviceDataElements.name}>
                  {serviceDataElements.name?.length > 26 ? serviceDataElements?.name?.slice(0, 27) + '...' : serviceDataElements?.name}
                </div>
                <div className="service_card_description" title={serviceDataElements.description}>
                  {serviceDataElements?.description?.length > 100 ? serviceDataElements?.description?.slice(0, 127) + '...' : serviceDataElements?.description}
                </div>
                {homeServiceCardsProps.cardType ===
                  HOMEPAGE.SERVICE_TYPE.SCHOOL && (
                    <div className="dsib_rating">
                      DSIB Rating : {serviceDataElements?.dsip_rating}
                    </div>
                  )}
                {homeServiceCardsProps.cardType ===
                  HOMEPAGE.SERVICE_TYPE.ACTIVITIES && (
                    <div className="ratings_wrapper">
                      <img
                        draggable="false"
                        src={star}
                        alt=""
                        className="rating_icon"
                      />
                      <div className="rating">{nullUndefinedEmptyCheck(serviceDataElements.rating) ? serviceDataElements.rating : "4.2"}</div>
                    </div>
                  )}
                <div className={`${NavigationArrowContainer} navigation_arrow_container`} onClick={() => NavigateRoute(serviceDataElements?.name, homeServiceCardsProps.cardType, serviceDataElements?.id)}>
                  <img
                    draggable="false"
                    src={navArrow}
                    alt="Learn and Play"
                    className="navigation_arrow"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    // // Add the "Explore All" card after the loop

    homeServiceCardsProps.serviceData.length > 10 && card.push(
      <>
        {homeServiceCardsProps.cardType ===
          HOMEPAGE.SERVICE_TYPE.SCHOOL && (
            <div className="school_explore-all__wapper" key={HOMEPAGE.SERVICE_TYPE.SCHOOL}
              onClick={() => navigate(ROUTES.SCHOOL_LIST_VIEW)}>
              <div className="text">Explore all</div>
              <div className="title">Schools</div>
            </div>
          )}
        {homeServiceCardsProps.cardType ===
          HOMEPAGE.SERVICE_TYPE.UNIVERSITY && (
            <div className="university_explore-all__wapper" key={HOMEPAGE.SERVICE_TYPE.UNIVERSITY}
              onClick={() => navigate(ROUTES.UNIVERSITY_LIST_VIEW)}>
              <div className="text">Explore all</div>
              <div className="title">Universities</div>
            </div>
          )}
        {homeServiceCardsProps.cardType ===
          HOMEPAGE.SERVICE_TYPE.ACTIVITIES && (
            <div className="activity_explore-all__wapper" key={HOMEPAGE.SERVICE_TYPE.ACTIVITIES}
              onClick={() => navigate(ROUTES.ACTIVITY_LIST_VIEW)}>
              <div className="text">Explore all</div>
              <div className="title">Activities</div>
            </div>
          )}
        {/* {homeServiceCardsProps.cardType ===
          HOMEPAGE.SERVICE_TYPE.THEME_PARK && (
            <div className="thempark_explore-all__wapper" key={HOMEPAGE.SERVICE_TYPE.THEME_PARK}>
              <div className="text">Explore all</div>
              <div className="title">Parks & Events</div>
            </div>
          )} */}
      </>
    );
    return card
  };

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(homeServiceCardsProps.serviceData)) {
      setServiceCardsTemplate(getServiceCards());
    }
  }, [homeServiceCardsProps.serviceData, HeaderLoction]);

  const [sericeCardsTemplate, setServiceCardsTemplate] = useState([]);


  /**Slide previous card */
  // const slidePrev = () => {
  //   if (carouselRef?.current) {
  //     carouselRef?.current?.slidePrev();
  //   }
  // };

  // /**Slide next card */
  // const slideNext = () => {
  //   if (carouselRef?.current) {
  //     carouselRef?.current?.slideNext();
  //   }
  // };

  const totalSlides = carouselRef?.current?.state?.itemsCount; // Change this based on the total number of slides

  const screenWidth = window.innerWidth;
  let itemsToSlide: number;

  if (screenWidth >= 1024) {
    itemsToSlide = 3;
  } else if (screenWidth >= 600) {
    itemsToSlide = 2;
  } else {
    itemsToSlide = 1;
  }


  const slideNext = () => {
    if (activeIndex < totalSlides - (itemsToSlide)) {
      setActiveIndex(activeIndex + itemsToSlide);
      carouselRef.current.slideTo(activeIndex + itemsToSlide);

    }
  };

  const slidePrev = () => {

    if (activeIndex > 0) {
      setActiveIndex(activeIndex - itemsToSlide);
      carouselRef.current.slideTo(activeIndex - itemsToSlide);

    }
  }

  const handleScroll = (e: any) => {
    const isTwoFingerScroll = e.touches && e.touches.length === 2;

    if (e.deltaY > 0 || isTwoFingerScroll) {
      // Scrolling down or two-finger scroll, move to the next slide
      slideNext();
    } else if (e.deltaY < 0 || isTwoFingerScroll) {
      // Scrolling up or two-finger scroll, move to the previous slide
      slidePrev();
    }
  };

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };


  const handleKeyPress = (e: any) => {
    if (e.key === NESTEDMENUITEM.ARROWRIGHT) {
      carouselRef.current.slideNext();
    } else if (e.key === NESTEDMENUITEM.ARROWLEFT) {
      carouselRef.current.slidePrev();
    }
  };

  const handleWheel = (e: any) => {
    if (e.deltaY > 0) {
      carouselRef.current.slideNext();
    } else if (e.deltaY < 0) {
      carouselRef.current.slidePrev();
    }
  };

  useEffect(() => {
    document.addEventListener(NESTEDMENUITEM.KEYDOWN, handleKeyPress);
    // document.addEventListener('wheel', handleWheel);

    return () => {
      document.removeEventListener(NESTEDMENUITEM.KEYDOWN, handleKeyPress);
      // document.removeEventListener('wheel', handleWheel);
    };
  }, [carouselRef]);

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name for depend languge changing*/
  const arrow_navs_lang = language === "English" ? "arrow_navs" : "arrow_navs_arab";
  const ServiceCardContainerLang = language === "English" ? "service_card_container_lang" : "service_card_container_arab";
  const NavigationArrowContainer = language === "English" ? "navigation_arrow_container_eng" : "navigation_arrow_container_arab";

  return (
    <>
      <div className="service_cards_wrapper">
        <div className="arrow_nav_tile_contianer">
          {/* <div className="arrow_navs me-2"> */}
          <div className={`${arrow_navs_lang} me-2`}>
            <img
              src={left}
              onClick={slidePrev}
              alt="Learn and Play"
              className={activeIndex > 0 ? "left_arrow " : "disable_arrow"}
            />
            <img
              src={right}
              onClick={slideNext}
              alt="Learn and Play"
              className={activeIndex < totalSlides - (itemsToSlide) ? "right_arrow" : "disable_arrow"}
            />
          </div>
        </div>
        {/* <div onWheel={handleScroll} onTouchMove={handleScroll} style={{ overflowY: 'hidden' }}> */}
        <AliceCarousel
          mouseTracking={false}
          keyboardNavigation={false}
          autoWidth
          disableButtonsControls
          items={sericeCardsTemplate}
          disableDotsControls
          onSlideChanged={syncActiveIndex}
          // animationDuration={1000} /* some high value here */
          // animationType="fadeout"
          autoPlay={false}
          responsive={{
            0: { items: 1 },
            600: { items: 2 },
            1024: { items: 3 },
          }}
          // ref={(el) => (carousel = el)}
          ref={carouselRef}
        />
        {/* </div> */}
      </div>
    </>
  );
};

export default HomeServiceCards;
