import "./ThemeParkPaymentSucces.scss";
import tick from "../../../Assets/ThemePark/tick.svg";
import vector from "../../../Assets/Tutor/vector.svg";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import { Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../../Constants/Constants";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MobileCalenderIcon from "../../../Assets/Tutor/tutorPaymentCalIcon.svg";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { GetSessionStorageData, nullUndefinedEmptyCheck, SetSessionStorageData } from "../../../Utilities/Helpers/Helper";
import moment from 'moment';
import logo_themePark from "../../../Assets/SideBar/themeParkActive.svg";
import OneDayPassIcon from '../../../Assets/ThemePark/OneDayPassIcon.svg';
import MySVGComponent from "../../../Assets/Asserts-Common/MySVGComponent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ThemeParkPaymentSucces = () => {
    const { t } = useTranslation();
    const route = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [ProfilePic, setProfilePic] = useState<any>("");
    const [CourseOpen, setCourseOpen] = useState(true);
    const token = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        var Picture = GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE);
        setProfilePic(Picture);

    })

    const downloadPDFLocal = async (data: any) => {
        try {
            const response: any = await fetch(data);
            const blob = await response.blob();
            const fileName = response?.url?.split('/')[3]
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link: any = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName ? fileName : `file.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) { }
    };

    const downloadPDFs = async (pdfUrls: any) => {
        pdfUrls.forEach(downloadPDFLocal);

    };

    const handleNavigate = () => {
        // URL of the .ics file you want to download
        const icsFileUrl = route?.state?.calender_link[0];

        // Create an anchor element
        const anchor = document.createElement('a');
        anchor.href = icsFileUrl;
        anchor.download = '(L & P)Calender.ics';

        // Trigger a click event on the anchor element
        anchor.click();
        handleClose();
        navigateHomePage();
    }

    const navigateHomePage = () => {
        if (token) {
            navigate(ROUTES.MYTICKETS);
            SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, 'My Tickets');
        } else {
            navigate(ROUTES.THEMEPARK__HOMEPAGE)
        }
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });
    /*Below class name based on English Arabic*/
    const TicketAvailableImg = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ticket-available-img" : "ticket-available-img-arab";
    const TicketPassTypeLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ticket-pass-type-eng" : "ticket-pass-type-arab";
    const PaymentSuccessfulLeft = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "paymentsuccessful-left-eng" : "paymentsuccessful-left-arab";

    return (
        <React.Fragment>
            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                <div className="ThemeParksuccess-container">
                    <div className="paymentsuccessful__layout">
                        <div className={`${PaymentSuccessfulLeft} paymentsuccessful-left`}>
                            <div className="alignment3">
                                {/* <img src={tick} alt="" className="img-tick" /> */}
                                <span className="img-tick" ><MySVGComponent checkmarkStrokeColor="#04D6FB" /></span>
                                <label htmlFor="" className="payment-text">{t("Payment Successful")} !</label>
                            </div>
                        </div>
                        <div className="paymentsuccessfull-right">
                            <div className="topContant">
                                <div className="consluting__wapper">
                                    <img src={logo_themePark} alt="" className="consluting_Img" />
                                    <div className="left__contant">{t("Theme Park")}</div>
                                </div>
                                <div className="right__contant">{t("AED")} {route?.state?.PaymentTotal}</div>
                            </div>
                            <div className="course__wapper">
                                {/* <div className="title">Booking Details</div> */}
                                <div className="course-detail-show">
                                    <div className="title">{t("Booking Details")}</div>
                                    <KeyboardArrowDownIcon
                                        className={CourseOpen ? "Icon" : "IconInActive"}
                                        onClick={() => setCourseOpen(!CourseOpen)}
                                    />
                                </div>
                                <div className="booked_ticket_detailes_wapper">
                                    {CourseOpen && (
                                        <>
                                            {route?.state?.ticketArr?.map((data: any) => {
                                                if (data?.tickets_select) {
                                                    return (
                                                        <>
                                                            <div className={"ticket-available ticket-available_select"} >
                                                                <img src={OneDayPassIcon} alt="OnedayPas" className={TicketAvailableImg} />
                                                                <p className={`${TicketPassTypeLang} ticket-pass-type`}>{data?.ticket_type}</p>
                                                                <p className="ticket-type-edite">
                                                                    <span>{data?.tickets_qty}{t("Ticket")} </span>
                                                                </p>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })}
                                        </>
                                    )}
                                </div>

                                <div className="title mt-2">Booked By</div>
                                <div className="profile__wapper">
                                    <img src={nullUndefinedEmptyCheck(ProfilePic) ? ProfilePic?.toString() : tutor_img} alt="" />
                                    <div className="profile__name">{GetSessionStorageData(AUTHENTICATION.SHARED.NAME)}</div>
                                </div>
                            </div>
                            <div className="transaction-container">
                                <label className="td-text">{t("Transaction Details")}</label>
                                <div className="transaction-deatiles__wapper">
                                    <div className="ti-container">
                                        <label>{t("Transaction ID")}</label>
                                        <span>485983593829238</span>
                                    </div>
                                    <div className="t12-container">
                                        <label>{t("Date & Time")}</label>
                                        <span>{moment().format("MMMM Do, YYYY h:mm a")}</span>
                                    </div>
                                </div>
                                <div className="display-css">
                                    <button className="btn-share" onClick={() => downloadPDFs(route?.state?.pdfTicketList)}>
                                        {/* <ShareOutlinedIcon className="share__icon" /> */}
                                        <span>{t("Download Receipt")}</span>
                                    </button>
                                    <button className="btn-done" onClick={navigateHomePage}>{t("Done")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {open && (
                    <div className="overlay-ModelBackround">
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        top: 6,
                                        right: 13,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <div className="ThemePark__Payment__Success__Pop">
                                    <div className="Payment__Pop__wapper">
                                        <img src={MobileCalenderIcon} alt="" />
                                        <div className="text">{t("Add booking to your calendar?")}</div>
                                    </div>
                                    <div className="Payment__bottom__btn">
                                        <button className="backBtn" onClick={handleClose}>{t("Cancel")}</button>
                                        <button className="SuccessBtn" onClick={() => handleNavigate()}>{t("Okay")}</button>
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                )}
            </Container>
        </React.Fragment>
    )
}

export default ThemeParkPaymentSucces;