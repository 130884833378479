import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  Marker,
  InfoWindow,
  CircleF,
  useJsApiLoader,
} from "@react-google-maps/api";
import "./UniversityMaps.scss";
import { MapProps } from "../../Models/UniversityModel";
import UniversityMapicon from "../../Assets/University/univmap.svg";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../Assets/University/universityShareIcon.svg";
import LikeIcon from "../../Assets/University/UniversityLikeIcon.svg";
import locationIcon from "../../Assets/Tutor/location.svg";
import StarIcon from "@mui/icons-material/Star";
import {
  GetSessionStorageData,
  encryptPassData,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
  TUTORMAPCONSTANT,
} from "../../Constants/Constants";
import {
  setLocation,
  setLocationName,
  setLoginSignUpOpen,
} from "../../Redux/Reducer/Reducer";
import { useDispatch, useSelector } from "react-redux";
import UniversityFill from "../../Assets/HeartFill/UniversityFill.svg";
import toast from "react-hot-toast";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import WebShareComponent from "../WebShare/WebShare";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UniversityMaps = ({
  zoom,
  radius,
  containerStyle,
  disable,
  zoomControl,
  draggable,
  clickableIcons,
  searchValue,
  getOneTutor,
  lat,
  lang,
  filter,
  initFilter,
  UniversityList,
  initSort
}: MapProps) => {
  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [universityList, setUniversityList] = useState<any>(UniversityList);
  const [userProfileImg, setUserProfileImg] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [libraries] = useState([TUTORMAPCONSTANT.LIBRARIES]);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentLocation({ lat: parseFloat(lat), lng: parseFloat(lang) });
    setUserProfileImg(
      GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE)
    );
    if (lat && lang) {
      UniversityListApi();
    }
  }, [lat, lang, searchValue, radius, filter]);

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  const payload = () => {
    var payload;
    var qsValue: any = [];
    var shValue: any = [];
    var theValue: any = [];
    var priceValue: any = [];

    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }
    if (initFilter.qs_ranking[0] === filter.qs_ranking[0] && initFilter.qs_ranking[1] === filter.qs_ranking[1]) {
      qsValue = []
    } else {
      qsValue = {
        min: filter.qs_ranking[0],
        max: filter.qs_ranking[1],
      }
    }
    if (initFilter.sh_ranking[0] === filter.sh_ranking[0] && initFilter.sh_ranking[1] === filter.sh_ranking[1]) {
      shValue = []
    } else {
      shValue = {
        min: filter.sh_ranking[0],
        max: filter.sh_ranking[1],
      }
    }
    if (initFilter.the_ranking[0] === filter.the_ranking[0] && initFilter.the_ranking[1] === filter.the_ranking[1]) {
      theValue = []
    } else {
      theValue = {
        min: filter.the_ranking[0],
        max: filter.the_ranking[1],
      }
    }
    return payload = {
      latitude: lat?.toString(),
      longitude: lang?.toString(),
      radius: filter.radius,
      price: priceValue,
      qs_ranking: qsValue,
      sh_ranking: shValue,
      the_ranking: theValue,
      programs: filter.programs,
      language_instruction: filter.language_instruction
    };
  };
  /**University get List Api*/
  const UniversityListApi = () => {
    var pay = payload();
    axiosInstance
      .post(`${API_END_POINTS.UNIVERSITYLIST}`, pay)
      .then((Response: any) => {
        setUniversityList(Response?.data?.result?.data);
      })
      .catch(() => { });
  };

  const navigateDetailedView = (Detailes: any) => {
    navigate(ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(Detailes?.id), {
      state: { tutor_Detailes: Detailes },
    });
  };

  const mapOptions = {
    disableDefaultUI: disable, // This will remove all default UI controls
    zoomControl: zoomControl, // This will specifically remove the zoom control
    draggable: draggable, // Disable dragging the map
    clickableIcons: clickableIcons, // Disable clicking on map icons
    disableDoubleClickZoom: disable,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  useEffect(() => {
    if (initSort?.price || initSort?.rating) {
      handleProceedSort(initSort?.price, initSort?.rating)
    }
  }, [initSort])

  const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
    if (checkedPrice == 'high') {
      const sortedData = [...universityList].sort((a: any, b: any) => b?.least_price - a?.least_price);
      setUniversityList(sortedData);
    } else if (checkedPrice == 'low') {
      const sortedData = [...universityList].sort((a: any, b: any) => a?.least_price - b?.least_price);
      setUniversityList(sortedData)
    } else if (checkedRating == 'high') {
      const sortedData = [...universityList].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
      setUniversityList(sortedData)
    } else if (checkedRating == 'low') {
      const sortedData = [...universityList].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
      setUniversityList(sortedData)
    }
  }

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
            UniversityListApi();
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: TUTORMAPCONSTANT.MAP__ID || "",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
    libraries,
  });

  /**Header search location getFormattedAddress Function */
  const geocoder: any = isLoaded ? new window.google.maps.Geocoder() : "";

  const getFormattedAddress = (lat: any, lng: any) => {
    geocoder.geocode(
      { location: { lat, lng } },
      (results: any, status: any) => {
        if (status) {
          if (results[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT]) {
            dispatch(
              setLocationName(
                results[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT]
                  ?.formatted_address
              )
            );
          }
        }
      }
    );
  };

  return isLoaded ? (
    <>
      {/* <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ""}
                libraries={["places"]}
            > */}
      <GoogleMap
        id={TUTORMAPCONSTANT.MAP__ID}
        mapContainerStyle={containerStyle}
        zoom={zoom}
        center={currentLocation}
        options={mapOptions}
        onClick={() => setSelectedLocation(null)}
      // onMouseOut={() => setSelectedLocation(null)}
      >
        <CircleF
          center={HeaderLoction}
          radius={radius}
          options={{
            fillColor: "#178FFF", // Fill color with opacity
            strokeColor: "rgba(79, 139, 255, 0.60)", // Border color
            strokeOpacity: 0.8, // Border opacity
            strokeWeight: 2, // Border width
            fillOpacity: 0.1, // Fill opacity
          }}
        />
        {HeaderLoction && (
          <MarkerF
            position={HeaderLoction}
            draggable={true}
            shape={{ coords: [17, 17, 18], type: "circle" }}
            icon={{
              scaledSize: new google.maps.Size(40, 40),
              url: userProfileImg,
            }}
            onDragEnd={(e: any) => {
              setCurrentLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
              dispatch(setLocation(currentLocation));
              getFormattedAddress(e?.latLng?.lat(), e?.latLng?.lng());
            }}
          />
        )}
        {universityList?.map((data: any) => {
          let object = {
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
          };
          return (
            <Marker
              position={object}
              icon={{
                scaledSize:
                  lat == data?.latitude && lang == data?.longitude
                    ? new google.maps.Size(65, 65)
                    : new google.maps.Size(40, 40),
                url: UniversityMapicon,
              }}
              onMouseOver={() => setSelectedLocation(data)}
              // onMouseOut={() => setSelectedLocation(null)}
              animation={
                lat == data?.latitude && lang == data?.longitude
                  ? window.google.maps.Animation.DROP
                  : undefined
              }
            >
              {selectedLocation === data && (
                <InfoWindow
                  onCloseClick={() => setSelectedLocation(null)}
                  options={{
                    maxWidth: 372,
                  }}
                >
                  <div
                    className=""
                    onDoubleClick={() => navigateDetailedView(data)}
                    onMouseEnter={() => setSelectedLocation(data)}
                    onMouseLeave={() => setSelectedLocation(null)}
                  >
                    <div
                      className="Hover__Card"
                      onClick={() => {
                        if (clickableIcons) {
                          getOneTutor(data);
                        }
                      }}
                    >
                      {/* <img
                        src={data?.images ? data?.images : tutor_img}
                        alt="profile_image"
                        onError={(e: any) => {
                          e.target.src = tutor_img;
                        }}
                        className="profile_image__map_university"
                      /> */}
                      <div className="rightside_contant">
                        <div className="w-100">
                          <div className="user__Detailes">
                            <div className="user__name">
                              {data?.university_name}
                            </div>
                            {/* <div className="user__icon__wrapper">
                              <WebShareComponent
                                params={
                                  ROUTES.UNIVERSITY_DETAILE_VIEW +
                                  "?id=" +
                                  btoa(data?.id)
                                }
                              >
                                <img src={shareIcon} alt="shareIcon" className="mb-1" />
                              </WebShareComponent>
                              <img
                                src={
                                  data?.is_favourite ? UniversityFill : LikeIcon
                                }
                                alt=""
                                onClick={() =>
                                  handleChangeFavourites(
                                    data.is_favourite,
                                    data?.user
                                  )
                                }
                              />
                            </div> */}
                          </div>
                          <div className="user__Detailes__location">
                            <img
                              src={locationIcon}
                              alt="locationIcon"
                              className="grayIcons"
                            />
                            <div className="graytext info_location" title={data?.city}>{data?.city}</div>
                            {/* <div className="graytext">
                              Founded : {data?.founded_year}
                            </div> */}
                          </div>

                          <div className="University__Detailes__rating">
                            <div className="Detailes__rating__contant">
                              <span>{t("QS")} :</span>
                              <div>{data?.QS_ranking ? data?.QS_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                            </div>
                            <div className="Detailes__rating__contant">
                              <span>{t("THE")} :</span>
                              <div>{data?.THE_ranking ? data?.THE_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                            </div>
                            <div className="Detailes__rating__contant">
                              <span>{t("SH")} : </span>
                              <div>{data?.SH_ranking ? data?.SH_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                            </div>
                            {/* <div className="d-flex gap-2 align-items-center">
                              {data?.rating ? (
                                <StarIcon className="StarIcon" />
                              ) : (
                                ""
                              )}
                              <div className="card__title">{data?.rating}</div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
      {/* </LoadScript > */}
    </>
  ) : (
    <></>
  );
};

export default UniversityMaps;
