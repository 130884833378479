import "./AdminChat.scss";
import TutorHubChat__Img from "../../Assets/TutorHub/TutorHubChat.svg";
import chatSendIcon from "../../Assets/TutorHub/chatSendIcon.svg";
import { useEffect, useRef, useState } from "react";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, SOCKET } from "../../Constants/Constants";
import { GetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AdminChat = () => {
    const { t } = useTranslation();
    const route = useLocation();
    const navigate = useNavigate();
    const [textMessage, setTextMessage] = useState("");
    const [activeIndex, setActiveIndex] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
    const [chatHistory, setChatHistory] = useState<any>([]);
    const [userId, setUserId] = useState<any>(GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID));
    const [socketInstance, setSocketInstance] = useState<any>();
    const [currentPage, setCurrentPage] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
    const [totalPages, setTotalPages] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);

    const chatContainerRef: any = useRef(null);
    useEffect(() => {
        setUserId(GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID));
        getChatHistory();
    }, []);

    useEffect(() => {
        // Scroll to the bottom of the chat container when new messages arrive
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [textMessage]);

    useEffect(() => {
        if (socketInstance) {
            socketInstance.close();
        };
        let socket = new WebSocket(`${process.env.REACT_APP_CHAT_WEB_SOCKET + route?.state?.roomData?.room_name}/`);
        setSocketInstance(socket)
    }, [route?.state?.roomData]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    useEffect(() => {
        if (socketInstance) {
            socketInstance.onopen = (event: any) => {
            };

            /* Listen to events for socket server */
            socketInstance.onmessage = (event: any) => {
                setChatHistory((prevChatHistory: any) => [...prevChatHistory, JSON.parse(event.data)]);
            };

            /* Closes socket on killing component */
            return () => {
                socketInstance.close();
            }
        }

    }, [socketInstance]);


    /* Sending message using socket */
    const onSendMessage = () => {
        socketInstance.send(JSON.stringify({
            sender: userId,
            receiver: route?.state?.roomData?.recipient,
            message: textMessage,
            room_id: route?.state?.roomData?.room_id
        }));
        setTextMessage("");
    };


    const getChatHistory = () => {
        var payload = {
            page: currentPage,
            limit: 100
        }
        axiosInstance.post(`${API_END_POINTS.CHAT_HISTORY + route?.state?.roomData?.room_id}`, payload)
            .then((response) => {
                if (response.data.status) {
                    setChatHistory(response?.data?.result?.data?.reverse());
                }
            })
    }
    return (
        <>
            <div className="mt-5">
                <div className="TutorHubChat__container mt-5">
                    <div className="Header__wapper">
                        <img src={TutorHubChat__Img} alt="" />
                        <div className="title">{t("Chat with Admin")}</div>
                    </div>

                    <div className="chat__container" ref={chatContainerRef}>
                        {chatHistory?.map((item: any, i: any) => {
                            return (
                                <>
                                    {/* <div className="chat__center">
                                            <div className="show__time">
                                                May 16, 2023
                                            </div>
                                        </div> */}

                                    {item?.sender != userId ? (
                                        <div className="chat__start">
                                            <div className="tutor__chat">
                                                {item?.message}
                                            </div>
                                            <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                        </div>
                                    ) : (
                                        <div className="chat__end">
                                            <div className="user__chat">
                                                {item?.message}
                                            </div>
                                            <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                        </div>
                                    )}
                                </>
                            )
                        })}
                    </div>

                    <div className="type__box__container">
                        <input type="text" placeholder={t("Type Your Message")} value={textMessage} onChange={(e: any) => { setTextMessage(e.target.value); }}
                            onKeyPress={(e: any) => {
                                if (e.code === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                                    if (textMessage.trim() === '') {
                                        e.preventDefault();
                                    } else {
                                        onSendMessage();
                                        e.preventDefault();
                                    }
                                }
                            }}
                            className="type__box__input" />
                        {nullUndefinedEmptyCheck(textMessage) && textMessage.trim() !== '' ?
                            (<img src={chatSendIcon} alt="" onClick={onSendMessage} className="sendIcon" />) :
                            (<img src={chatSendIcon} alt="" className="sendIcon__disable" />)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminChat;
