import React, { useEffect, useState } from "react";
import search from "../../Assets/Tutor/searchIconGray.svg";
import schoolIocn from "../../Assets/School/schoolIocn.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
} from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { store } from "../../Redux/Store/Store";
import { setLoader, setLoginSignUpOpen, setSchoolFilter } from "../../Redux/Reducer/Reducer";
import {
  GetSessionStorageData,
  SetSessionStorageData,
  encryptPassData,
  isObjectEmptyValue,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import ExpandMap from "../../Assets/Activities/ExpandMap.svg";
import schoolImg from "../../Assets/School/schoolImg.svg";
import LikeIcon from "../../Assets/School/schoolLikeIcon.svg";
import ShareIcon from "../../Assets/School/shareIcon.svg";
import locationIcon from "../../Assets/Tutor/location.svg";
import "./SchoolDashboardLayout";
import SchoolMaps from "../../Components/SchoolMaps/SchoolMaps";
import toast from "react-hot-toast";
import SchoolFill from "../../Assets/HeartFill/SchoolFill.svg";
import { useDispatch, useSelector } from "react-redux";
import spacemen from "../../Assets/Global/spacemen.svg";
import WebShareComponent from "../../Components/WebShare/WebShare";

import AppFilter from "../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";

// Render the  Map view Page size
const containerStyle = {
  width: "100%",
  height: "100%",
};

const SchoolDashboardLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [tutorList, setTutorList] = useState([]);
  const [searchTextList, setSearchTextList] = useState([]);
  const [currentLat, setCurrentLat] = useState<any>("");
  const [currentLang, setCurrentLang] = useState<any>("");
  const { t } = useTranslation();

  const getSchoolFilter: any = useSelector((state: any) => {
    return state.filterReducer?.schoolfilter
  })
  // Filter
  const init_filter = {
    rating: [],
    price: [0, 0],
    curriculum: [],
    radius: 10,
    grade: [0, 15],
  }
  const [FilterPopOpen, setFilterPopOpen] = useState(false);
  const [FilterData, setFilterData] = useState<any>([]);
  const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
  const [initFilter, setInitFilter] = useState<any>(init_filter);
  const [initSort, setSort] = useState<any>({
    price: '',
    rating: ''
  });


  const onOpenFilterPop = () => {
    setFilterPopOpen(true);
  };

  const onCloseFilterPop = () => {
    setFilterPopOpen(false);
  };

  // Route to the Map view Page
  const handleRouteMap = () => {
    navigate(ROUTES.SCHOOL_MAPS_VIEW, {
      state: {
        tutor_list: tutorList,
        search_text: searchValue,
        redius: FilterApplyData.radius,
        lat: currentLat,
        lang: currentLang,
        filter: FilterApplyData,
      },
    });
  };

  /**Sync card index on slide */
  const navigateSchoolDetailedView = (value: any) => {
    navigate(ROUTES.SCHOOL_DETAILE_VIEW + "?id=" + encryptPassData(value?.id), {
      state: { tutor_Detailes: value },
    });
  };

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng)) {
      setCurrentLat(HeaderLoction?.lat);
      setCurrentLang(HeaderLoction?.lng);
      if (getSchoolFilter && isObjectEmptyValue(getSchoolFilter)) {
        SchoolListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, getSchoolFilter);
      } else {
        SchoolListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
      }
    }
  }, [HeaderLoction]);

  useEffect(() => {
    getFilterData();
  }, []);

  const payload = (latitude: any, longitude: any, value: string, filter: any) => {
    var payload;
    var priceValue: any = [];
    var gradeValue: any = [];
    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }
    if (initFilter.grade[0] === filter.grade[0] && initFilter.grade[1] === filter.grade[1]) {
      gradeValue = []
    } else {
      gradeValue = {
        min: filter.grade[0],
        max: filter.grade[1],
      }
    }
    return payload = {
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      radius: filter.radius,
      search: value ? value : "",
      price: priceValue,
      rating: filter.rating,
      curriculum: filter.curriculum,
      grade: gradeValue
    };
  };

  /**School get List Api*/
  const SchoolListApi = (latitude: any, longitude: any, value: string, filter: any) => {
    var pay = payload(latitude, longitude, value, filter);
    axiosInstance
      .post(`${API_END_POINTS.SCHOOLLIST}`, pay)
      .then((Response: any) => {
        setTutorList(Response?.data?.result?.data);
      })
      .catch(() => { });
  };

  /**Tutor Search Keyword Api*/
  const searchKeyword = (search: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    var payload = {
      search_text: search.trim(),
    };
    axiosInstance
      .post(`${API_END_POINTS.SCHOOL_KEY_WORD_SEARCH}`, payload)
      .then((Response: any) => {
        setSearchTextList(Response?.data.result?.data);
      })
      .catch(() => { });
  };

  /**Get Filter Categories List*/
  const getFilterData = () => {
    axiosInstance
      .get(`${API_END_POINTS.SCHOOLFILTERLIST}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          let data = Response?.data?.result?.data;
          setFilterData(data);
          if (data) {
            let init = {
              price: [data.price?.min, data.price?.max],
              grade: [data.grade?.min, data.grade?.max],
              rating: [],
              curriculum: [],
              radius: 10,
            }
            setInitFilter(init);
            if (getSchoolFilter && isObjectEmptyValue(getSchoolFilter)) {
              setFilterApplyData(getSchoolFilter);
            } else {
              setFilterApplyData(init);
            }
          }
          // setFilterApplyData({...FilterApplyData, price:[0, data.price?.max] })
          // SchoolListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, {...FilterApplyData, price:[0, data.price?.max] });
        }
      })
      .catch(() => { });
  };

  /**Filter Apply api call Function*/
  const FilterApply = (filter_data: any) => {
    setFilterApplyData(filter_data);
    SetSessionStorageData("school_filter", JSON.stringify(filter_data));
    store.dispatch(setSchoolFilter(filter_data));
    SchoolListApi(currentLat, currentLang, searchValue, filter_data);
  };

  /**AutoComplete Onchange Function*/
  const onInputChange = (value: any) => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    if (nullUndefinedEmptyCheck(value) && value?.length > 2) {
      setSearchValue(value);
      searchKeyword(value);
    } else if (value?.length == 0) {
      SchoolListApi(currentLat, currentLang, searchValue, FilterApplyData);
    }
  };

  /**AutoComplete submit Function*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //  prevent page refresh
    event.preventDefault();
    SchoolListApi(currentLat, currentLang, searchValue, FilterApplyData);
  };

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FOUR_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            SchoolListApi(currentLat, currentLang, searchValue, FilterApplyData);
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  /**Loader state*/
  const Loader: any = useSelector((state: any) => {
    return state.loaderReducer.loader;
  });

  const handlePassListPage = (value: any) => {
    if (nullUndefinedEmptyCheck(value)) {
      if (value?.includes("near me")) {
        navigate(ROUTES.SCHOOL_LIST_VIEW, {
          state: {
            tutor_list: tutorList,
            search_text: value,
            redius: FilterApplyData.radius,
            lat: currentLat,
            lang: currentLang,
            map_show: true,
            filter: FilterApplyData
          },
        });
      } else {
        navigate(ROUTES.SCHOOL_LIST_VIEW, {
          state: {
            tutor_list: tutorList,
            search_text: value,
            redius: FilterApplyData.radius,
            lat: currentLat,
            lang: currentLang,
            map_show: false,
            filter: FilterApplyData

          },
        });
      }
    } else {
      SchoolListApi(currentLat, currentLang, value, FilterApplyData);
    }
  };

  const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
    if (checkedPrice == 'high') {
      const sortedData = [...tutorList].sort((a: any, b: any) => b?.least_fee - a?.least_fee);
      setTutorList(sortedData);
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedPrice == 'low') {
      const sortedData = [...tutorList].sort((a: any, b: any) => a?.least_fee - b?.least_fee);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'high') {
      const sortedData = [...tutorList].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'low') {
      const sortedData = [...tutorList].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    }
  }
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /*Below class name based on English Arabic*/
  const Ssearch_Button = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ssearch_button_english" : "ssearch_button_arab";
  const RowOverRideSchool = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "row-override-school" : "row-override-school_arab";


  return (
    <>
      <div className="University__dashboard__container">

        <div className="row">

          <div className="col-12 title_container">

            <div className="Dashboard__schoolCard_Img_wapper">
              <img
                draggable="false"
                src={schoolIocn}
                alt="SchoolImg"
                className=""
              />
              <div className="text">{t("Schools")}</div>
            </div>


          </div>
          {/* <div className="row mb-2 row-override-school"> */}
          <div className={`row  mb-2 ${RowOverRideSchool}`}>
            <div className="col-lg-4 col-md-12 input-style-phone">
              <div className="search_field_wrapper">
                <div className="search__autocomplete">
                  <form onSubmit={handleSubmit}>
                    <Autocomplete
                      freeSolo
                      popupIcon
                      id="free-solo-2-demo"
                      disableClearable={false}
                      clearIcon={false}
                      onChange={(e, value) => {
                        setSearchValue(value);
                        handlePassListPage(value);
                      }}
                      options={searchTextList?.map(
                        (option: any) => option?.school_name
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("Search for School")}
                          onChange={(inputChangeEvent: any) => {
                            onInputChange(inputChangeEvent.target.value.trim());
                          }}
                          onKeyDown={(e: any) => {
                            if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                              if (searchValue.trim() === "") {
                                e.preventDefault();
                              } else {
                                handlePassListPage(searchValue);
                                e.preventDefault();
                              }
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: "text",
                          }}
                        />
                      )}
                    />
                    <img
                      draggable="false"
                      src={search}
                      alt="search"
                      className={`${Ssearch_Button} search_button`}
                      onClick={() => handlePassListPage(searchValue)}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <AppFilter
                FilterPopOpen={FilterPopOpen}
                onOpenPop={onOpenFilterPop}
                onClosePop={onCloseFilterPop}
                FilterData={FilterData}
                FilterApply={FilterApply}
                FilterApplyData={FilterApplyData}
                initFilter={initFilter}
                initSort={initSort}
                handleApplySort={handleProceedSort}
                type="school"
                color="#FFC812" />
            </div>

          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">


            <div className="University_map_view">
              <div className="University__viewMap" onClick={handleRouteMap}>
                <img src={ExpandMap} alt="ExpandMap" />
                <span>{t("View on Map")}</span>
              </div>
              <SchoolMaps
                zoom={12}
                radius={FilterApplyData.radius * 1000}
                lat={currentLat}
                lang={currentLang}
                containerStyle={containerStyle}
                disable={true}
                zoomControl={false}
                clickableIcons={false}
                draggable={false}
                initSort={initSort}
                UniversityList={tutorList}
                getOneTutor={[]}
                filter={isObjectEmptyValue(getSchoolFilter) ? getSchoolFilter : FilterApplyData}
                initFilter={initFilter}
                searchValue={searchValue}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  university__list__container">

            {tutorList.map((item: any, i: any) => {
              return (
                <>
                  <div className="school__list__wapper" key={i} onClick={() => navigateSchoolDetailedView(item)}>
                    <div
                      className="university__leftside"
                    >
                      <img
                        src={
                          item?.images?.length > 0
                            ? item?.images[0]
                            : schoolImg
                        }
                        alt="schoolImg"
                        onError={(e: any) => {
                          e.target.src = schoolImg;
                        }}
                      />
                    </div>
                    <div className="university__rightside">
                      <div className="university__name">
                        <span
                          className="name"
                        >
                          {item?.school_name}
                        </span>
                        <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                          <WebShareComponent
                            params={
                              ROUTES.SCHOOL_DETAILE_VIEW +
                              "?id=" +
                              encryptPassData(item?.id)
                            }
                          >
                            <img src={ShareIcon} alt="" className="mb-1" />
                          </WebShareComponent>
                          <img
                            src={item.is_favourite ? SchoolFill : LikeIcon}
                            alt=""
                            onClick={() =>
                              handleChangeFavourites(
                                item.is_favourite,
                                item?.user
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="university__location" >
                        <img src={locationIcon} alt="" />
                        <span className="location">
                          {/* {item?.city ? item?.city : GLOBAL_CONSTANTS.INITIAL_VALUES.NA} */}
                          {item?.city ? item?.city : item.address}
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-12 university__ranking">
                          <div className="text__black">{t("Curriculum")} : </div>
                          <div className="text__green" title={item?.curriculam}>{item?.curriculam ? item?.curriculam : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-md-6 col-sm-12 university__ranking">
                          {/* <div className="text__black">{item?.is_type ? "Year" : "Grade"}  : </div> */}
                          <div className="text__black">{t(item?.is_type ? "Year" : "Grade")} : </div>
                          <div className="text__green" title={item?.grade}>
                            {item?.grade ? item?.grade : "--"}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 marginTop university__ranking">
                          <div className="text__black">{t("Founded")} :</div>
                          <div className="text__green">{item?.founded_year ? item?.founded_year : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-md-6 col-sm-12 marginTop university__ranking">
                          <div className="text__black">{t("No. of students")} : </div>
                          <div className="text__green">{item?.student_count ? item?.student_count : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                      </div>
                      <div className="university__rating">
                        <div className="text__black">{t("Rating")} : </div>
                        <div className="text__green">{item?.dsip_rating ? item?.dsip_rating : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {tutorList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div >
    </>
  );
};

export default SchoolDashboardLayout;
