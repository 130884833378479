import React, { useState } from 'react';
import { RegionDropdown } from 'react-country-region-selector';
import LatLanComponent from './LatLangLib';
import './CountryRegLib.scss';
import CountrySelector from './countrySelector';
import { GetSessionStorageData } from '../../Utilities/Helpers/Helper';
import { AUTHENTICATION } from '../../Constants/Constants';
import { useTranslation } from 'react-i18next';
import GoogleCountryDropdown from './googleCountry';

const LocationSelector: React.FC = () => {
  const { t } = useTranslation();
  var seesionLocationName: any = GetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME)
  var seesionRegionName: any = GetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME)
  const [country, setCountry] = useState<string>(seesionLocationName ?? AUTHENTICATION.SHARED.UNITED_ARAB);
  const [region, setRegion] = useState<string>(seesionRegionName ?? 'Dubai');
  const cityOptions: string[] = [
    'Dubai',
    'Abu Dhabi',
    'Sharjah',
    'Ajman',
    'Al Ain',
    'Fujairah',
    'Ras Al-Khaimah',
    'Umm Al-Quwain',

    // 'Kalba',
    // 'Madinat Zayed',
    // 'Khor Fakkan',
    // 'Dibba Al-Fujairah',
    // 'Ruwais',
    // 'Ghayathi',
    // 'Dhaid',
    // 'Jebel Ali',
    // 'Liwa Oasis',
    // 'Hatta',
    // 'Ar-Rams',
    // 'Dibba Al-Hisn',
    // 'Al Jazirah Al Hamra',
  ];

  const handleCountryChange = (val: string) => {
    setCountry(val);
  };

  const handleRegionChange = (val: string) => {
    setRegion(val);
  };

  return (
    <div className='location-dropdown-wrap'>
      {/* <CountrySelector country={country} onChange={handleCountryChange} /> */}
      <GoogleCountryDropdown country={country} onChange={handleCountryChange} />
      {/* {country === AUTHENTICATION.SHARED.UNITED_ARAB && (
        <RegionDropdown
          country={country}
          value={region}
          onChange={handleRegionChange}
          classes="my-country-dropdown-two"
        />
      )} */}
      {country === AUTHENTICATION.SHARED.UNITED_ARAB && (
        <select value={region} onChange={(e) => handleRegionChange(e.target.value)} className='my-country-dropdown-two'>
          {cityOptions.map((city) => (
            <option key={city} value={city}>
              {t(`${city}`)}
            </option>
          ))}
        </select>
      )}
      <LatLanComponent country={country} region={region} />
    </div>
  );
};

export default LocationSelector;
