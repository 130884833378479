import { useState, useEffect, useRef } from "react";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../../Assets/Activities/share_activity.svg";
import LikeIcon from "../../../Assets/Activities/like_activity.svg";
import experienceBlue from "../../../Assets/Tutor/experienceBlue.svg";
import Private_session from "../../../Assets/Tutor/twomemberBlue.svg";
import mode_of_teaching from "../../../Assets/Tutor/oneMemberBlue.svg";
import syllabus from "../../../Assets/Tutor/syllabusBlue.svg";
import pdfIcon from "../../../Assets/Tutor/pdfIcon.svg";
import navigation_arrows from "../../../Assets/home/nav_arrow.svg";
import back_img from "../../../Assets/Activities/back_img.svg";
import activitiesIcon from "../../../Assets/Activities/activitiesIcon.svg";
import StarIcon from '@mui/icons-material/Star';
import AliceCarousel from "react-alice-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import "./ActivitiesDetaileView.scss";
import { Container, Rating } from "@mui/material";
import React from "react";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES, getGoogleMapLink } from "../../../Constants/Constants";
import MapIconActivities from "../../../Assets/Activities/MapIconActivities.svg"
import { GetSessionStorageData, decrypPassData, encryptPassData, nullUndefinedEmptyCheck } from "../../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import toast from "react-hot-toast";
import ActivityFill from "../../../Assets/HeartFill/ActivityFill.svg";
import { useDispatch, useSelector } from "react-redux";
import { setLoginSignUpOpen, setChildLoginRestriction } from "../../../Redux/Reducer/Reducer";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import { Dialog, DialogContent, DialogTitle, stackClasses } from "@mui/material";
import ActivityRating from "../../../Assets/Activities/ActivityRating.svg";
import gendar_ac from "../../../Assets/Activities/gendar_ac.svg";
import calendar_ac from "../../../Assets/Activities/calendar_ac.svg";
import total_session from "../../../Assets/Activities/total_session.svg";
import call_icon from "../../../Assets/Activities/call_icon.svg";
import moment from "moment";
import CampBookSession from "../../../Components/CampBookSession/CampBookSession";
import NavigateBeforeSharpIcon from "@mui/icons-material/NavigateBeforeSharp";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import ClockIcon from "../../../Assets/Activities/Clock.svg";
import { useTranslation } from "react-i18next";

const ActivitiesDetaileView = () => {
    const route = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const searchParams = new URLSearchParams(location.search);
    const qureyId: any = searchParams.get('id');
    const qureyType: any = searchParams.get('type');
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });
    const [sericeCardsTemplate, setServiceCardsTemplate] = useState<any>([]);
    const [ActivitiesDetailes, setActivitiesDetailes] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [courseIndex, setCourseIndex] = useState<any>([]);
    const [courseSelectIndex, setCourseSelectIndex] = useState<any>([0]);
    const [AuthenticationOpen, setAuthenticationOpen] = useState(false);
    const [CampBookOpen, setCampBookOpen] = useState(false);
    const carouselRef = useRef<any>(null);

    let ACCOUNT_TYPE = GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE);

    /**Sync card index on slide */
    const syncActiveIndex = ({ item }: any) => {
        setActiveIndex(item);
    };

    /**Navigate BookSlot Page */
    const navigateBookSlot = () => {
        if (nullUndefinedEmptyCheck(courseIndex?.id)) {
            navigate(ROUTES.ACTIVITY_CALENDAR_VIEW, { state: { activity_sesstion_id: courseIndex?.id, camp: ActivitiesDetailes.type_camp, person: ActivitiesDetailes, is_reschedule: false } })
        }
    }

    /**Header search location*/
    const HeaderLoction: any = useSelector((state: any) => {
        return state.locationReducer.location;
    });

    /**Navigate Map Page */
    const navigateToMap = () => {
        navigate(ROUTES.ACTIVITY_MAPS_VIEW,
            {
                state: {
                    activities_list: [], search_text: '', redius: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
                    lat: parseFloat(ActivitiesDetailes?.latitude), lang: parseFloat(ActivitiesDetailes?.longitude)
                }
            });
    }

    /**Get One List*/
    const getOneList = () => {
        var id: any = "";
        if (decrypPassData(qureyType) == "camp") {
            id = "?camp_session_id=" + decrypPassData(qureyId) + '&is_service_type=' + decrypPassData(qureyType);
        } else {
            id = "?activity_id=" + decrypPassData(qureyId) + '&is_service_type=' + decrypPassData(qureyType);
        }
        axiosInstance
            .get(`${API_END_POINTS.ACTIVITIES_DEATAILES + id}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setActivitiesDetailes(Response?.data?.result?.data);
                    setCourseIndex(Response?.data?.result?.data?.trainer_activity?.length > 0 ? Response?.data?.result?.data?.trainer_activity[0] : [])
                    setServiceCardsTemplate(getServiceCards(Response?.data?.result?.data));

                }
            })
            .catch(() => { });
    };


    /**slide card Data binding */
    const getServiceCards = (details: any) => {
        var Array = details?.is_service_type == "camp" ? details?.camp_images : details?.cover_images;
        return Array?.map((item: any) => {
            return (
                <div className="service_card">
                    <div className="service_card_container">
                        <img
                            src={item ? item : card_bg} alt=""
                            onError={(e: any) => { e.target.src = card_bg; }}
                            className="slider-img"
                            onMouseMove={(event: any) => {
                                positionElement(event);
                            }}
                            onMouseLeave={handleMouseLeave}
                        />
                    </div>
                </div>

            )
        })
    }

    useEffect(() => {
        // setActivitiesDetailes(route?.state?.Activies_Detailes)
        getOneList();

    }, []);

    const handleChangeFavourites = (val: any, id: any) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            var payload: any

            if (ActivitiesDetailes?.is_service_type == "camp") {
                payload = {
                    service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT,
                    service_provider: id,
                    is_favourite: !val,
                    camp_session: ActivitiesDetailes?.id
                }
            } else {

                payload = {
                    service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT,
                    service_provider: id,
                    is_favourite: !val
                }
            }
            axiosInstance
                .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
                .then((Response: any) => {
                    if (Response?.data?.status) {
                        toast.success(Response?.data?.message, {
                            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                        });
                        getOneList();
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                });
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    }

    const handleAuthentication = () => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD) {
                dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
            } else {
                if (ActivitiesDetailes?.is_service_type == "camp") {
                    onOpenCampBookSession()
                } {
                    navigateBookSlot()
                }
            }
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    }

    const handleOpenMap = () => {
        let getDestination = { lat: ActivitiesDetailes.latitude, lng: ActivitiesDetailes.longitude }
        const mapsUrl = getGoogleMapLink(getDestination);
        if (mapsUrl) {
            window.open(mapsUrl, '_blank');
        }
    };

    /**Slide previous card */
    const slidePrev = () => {
        if (carouselRef?.current) {
            carouselRef?.current?.slidePrev();
        }
    };

    /**Slide next card */
    const slideNext = () => {
        if (carouselRef?.current) {
            carouselRef?.current?.slideNext();
        }
    };


    /**Handle pointer navigation mouse leave */
    const handleMouseLeave = () => {
        setMousePosition({ x: null, y: null });
    };
    const positionElement = (e: any) => {
        setMousePosition({ x: e.pageX, y: e.pageY });
    };
    const onOpenCampBookSession = () => {
        setCampBookOpen(true);
    };

    const onCloseCampBookSession = () => {
        setCampBookOpen(false);
    };

    const getSlotDays = (detail: any) => {
        let slotDescription;
        let weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

        switch (detail?.slot_mode) {
            case 'weekend':
                slotDescription = "Weekends (Saturday & Sunday)";
                break;
            case 'weekdays':
                slotDescription = "Weekdays (Monday to Friday)";
                break;
            case 'daily':
                slotDescription = "Daily (Monday to Sunday)";
                break;
            case 'custom':
                let selectedDays = detail?.days.sort();
                let slots = selectedDays.map((day: number) => weekdays[day])
                slotDescription = slots.join(', ');
                break;
            default:
                slotDescription = "";
                break;
        }
        return slotDescription;
    }
    const renderTimeRange = (startTime: string, endTime: string) => {
        const formattedStartTime = moment(startTime, 'HH:mm:ss').format('h:mm a');
        const formattedEndTime = moment(endTime, 'HH:mm:ss').format('h:mm a');
        const timeRange = `${formattedStartTime} to ${formattedEndTime}`;
        return timeRange;
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });

    /*Below class name based on English Arabic*/
    const Facility_needed_Activities = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "Facility_needed_Activities" : "Facility_needed_Activities_arb";
    const Activities_back_img = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "back-img" : "back-img_arb";
    const displaycontainer5 = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "display-container5" : "display-container5_arb";

    return (
        <React.Fragment>
            <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
                <div className="Activities__Detaile__view">

                    <div className="row">
                        <div className="activities_Img_wapper" onClick={() => navigate(-1)}>
                            <img src={back_img} alt="" className={Activities_back_img} />
                            <img
                                draggable="false"
                                src={activitiesIcon}
                                alt="activitiesImg"
                                className=""
                            />
                            <div className="activities__text">{t("Activities")}</div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-xl-4 col-lg-4 tutorlist-conatiner">
                            <div className="display-container">
                                {ActivitiesDetailes?.is_service_type == "camp" ? (
                                    <img src={ActivitiesDetailes?.camp_images?.length > 0 ? ActivitiesDetailes?.camp_images[0] : tutor_img} onError={(e: any) => { e.target.src = tutor_img; }} alt="" className="tutor-image" />
                                ) : (
                                    <img src={ActivitiesDetailes?.profile_image ? ActivitiesDetailes?.profile_image : tutor_img} onError={(e: any) => { e.target.src = tutor_img; }} alt="" className="tutor-image" />
                                )}
                                <label className="text14">{ActivitiesDetailes?.is_service_type == "camp" ? ActivitiesDetailes?.camp_session_title : ActivitiesDetailes?.profile_details?.firstname + " " + ActivitiesDetailes?.profile_details?.lastname}</label>
                                {ActivitiesDetailes?.is_service_type == "camp" && (<div className="text12">{t("By")} {ActivitiesDetailes?.camp_name}</div>)}
                                <div className="text10">{ActivitiesDetailes?.description}</div>
                                <div className="user__Detailes__Education_Activities">
                                    {nullUndefinedEmptyCheck(ActivitiesDetailes?.rating) ? (<div className="graytext">{ActivitiesDetailes?.rating}</div>) : ""}
                                    <Rating name="half-rating-read" className="StarIcon" value={parseInt(ActivitiesDetailes?.rating)} readOnly />
                                </div>
                                <div className="user__Detailes__Education_Activities">
                                    {ActivitiesDetailes?.is_service_type == "camp" ? (
                                        <>
                                            <label className="text_Price">{t("Price")}</label>
                                            <label className="text11">{ActivitiesDetailes?.total_class_fee} {t("AED")}</label>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="user__Detailes__Education_Activities mb-3">
                                    {ActivitiesDetailes?.is_service_type == "camp" ? (
                                        <>
                                            <label className="text11">{moment(new Date(ActivitiesDetailes?.start_date)).format("MMM DD")} - {moment(new Date(ActivitiesDetailes?.end_date)).format("MMM DD")}</label>
                                            <label className="text10 mb-0">{t("for")} {ActivitiesDetailes?.total_class} {t("classes")}</label>
                                        </>
                                    ) : (
                                        <label className="text11">{courseIndex?.expected_fee} {t("AED")}</label>
                                    )}
                                </div>

                                <div className="user__Detailes__Education_Activities mb-3">
                                    {ActivitiesDetailes?.is_service_type == "camp" ? (
                                        <>
                                            {/* <label className="text_Price">Slots</label> */}

                                            <img src={calendar_ac} alt="" width={20} /><label className="text10 mb-0"> {getSlotDays(ActivitiesDetailes)}   </label>
                                        </>
                                    ) : ""}
                                </div>

                                {ActivitiesDetailes?.is_service_type == "camp" && (
                                    <div className="user__Detailes__Education_Activities">
                                        <img src={ClockIcon} alt="" className="ClockIcon_detailes" />
                                        <span className="text10 mb-0">{renderTimeRange(ActivitiesDetailes?.start_time, ActivitiesDetailes?.end_time)}</span>
                                    </div>
                                )}

                                <div className="iconBtn__wapper">
                                    <button className="iconBtn" onClick={() => handleChangeFavourites(ActivitiesDetailes.is_favourite, ActivitiesDetailes?.user)}>
                                        <img src={ActivitiesDetailes?.is_favourite ? ActivityFill : LikeIcon} alt="" className="img-size5" />
                                        <span>{t("Add favorite")} </span>
                                    </button>
                                    <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(ActivitiesDetailes?.id) + '&type=' + encryptPassData(ActivitiesDetailes?.is_service_type)}>
                                        <button className="iconBtn">
                                            <img src={shareIcon} alt="" className="img-size5" />
                                            <span>{t("Share Profile")}</span>
                                        </button>
                                    </WebShareComponent>
                                </div>
                                {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                                    <button className="Book-Slots-btn" onClick={() => ActivitiesDetailes?.is_service_type == "camp" ? onOpenCampBookSession() : navigateBookSlot()}>
                                        {t("Book Slots")}
                                    </button>
                                ) : (
                                    <button className="Book-Slots-btn" onClick={handleAuthentication}>
                                        {t("Book Slots")}
                                    </button>
                                )}
                            </div>
                        </div>


                        <div className="col-xl-7 col-lg-7 tutorlist-conatiner detailes__scroll__container">
                            {sericeCardsTemplate.length ?
                                <div className={displaycontainer5}>
                                    <div className="arrow_navs">
                                        <NavigateBeforeSharpIcon
                                            onClick={slidePrev}
                                            className="left_arrow"
                                        />
                                        <NavigateNextSharpIcon
                                            onClick={slideNext}
                                            className="right_arrow"
                                        />
                                    </div>
                                    <AliceCarousel
                                        mouseTracking
                                        autoWidth
                                        disableButtonsControls
                                        items={sericeCardsTemplate}
                                        disableDotsControls
                                        activeIndex={activeIndex}
                                        onSlideChanged={syncActiveIndex}
                                        ref={carouselRef}

                                    />

                                    {/* {mousePosition.x !== null && mousePosition.y !== null && (
                                    <div
                                        className="custom_cursor"
                                        style={{ top: mousePosition.y, left: mousePosition.x }}
                                    >
                                        <img src={navigation_arrows} alt="Learn and Play" />
                                    </div>
                                )} */}
                                </div> : ""
                            }

                            <div className="contant_wrapper">
                                <label className="title_header ">{t("Location")}</label>
                                <div className="text_contant">{ActivitiesDetailes?.address}</div>
                            </div>

                            <div className="title_header">
                                {t("Activities")}
                            </div>
                            <div className="display-container2">
                                {ActivitiesDetailes?.is_service_type == "camp" ? (
                                    <div className='View__on__map'>
                                        <img src={ActivitiesDetailes?.category_icon} alt="" />
                                        <span>{ActivitiesDetailes?.category_name}</span>
                                    </div>

                                ) : (
                                    <>
                                        {ActivitiesDetailes?.trainer_activity?.map((sub: any, i: any) => {
                                            return (
                                                <>
                                                    <div className={courseSelectIndex == i ? "courses-Active" : "courses-div"} onClick={() => {
                                                        setCourseSelectIndex(i);
                                                        setCourseIndex(sub)
                                                    }}>
                                                        <img src={sub.category_icon} alt="" />
                                                        <div className="coursetxt">{sub.activites}</div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </>
                                )}

                                <div className='View__on__map' onClick={handleOpenMap}>
                                    <img src={MapIconActivities} alt="" />
                                    <span>{t("View on Map")}</span>
                                </div>
                            </div>

                            {ActivitiesDetailes?.is_service_type == "camp" && (
                                <div className="row w-100">
                                    <div className="col-md-6 bottom__contant__wapper">
                                        <div className="bottom__contant__left">
                                            <img src={total_session} alt="" />
                                            <div>{t("No. of session")}</div>
                                        </div>
                                        <div>{ActivitiesDetailes?.total_class} {t("classes")} </div>
                                    </div>
                                    <div className="col-md-6 bottom__contant__wapper">
                                        <div className="bottom__contant__left">
                                            <img src={gendar_ac} alt="" />
                                            <div>{t("Gender")}</div>
                                        </div>
                                        <div>{ActivitiesDetailes?.gender}</div>
                                    </div>
                                    <div className="col-md-6 bottom__contant__wapper">
                                        <div className="bottom__contant__left">
                                            <img src={calendar_ac} alt="" />
                                            <div>{t("Available Batch")}</div>
                                        </div>
                                        <div>{ActivitiesDetailes?.batch_size + "/" + ActivitiesDetailes?.total_batch_size}</div>
                                    </div>
                                    <div className="col-md-6 bottom__contant__wapper">
                                        <div className="bottom__contant__left">
                                            <img src={ActivityRating} alt="" />
                                            <div>{t("Rating")}</div>
                                        </div>
                                        <div>{ActivitiesDetailes?.rating}</div>
                                    </div>
                                </div>
                            )}

                            {ActivitiesDetailes?.is_service_type == "personal" ? (
                                <div className={Facility_needed_Activities}>
                                    <div className='title_header'>{t("Facility needed")}</div>
                                    <ul className="mb-0">
                                        {courseIndex?.additional_notes?.map((item: any) => {
                                            return (
                                                <>
                                                    {nullUndefinedEmptyCheck(item) && (<li>{item}</li>)}
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>
                            ) : ""}

                            {ActivitiesDetailes?.is_service_type == "camp" ? (
                                <div className="contant_wrapper">
                                    <label className="title_header">{t("Description")}</label>
                                    <div className="text_contant">{ActivitiesDetailes?.description}</div>
                                </div>
                            ) : (
                                <div className="contant_wrapper">
                                    <label className="title_header">{t("About Me")}</label>
                                    <div className="text_contant">{ActivitiesDetailes?.about_me}</div>
                                </div>
                            )}

                            {ActivitiesDetailes?.is_service_type == "camp" ? (
                                <div className={Facility_needed_Activities}>
                                    <div className='title_header'>{t("Amenities")}</div>

                                    <div className="Amenities__container">
                                        {ActivitiesDetailes?.amenities?.map((item: any) => {
                                            return (
                                                <>
                                                    <div className="Amenities__wapper">
                                                        {/* <div></div> */}
                                                        <img src={item?.amenity_icon} alt="" />
                                                        <span className="text">{item?.amenity_name}</span>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>

                                </div>
                            ) : ""}

                            {ActivitiesDetailes?.is_service_type == "camp" ? (
                                <div className="contant_wrapper">
                                    <div className='title_header'>{t("Contact US")}</div>
                                    <div className="contact_us_wapper" onClick={() => {
                                        navigator.clipboard.writeText(ActivitiesDetailes?.phone_number);
                                        toast.success("Copied!", {
                                            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                                        });
                                    }}>
                                        <img src={call_icon} alt="" />
                                        <div className='text_contant'>{ActivitiesDetailes?.phone_number}</div>
                                        <span className="text_call">{t("Call")}</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="contant_wrapper">
                                    <label className="title_header">{t("Equipments needed")}</label>
                                    <div className="text_contant">{courseIndex?.equipments}</div>
                                </div>
                            )}

                            {/* <div className="row">
                                <div className="col-md-6 bottom__contant__wapper">
                                    <div className="bottom__contant__left">
                                        <img src={experienceBlue} alt="" />
                                        <div>Experience</div>
                                    </div>
                                    <div>{courseIndex?.experience} Years</div>
                                </div>
                                <div className="col-md-6 bottom__contant__wapper">
                                    <div className="bottom__contant__left">
                                        <img src={Private_session} alt="" />
                                        <div>Private 1-1 sessions</div>
                                    </div>
                                    <div>{courseIndex?.course_mode}</div>
                                </div>
                                <div className="col-md-6 bottom__contant__wapper">
                                    <div className="bottom__contant__left">
                                        <img src={mode_of_teaching} alt="" />
                                        <div>Mode Of Teaching</div>
                                    </div>
                                    <div>{courseIndex?.course_mode}</div>
                                </div>
                                <div className="col-md-6 bottom__contant__wapper">
                                    <div className="bottom__contant__left">
                                        <img src={syllabus} alt="" />
                                        <div>Syllabus Covers</div>
                                    </div>
                                    <img src={pdfIcon} alt="" />
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>

                {CampBookOpen && (
                    <CampBookSession
                        TrialOpen={CampBookOpen}
                        onClose={onCloseCampBookSession}
                        campsData={ActivitiesDetailes}
                    />
                )}
                {AuthenticationOpen && (
                    <div className="overlay-ModelBackround">
                        <Dialog open={AuthenticationOpen}>
                            <DialogContent>
                                <div className='ChildSelect__container'>
                                    <div className='text__contant'>{t("Parent only allowed to Book slot")}</div>
                                    <div className="ChildSelectBtn__wapper__Activities">
                                        <button className='BackBtn' onClick={() => setAuthenticationOpen(false)}>{t("Back")}</button>
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                )}
            </Container>
        </React.Fragment>
    )
}
export default ActivitiesDetaileView;