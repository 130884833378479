import "./LegalAndSecurity.scss";
import { useTranslation } from "react-i18next";


const LegalAndSecurity = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="LegalAndSecurity__container">
                {/* <div className="Data__wapper">
                    <div className="title">{t("Lorem ipsum")}</div>
                    <div className="pragraph">{t("Lorem ipsum Des")}</div>
                </div> */}
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_1")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_1")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_2")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_2")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_3")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_3")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_4")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_4")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_5")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_5")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_6")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_6")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_7")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_7")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_8")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_8")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_9")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_9")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_10")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_10")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_11")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_11")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_12")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_12")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_13")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_13")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_14")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_14")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_15")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_15")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_16")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_16")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_17")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_17")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_18")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_18")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_19")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_19")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_20")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_20")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_21")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_21")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_22")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_22")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_23")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_23")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_24")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_24")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_25")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_25")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_26")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_26")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_27")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_27")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_28")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_28")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_29")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_29")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_30")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_30")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_31")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_31")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_32")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_32")}</div>
                </div>
                <div className="Data__wapper">
                    <div className="title">{t("terms_legal_security_title_33")}</div>
                    <div className="pragraph">{t("terms_legal_security_desc_33")}</div>
                </div>
            </div>
        </>
    )
}

export default LegalAndSecurity;