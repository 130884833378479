import "./TopTutors.scss";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import BadgeIcon from "../../Assets/Tutor/BadgeIcon.svg"
import StarIcon from '@mui/icons-material/Star';
import { ROUTES } from "../../Constants/Constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TopTutors = ({ tutorList, radius, lat, lang, FilterApplyData }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**Coursecategory Based filter the Data*/
  const handlePassCoursecategory = (value: any) => {
    navigate(ROUTES.TUTORS_LIST_VIEW, {
      state: {
        tutor_list: [value], search_text: '',
        redius: radius, lat: lat, lang: lang, filter: FilterApplyData,
      }
    });
  }

  const handlePassList = () => {
    navigate(ROUTES.TUTORS_LIST_VIEW, {
      state: {
        tutor_list: tutorList, search_text: '',
        redius: radius, lat: lat, lang: lang, filter: FilterApplyData,
      }
    });
  }

  return (
    <>
      <div className="top_tutors_card_wrapper">
        <div className="top_tutors_title" onClick={handlePassList} >{t("Top tutors near you")}</div>
        <div className="scroll__wapper">
          {tutorList?.slice(0, 5)?.map((val: any, i: any) => {
            return (
              <div className="top_tutors_card mt-2" key={i} onClick={() =>
                handlePassCoursecategory(val)}>
                <div className="d-flex">
                  <img src={val?.profile_image ? val?.profile_image : tutor_img}
                    onError={(e: any) => { e.target.src = tutor_img; }} alt="tutor_img" className="tutor_img" />
                  <div className="ms-2">
                    <div className="card__title">{val?.profile_details?.firstname}{" "} {val?.profile_details?.lastname}</div>
                    <div className="badge">
                      <img src={BadgeIcon} alt="BadgeIcon" className="BadgeIcon" />
                      <div>
                        {val?.tutor_subjects?.length > 0 ? val?.tutor_subjects[0]?.courses + " - "
                          + val?.tutor_subjects[0]?.grade_name : ""}
                      </div>
                    </div>
                  </div>
                </div>
                {val?.rating ? (
                  <div>
                    <StarIcon className="StarIcon" />
                    <h4 className="card__title">{val?.rating}</h4>
                  </div>
                ) : ""}

              </div>
            )
          })
          }
        </div>

        {tutorList.length === 0 ? (
          <>
            <div className="empty__data">
              <span className="text">{t("No Data Found")}</span>
            </div>
          </>
        ) : ""}

      </div>
    </>
  );
};

export default TopTutors;
