import { useState, useEffect } from "react";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import schoolIocn from "../../../Assets/School/schoolIocn.svg";
import navigation_arrows from "../../../Assets/home/nav_arrow.svg";
import back_img from "../../../Assets/School/backImg.svg";
import AliceCarousel from "react-alice-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import "./SchoolDetaileView.scss";
import shareIcon from "../../../Assets/School/shareIcon.svg";
import LikeIcon from "../../../Assets/School/schoolLikeIcon.svg";
import comments from "../../../Assets/School/commentIcon.svg";
import CalenderIcon from "../../../Assets/School/CalendarIcon.svg";
import viewOnMap from "../../../Assets/School/ViewOnMap.svg";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import curriculum from "../../../Assets/School/Curiculam.svg";
import DSIBrating from "../../../Assets/School/DSIBrating.svg";
import FoundYear from "../../../Assets/School/FoundYear.svg";
import Ranking from "../../../Assets/School/Ranking.svg";
import { Container } from "@mui/material";
import React from "react";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
  getGoogleMapLink,
} from "../../../Constants/Constants";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import toast from "react-hot-toast";
import SchoolFill from "../../../Assets/HeartFill/SchoolFill.svg";
import {
  GetSessionStorageData,
  decrypPassData,
  encryptPassData,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import { setChildLoginRestriction, setLoader, setLoginSignUpOpen } from "../../../Redux/Reducer/Reducer";
import emailIcon from "../../../Assets/School/emailIcon.svg";
import websiteIcon from "../../../Assets/School/websiteIcon.svg";
import callIcon from "../../../Assets/School/callIcon.svg";
import PersonIcon from "../../../Assets/School/PersonIcon.svg";
import location_icon from "../../../Assets/Header/header_location_icon.svg";
import down_arrow from "../../../Assets/School/school_down_arrow.svg";
import up_arrow from "../../../Assets/School/school_up_arrow.svg";
import filp_arrow from "../../../Assets/School/school_filp_arrow.png";
import pdf from "../../../Assets/School/school__pdf.svg";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import { NULL } from "sass";
import { store } from "../../../Redux/Store/Store";
import { useDispatch, useSelector } from "react-redux";
import ViewMoreLess from "../../../Components/ViewMoreCommon";
import { useTranslation } from "react-i18next";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SchoolDetaileView = () => {
  const { t } = useTranslation();
  const route = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qureyId: any = searchParams.get("id");
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [sericeCardsTemplate, setServiceCardsTemplate] = useState<any>([]);
  const [toturDetailes, setToturDetailes] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [courseIndex, setCourseIndex] = useState<any>(
    toturDetailes?.tutor_subjects?.length > 0
      ? toturDetailes?.tutor_subjects[0]
      : []
  );
  const [courseSelectIndex, setCourseSelectIndex] = useState<any>([0]);

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };
  const dispatch = useDispatch();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [Detailesvalue, setDetailesValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeDetailes = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setDetailesValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  /**Get One List*/
  const getOneList = () => {
    var id: any = "?id=" + decrypPassData(qureyId);
    axiosInstance
      .get(`${API_END_POINTS.SCHOOLLIST + id}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setToturDetailes(Response?.data?.result?.data);
        }
      })
      .catch(() => { });
  };

  const navigateBookSlot = () => {
    // navigate(ROUTES.TUTORS_CALENDAR_VIEW, { state: { tutor_sesstion_id: courseIndex?.id } })
  };

  const getServiceCards = () => {
    return toturDetailes?.cover_images?.map((item: any) => {
      return (
        <div className="service_card">
          <div className="service_card_container">
            <img
              src={item ? item : card_bg}
              alt="" onError={(e: any) => { e.target.src = card_bg; }}
              className="slider-img"
              onMouseMove={(event: any) => {
                positionElement(event);
              }}
              onMouseLeave={handleMouseLeave}
            />
          </div>
        </div>
      );
    });
  };
  useEffect(() => {
    setServiceCardsTemplate(getServiceCards());
    getOneList();
  }, []);

  /**Handle pointer navigation mouse leave */
  const handleMouseLeave = () => {
    setMousePosition({ x: null, y: null });
  };
  const positionElement = (e: any) => {
    setMousePosition({ x: e.pageX, y: e.pageY });
  };

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FOUR_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
            getOneList();
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  // Route to the Map view Page
  const handleRouteMap = () => {
    var Filter = {
      selectedCheckCourse: [],
      selectedCheckGender: [],
      selectedCheckGrade: [],
      startRating: [],
    };
    navigate(ROUTES.SCHOOL_MAPS_VIEW, {
      state: {
        tutor_list: toturDetailes,
        search_text: null,
        redius: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
        lat: parseFloat(toturDetailes?.latitude),
        lang: parseFloat(toturDetailes?.longitude),
        filter: Filter,
      },
    });
  };

  const handleOpenMap = (data: any) => {
    let getDestination = { lat: data.latitude, lng: data.longitude }
    const mapsUrl = getGoogleMapLink(getDestination);
    if (mapsUrl) {
      window.open(mapsUrl, '_blank');
    }
  };

  const DownloadPdf = (data: any) => {
    if (nullUndefinedEmptyCheck(data)) {
      store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      fetch(data, {
        method: "GET",
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          const urlParts = data?.split('/');
          const fileName = urlParts[urlParts?.length - 1];
          a.style.display = "none";
          a.href = url;
          a.download = fileName; // the filename you want
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setTimeout(() => {
            toast.success("Download Pdf Successfully", {
              position: "top-right",
            });
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
          }, 500);
        });
    } else {
      toast.error("No data Found", {
        position: "top-right",
      });
    }
  };

  const NavigateCalendar = (url: any, id: any) => {
    if (nullUndefinedEmptyCheck(id)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
        var payload = {
          school_id: id,
        };
        axiosInstance
          .post(`${API_END_POINTS.SCHOOL_CONTACT_NOTIFICATION}`, payload)
          .then((Response: any) => {
            if (Response?.data?.status) {
              // Open a new tab/window
              // window.open(url, "_blank");
              toast.success(Response?.data?.message, {
                position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
              });
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
          });
      } else {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      }
    } else {
      // Open a new tab/window
      window.open(url, "_blank");
    }
  };


  const handleChatRequest = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
        axiosInstance
          .get(`${API_END_POINTS.SCHOOL_EXPERT + '?school_id=' + toturDetailes?.id}`)
          .then((Response: any) => {
            if (Response?.data?.status) {
              var status = Response?.data?.result?.data[0];
              if (status?.room_id === 0) {
                let payload = {
                  school_id: toturDetailes?.id,
                }
                axiosInstance
                  .post(`${API_END_POINTS.SCHOOL_EXPERT}`, payload)
                  .then((Response: any) => {
                    if (Response?.data?.status) {
                      var status_new = Response?.data?.result?.data[0];
                      navigate(ROUTES.SCHOOL_CHAT_VIEW, {
                        state: { roomData: status_new, is_notification: false, },
                      });
                    }
                  })
                  .catch(() => { });
              } else {
                navigate(ROUTES.SCHOOL_CHAT_VIEW, {
                  state: { roomData: status, is_notification: false, },
                });
              }
            }
          })
          .catch(() => { });
      } else {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const TutorListConatinerLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "tutorlist-conatiner-english" : "tutorlist-conatiner-arab";
  const UnversityIconLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "unversity-icon-english" : "unversity-icon-arab";
  const school__tap__contant = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "school__tap__contant" : "school__tap__contant_arb";
  const school_back_img = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "back-img" : "back-img_arb";


  return (
    <React.Fragment>
      <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
        <div className="University__Detaile__view">
          <div className="tittle-div">
            <img
              src={back_img}
              alt=""
              className={school_back_img}
              onClick={() => navigate(-1)}
            />
            <img src={schoolIocn} alt="" className={`${UnversityIconLang} unversity-icon`} />
            <label className="text-tittle">{t("Schools")}</label>
          </div>
          <div className="row mt-2 w-100">
            <div className={`${TutorListConatinerLang} col-xl-4 col-lg-12 tutorlist-conatiner`}>
              <div className="display-container">
                <img
                  src={
                    toturDetailes?.images?.length > 0 ? toturDetailes?.images[0] : tutor_img
                  }
                  onError={(e: any) => { e.target.src = tutor_img }}
                  alt=""
                  className="tutor-image"
                />
                <label className="text14">{toturDetailes?.school_name}</label>
                <div className="text10">{toturDetailes?.about_me}</div>
                {/* <label className="text11__school">{courseIndex?.final_fee} AED</label> */}

                <div className="View__on__map__container_school">
                  {nullUndefinedEmptyCheck(
                    toturDetailes?.academic_calendar
                  ) && (
                      <div
                        className="wapperDiv"
                        onClick={() =>
                          NavigateCalendar(toturDetailes?.academic_calendar, null)
                        }
                      >
                        <img src={CalenderIcon} alt="" />
                        <span>{t("Academic Calendar")}</span>
                      </div>
                    )}
                  <div className="wapperDiv" onClick={() => handleOpenMap(toturDetailes)}>
                    <img src={viewOnMap} alt="" />
                    <span>{t("View on Map")}</span>
                  </div>
                </div>

                <div className="iconBtn__wapper__school">
                  <button
                    className="iconBtn"
                    onClick={() =>
                      handleChangeFavourites(
                        toturDetailes?.is_favourite,
                        toturDetailes?.user
                      )
                    }
                  >
                    <img
                      src={toturDetailes?.is_favourite ? SchoolFill : LikeIcon}
                      alt=""
                      className="img-size5"
                    />
                    <span>{t("Add favorite")}</span>
                  </button>

                  <WebShareComponent
                    params={
                      ROUTES.SCHOOL_DETAILE_VIEW +
                      "?id=" +
                      encryptPassData(toturDetailes?.id)
                    }
                  >
                    <button className="iconBtn">
                      <img src={shareIcon} alt="" className="img-size5" />
                      <span>{t("Share Profile")}</span>
                    </button>
                  </WebShareComponent>
                </div>
                {/* <button className="Book-Slots-btn__school" onClick={navigateBookSlot}>
                                    Book Slots
                                </button> */}
              </div>
            </div>
            <div className={`${TutorListConatinerLang} col-xl-7 col-lg-12 tutorlist-conatiner detailes__scroll__container_university`}>
              <div className="display-container5">
                <AliceCarousel
                  mouseTracking
                  autoWidth
                  disableButtonsControls
                  items={sericeCardsTemplate}
                  disableDotsControls
                  activeIndex={activeIndex}
                  onSlideChanged={syncActiveIndex}
                />
                {mousePosition.x !== null && mousePosition.y !== null && (
                  <div
                    className="custom_cursor"
                    style={{ top: mousePosition.y, left: mousePosition.x }}
                  >
                    <img src={navigation_arrows} alt="Learn and Play" />
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-md-6 bottom__contant__wapper__school">
                  <div className="bottom__contant__left">
                    <img src={DSIBrating} alt="" />
                    <div>{t("DSIB Rating")}</div>
                  </div>
                  <div className="bottom__contant__right">
                    {toturDetailes?.dsip_rating}
                  </div>
                </div>
                <div className="col-md-6 bottom__contant__wapper__school">
                  <div className="bottom__contant__left">
                    <img src={FoundYear} alt="" />
                    <div>{t("Founded")}</div>
                  </div>
                  <div className="bottom__contant__right">
                    {toturDetailes?.founded_year ? toturDetailes?.founded_year : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                  </div>
                </div>
                <div className="col-md-6 bottom__contant__wapper__school">
                  <div className="bottom__contant__left">
                    <img src={curriculum} alt="" />
                    <div>{t("Curriculum")}</div>
                  </div>
                  <div className="bottom__contant__right">
                    {toturDetailes?.curriculam}
                  </div>
                </div>
                <div className="col-md-6 bottom__contant__wapper__school">
                  <div className="bottom__contant__left">
                    <img src={Ranking} alt="" />
                    <div>{t("Ranking")}</div>
                  </div>
                  <div className="bottom__contant__right">
                    {toturDetailes?.ranking ? toturDetailes?.ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                  </div>
                </div>
              </div>

              {toturDetailes?.description && (
                <div className="School_description_contant">
                  <label className="title">{t("Description")}</label>
                  <ViewMoreLess
                    text={toturDetailes?.description}
                    maxLength={100}
                  />
                </div>
              )}
              {(toturDetailes?.application_process?.length > 0 || toturDetailes?.eligibility_criteria?.length > 0) && (
                <div className="school__Taps__container">
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      marginTop: "1rem",
                    }}
                  >
                    <Tabs
                      className="School__Taps"
                      value={value}
                      // centered
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="scrollable"
                    // scrollButtons
                    >
                      <Tab label={t("Application Process")} {...a11yProps(0)} />
                      <Tab label={t("Eligibility Criteria")} {...a11yProps(1)} />
                    </Tabs>
                  </Box>

                  <TabPanel value={value} index={0}>
                    <div className={school__tap__contant}>
                      <ul>
                        {toturDetailes?.application_process?.map((data: any) => {
                          return <li>{data}</li>;
                        })}
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className={school__tap__contant}>
                      <ul>
                        {toturDetailes?.eligibility_criteria?.map((data: any) => {
                          return <li>{data}</li>;
                        })}
                      </ul>
                    </div>
                  </TabPanel>
                </div>
              )}
              <div className="school__Taps__container">
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Tabs
                    className="School__Taps"
                    value={Detailesvalue}
                    // centered
                    onChange={handleChangeDetailes}
                    aria-label="basic tabs example"
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="scrollable"
                  // scrollButtons
                  >
                    <Tab label={t("School Details")} {...a11yProps(0)} />
                    <Tab label={t("Fees Structure")} {...a11yProps(1)} />
                    <Tab label={t("Fees Per Year")} {...a11yProps(2)} />
                  </Tabs>
                </Box>

                <TabPanel value={Detailesvalue} index={0}>
                  <div className="School__personal__detailes__wapper">
                    <div className="detailes__wapper">
                      <div className="left__side">
                        <img src={websiteIcon} alt="" />
                        <span>{t("Website")}</span>
                      </div>
                      <a
                        className="link"
                        href={toturDetailes?.web_link}
                        target="_blank"
                      >
                        {toturDetailes?.web_link}
                      </a>
                    </div>
                    <div className="detailes__wapper">
                      <div className="left__side">
                        <img src={emailIcon} alt="" />
                        <span>{t("Email")}</span>
                      </div>
                      <div className="number">{toturDetailes?.email}</div>
                    </div>
                    <div className="detailes__wapper">
                      <div className="left__side">
                        <img src={callIcon} alt="" />
                        <span>{t("Call")}</span>
                      </div>
                      <div className="number">
                        {toturDetailes?.phone_number}
                      </div>
                    </div>
                    {/* <div className="detailes__wapper">
                      <div className="left__side">
                        <img src={PersonIcon} alt="" />
                        <span>{t("Principal")}</span>
                      </div>
                      <div className="text">{toturDetailes?.principal}</div>
                    </div> */}
                    <div className="detailes__wapper">
                      <div className="left__side">
                        <img src={location_icon} alt="" />
                        <span>{t("Location")}</span>
                      </div>
                      <div className="text" title={toturDetailes?.address}>
                        {toturDetailes?.address}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={Detailesvalue} index={1}>
                  <div className="School__fess__structure__wapper">
                    {toturDetailes?.fees_structure?.map((data: any) => {
                      return (
                        <div className="detailes__wapper">
                          <div className="left__side">
                            {/* <img src={down_arrow} alt="" /> */}
                            <span>{Object?.keys(data)}</span>
                          </div>
                          <div className="fees__wapper">
                            <div className="text">{t("AED")}</div>
                            <div className="number">{Object?.values(data)}</div>
                          </div>
                        </div>
                      );
                    })}
                    {/* <div className="detailes__wapper">
                      <div className="left__side">
                        <img src={down_arrow} alt="" />
                        <span>Lowest Fee</span>
                      </div>
                      <div className="fees__wapper">
                        <div className="text">AED</div>
                        <div className="number">92,578</div>
                      </div>
                    </div>
                    <div className="detailes__wapper">
                      <div className="left__side">
                        <img src={up_arrow} alt="" />
                        <span>Highest Fee</span>
                      </div>
                      <div className="fees__wapper">
                        <div className="text">AED</div>
                        <div className="number">92,578</div>
                      </div>
                    </div>
                    <div className="detailes__wapper">
                      <div className="left__side">
                        <img src={filp_arrow} alt="" />
                        <span>Average Fee</span>
                      </div>
                      <div className="fees__wapper">
                        <div className="text">AED</div>
                        <div className="number">92,578</div>
                      </div>
                    </div> */}
                  </div>
                </TabPanel>
                <TabPanel value={Detailesvalue} index={2}>
                  <div className="School__fess__per_Year__wapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" className="light_text">{t("Grade\Year")}</th>
                          <th scope="col" className="light_text">{t("Annual Fee")}</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {toturDetailes?.fees_per_year?.map((data: any) => {
                          return (
                            <tr>
                              <td className="text">{data?.grade}</td>
                              <td><div className="fees__wapper">
                                <div className="text">{t("AED")}</div>
                                <div className="number">{data?.fee}</div>
                              </div></td>
                              {data?.detail && (
                                <td><img
                                  src={pdf}
                                  alt="pdf"
                                  onClick={() => DownloadPdf(data?.detail)}
                                /></td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
              </div>

              <div className="school_join__now_wapper">
                <button
                  className="join__now_Btn"
                  onClick={() => {
                    const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
                    if (nullUndefinedEmptyCheck(accessToken)) {
                      // NavigateCalendar(toturDetailes?.web_link, toturDetailes?.id);
                      handleChatRequest();
                    } else {
                      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
                    }
                  }}>
                  {t("Chat with our consultant")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default SchoolDetaileView;
