import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from "react";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, GLOBAL_CONSTANTS } from "../../Constants/Constants";
import { nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import chatReqestIcon from "../../Assets/University/chatReqestIcon.svg";
import "./UniversityChatRequest.scss";
import toast from 'react-hot-toast';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const UniversityChatRequest = ({ open, handleClose, data, message }: any) => {
    const [textMessage, setTextMessage] = useState("");


    const CreateReqApi = () => {
        var payload = {
            request_message: textMessage
        }
        axiosInstance.post(`${API_END_POINTS.UNIVERSITY_CHAT_CONSULTANT}`, payload)
            .then((response) => {
                if (response.data.status) {
                    toast.success(response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                    handleClose();
                }
            })
    }

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="UniversityChatRequest__dailog__box"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <div className="DialogTitle__wapper">
                        {data ? (<div className="title">Chat Request</div>)
                            : (<div></div>)}
                        <div className="closeBtn" onClick={handleClose}>Close</div>
                    </div>
                </DialogTitle>
                {data ? (
                    <DialogContent dividers>
                        <div className='DialogContent__wapper'>
                            <div className='discription'>{message}</div>
                            <textarea name="" className='textareaInput' placeholder='Write your message here...' value={textMessage} onChange={(e: any) => { setTextMessage(e.target.value) }}
                                onKeyPress={(e: any) => {
                                    if (e.code === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                                        if (textMessage.trim() === '') {
                                            e.preventDefault();
                                        } else {
                                            CreateReqApi();
                                            e.preventDefault();
                                        }
                                    }
                                }}></textarea>
                            {nullUndefinedEmptyCheck(textMessage) && textMessage.trim() !== '' ?
                                (<button className='SubmitBtn mt-4' onClick={CreateReqApi}>Submit</button>) :
                                (<button className='SubmitBtnDisable mt-4'>Submit</button>)}

                        </div>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <div className='DialogContent__wapper'>
                            <img src={chatReqestIcon} className='' alt="" />

                            <div className='discription'>{message}</div>

                            <button className='SubmitBtn mt-4' onClick={handleClose}>Okay</button>
                        </div>
                    </DialogContent>
                )}

            </BootstrapDialog>
        </>
    )
}

export default UniversityChatRequest;