import { Dialog, DialogContent } from "@mui/material";
import "./CampBookSession.scss";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import Radio from "@mui/material/Radio";
import { GetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import calendar_ac from "../../Assets/Activities/calendar_ac.svg";
import total_session from "../../Assets/Activities/total_session.svg";
import ClockIcon from "../../Assets/Activities/Clock.svg";
import Discount_symble_blue from "../../Assets/Global/Discount_activity.svg";
import Discount_Symbole from "../../Assets/Global/Discount_Symbole.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CampBookSession = ({ TrialOpen, onClose, campsData }: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [childProfileData, setChildProfileData] = useState<any>([]);
    const [DiscountList, setDiscountList] = useState<any>([]);
    const [DiscountSelect, setDiscountSelect] = useState<any>('');

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });

    useEffect(() => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            getProfileList();
            getDiscountList();
        }
        setSelectedValues([]);
    }, [TrialOpen]);

    /**Get Profile  List*/
    const getProfileList = () => {
        axiosInstance
            .get(`${API_END_POINTS.PROFILE_LIST}`)
            .then((profileListResponse: any) => {
                if (profileListResponse.data[AUTHENTICATION.SHARED.STATUS] === AUTHENTICATION.SHARED.TRUE) {
                    setChildProfileData(profileListResponse?.data?.result?.data?.child);
                }
            })
            .catch(() => { });
    };

    /**Get Discount List*/
    const getDiscountList = () => {
        axiosInstance
            .get(`${API_END_POINTS.CAMP_DISCOUNT_LIST + campsData?.user}`)
            .then((Response: any) => {
                if (Response.data[AUTHENTICATION.SHARED.STATUS] === AUTHENTICATION.SHARED.TRUE) {
                    setDiscountList(Response?.data?.result?.data);
                }
            })
            .catch(() => { });
    };

    /** handle Discount*/
    const handleDiscount = (option: any) => {
        DiscountSelect?.id == option?.id ? setDiscountSelect("") : setDiscountSelect(option);

    };

    const handleChange = (item: any) => {
        if (selectedValues.includes(item?.id)) {
            setSelectedValues(selectedValues.filter((data: any) => data?.id !== item?.id));
        } else {
            setSelectedValues([...selectedValues, item?.id]);
        }
    };

    const isChecked = (id: any) => {
        return selectedValues.includes(id);
    };

    const ConfirmSession = () => {
        navigate(ROUTES.ACTIVITY_CHECKOUT, {
            state: {
                selected_child_data: selectedValues,
                camp_book_session: true,
                campsData: campsData,
                campsDiscount: DiscountSelect,
            },
        });
        onClose();
    };

    return (
        <>
            <Dialog open={TrialOpen}>
                <DialogContent dir={
                    language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
                        ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
                        : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
                } >
                    <div className="CampBookSession_wrapper">
                        <div onClick={onClose} className="skip_button">
                            <CloseIcon className="close__icon" />
                        </div>
                        <div className="row mt-2">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="leftside__contant">
                                    <img src={campsData?.camp_images?.length > 0 ? campsData?.camp_images[0] : tutor_img} className="freeTrial_img" alt="" />
                                    <div className="age_text">{t("Age")} {campsData?.max_age} {t("And Below")}</div>
                                    <div className="contant_wapper">
                                        <div className="title">{campsData?.camp_session_title}</div>
                                        <div className="price_text">{campsData?.total_class_fee} {t("AED")}</div>
                                    </div>
                                    <div className="descrption">
                                        {campsData?.description}
                                    </div>
                                    <div className="date__filed_wapper">
                                        <div className="date__filed_wapper">
                                            <img src={calendar_ac} alt="" />
                                            <span>{moment(new Date(campsData?.start_date)).format("MMM DD")} - {moment(new Date(campsData?.end_date)).format("MMM DD")}</span>
                                        </div>
                                        <div className="date__filed_wapper">
                                            <img src={total_session} alt="" />
                                            <span>{campsData.total_class} {t("classes")}</span>
                                        </div>
                                    </div>
                                    <div className="date__filed_wapper">
                                        <div className="date__filed_wapper">
                                            <img src={ClockIcon} alt="" />
                                            <span>{campsData?.start_time} - {campsData?.end_time}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="rightSide__Card">
                                    <div className="lable_title">
                                        {t("Booking For")}
                                    </div>
                                    <div className="scrool__conatiner">
                                        {childProfileData?.map((item: any, i: any) => {
                                            return (
                                                <>
                                                    <div className="trial_card" key={i}>
                                                        <div className="card_profile_wapper">
                                                            <img
                                                                src={nullUndefinedEmptyCheck(item.profile_image) ? item.profile_image : tutor_img}
                                                                className="MemberBlueImg"
                                                                alt=""
                                                            />
                                                            <div className="name_text">
                                                                {item?.name}
                                                            </div>
                                                        </div>

                                                        <Radio
                                                            key={item.id}
                                                            id={`session${item.id}`}
                                                            onChange={() => handleChange(item)}
                                                            checked={isChecked(item.id)}
                                                            value={item.id}
                                                            name={`session${item.id}`}
                                                        />
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                                {DiscountSelect ? (
                                    <>
                                        {DiscountSelect?.buy_sessions <= selectedValues.length ? (
                                            <button className="ConfirmSessionBtn" onClick={ConfirmSession}>
                                                {t("Confirm Session")}
                                            </button>
                                        ) : (
                                            <button className="ConfirmSessionBtn Btn__disable">
                                                {t("Confirm Session")}
                                            </button>
                                        )}

                                    </>
                                ) : (
                                    <>
                                        {selectedValues.length > 0 ? (
                                            <button className="ConfirmSessionBtn" onClick={ConfirmSession}>
                                                {t("Confirm Session")}
                                            </button>
                                        ) : (
                                            <button className="ConfirmSessionBtn Btn__disable">
                                                {t("Confirm Session")}
                                            </button>
                                        )}
                                    </>

                                )}
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                                <div className='Calendar__discount__container__activity'>
                                    {DiscountList?.map((data: any,) => {
                                        return (
                                            <>
                                                <div className={DiscountSelect?.id == data?.id ? "discount__card__active" : "discount__card"} onClick={() => handleDiscount(data)}>
                                                    <img src={DiscountSelect?.id == data?.id ? Discount_Symbole : Discount_symble_blue} alt="" className='discount__Img' />
                                                    <div className='Black_text'>{t("Book for")} {data?.buy_sessions} {data?.buy_sessions > 1 ? t('Children') : t('Child')},</div>
                                                    <div className='blue__text'>{t("Get")} {parseInt(data?.discount)}%</div>
                                                    <div className='badge_blue'>{t("free")}</div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CampBookSession;
