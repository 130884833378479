import "./MySessions.scss";
import TutorImg from "../../Assets/Tutor/TutorImg.svg";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, GLOBAL_CONSTANTS } from "../../Constants/Constants";
import { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import activitiesIcon from "../../Assets/Activities/activitiesIcon.svg";
import { Container } from "@mui/material";
import React from "react";
import spacemen from "../../Assets/Global/spacemen.svg";
import { useSelector } from "react-redux";
import { nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import search from "../../Assets/Tutor/searchIconGray.svg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MySessions = () => {
    const { t } = useTranslation();
    const [sortValue, setSortValue] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL);
    const [SessionList, setSessionList] = useState([]);
    const [isToday, setIsToday] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const route = useLocation();

    const handleChange = (event: SelectChangeEvent) => {
        setSortValue(event.target.value);
        if (event.target.value === GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL) {
            getMySession("");
        } else {
            getMySession(event.target.value);
        }
    };

    /**Loader state*/
    const Loader: any = useSelector((state: any) => {
        return state.loaderReducer.loader;
    });

    useEffect(() => {
        getMySession(route.state?.is_sorted ? route.state?.is_sorted : "");
        setSortValue(route.state?.is_sorted ? route.state?.is_sorted : GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL);
    }, [isToday, searchValue])

    /**Get getMySession  List*/
    const getMySession = (value: any) => {
        axiosInstance
            .get(`${API_END_POINTS.MY_SESSIONS + value + GLOBAL_CONSTANTS.INITIAL_VALUES.AND_ISTODAY + isToday + GLOBAL_CONSTANTS.INITIAL_VALUES.AND_SEARCH + searchValue}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    if (route?.state?.booking_id) {
                        var SelectedArr = Response?.data?.result?.data?.filter(
                            (item: any) => item?.booking_id == route?.state?.booking_id
                        );
                        setSessionList(SelectedArr);
                    } else {
                        setSessionList(Response?.data?.result?.data)
                    }
                }
            })
            .catch(() => { });
    };

        /**get selected or current language from redux */
        const language: any = useSelector((state: any) => {
            return state.languageReducer.language;
          });
        /*Below class name based on English Arabic*/
         const SessionWapperLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "SessionWapperLang_Eng" : "SessionWapperLang_Arab";

    return (
        <>
            <div className="MySessions_container_parent">
                <Container component="main" maxWidth="md" sx={{ mb: 4, ml: 4 }}>
                    <div className="MySessions__container">
                        <div className="row">
                            {route?.state?.booking_id ? (
                                <></>
                            ) : (
                                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                    <div className="Header__top__wapper">
                                        <div className="left__contant">
                                            <div className={isToday ? "is_today__active" : "  is_today"} onClick={() => setIsToday(!isToday)}>{t("Today")}</div>
                                            {/* <div className="ParentTag">
                                                <input type="text" className="searchInput" onChange={(e) => setSearchValue(e.target.value)}
                                                    onKeyPress={(e: any) => {
                                                        if (e.code === 'Enter') {
                                                            if (searchValue.trim() === '') {
                                                                e.preventDefault();
                                                                getMySession(sortValue);
                                                            } else {
                                                                e.preventDefault();
                                                            }
                                                        }
                                                    }} placeholder="search" />
                                                <img src={search} alt="" className="searchIcon" onClick={() => getMySession(sortValue)} />
                                            </div> */}
                                        </div>

                                        <div className="sort__wapper__container">
                                            <div className="lable">{t("Sort")}</div>
                                            <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={sortValue}
                                                    label="Sort"
                                                    onChange={handleChange}
                                                    className="select_list_item"
                                                >
                                                    <MenuItem value={'all'}>{t("All")}</MenuItem>
                                                    <MenuItem value={'new'}>{t("New")}</MenuItem>
                                                    {/* <MenuItem value={'in_progress'}>In Progress</MenuItem> */}
                                                    <MenuItem value={'completed'}>{t("Completed")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="Detaile_slot_scroll_container">
                                {SessionList?.map((data: any, i) => {
                                    return (
                                        <>
                                            <div className="col-xl-6 col-lg-12 col-md-12 col-12 col-lg-d-flex" key={i}>
                                                <div className={`${SessionWapperLang} session__wapper`}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>
                                                                <div className="left__contant">
                                                                    {data?.type == "Tutor" ? (
                                                                        <div className='tutor__img__wapper'>
                                                                            <img src={TutorImg} alt="" />
                                                                        </div>
                                                                    ) : (
                                                                        <div className='activity__img__wapper'>
                                                                            <img src={activitiesIcon} alt="" />
                                                                        </div>
                                                                    )}
                                                                    <div className="d-flex flex-column text_head_wrap">
                                                                        <div title={data?.session_name} className="Black__text">{data?.session_name}</div>
                                                                        <div className="name__text">{t("by")} {data?.session_creator_name}</div>
                                                                    </div>
                                                                </div>
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography>
                                                                <div className="row__contant">
                                                                    <div className="left__light__text">{t("Total")}</div>
                                                                    <div className="right__black__text">{data?.total_slots_count} {t("Sessions")}</div>
                                                                </div>
                                                                <div className="row__contant">
                                                                    <div className="left__light__text">{t("Completed")} </div>
                                                                    <div className="right__black__text">{data?.completed_slots_count} {t("Sessions")}</div>
                                                                </div>
                                                                <div className="row__contant">
                                                                    <div className="left__light__text">{t("Balance")}</div>
                                                                    <div className="right__black__text">{data?.remaining_slots_count} {t("Sessions")}</div>
                                                                </div>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <div className="row__contant">
                                                        <div className="left__light__text">{t("No. of Sessions")}</div>
                                                        <div className="right__black__text">{data?.completed_slots_count}/{data?.total_slots_count}</div>
                                                    </div>
                                                    <div className="row__contant">
                                                        <div className="left__light__text">{t("Date")}</div>
                                                        <div className="right__black__text">{data?.date}</div>
                                                    </div>
                                                    <div className="row__contant">
                                                        <div className="left__light__text">{t("Booked Time")} </div>
                                                        <div className="d-flex flex-column">
                                                            {data?.time?.map((item: any) => {
                                                                return (
                                                                    <div className="right__black__text">{item}</div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="row__contant">
                                                        <div className="left__light__text">{t("Booked by")}</div>
                                                        <div className='d-flex align-items-center'>
                                                            <img src={nullUndefinedEmptyCheck(data?.parent_profile) ? data?.parent_profile : tutor_img} className='child_profile_img' alt="" />
                                                            <div className='createdBy ms-2'>{data?.parent_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row__contant">
                                                        <div className="left__light__text">{t("Booking ID")}</div>
                                                        <div className="right__black__text">{data?.booking_id}</div>
                                                    </div>
                                                    <div className="row__contant">
                                                        <button className={data?.status == "completed" ? "CompletedBtn" : "inprogressBtn"}>{data?.status}</button>
                                                        <div className='d-flex align-items-center'>
                                                            <img src={data?.profile ? data?.profile : tutor_img} className='child_profile_img' alt="" />
                                                            <div className='createdBy ms-2'>{data?.name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            {SessionList?.length === 0 && !Loader && (
                                <>
                                    <div className="No__data__contant">
                                        <img src={spacemen} alt="" />
                                        <div className="text">{t("No Data Found")}</div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default MySessions;