import { Autocomplete, Tab, Tabs, TextField, Typography } from "@mui/material";
import activitiesIcon from "../../../Assets/Activities/activitiesIcon.svg";
import search from "../../../Assets/Tutor/searchIconGray.svg";
import FilterIcon from "../../../Assets/Tutor/FilterIcon.svg";
import "./ActivitiesListView.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES, getGoogleMapLink } from "../../../Constants/Constants";
import { GetSessionStorageData, encryptPassData, isObjectEmptyValue, nullUndefinedEmptyCheck } from "../../../Utilities/Helpers/Helper";
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { store } from "../../../Redux/Store/Store";
import { setActivitiesFilter, setChildLoginRestriction, setLoader, setLoginSignUpOpen } from "../../../Redux/Reducer/Reducer";
import axios from "axios";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "../Dropdown";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import card_bg from "../../../Assets/Tutor/card_bg.png";
import { Rating, Tooltip } from '@mui/material';
import MapIconActivities from "../../../Assets/Activities/MapIconActivities.svg";
import AliceCarousel from "react-alice-carousel";
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullSharpIcon from '@mui/icons-material/OpenInFullSharp';
import NavigateNextSharpIcon from '@mui/icons-material/NavigateNextSharp';
import NavigateBeforeSharpIcon from '@mui/icons-material/NavigateBeforeSharp';
import shareIcon from "../../../Assets/Activities/share_activity.svg";
import LikeIcon from "../../../Assets/Activities/like_activity.svg";
import comments from "../../../Assets/Activities/commend_activity.svg";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import locationIcon from "../../../Assets/Tutor/location.svg";
import StarIcon from '@mui/icons-material/Star';
import toast from "react-hot-toast";
import ActivityFill from "../../../Assets/HeartFill/ActivityFill.svg";
import { useDispatch, useSelector } from "react-redux";
import spacemen from "../../../Assets/Global/spacemen.svg";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import { Dialog, DialogContent, } from "@mui/material";
import ExpandMap from "../../../Assets/Activities/ExpandMap.svg";
import ActivitiesMaps from "../../../Components/ActivitiesMaps/ActivitiesMaps";
import PropTypes from "prop-types";
import moment from "moment";
import ViewMoreLess from "../../../Components/ViewMoreCommon";
import ActivityRating from "../../../Assets/Activities/ActivityRating.svg";
import gendar_ac from "../../../Assets/Activities/gendar_ac.svg";
import calendar_ac from "../../../Assets/Activities/calendar_ac.svg";
import total_session from "../../../Assets/Activities/total_session.svg";
import CampBookSession from "../../../Components/CampBookSession/CampBookSession";
import call_icon from "../../../Assets/Activities/call_icon.svg";
import campIcon from "../../../Assets/Activities/camp_icon.svg";
import ptIcon from "../../../Assets/Activities/cap.svg";
import ClockIcon from "../../../Assets/Activities/Clock.svg";
import back_img from "../../../Assets/Activities/back_img.svg";
import AppFilter from "../../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";
// import { Container, Rating } from "@mui/material";

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


const ActivitiesListView = () => {
    const route = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const carouselRef = useRef<any>(null);
    const [searchValue, setSearchValue] = useState(route?.state?.search_text);
    const [triggerVal, setTriggerVal] = useState(false);
    const [activitiesList, setActivitiesList] = useState<any>([]);
    const [searchTextList, setSearchTextList] = useState([]);
    const [currentLat, setCurrentLat] = useState<any>('');
    const [currentLang, setCurrentLang] = useState<any>('');
    const [FilterData, setFilterData] = useState<any>([]);
    const [ActiviesDetailes, setActiviesDetailes] = useState<any>([]);
    const [ActiviesCamps, setActiviesCamps] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [courseIndex, setCourseIndex] = useState<any>([]);
    const [courseSelectIndex, setCourseSelectIndex] = useState<any>([0]);
    const [ResetBoolean, setResetBoolean] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentCampIndex, setCurrentCampIndex] = useState(0);
    const [AuthenticationOpen, setAuthenticationOpen] = useState(false);
    const [ViewOnMapActive, setViewOnMapActive] = useState(route?.state?.map_show);
    // const InitialValuve = activitiesList?.camp >= 0 ? 0 : 1;
    const [Tapvalue, setTapValue] = useState(0);
    const [CampBookOpen, setCampBookOpen] = useState(false);

    const getActivityFilter: any = useSelector((state: any) => {
        return state.filterReducer?.activityfilter
    });

    // Filter
    const init_filter = {
        activities: [],
        gender: [],
        price: [0, 0],
        age: [0, 0],
        experience: [0, 0],
        rating: 0,
        available: [],
        time: [0, 24],
        radius: 10
    }
    const [FilterPopOpen, setFilterPopOpen] = useState(false);
    const [initFilter, setInitFilter] = useState<any>(isObjectEmptyValue(getActivityFilter) ? getActivityFilter : init_filter);
    const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
    const [initSort, setSort] = useState<any>({
        price: '',
        rating: ''
    });

    const onOpenFilterPop = () => {
        setFilterPopOpen(true);
    };

    const onCloseFilterPop = () => {
        setFilterPopOpen(false);
    };

    // useEffect(() => {
    //     // Update Tapvalue if the data changes
    //     const newValue = activitiesList?.personal?.length >= 0 ? 1 : 0;
    //     console.log(newValue, "new")
    //     setTapValue(newValue);
    // }, [activitiesList]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTapValue(newValue);
    };

    /** Onchange Function to Prev Button*/
    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            activitiesList?.personal?.map((value: any, i: any) => {
                if (currentIndex - 1 === i) {
                    setActiviesDetailes(value);
                    setCourseIndex(value?.trainer_activity[0])
                    setCurrentLat(value?.latitude)
                    setCurrentLang(value?.longitude)
                }
            })
        }
    };

    /** Onchange Function to Next Button*/
    const handleNext = () => {
        if (currentIndex < activitiesList?.personal?.length - 1) {
            setCurrentIndex(currentIndex + 1);
            activitiesList?.personal?.map((value: any, i: any) => {
                if (currentIndex + 1 === i) {
                    setActiviesDetailes(value);
                    setCourseIndex(value?.trainer_activity[0])
                    setCurrentLat(value?.latitude)
                    setCurrentLang(value?.longitude)
                }
            })
        }
    };

    /** Onchange Function to Prev Button*/
    const handlePrevCamps = () => {
        if (currentCampIndex > 0) {
            setCurrentCampIndex(currentCampIndex - 1);
            activitiesList?.camp?.map((value: any, i: any) => {
                if (currentCampIndex - 1 === i) {
                    setActiviesCamps(value);
                    setCurrentLat(value?.latitude)
                    setCurrentLang(value?.longitude)
                }
            })
        }
    };

    /** Onchange Function to Next Button*/
    const handleNextCamps = () => {
        if (currentCampIndex < activitiesList?.camp?.length - 1) {
            setCurrentCampIndex(currentCampIndex + 1);
            activitiesList?.camp?.map((value: any, i: any) => {
                if (currentCampIndex + 1 === i) {
                    setActiviesCamps(value);
                    setCurrentLat(value?.latitude)
                    setCurrentLang(value?.longitude)
                }
            })
        }
    };

    /**Slide previous card */
    const slidePrev = () => {
        if (carouselRef?.current) {
            carouselRef?.current?.slidePrev();
        }
    };

    /**Slide next card */
    const slideNext = () => {
        if (carouselRef?.current) {
            carouselRef?.current?.slideNext();
        }
    };

    /**Sync card index on slide */
    const syncActiveIndex = ({ item }: any) => {
        setActiveIndex(item);
    };

    /**Sync card index on slide */
    const navigateTutorDetailedView = (Detailes: any) => {
        navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(Detailes?.id) + '&type=' + encryptPassData(Detailes?.is_service_type), { state: { Activies_Detailes: Detailes } });
    }

    // Route to the Map view Page
    const handleRouteMap = () => {
        navigate(ROUTES.ACTIVITY_MAPS_VIEW,
            {
                state: {
                    activities_list: activitiesList, search_text: searchValue,
                    redius: FilterApplyData.radius, lat: currentLat, lang: currentLang, filter: FilterApplyData
                }
            });
    }

    const handleOpenMap = () => {
        let getDestination = { lat: currentLat, lng: currentLang }
        const mapsUrl = getGoogleMapLink(getDestination);
        if (mapsUrl) {
            window.open(mapsUrl, '_blank');
        }
    };

    useEffect(() => {
        setCourseIndex(ActiviesDetailes?.trainer_activity?.length > 0 ? ActiviesDetailes?.trainer_activity[0] : [])
    }, [ActiviesDetailes])

    /**Navigate BookSlot Page */
    const navigateBookSlot = () => {
        navigate(ROUTES.ACTIVITY_CALENDAR_VIEW, { state: { activity_sesstion_id: courseIndex?.id, camp: ActiviesDetailes.type_camp, person: ActiviesDetailes, is_reschedule: false } })
    }

    const CoverImg = ActiviesDetailes?.cover_images?.map((item: any) => {

        return (
            <>
                <img src={item ? item : card_bg} alt="card_bg" className='card_bg_img'
                    onError={(e: any) => { e.target.src = card_bg; }} />
            </>
        )
    })

    const CoverImgCamps = ActiviesCamps?.camp_images?.map((item: any) => {

        return (
            <>
                <img src={item ? item : card_bg} alt="card_bg" className='card_bg_img'
                    onError={(e: any) => { e.target.src = card_bg; }} />
            </>
        )
    })

    const CoverImgEmpty = [0, 1, 2]?.map((item: any) => {
        return (
            <>
                <img src={card_bg} alt="card_bg" className='card_bg_img' />
            </>
        )
    })

    /**Header search location*/
    const HeaderLoction: any = useSelector((state: any) => {
        return state.locationReducer.location;
    });

    useEffect(() => {
        if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng)) {
            setCurrentLat(HeaderLoction?.lat);
            setCurrentLang(HeaderLoction?.lng)
            if (getActivityFilter && isObjectEmptyValue(getActivityFilter)) {
                ActivitiesListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, getActivityFilter);
            } else {
                ActivitiesListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
            }
        }
    }, [HeaderLoction, getActivityFilter])

    useEffect(() => {
        getFilterData();
    }, [])

    const timeFormat = (value: number) => {
        if (value === 24) {
            return `23:59`;
        }
        const hours = Math.floor(value);
        const minutes = (value % 1) * 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }


    /**Activities List Api Payload*/
    const payload = (latitude: any, longitude: any, value: string, filter: any) => {
        var payload;
        var priceValue: any = [];
        var experienceValue: any = [];
        var ageValue: any = [];
        var timeValue: any = [];
        if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
            priceValue = []
        } else {
            priceValue = {
                min: filter.price[0],
                max: filter.price[1],
            }
        }
        if (initFilter.experience[0] === filter.experience[0] && initFilter.experience[1] === filter.experience[1]) {
            experienceValue = []
        } else {
            experienceValue = {
                min: filter.experience[0],
                max: filter.experience[1],
            }
        }
        if (initFilter.age[0] === filter.age[0] && initFilter.age[1] === filter.age[1]) {
            ageValue = []
        } else {
            ageValue = {
                min: filter.age[0],
                max: filter.age[1],
            }
        }
        if (initFilter.time[0] === filter.time[0] && initFilter.time[1] === filter.time[1]) {
            timeValue = []
        } else {
            timeValue = {
                min: timeFormat(filter.time[0]),
                max: timeFormat(filter.time[1]),
            }
        }
        return payload = {
            latitude: latitude?.toString(),
            longitude: longitude?.toString(),
            radius: filter.radius,
            search: value ? value : "",
            price: priceValue,
            experience: experienceValue,
            age: ageValue,
            time: timeValue,
            activities: filter.activities,
            gender: filter.gender,
            available: filter.available[0] == 'All' ? [] : filter.available,
            rating: filter.rating,
        }
    }

    /**Activities get List Api*/
    const ActivitiesListApi = (latitude: any, longitude: any, value: string, filter: any) => {
        var pay = payload(latitude, longitude, value, filter);
        axiosInstance
            .post(`${API_END_POINTS.ACTIVITIESLIST}`, pay)
            .then((Response: any) => {

                if (nullUndefinedEmptyCheck(route?.state?.activities_list)) {
                    // setActivitiesList(route?.state?.activities_list);
                    // setActiviesDetailes(route?.state?.activities_list[0])
                    // setCourseIndex(route?.state?.activities_list[0]?.trainer_activity[0])
                    // setTriggerVal(route?.state?.activities_list[0] ? true : false);
                } else {
                    var arr1 = Response?.data?.result?.data?.camp;
                    var arr2 = Response?.data?.result?.data?.personal;
                    var children: any = [...arr1, ...arr2];
                    setActivitiesList(Response?.data?.result?.data);
                    setActiviesCamps(Response?.data?.result?.data?.camp[0]);
                    setActiviesDetailes(Response?.data?.result?.data?.personal[0]);
                    setCourseIndex(Response?.data?.result?.data?.personal[0]?.trainer_activity[0]);
                    if (arr1?.length > 0) {
                        setTapValue(0);
                    } else {
                        setTapValue(1);
                    }

                    setTriggerVal(children?.length > 0 ? true : false);
                }
            })
            .catch(() => { });
    };

    /**Get Filter Categories List*/
    const getFilterData = () => {
        axiosInstance
            .get(`${API_END_POINTS.ACTIVITIES_FILTERLIST}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    let data = Response?.data?.result?.data;
                    setFilterData(data);
                    if (data) {
                        let init = {
                            activities: [],
                            price: [data.price?.min, data.price?.max],
                            experience: [data.experience?.min, data.experience?.max],
                            gender: [],
                            rating: 0,
                            age: [data.age?.min, data.age?.max],
                            available: [],
                            time: [0, 24],
                            radius: 10,
                        }
                        setInitFilter(init);
                        if (getActivityFilter && isObjectEmptyValue(getActivityFilter)) {
                            setFilterApplyData(getActivityFilter);
                        } else {
                            setFilterApplyData(init);
                        }
                    }

                }
            })
            .catch(() => { });
    };

    /**Activities Search Keyword Api*/
    const searchActivitiesKeyword = (search: any) => {
        const accessToken = GetSessionStorageData(
            AUTHENTICATION.SHARED.ACCESS_TOKEN
        );
        var payload = {
            search_text: search.trim()
        };
        axios
            .post(`${process.env.REACT_APP_BASE_BACKEND_API_URL + API_END_POINTS.ACTIVITIES_KEY_WORD_SEARCH}`, payload, {
                // headers: {
                //     Authorization: "Bearer " + accessToken,
                // },
            })
            .then((Response: any) => {
                setSearchTextList(Response?.data.result?.data);
            })
            .catch(() => { });
    };

    /**Filter Apply api call Function*/
    const FilterApply = (filter: any) => {
        setFilterApplyData(filter)
        store.dispatch(setActivitiesFilter(filter));
        ActivitiesListApi(currentLat, currentLang, searchValue, filter);
    }

    /**Filter Reset Function*/
    const FilterReset = () => {
        store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));

        setTimeout(() => {
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
        }, 500)
    }

    /**AutoComplete Onchange Function*/
    const onInputChange = (value: any) => {
        store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
        if (nullUndefinedEmptyCheck(value)) {
            setSearchValue(value);
            searchActivitiesKeyword(value);
        } else {
            ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData)
        }
    }

    /**AutoComplete submit Function*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        //  prevent page refresh
        event.preventDefault();
        ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData)
    };

    /**Open the Model Diloge*/
    const handlePassSchoolData = (value: any) => {
        activitiesList?.personal?.map((item: any, i: any) => {
            if (value === i) {
                setActiviesDetailes(item);
                setCurrentIndex(value);
                setCourseIndex(item?.trainer_activity?.length > 0 ? item?.trainer_activity[0] : [])
            }
        })

        if (nullUndefinedEmptyCheck(value)) {
            setTriggerVal(true);
        } else {
            setTriggerVal(false);
        }
    };

    /**Open the Model Diloge*/
    const handlePassCampData = (value: any) => {
        activitiesList?.camp?.map((item: any, i: any) => {
            if (value === i) {
                setActiviesCamps(item);
                setCurrentCampIndex(value);
                setCourseIndex(item?.trainer_activity?.length > 0 ? item?.trainer_activity[0] : [])
            }
        })
        if (nullUndefinedEmptyCheck(value)) {
            setTriggerVal(true);
        } else {
            setTriggerVal(false);
        }
    };

    /** Favourites Api*/
    const handleChangeFavourites = (val: any, id: any, is_service_type: any, campId: any) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            var payload: any
            if (is_service_type == "camp") {
                payload = {
                    service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT,
                    service_provider: id,
                    is_favourite: !val,
                    camp_session: campId
                }
            } else {
                payload = {
                    service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT,
                    service_provider: id,
                    is_favourite: !val
                }
            }
            axiosInstance
                .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
                .then((Response: any) => {
                    if (Response?.data?.status) {
                        ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData);
                        toast.success(Response?.data?.message, {
                            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                        });
                    }
                })
                .catch((error) => { });
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    };

    /**Loader state*/
    const Loader: any = useSelector((state: any) => {
        return state.loaderReducer.loader;
    });

    const handleAuthentication = () => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD) {
                dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
            } else {
                if (ActiviesCamps?.is_service_type == "camp") {
                    onOpenCampBookSession()
                } {
                    navigateBookSlot()
                }
            }
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    }
    // Render the  Map view Page size
    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const onOpenCampBookSession = () => {
        setCampBookOpen(true);
    };

    const onCloseCampBookSession = () => {
        setCampBookOpen(false);
    };

    const getSlotDays = (detail: any) => {
        let slotDescription;
        let weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

        switch (detail?.slot_mode) {
            case 'weekend':
                slotDescription = "Weekends (Saturday & Sunday)";
                break;
            case 'weekdays':
                slotDescription = "Weekdays (Monday to Friday)";
                break;
            case 'daily':
                slotDescription = "Daily (Monday to Sunday)";
                break;
            case 'custom':
                let selectedDays = detail?.days.sort();
                let slots = selectedDays.map((day: number) => weekdays[day])
                slotDescription = slots.join(', ');
                break;
            default:
                slotDescription = "";
                break;
        }
        return slotDescription;
    }

    const renderTimeRange = (startTime: string, endTime: string) => {
        const formattedStartTime = moment(startTime, 'HH:mm:ss').format('h:mm a');
        const formattedEndTime = moment(endTime, 'HH:mm:ss').format('h:mm a');
        const timeRange = `${formattedStartTime} to ${formattedEndTime}`;
        return timeRange;
    }

    const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
        if (checkedPrice == 'high') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => b?.least_price - a?.least_price);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => b?.least_price - a?.least_price);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setActiviesCamps(campData[0]);
            setActiviesDetailes(personalData[0]);
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        } else if (checkedPrice == 'low') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => a?.least_price - b?.least_price);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => a?.least_price - b?.least_price);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setActiviesCamps(campData[0]);
            setActiviesDetailes(personalData[0]);
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        } else if (checkedRating == 'high') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setActiviesCamps(campData[0]);
            setActiviesDetailes(personalData[0]);
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        } else if (checkedRating == 'low') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setActiviesCamps(campData[0]);
            setActiviesDetailes(personalData[0]);
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        }
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });

    /*Below class name based on English Arabic*/
    const Search_icon_top = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_button_eng" : "search_button_arb";
    const Activities_back_img = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "back-img" : "back-img_arb";
    const RowOverideActivity = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "RowOverideActivity_Eng" : "RowOverideActivity_Arab";
    //
    const RowOverideActivityLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "RowOverideActivityLang_Eng" : "RowOverideActivityLang_Arab";

    return (
        <>
            <div className="ActivitiesList__view">

                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="List__topView__wapper">
                            <div className="activities_Img_wapper" onClick={() => navigate(-1)}>
                                <img src={back_img} alt="" className={Activities_back_img} />
                                <img
                                    draggable="false"
                                    src={activitiesIcon}
                                    alt="activitiesImg"
                                    className=""
                                />
                                <div className="activities__text">{t("Activities")}</div>
                            </div>

                            <div className={ViewOnMapActive ? "activities__listView__Active List__topView__onMap" : "List__topView__onMap"} onClick={() => setViewOnMapActive(!ViewOnMapActive)}>
                                <img src={ExpandMap} alt="ExpandMap" />
                                <span>{ViewOnMapActive ? t("View on List") : t("View on Map")} </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row row-overide-activity"> */}
                    <div className={`${RowOverideActivityLang} row row-overide-activity`}>
                        <div className="col-lg-3 col-md-12 input-style-resp">
                            <div className="search_field_wrapper">
                                <div className="search__autocomplete">
                                    <form onSubmit={handleSubmit}>
                                        <Autocomplete
                                            freeSolo
                                            popupIcon
                                            id="free-solo-2-demo"
                                            value={searchValue}
                                            disableClearable={false}
                                            clearIcon={false}
                                            onChange={(e, value) => {
                                                setSearchValue(value);
                                                ActivitiesListApi(currentLat, currentLang, value, FilterApplyData)
                                            }}
                                            options={searchTextList?.map((option: any) => option?.tag)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={t("Search for Activities")}
                                                    onChange={(inputChangeEvent: any) => {
                                                        onInputChange(inputChangeEvent.target.value.trim())
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                                                            if (searchValue === '') {
                                                                e.preventDefault();
                                                            } else {
                                                                ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData);
                                                                e.preventDefault();
                                                            }
                                                        }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'text',
                                                    }}
                                                />
                                            )}
                                        />
                                        <img
                                            draggable="false"
                                            src={search}
                                            alt="search"
                                            className={Search_icon_top}
                                            onClick={() => ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData)}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 activity-filter-respons">
                            <AppFilter
                                FilterPopOpen={FilterPopOpen}
                                onOpenPop={onOpenFilterPop}
                                onClosePop={onCloseFilterPop}
                                FilterData={FilterData}
                                FilterApply={FilterApply}
                                FilterApplyData={FilterApplyData}
                                initFilter={initFilter}
                                initSort={initSort}
                                handleApplySort={handleProceedSort}
                                type="activity"
                                color="#F0962C" />
                        </div>


                    </div>
                    <div className={`${RowOverideActivity} row row-overide-activiList`}>
                        <div className={`${RowOverideActivity} col-xl-6 col-lg-6 col-md-12 col-sm-12 row-overide-activiList-chield`}>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    marginTop: "2rem",
                                }}
                            >
                                <Tabs
                                    className="Activities__Taps"
                                    value={Tapvalue}
                                    centered
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                >
                                    <Tab label={t("Camps")}  {...a11yProps(0)} />
                                    <Tab label={t("Personal trainers")}  {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            {ViewOnMapActive ? (
                                <>
                                    <div className="Maps__view__listPage">
                                        <ActivitiesMaps zoom={12} radius={FilterApplyData.radius * 1000} lat={currentLat} lang={currentLang} containerStyle={containerStyle} disable={true} zoomControl={false} initSort={initSort}
                                            clickableIcons={false} draggable={false} ActivitiesList={activitiesList} getOneTutor={[]} filter={FilterApplyData} searchValue={searchValue} initFilter={initFilter} />
                                        <div className="viewMap" onClick={() => handleRouteMap()}>
                                            <img src={ExpandMap} alt="ExpandMap" />
                                            <span>{t("View on Map")}</span>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="activities__listView__container" >
                                    <TabPanel value={Tapvalue} index={0}>
                                        {activitiesList?.camp?.map((item: any, i: any) => {
                                            const startDate = new Date(item?.start_date);
                                            const today = new Date();
                                            today.setHours(0, 0, 0, 0);

                                            // if (!(startDate < today)) {
                                            return (
                                                <>
                                                    <div className={currentCampIndex === i ? "activities__list__wapper activities__listView__Active" : "activities__list__wapper"} key={i} onClick={() => handlePassCampData(i)} onDoubleClick={() => navigateTutorDetailedView(item)}>
                                                        <div className="activities__leftside">
                                                            <img src={campIcon} alt="" className="identity_icon" />
                                                            <img className="profile_img" src={item?.camp_images.length > 0 ? item?.camp_images[0] : tutor_img} alt="activitiesImg"
                                                                onError={(e: any) => { e.target.src = tutor_img; }} />
                                                        </div>
                                                        <div className="activities__rightside">
                                                            <div className="activity_top">
                                                                <div className="activities__name">
                                                                    <div className="age_group_wapper">
                                                                        <span className="name" title={item?.camp_session_title}>{item?.camp_session_title?.substring(0, 12) + "..."}</span>
                                                                        <span className="age_group_badge" >{t("under")} {item?.max_age}</span>
                                                                    </div>
                                                                    <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                                                                        <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(item?.id) + '&type=' + encryptPassData(item?.is_service_type)}>
                                                                            <img src={shareIcon} alt="" className="mb-1" />
                                                                        </WebShareComponent>
                                                                        {item?.is_favourite ? (<img src={ActivityFill} alt=""
                                                                            onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />) : (<img src={LikeIcon} alt=""
                                                                                onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="camp_dates">{t("By")} {item?.camp_name}</div>

                                                            <div className="activities__location">
                                                                <img src={locationIcon} alt="" />
                                                                <span className="location" title={item?.city}>{item?.city}</span>
                                                            </div>

                                                            <div className="camp_dates">{moment(new Date(item?.start_date)).format("MMM DD")} - {moment(new Date(item?.end_date)).format("MMM DD")}</div>
                                                            <div className="activities__rating">
                                                                <div className="total_class_wapper">
                                                                    <div className="text__contant">{t("AED")} {item?.total_class_fee}</div>
                                                                    <div className="total_class_text">{t("for")} {item?.total_class} {t("classes")}</div>
                                                                </div>
                                                                <div className="d-flex flex-row">
                                                                    {/* {item?.rating && (<StarIcon className="StarIcon" />)} */}
                                                                    <Rating name="half-rating-read" className="StarIcon" value={parseInt(item?.rating)} readOnly />
                                                                    <span className="points ms-2">{item?.rating}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div >
                                                </>
                                            )
                                            // }
                                        })}
                                        {activitiesList?.camp?.length === 0 && !Loader && (
                                            <>
                                                <div className="No__data__contant">
                                                    <img src={spacemen} alt="" />
                                                    <div className="text">{t("No Data Found")}</div>
                                                </div>
                                            </>
                                        )}
                                    </TabPanel>
                                    <TabPanel value={Tapvalue} index={1} >
                                        {activitiesList?.personal?.map((item: any, i: any) => {
                                            return (
                                                <>
                                                    <div className={currentIndex === i ? "activities__list__wapper activities__listView__Active" : "activities__list__wapper"} key={i} onClick={() => handlePassSchoolData(i)} onDoubleClick={() => navigateTutorDetailedView(item)}>
                                                        <div className="activities__leftside">
                                                            <img src={ptIcon} alt="" className="identity_icon" />
                                                            <img className="profile_img" src={nullUndefinedEmptyCheck(item?.profile_image) ? item?.profile_image : tutor_img} alt="activitiesImg"
                                                                onError={(e: any) => { e.target.src = tutor_img; }} />
                                                        </div>
                                                        <div className="activities__rightside">
                                                            <div className="activity_top">
                                                                <div className="activities__name">
                                                                    <span className="name">{item?.type_camp ? item?.campname.substring(0, 12) + "..." : (item?.profile_details?.firstname + item?.profile_details?.lastname).substring(0, 12) + "..."}</span>
                                                                    <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                                                                        <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(item?.id) + '&type=' + encryptPassData(item?.is_service_type)}>
                                                                            <img src={shareIcon} alt="" className="mb-1" />
                                                                        </WebShareComponent>
                                                                        {item?.is_favourite ? (<img src={ActivityFill} alt=""
                                                                            onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />) : (<img src={LikeIcon} alt=""
                                                                                onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />)}
                                                                    </div>
                                                                </div>

                                                                <div className="activities__location">
                                                                    <img src={locationIcon} alt="" />
                                                                    <span className="location" title={item?.city}>{item?.city}</span>
                                                                </div>

                                                                {item?.trainer_activity?.length > 0 ?
                                                                    <div className="Slots_activities">
                                                                        <div className="lable">{item?.trainer_activity?.length > 0 ? item?.trainer_activity[0]?.activites : ""}</div>
                                                                    </div>
                                                                    : ""}


                                                                {/* <div className="Slots_activities">
                                                            <div className='Slots'>Slots:</div>
                                                            {item?.is_slots_available && (<div className='SlotsYes'>Yes</div>)}
                                                        </div> */}

                                                            </div>
                                                            <div className="activities__rating">
                                                                <div className="text__contant">{t("AED")}  {item?.trainer_activity?.length > 0 ? item?.trainer_activity[0]?.expected_fee : ""}</div>
                                                                <div className="d-flex flex-row">
                                                                    {/* {item?.rating && (<StarIcon className="StarIcon" />)} */}
                                                                    <Rating name="half-rating-read" className="StarIcon" value={parseInt(item?.rating)} readOnly />
                                                                    <span className="points ms-2">{item?.rating}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div >
                                                </>
                                            )
                                        })}
                                        {activitiesList?.personal?.length === 0 && !Loader && (
                                            <>
                                                <div className="No__data__contant">
                                                    <img src={spacemen} alt="" />
                                                    <div className="text">{t("No Data Found")}</div>
                                                </div>
                                            </>
                                        )}
                                    </TabPanel>
                                </div>
                            )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">

                            {!triggerVal && !Loader ? (
                                <>
                                    <div className="No__data__contant">
                                        <img src={spacemen} alt="" />
                                        <div className="text">{t("No Data Found")}</div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {Tapvalue == 0 ?
                                        (
                                            <>
                                                {activitiesList?.camp?.length > 0 && (
                                                    <div className={triggerVal ? "activitiesListView__rightSide__Pop" : "animate-slideInRight Model__display__none"}>
                                                        <div className='top__contant__wapper_Activities'>
                                                            <div className='top__PageBtn__icons'>
                                                                {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                                                                    <NavigateBeforeSharpIcon className={currentCampIndex > 0 ? 'prev__icons' : 'icons__disable'} onClick={handlePrevCamps} />
                                                                ) : (
                                                                    <NavigateNextSharpIcon className={currentCampIndex > 0 ? 'prev__icons' : 'icons__disable'} onClick={handlePrevCamps} />
                                                                )}
                                                                <div className={currentCampIndex > 0 ? 'prev__text' : 'text__disable'} onClick={handlePrevCamps}>{t("Prev")}</div>
                                                                <div className={currentCampIndex < activitiesList?.camp?.length - 1 ? 'prev__text' : 'text__disable'} onClick={handleNextCamps}>{t("Next")}</div>
                                                                {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                                                                    <NavigateNextSharpIcon className={currentIndex < activitiesList?.camp?.length - 1 ? 'next__icons' : 'icons__disable'} onClick={handleNextCamps} />
                                                                ) : (
                                                                    <NavigateBeforeSharpIcon className={currentIndex < activitiesList?.camp?.length - 1 ? 'next__icons' : 'icons__disable'} onClick={handleNextCamps} />
                                                                )}
                                                            </div>
                                                            <div className='top__close__icons'>
                                                                <Tooltip title="Expanded">
                                                                    <OpenInFullSharpIcon className='expanded__icons' onClick={() => navigateTutorDetailedView(ActiviesCamps)} />
                                                                </Tooltip>
                                                                <Tooltip title="close">
                                                                    <CloseIcon className='close__icons' onClick={() => setTriggerVal(!triggerVal)} />
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className='top_cover_img_wrapper'>
                                                            <div className="arrow_navs">
                                                                <NavigateBeforeSharpIcon
                                                                    onClick={slidePrev}
                                                                    className="left_arrow"
                                                                />
                                                                <NavigateNextSharpIcon
                                                                    onClick={slideNext}
                                                                    className="right_arrow"
                                                                />
                                                            </div>
                                                            <AliceCarousel
                                                                // infinite
                                                                mouseTracking
                                                                autoWidth
                                                                disableButtonsControls
                                                                items={ActiviesCamps?.camp_images?.length > 0 ? CoverImgCamps : CoverImgEmpty}
                                                                disableDotsControls
                                                                activeIndex={activeIndex}
                                                                onSlideChanged={syncActiveIndex}
                                                                ref={carouselRef}
                                                            />
                                                            <img src={ActiviesCamps?.camp_images?.length > 0 ? ActiviesCamps?.camp_images[0] : tutor_img} alt="tutor_img" className="profileImg"
                                                                onError={(e: any) => { e.target.src = tutor_img; }} />
                                                        </div>

                                                        <div className="card__container">
                                                            <div className="w-100">
                                                                <div className="user__Detailes">
                                                                    <div className="title_header">{ActiviesCamps?.camp_session_title}</div>
                                                                    <div className="user__icon__wrapper">
                                                                        {/* <img src={comments} alt="comments" /> */}
                                                                        <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(ActiviesCamps?.id) + '&type=' + encryptPassData(ActiviesDetailes?.is_service_type)}>
                                                                            <img src={shareIcon} alt="shareIcon" className="mb-1" />
                                                                        </WebShareComponent>
                                                                        <img src={ActiviesCamps?.is_favourite ? ActivityFill : LikeIcon} alt=""
                                                                            onClick={() => handleChangeFavourites(ActiviesCamps.is_favourite, ActiviesCamps?.user, ActiviesCamps?.is_service_type, ActiviesCamps?.id)} />
                                                                    </div>
                                                                </div>

                                                                <div className="user__Detailes__Grade__Activities">
                                                                    <div className='Grade__Activities'>
                                                                        <img src={ActiviesCamps?.category_icon} alt="" />
                                                                        <span>{ActiviesCamps?.category_name}</span>
                                                                    </div>

                                                                    <div className='View__on__map' onClick={handleOpenMap} >
                                                                        <img src={MapIconActivities} alt="" />
                                                                        <span>{t("View on Map")}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="user__Detailes__fess__Activities mt-2">
                                                                    <div className="contant_wapper">
                                                                        <img src={calendar_ac} alt="" />
                                                                        <div className="claendar_text">{moment(new Date(ActiviesCamps?.start_date)).format("MMM DD")} - {moment(new Date(ActiviesCamps?.end_date)).format("MMM DD")}</div>
                                                                    </div>
                                                                    <div className="contant_wapper">
                                                                        <div className='claendar_text'>{t("Price")}</div>
                                                                        <div className="title">{t("AED")} {ActiviesCamps?.total_class_fee}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="user__Detailes__fess__Activities">
                                                                    <div className="contant_wapper">
                                                                        <img src={ClockIcon} alt="" />
                                                                        <div className="claendar_text">{renderTimeRange(ActiviesCamps?.start_time, ActiviesCamps?.end_time)}</div>
                                                                    </div>
                                                                    <div className="claendar_text">{getSlotDays(ActiviesCamps)}</div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-md-6 bottom__contant__wapper">
                                                                        <div className="bottom__contant__left">
                                                                            <img src={total_session} alt="" />
                                                                            <div className="text_contant_bold">{t("No. of session")}</div>
                                                                        </div>
                                                                        <div>{ActiviesCamps?.total_class} {t("classes")}</div>
                                                                    </div>
                                                                    <div className="col-md-6 bottom__contant__wapper">
                                                                        <div className="bottom__contant__left">
                                                                            <img src={gendar_ac} alt="" />
                                                                            <div className="text_contant_bold">{t("Gendar")}</div>
                                                                        </div>
                                                                        <div>{ActiviesCamps?.gender}</div>
                                                                    </div>
                                                                    <div className="col-md-6 bottom__contant__wapper">
                                                                        <div className="bottom__contant__left">
                                                                            <img src={calendar_ac} alt="" />
                                                                            <div className="text_contant_bold">{t("Available Batch")}</div>
                                                                        </div>
                                                                        <div>{ActiviesCamps?.batch_size + "/" + ActiviesCamps?.total_batch_size}</div>
                                                                    </div>
                                                                    <div className="col-md-6 bottom__contant__wapper">
                                                                        <div className="bottom__contant__left">
                                                                            <img src={ActivityRating} alt="" />
                                                                            <div className="text_contant_bold">{t("Rating")}</div>
                                                                        </div>
                                                                        <div>{ActiviesCamps?.rating}</div>
                                                                    </div>
                                                                </div>


                                                                {/* <div className="user__Detailes__Education_Activities">
                                                        <div className="graytext">Rating  &nbsp;{ActiviesCamps?.rating ? ActiviesCamps?.rating : 0}/5</div>
                                                        <Rating name="half-rating-read" defaultValue={parseInt(ActiviesCamps?.rating)} readOnly />
                                                    </div> */}

                                                                <div className="Facility_needed_Activities mt-3">
                                                                    <label className="title_header">{t("Description")}</label>
                                                                    <ViewMoreLess
                                                                        text={ActiviesCamps?.description}
                                                                        maxLength={100}
                                                                    />
                                                                </div>

                                                                <div className='Facility_needed_Activities'>
                                                                    <div className='title_header'>{t("Amenities")}</div>

                                                                    <div className="Amenities__container">
                                                                        {ActiviesCamps?.amenities?.map((item: any) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="Amenities__wapper">
                                                                                        <div></div>
                                                                                        <img src={item?.amenity_icon} alt="" />
                                                                                        <span className="text">{item?.amenity_name}</span>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>

                                                                </div>

                                                                <div className='user__Detailes__Activities'>
                                                                    <div className='title_header'>{t("Contact US")}</div>
                                                                    <div className="contact_us_wapper" onClick={() => {
                                                                        navigator.clipboard.writeText(ActiviesCamps?.phone_number);
                                                                        toast.success("Copied!", {
                                                                            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                                                                        });
                                                                    }}>
                                                                        <img src={call_icon} alt="" />
                                                                        <div className='number_text'>{ActiviesCamps?.phone_number}</div>
                                                                        <span className="text_call">{t("Call")}</span>
                                                                    </div>
                                                                </div>


                                                                <div className='user__Detailes__Activities__bookBtn__container'>
                                                                    {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) == GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT ? (
                                                                        <button className='bookBtn' onClick={onOpenCampBookSession}>{t("Book Slots")}</button>
                                                                    ) : (
                                                                        <button className="bookBtn" onClick={handleAuthentication}>
                                                                            {t("Book Slots")}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <> {activitiesList?.personal?.length > 0 && (
                                                <div className={triggerVal ? "activitiesListView__rightSide__Pop" : "animate-slideInRight Model__display__none"}>
                                                    <div className='top__contant__wapper_Activities'>
                                                        <div className='top__PageBtn__icons'>
                                                            {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                                                                <NavigateBeforeSharpIcon className={currentIndex > 0 ? 'prev__icons' : 'icons__disable'} onClick={handlePrev} />
                                                            ) : (
                                                                <NavigateNextSharpIcon className={currentIndex > 0 ? 'prev__icons' : 'icons__disable'} onClick={handlePrev} />
                                                            )}
                                                            <div className={currentIndex > 0 ? 'prev__text' : 'text__disable'} onClick={handlePrev}>{t("Prev")}</div>
                                                            <div className={currentIndex < activitiesList?.personal?.length - 1 ? 'prev__text' : 'text__disable'} onClick={handleNext}>{t("Next")}</div>
                                                            {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                                                                <NavigateNextSharpIcon className={currentIndex < activitiesList?.personal?.length - 1 ? 'next__icons' : 'icons__disable'} onClick={handleNext} />
                                                            ) : (
                                                                <NavigateBeforeSharpIcon className={currentIndex < activitiesList?.personal?.length - 1 ? 'next__icons' : 'icons__disable'} onClick={handleNext} />
                                                            )}
                                                        </div>
                                                        <div className='top__close__icons'>
                                                            <Tooltip title="Expanded">
                                                                <OpenInFullSharpIcon className='expanded__icons' onClick={() => navigateTutorDetailedView(ActiviesDetailes)} />
                                                            </Tooltip>
                                                            <Tooltip title="close">
                                                                <CloseIcon className='close__icons' onClick={() => setTriggerVal(!triggerVal)} />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div className='top_cover_img_wrapper'>
                                                        <div className="arrow_navs">
                                                            <NavigateBeforeSharpIcon
                                                                onClick={slidePrev}
                                                                className="left_arrow"
                                                            />
                                                            <NavigateNextSharpIcon
                                                                onClick={slideNext}
                                                                className="right_arrow"
                                                            />
                                                        </div>
                                                        <AliceCarousel
                                                            // infinite
                                                            mouseTracking
                                                            autoWidth
                                                            disableButtonsControls
                                                            items={ActiviesDetailes?.cover_images?.length > 0 ? CoverImg : CoverImgEmpty}
                                                            disableDotsControls
                                                            activeIndex={activeIndex}
                                                            onSlideChanged={syncActiveIndex}
                                                            ref={carouselRef}
                                                        />
                                                        <img src={ActiviesDetailes?.profile_image ? ActiviesDetailes?.profile_image : tutor_img} alt="tutor_img" className="profileImg"
                                                            onError={(e: any) => { e.target.src = tutor_img; }} />
                                                    </div>

                                                    <div className="card__container">
                                                        <div className="w-100">
                                                            <div className="user__Detailes">
                                                                <div className="title_header">{ActiviesDetailes?.type_camp ? ActiviesDetailes?.campname : ActiviesDetailes?.profile_details?.firstname ? `${ActiviesDetailes?.profile_details?.firstname} ${ActiviesDetailes?.profile_details?.lastname}` : ""}</div>
                                                                <div className="user__icon__wrapper">
                                                                    {/* <img src={comments} alt="comments" /> */}
                                                                    <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(ActiviesDetailes?.id) + '&type=' + encryptPassData(ActiviesDetailes?.is_service_type)}>
                                                                        <img src={shareIcon} alt="shareIcon" className="mb-1" />
                                                                    </WebShareComponent>
                                                                    <img src={ActiviesDetailes?.is_favourite ? ActivityFill : LikeIcon} alt=""
                                                                        onClick={() => handleChangeFavourites(ActiviesDetailes.is_favourite, ActiviesDetailes?.user, ActiviesDetailes?.is_service_type, ActiviesDetailes?.id)} />
                                                                </div>
                                                            </div>

                                                            <div className="user__Detailes__Grade__Activities">
                                                                <div className='Grade__Activities'>
                                                                    <img src={courseIndex?.category_icon} alt="" />
                                                                    <span>{courseIndex?.activites}</span>
                                                                </div>

                                                                <div className='View__on__map' onClick={handleOpenMap} >
                                                                    <img src={MapIconActivities} alt="" />
                                                                    <span>{t("View on Map")}</span>
                                                                </div>
                                                            </div>

                                                            <div className="user__Detailes__fess__Activities">
                                                                <div className='Avg__Fees'>{t("Fees Per Session")}</div>
                                                                <h1 className="title">{t("AED")} {courseIndex?.expected_fee}</h1>
                                                            </div>
                                                            <div className="user__Detailes__Education_Activities">
                                                                <div className="graytext">{t("Rating")} &nbsp;{ActiviesDetailes?.rating ? ActiviesDetailes?.rating : 0}/5</div>
                                                                <Rating name="half-rating-read" defaultValue={parseInt(ActiviesDetailes?.rating)} readOnly />
                                                            </div>

                                                            <div className='Facility_needed_Activities'>
                                                                <div className='title_header'>{t("Facility needed")}</div>

                                                                <ul className="">
                                                                    {courseIndex?.additional_notes?.map((item: any) => {
                                                                        return (
                                                                            <>
                                                                                {nullUndefinedEmptyCheck(item) && (<li>{item}</li>)}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </ul>

                                                            </div>
                                                            <div className='user__Detailes__Activities mt-2'>
                                                                <div className='title_header'>{t("Activities")}</div>
                                                                <div className='badge_wrapeer'>
                                                                    {ActiviesDetailes?.trainer_activity?.map((sub: any, i: any) => {
                                                                        return (
                                                                            <div className={courseSelectIndex == i ? "badge__container-Active" : "badge__container"} onClick={() => {
                                                                                setCourseSelectIndex(i);
                                                                                setCourseIndex(sub)
                                                                            }}>
                                                                                <img src={sub?.category_icon} alt="" />
                                                                                <span>{sub?.activites}</span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div className='user__Detailes__Activities'>
                                                                <div className='title_header'>{t("About Me")}</div>
                                                                <div className='description__contant'>
                                                                    {ActiviesDetailes?.about_me ? ActiviesDetailes?.about_me : ActiviesDetailes?.about_camp}
                                                                </div>
                                                            </div>
                                                            <div className='user__Detailes__Activities'>
                                                                <div className='title_header'>{t("Equipments needed")}</div>
                                                                <div className='description__contant'>
                                                                    {courseIndex?.equipments}
                                                                </div>
                                                            </div>

                                                            <div className='user__Detailes__Activities__bookBtn__container'>
                                                                {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) == GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT ? (
                                                                    <button className='bookBtn' onClick={navigateBookSlot} >{t("Book Slots")}</button>
                                                                ) : (
                                                                    <button className="bookBtn" onClick={handleAuthentication}>
                                                                        {t("Book Slots")}
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            </>
                                        )}

                                </>
                            )}
                        </div>
                    </div>

                </div>
            </div>
            {CampBookOpen && (
                <CampBookSession
                    TrialOpen={CampBookOpen}
                    onClose={onCloseCampBookSession}
                    campsData={ActiviesCamps}
                />
            )}
            {AuthenticationOpen && (
                <div className="overlay-ModelBackround">
                    <Dialog open={AuthenticationOpen}>
                        <DialogContent>
                            <div className='ChildSelect__container'>
                                <div className='text__contant'>{t("Parent only allowed to Book slot")}</div>
                                <div className="ChildSelectBtn__wapper__Activities">
                                    <button className='BackBtn' onClick={() => setAuthenticationOpen(false)}>{t("Back")}</button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </>
    )
}

export default ActivitiesListView;