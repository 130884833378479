import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetSessionStorageData } from "../../Utilities/Helpers/Helper";
import { AUTHENTICATION } from "../../Constants/Constants";

/**Modals */
/**Language modal */
export interface LanguageState {
  language: string;
  jsonDataEnglish: any;
  jsonDataArabic: any;
}

/**Loader modal */
export interface LoaderState {
  loader: boolean;
}

/**Login sign up model */
export interface LoginSignUpDialog {
  open: boolean;
}

/**Login sign up model */
export interface guestSignupDialog {
  open: boolean;
}

export interface LocationProps {
  location: any;
}

export interface S3Props {
  datakey: any;
}

export interface LocationNameProps {
  locationName: any;
}

export interface LocationRadiusProps {
  locationRadius: any;
}

export interface NotificationRefesh {
  notificationRefresh: any;
}

export interface FilterState {
  tutorfilter: any;
  universityfilter: any;
  schoolfilter: any;
  activityfilter: any;
}

/**Language state */
const value = GetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH);
/**Initial state */
const initialState: LanguageState = {
  language: value ?? "English",
  jsonDataEnglish: {},
  jsonDataArabic: {},
};

/**location state */
const initialStateLocation: LocationProps = {
  location: {},
};

/**S3 data key state */
const initialStateS3Key: S3Props = {
  datakey: {},
};

/**location Name state */
const initialLocationName: LocationNameProps = {
  locationName: '',
};

/**location Radius state */
const initialLocationRadius: LocationRadiusProps = {
  locationRadius: '',
};

/**Loader State */
const initialLoaderState: LoaderState = {
  loader: false,
};

/**Login signUp dialog */
const loginSignUpDialogState: LoginSignUpDialog = {
  open: false,
};

/**Login signUp dialog */
const guestSignupDialogState: guestSignupDialog = {
  open: false,
};

/**Notification Refresh state */
const initialNotification: NotificationRefesh = {
  notificationRefresh: '',
};

// const tutor_filter: any = GetSessionStorageData("tutor_filter");
// const school_filter: any = GetSessionStorageData("school_filter");

// tutor filter 
const initialFilter: FilterState = {
  tutorfilter: {},
  universityfilter: {},
  schoolfilter: {},
  activityfilter: {}

}

/**Update language */
export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state: any, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    getLanguageJsonEnglish: (state: any, action: PayloadAction<string>) => {
      state.jsonDataEnglish = action.payload;
    },
    getLanguageJsonArabic: (state: any, action: PayloadAction<string>) => {
      state.jsonDataArabic = action.payload;
    },
  },
});

/**Update location */
export const locationSlice = createSlice({
  name: "location",
  initialState: initialStateLocation,
  reducers: {
    setLocation: (state: any, action: any) => {
      state.location = action.payload;
    },
  },
});

/**Update s3 data key */
export const s3DataKeySlice = createSlice({
  name: "datakey",
  initialState: initialStateS3Key,
  reducers: {
    setS3DataKey: (state: any, action: any) => {
      state.datakey = action.payload;
    },
  },
});

/**Update locationName */
export const locationNameSlice = createSlice({
  name: "locationName",
  initialState: initialLocationName,
  reducers: {
    setLocationName: (state: any, action: any) => {
      state.locationName = action.payload;
    },
  },
});

/**Update locationRadius */
export const locationRadiusSlice = createSlice({
  name: "locationRadius",
  initialState: initialLocationRadius,
  reducers: {
    setLocationRadius: (state: any, action: any) => {
      state.locationRadius = action.payload;
    },
  },
});

/**Update Notification Refrsh */
export const notificationRereshSlice = createSlice({
  name: "notificationRefresh",
  initialState: initialNotification,
  reducers: {
    setNotificationRefresh: (state: any, action: any) => {
      state.notificationRefresh = action.payload;
    },
  },
});

/**Update loader */
export const loaderSlice = createSlice({
  name: "loader",
  initialState: initialLoaderState,
  reducers: {
    setLoader: (state: any, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
  },
});

/**Login Sign Up dialog */
export const LoginSignUpSlice = createSlice({
  name: "loginSignUp",
  initialState: loginSignUpDialogState,
  reducers: {
    setLoginSignUpOpen: (state: any, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

/**Guest User Sign Up dialog */
export const GuestSignupSlice = createSlice({
  name: "guestSignUp",
  initialState: guestSignupDialogState,
  reducers: {
    setGuestSignupOpen: (state: any, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

/**Login Sign Up dialog */
export const ChildLoginRestrictionSlice = createSlice({
  name: "childLoginRestriction",
  initialState: loginSignUpDialogState,
  reducers: {
    setChildLoginRestriction: (state: any, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

// search filter
export const FilterSlice = createSlice({
  name: "filter",
  initialState: initialFilter,
  reducers: {
    setTutorFilter: (state: any, action: PayloadAction<any>) => {
      state.tutorfilter = action.payload;
    },
    setSchoolFilter: (state: any, action: PayloadAction<any>) => {
      state.schoolfilter = action.payload;
    },
    setUniversityFilter: (state: any, action: PayloadAction<any>) => {
      state.universityfilter = action.payload;
    },
    setActivitiesFilter: (state: any, action: PayloadAction<any>) => {
      state.activityfilter = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
/* export const { setLanguage } = languageSlice.actions;

export const { setProfileData } = profileData.actions;

export default languageSlice.reducer; */

export const locationRadiusReducer = locationRadiusSlice.reducer;
export const { setLocationRadius } = locationRadiusSlice.actions;

export const notificationRefreshReducer = notificationRereshSlice.reducer;
export const { setNotificationRefresh } = notificationRereshSlice.actions;

export const s3DataKeyReducer = s3DataKeySlice.reducer;
export const { setS3DataKey } = s3DataKeySlice.actions;

export const locationNameReducer = locationNameSlice.reducer;
export const { setLocationName } = locationNameSlice.actions;

export const locationReducer = locationSlice.reducer;
export const { setLocation } = locationSlice.actions;

export const languageReducer = languageSlice.reducer;
export const { setLanguage, getLanguageJsonEnglish, getLanguageJsonArabic } = languageSlice.actions;

export const loaderReducer = loaderSlice.reducer;
export const { setLoader } = loaderSlice.actions;

export const loginSignUpReducer = LoginSignUpSlice.reducer;
export const { setLoginSignUpOpen } = LoginSignUpSlice.actions;

export const guestSignupReducer = GuestSignupSlice.reducer;
export const { setGuestSignupOpen } = GuestSignupSlice.actions;

export const childLoginRestrictionReducer = ChildLoginRestrictionSlice.reducer;
export const { setChildLoginRestriction } = ChildLoginRestrictionSlice.actions;

export const filterReducer = FilterSlice.reducer;
export const { setTutorFilter, setActivitiesFilter, setSchoolFilter, setUniversityFilter } = FilterSlice.actions