import React, { useEffect, useState } from 'react';
import "./TutorHubDetailes.scss";
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import video_call_icon from "../../Assets/TutorHub/video_call_icon.svg";
import TutorHubSession from '../TutorHubSession/TutorHubSession';
import TutorHubDocuments from '../TutorHubDocuments/TutorHubDocuments';
import TutorHubChat from '../TutorHubChat/TutorHubChat';
import { API_END_POINTS, GLOBAL_CONSTANTS, TutorHubArray } from '../../Constants/Constants';
import TutorHubFeedback from '../TutorHubFeedback/TutorHubFeedback';
import { useLocation, useNavigate } from "react-router-dom";
import { nullUndefinedEmptyCheck } from '../../Utilities/Helpers/Helper';
import { axiosInstance } from '../../Utilities/Interceptor/Interceptor';
import { useTranslation } from "react-i18next";

const TutorHubDetailes = () => {
    const { t } = useTranslation();
    const route = useLocation();
    const [ActiveState, setActiveState] = useState<any>(route?.state?.activeState);
    const [RoomData, setRoomData] = useState<any>(null);
    const [ReceiverID, setReceiverID] = useState<any>(null);
    const [DataList, setDataList] = useState<any>(null);

    const handleOpenNewTab = (url: any) => {
        // Open a new tab/window
        window.open(url, '_blank');
    };

    useEffect(() => {
        getOneData();
    }, [])

    const getOneData = () => {
        if (route?.state?.Detailes_list?.teach_service_provider_id == 1) {
            axiosInstance
                .get(
                    `${API_END_POINTS.TUTOR_HUB + '' + GLOBAL_CONSTANTS.INITIAL_VALUES.AND_SEARCH + '' +
                    GLOBAL_CONSTANTS.INITIAL_VALUES.AND_ISTODAY + false}`
                )
                .then((Response: any) => {
                    if (Response?.data?.status) {
                        var SelectedArr = Response?.data?.result?.data?.filter(
                            (item: any) => item.id == route?.state?.Detailes_list?.id
                        );
                        setDataList(SelectedArr[0]);
                        ChatRoomCreate(SelectedArr[0]?.teach_service_provider_id, SelectedArr[0]?.id, SelectedArr[0]?.session_creator_id);
                    }
                })
                .catch(() => { });
        } else {
            axiosInstance
                .get(
                    `${API_END_POINTS.Activity_HUB + '' + GLOBAL_CONSTANTS.INITIAL_VALUES.AND_SEARCH + '' +
                    GLOBAL_CONSTANTS.INITIAL_VALUES.AND_ISTODAY + false}`
                )
                .then((Response: any) => {
                    if (Response?.data?.status) {
                        var getId = route?.state?.Detailes_list?.booking_id ?? route?.state?.Detailes_list?.id
                        var SelectedArr = Response?.data?.result?.data?.filter(
                            (item: any) => item.id == getId
                        );
                        setDataList(SelectedArr[0]);
                        ChatRoomCreate(SelectedArr[0]?.teach_service_provider_id, SelectedArr[0]?.id, SelectedArr[0]?.session_creator_id);
                    }
                })
                .catch(() => { });
        }

    }

    const ChatRoomCreate = (teach_service_provider_id: any, id: any, session_creator_id: any) => {
        var payload = {
            service_provider_type: teach_service_provider_id,
            booking_id: id,
            type: 'User',
            recipient_id: session_creator_id
        }
        setReceiverID(session_creator_id)
        axiosInstance.post(`${API_END_POINTS.CHAT_ROOM}`, payload)
            .then((response) => {
                if (response?.data?.status) {
                    setRoomData(response?.data?.result?.data);
                }
            })
    }

    return (
        <>
            {(nullUndefinedEmptyCheck(DataList) && (
                <div className='TutorHubDetailes__container'>
                    <div className='header_section'>
                        <div className="Profile_contant">
                            <div className="tutor__contant">
                                <img src={nullUndefinedEmptyCheck(DataList?.session_creator_profile) ? DataList?.session_creator_profile : tutor_img} className="profile__Img"
                                    onError={(e: any) => { e.target.src = tutor_img; }} alt="" />
                                <div className="d-flex flex-column">
                                    <div className="Black__text">{DataList?.session_creator_name ? DataList?.session_creator_name : ""}</div>
                                    <div className="name__text">{DataList?.type ? DataList?.type : ""}</div>
                                </div>
                            </div>
                            {/* <div className="right_options">
                            <div className="video_call__wapeer" onClick={() => handleOpenNewTab(route?.state?.Detailes_list?.meet_link)}>
                                <div className="badgeIcon badeg__pos"></div>
                                <img src={video_call_icon} alt="" />
                            </div>
                        </div> */}
                        </div>
                    </div>

                    <div className='TutorHubList__wappper'>
                        {TutorHubArray?.map((data: any, i) => {
                            return (
                                <>
                                    {DataList?.teach_service_provider_id == 1 ? (
                                        <div className={ActiveState === data.id ? 'List__data_active' : 'List__data'} key={data.id} onClick={() => setActiveState(data.id)}>
                                            <img src={ActiveState === data.id ? data.activeImg : data.InactiveImg} alt="" />
                                            <div className='text'>{t(data.title)}</div>
                                        </div>
                                    ) : (
                                        <> {data.id !== 2 && DataList?.teach_service_provider_id == 2 ? (
                                            <div className={ActiveState === data.id ? 'List__data_active' : 'List__data'} key={data.id} onClick={() => setActiveState(data.id)}>
                                                <img src={ActiveState === data.id ? data.activeImg : data.InactiveImg} alt="" />
                                                {/* <div className='text'>{data.title}</div> */}
                                                <div className='text'>{t(data.title)}</div>
                                            </div>
                                        ) : (
                                            <>
                                                {data.id !== 2 && data.id !== 3 && (
                                                    <div className={ActiveState === data.id ? 'List__data_active' : 'List__data'} key={data.id} onClick={() => setActiveState(data.id)}>
                                                        <img src={ActiveState === data.id ? data.activeImg : data.InactiveImg} alt="" />
                                                        <div className='text'>{t(data.title)}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        </>
                                    )}
                                </>
                            )
                        })}
                    </div>

                    {ActiveState === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT ? (
                        <TutorHubSession getData={DataList} type={route?.state?.type} />
                    ) : ActiveState === GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT && DataList?.teach_service_provider_id === 1 ? (
                        <TutorHubDocuments getData={DataList} type={route?.state?.type} />
                    ) : ActiveState === GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT && RoomData ? (
                        <TutorHubChat type={route?.state?.type} chatName={DataList?.session_creator_name}
                            session_receiver_id={ReceiverID} roomData={RoomData} />
                    ) : <TutorHubFeedback getData={DataList} type={route?.state?.type} />}

                </div>
            ))}
        </>
    )
}
export default TutorHubDetailes;