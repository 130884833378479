import AliceCarousel from "react-alice-carousel";
import "./HomeOffersCarousel.scss";
import { useEffect, useState, } from "react";
import { encryptPassData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import gsap from 'gsap';
import { useNavigate } from "react-router-dom";
import { GLOBAL_CONSTANTS, HOMEPAGE, ROUTES } from "../../Constants/Constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HomeOffersCarousel = (homeOffersCarouselProps: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  /**Initial states */
  const [offersActiveIndex, setOffersActiveIndex] = useState(0);

  /**Slide previous card */
  const slidePrev = () => {
    setOffersActiveIndex(offersActiveIndex - 1);
  };

  /**Slide next card */
  const slideNext = () => {
    setOffersActiveIndex(offersActiveIndex + 1);
  };

  /**Sync card index on slide */
  const syncOffersActiveIndex = ({ item }: any) => {
    setOffersActiveIndex(item);
  };

  const handleMouseEnter = (event: any) => {
    const element = event.currentTarget;
    const tl = gsap.timeline({ paused: true });
    tl.to(element, { duration: 0.6, })
      .to(element, { duration: 0.6, width: 390, }, '-=0.6')
    tl.play();
  };
  const handleMouseLeave = (event: any) => {
    const element = event.currentTarget;
    gsap.to(element, { duration: 0.6, backgroundColor: 'initial' });
    gsap.to(element, { duration: 0.6, width: 260, });
  };

  const NavigateRoute = (data: any,) => {
    let type = data.type;
    let provider_id = data.provider_id;
    if (type === HOMEPAGE.CAROUSEL_TYPE.UNIVERSITY) {
      if (nullUndefinedEmptyCheck(provider_id)) {
        navigate(ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(provider_id));
      } else {
        navigate(ROUTES.UNIVERSITYLAYOUT);
      }
    } else if (type === HOMEPAGE.CAROUSEL_TYPE.SCHOOL) {
      if (nullUndefinedEmptyCheck(provider_id)) {
        navigate(ROUTES.SCHOOL_DETAILE_VIEW + "?id=" + encryptPassData(provider_id));
      } else {
        navigate(ROUTES.SCHOOLLAYOUT);
      }
    } else if (type === HOMEPAGE.CAROUSEL_TYPE.CAMP) {
      if (nullUndefinedEmptyCheck(provider_id)) {
        navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(provider_id) + '&type=' + encryptPassData("camp"));
      } else {
        navigate(ROUTES.ACTIVITYLAYOUT);
      }
    } else if (type === HOMEPAGE.CAROUSEL_TYPE.TRAINER) {
      if (nullUndefinedEmptyCheck(provider_id)) {
        navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(provider_id) + '&type=' + encryptPassData("personal"));
      } else {
        navigate(ROUTES.ACTIVITYLAYOUT);
      }
    } else if (type === HOMEPAGE.CAROUSEL_TYPE.THEME_PARK) {
      if (nullUndefinedEmptyCheck(provider_id)) {
        navigate(ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + encryptPassData(provider_id));
      } else {
        navigate(ROUTES.THEMEPARK_LAYOUT);
      }
    } else if (type === HOMEPAGE.CAROUSEL_TYPE.TUTOR) {
      if (nullUndefinedEmptyCheck(provider_id)) {
        navigate(ROUTES.TUTORS_DETAILE_VIEW + "?id=" + encryptPassData(provider_id));
      } else {
        navigate(ROUTES.TUTORS);
      }
    }
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const OfferCardLanguage = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "offer_card_eng" : "offer_card_arab";
  const OfferCardsWrapperLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "offer_cards_wrapper_eng" : "offer_cards_wrapper_arab";

  /**Offer cards template */
  const getOfferCardsList = () => {
    return homeOffersCarouselProps?.offersData.map(
      (offersDataElements: any, index: any) => {
        return (
          <>
            <div className="offer_card" key={index}>
              <div className="offer_card_container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img
                  draggable="false"
                  src={offersDataElements.image}
                  alt="Learn and Play"
                  className="offers_image"
                />
                <div className="offer_details_container">
                  <div className="offer_title_description_box">
                    <div className="offer_title">
                      {offersDataElements.description}
                    </div>
                    {/* <div className="offer_sub_title">upto 50% off</div> */}
                  </div>
                  <div className="view_button">View</div>
                </div>
              </div>
            </div>
          </>
        );
      }
    );
  };

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(homeOffersCarouselProps?.offersData)) {
      setOffersCardsList(getOfferCardsList());
    }
  }, [homeOffersCarouselProps?.offersData, HeaderLoction]);

  const [offersCardsList, setOffersCardsList] = useState<any>([]);

  return (
    <>
      <div className={`${OfferCardsWrapperLang} offer_cards_wrapper`}>
        {/* <AliceCarousel
          mouseTracking
          autoWidth
          disableButtonsControls
          items={offersCardsList}
          disableDotsControls
          activeIndex={offersActiveIndex}
          onSlideChanged={syncOffersActiveIndex}
        /> */}

        <div className="scroll__wapper">
          {homeOffersCarouselProps?.offersData?.length > 0 && homeOffersCarouselProps?.offersData?.slice(0, 5)?.map(
            (offersDataElements: any, index: any) => {
              return (
                <>
                  {/* <div className="offer_card" key={index} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}> */}
                  <div className={`${OfferCardLanguage} offer_card`} key={index}>
                    <div className="offer_card_container" onClick={() => NavigateRoute(offersDataElements)}>
                      <img
                        draggable="false"
                        src={offersDataElements.image}
                        alt="Learn and Play"
                        className="offers_image"
                      />
                      <div className="offer_details_container">
                        <div className="offer_title_description_box">
                          <div className="offer_title  notranslate" title={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? offersDataElements?.description : offersDataElements?.description_ar}>
                            {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                              <>
                                {offersDataElements?.description.length > 60 ? offersDataElements?.description?.slice(0, 60) + '...' : offersDataElements?.description?.slice(0, 60)}
                              </>
                            ) : (
                              <>
                                {offersDataElements?.description_ar}
                              </>
                            )}
                          </div>
                          {/* <div className="offer_sub_title">upto 50% off</div> */}
                        </div>
                        <div className="view_button" onClick={() => NavigateRoute(offersDataElements?.type)}>{t("View")}</div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          )}
        </div>

      </div>
    </>
  );
};

export default HomeOffersCarousel;
