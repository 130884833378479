import React, { useEffect } from 'react';
import { setLocation, setLocationName } from '../../Redux/Reducer/Reducer';
import { useDispatch } from 'react-redux';
import { SetSessionStorageData } from '../../Utilities/Helpers/Helper';
import { AUTHENTICATION } from '../../Constants/Constants';

interface LatLanComponentProps {
  region: string;
  country: string;
}

const LatLanComponent: React.FC<LatLanComponentProps> = ({ region, country }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (country) {
      // Construct the URL with the API key as a query parameter
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(country === AUTHENTICATION.SHARED.UNITED_ARAB ? region : country)}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;

      // Make the API call
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          const { lat, lng } = data?.results[0]?.geometry?.location;
          var location: any = { lat, lng }
          dispatch(setLocation(location));
          dispatch(setLocationName(country));
          SetSessionStorageData(AUTHENTICATION.SHARED.CURRENT_LOCATION, JSON.stringify(location))
          SetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME, country)
          SetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME, region)
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [country, region]);

  return null;
};

export default LatLanComponent;


