import universityIcon from "../../../Assets/University/universityIcon.svg";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import PhoneInput from "react-phone-input-2";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../../Constants/Constants";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./UniversityCheckOut.scss";
import { useState, useEffect } from "react";
import { GetSessionStorageData } from "../../../Utilities/Helpers/Helper";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { nullUndefinedEmptyCheck } from '../../../Utilities/Helpers/Helper';
import defaultProfile from "../../../Assets/Authentication/defaultProfile.svg";
import toast from "react-hot-toast";
import conslutingImg from "../../../Assets/University/conslutingImg.svg";

const UniversityCheckOut = () => {
    const route = useLocation();
    const navigate = useNavigate();
    const [userProfileImg, setUserProfileImg] = useState<any>();
    const [userName, setUserName] = useState<any>();
    const [userEmail, setUserEmail] = useState<any>();
    const [userMobile, setUserMobile] = useState<any>('');
    const [open, setOpen] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [orderDetailes, setOrderDetailes] = useState([]);
    const [childProfileData, setChildProfileData] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState<any>([]);
    const [checked, setChecked] = useState(false);
    const [ProfileData, setProfileData] = useState<any>('');
    const [PaymentTotal, setPaymentTotal] = useState<any>('');

    const handleClickOpen = (value: any) => {
        setOpen(true);
        setViewMode(value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigatePaymentSuccess = () => {
        slotBookingApi();
    }

    const calculateTotal = () => {
        // Use the reduce method to sum the array elements
        const total = route?.state?.tutor_checkOut?.reduce((accumulator: any, currentValue: any) => {
            return accumulator + parseInt(currentValue.slot_price);
        }, 0); // 0 is the initial value of the accumulator

        return total;
    };

    useEffect(() => {
        groupData();
        getProfileList();
        setProfileData(route?.state?.tutor_del);
        setUserProfileImg(route?.state?.childProfileData?.profile_image);
        setUserName(route?.state?.childProfileData?.name);
        setUserEmail(GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL));
        setPaymentTotal(calculateTotal());
    }, [])


    /**Get profile data */
    const getProfileList = () => {
        axiosInstance
            .get(`${API_END_POINTS.PROFILE_LIST}`)
            .then((profileListResponse: any) => {
                if (
                    profileListResponse.data[AUTHENTICATION.SHARED.STATUS] ===
                    AUTHENTICATION.SHARED.TRUE
                ) {
                    setChildProfileData(profileListResponse?.data?.result?.data?.child);
                    var SelectedArr = profileListResponse?.data?.result?.data?.child?.filter((item: any) => item.id == route?.state?.childProfileData?.id);
                    setSelectedOption(SelectedArr[0]);
                    setUserMobile(profileListResponse?.data?.result?.data?.parent[0]?.phone_number);
                }
            })
            .catch(() => { });
    };


    const payload = () => {
        var transformedData: any = [];
        // Iterate through the input response and transform each item
        route?.state?.tutor_checkOut.forEach((item: any) => {
            transformedData?.push({
                time_slot_id: item.id,
                start_time: item.start_time,
                end_time: item.end_time,
                slot_price: item.slot_price,
                start_date: moment(item.start).format(GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT),
                session_slot_id: item.session_slot
            });
        });
        const transformedSlotIds = route?.state?.tutor_checkOut?.map((item: any) => item.id.toString());

        var Response = {
            child_id: selectedOption.id.toString(),
            service_provider_id: route?.state?.tutor_checkOut[0]?.service_provider_id.toString(),
            teach_service_provider_id: route?.state?.tutor_checkOut[0]?.teach_service_provider_id.toString(),
            session_title: route?.state?.tutor_checkOut[0]?.title,
            booked_email: userEmail,
            booked_phone_number: userMobile,
            time_slot_ids: transformedSlotIds,
            slot_count: route?.state?.tutor_checkOut?.length.toString(),
            payment_mode: GLOBAL_CONSTANTS.INITIAL_VALUES.CARD,
            payment_type: GLOBAL_CONSTANTS.INITIAL_VALUES.ONLINE,
            booked_slot_detail: transformedData,
            course_type: route?.state?.courseIndex?.course_mode == "Online" ? GLOBAL_CONSTANTS.INITIAL_VALUES.ONLINE : route?.state?.courseIndex?.course_mode,
        }

        return Response;
    }


    /**Post slot booking Api */
    const slotBookingApi = () => {
        var pay = payload();
        axiosInstance
            .post(`${API_END_POINTS.SLOT_BOOKING}`, pay)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    toast.success(Response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                    handleClose();
                    navigate(ROUTES.TUTORS_PAYMENT_SUCCESS, {
                        state: {
                            calender_link: Response?.data?.result?.data,
                            ProfileData: ProfileData,
                            PaymentTotal: PaymentTotal,
                            orderDetailes: orderDetailes
                        }
                    });
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message, {
                    position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                });
                handleClose();
            });
    };

    /**onclick Function to get particular child data*/
    const handleChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    // Create a map to store groups
    const groupData = () => {
        const groupedData = new Map();

        route?.state?.tutor_checkOut?.forEach((item: any) => {
            const { title, start_time, end_time } = item;
            const groupKey = `${title}-${start_time}-${end_time}`;

            if (!groupedData.has(groupKey)) {
                groupedData.set(groupKey, { title, start_time, end_time, dates: [] });
            }

            const group = groupedData.get(groupKey);
            group.dates.push(item?.session_slot_date);
        });

        // Convert the map to an array of objects
        const result: any = Array.from(groupedData.values());
        setOrderDetailes(result)
    }

    // An array with one date is considered sorted and continuous.
    const isDateArray = (arr: any) => {
        if (arr.length <= 1) {
            return true;
        }

        for (let i = 1; i < arr.length; i++) {
            const currentDate: any = new Date(arr[i]);
            const previousDate: any = new Date(arr[i - 1]);

            // Check if the current date is not one day after the previous date
            if (currentDate - previousDate !== 24 * 60 * 60 * 1000) {
                return false; // Dates are not continuous.
            }

            // Check if the current date is greater than or equal to the previous date
            if (currentDate <= previousDate) {
                return false; // Dates are not sorted in ascending order.
            }
        }
        return true; // Dates are sorted in ascending order and continuous.
    }

    function formatDatesWithSpaces(dateArray: any) {
        const formattedDates = dateArray.map((dateStr: any) => {
            const [year, month, day] = dateStr.split('-');
            return `${day}/${month}/${year}`;
        });

        return formattedDates.join(', ');
    }

    return (
        <>
            <div className="Universitycheckout-container">
                <div className="tutoImg_wapper">
                    <img src={universityIcon} alt="" className="img-size1" />
                    <label className="checkout-txt">Checkout</label>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="billing-container">
                            <label className="billingtext">Billing Contact</label>
                            <div className="inputfieldss">
                                <div className="billng_contact_fields">
                                    <label className="emailtext">Email ID</label>
                                    <input className="inputdesign1" value={userEmail} readOnly
                                        onChange={(e) => { setUserEmail(e.target.value) }} placeholder="Enter Your mail address" />
                                </div>
                                <div className="phone-num">
                                    <label className="emailtext" >Phone Number</label>
                                    <PhoneInput
                                        inputProps={{
                                            className: "mobile__input",
                                            name: `${AUTHENTICATION.LOGIN_SIGNUP_FIELDS.MOBILE}`,
                                        }}
                                        disabled
                                        value={userMobile}
                                        country={"ae"}
                                        countryCodeEditable={
                                            GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
                                        }
                                        enableSearch={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                                    />
                                </div>
                            </div>
                            <div className="OrderDetails__contants mt-5">
                                <div className="Order__Details">Order Details</div>
                            </div>
                            <div className="OrderDetails__contants">
                                <div className="consluting__wapper">
                                    <img src={conslutingImg} alt="" className="consluting_Img" />
                                    <div className="serice__text">Consulting Service</div>
                                </div>
                                <div className="Order__amount">AED {calculateTotal()}</div>
                            </div>
                            {orderDetailes.slice(0, 2)?.map((data: any) => {
                                return (
                                    <>
                                        <div className="OrderDetails__contants">
                                            <div className="left__contant">
                                                {isDateArray(data?.dates) ? (
                                                    <div className="date">{moment(data?.dates[0]).format("DD/MM/YYYY")} - {moment(data?.dates[data?.dates?.length - 1]).format("DD/MM/YYYY")}</div>
                                                ) : (
                                                    <div className="date">{formatDatesWithSpaces(data?.dates)}</div>
                                                )}
                                                <div className="course">{data.title}</div>
                                                <div className="session__count">No. of sessions : {data?.dates?.length}</div>
                                            </div>
                                            <div className="right__contant">
                                                {moment(data?.start_time, 'HH:mm:ss').format("h:mm a")} to {moment(data?.end_time, 'HH:mm:ss').format("h:mm a")}
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                            {orderDetailes.length > 2 && (
                                <div className="OrderDetails__view__more" onClick={() => handleClickOpen(true)}> View More</div>
                            )}

                            <div className="Course__Details__bottom__contant">
                                <div className="title">Session Details</div>
                                <div className="bottom__contant__wapper">
                                    <div className="black__text">Education Consulting Services</div>
                                    <div className="light__text">Sessions: 1 (19/05/2023)</div>
                                </div>
                                <div className="bottom__contant__wapper">
                                    <div className="bottom__contant">
                                        <img src={nullUndefinedEmptyCheck(ProfileData?.profile_image) ? ProfileData?.profile_image : tutor_img} alt="" />
                                        <div className="name">{ProfileData?.profile_details?.firstname} {" "} {ProfileData?.profile_details?.lastname}</div>
                                    </div>
                                    <div className="bottom__contant">
                                        <div>Course to</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl>
                                                <Select
                                                    labelId="custom-select-label"
                                                    id="custom-select"
                                                    className='child_select__dropdown'
                                                    value={selectedOption}
                                                    onChange={handleChange}
                                                >
                                                    {childProfileData.map((childProfileElements: any, i: any) => {
                                                        return (
                                                            <MenuItem key={i} value={childProfileElements}                                             >
                                                                <div className="avatar_name_wrapper">
                                                                    <div className="avatar_name_active_container">
                                                                        <img
                                                                            src={
                                                                                nullUndefinedEmptyCheck(
                                                                                    childProfileElements.profile_image
                                                                                )
                                                                                    ? childProfileElements.profile_image
                                                                                    : defaultProfile
                                                                            }
                                                                            alt="Learn and Play"
                                                                            className="avatar_pic"
                                                                        />
                                                                        <div className="profile_name_role">
                                                                            <div className="profile_name">
                                                                                {childProfileElements?.name}
                                                                            </div>
                                                                            {/* <div className="role">Kid</div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="summary">
                            <label className="billingtext">Summary</label>
                            <div className="Price__detailes__wapper">
                                <div className="original-price">
                                    <label className="original__price__text">Original Price</label>
                                    <label className="original__price__text">AED {calculateTotal()}</label>
                                </div>
                                <div className="original-price">
                                    <label className="discount-text">Discounts</label>
                                    <label className="discount-text">-AED 0</label>
                                </div>
                            </div>
                            <hr className="hr-tag" />
                            <div className="Price__detailes__wapper">
                                <div className="original-price">
                                    <label className="total__small">Total</label>
                                    <label className="total__small">AED {calculateTotal()}</label>
                                </div>
                                <div className="original-price">
                                    <label className="total__large">Total</label>
                                    <label className="total__large">AED {calculateTotal()}</label>
                                </div>
                            </div>
                            <div className="summary-btndiv">
                                <div className="summary__contant">
                                    <input type="checkbox" className="checkbox" checked={checked}
                                        onChange={() => setChecked(!checked)} />
                                    <label className="paratext ms-3">By completing your purchase, you agree to these</label><br />
                                    <label className="terms__text">Terms of  Services</label>
                                </div>
                                <button className={checked ? "proceedBtn" : "proceedBtn__disable"} disabled={checked ? false : true} onClick={() => handleClickOpen(false)}>Proceed Payment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {open && (
                <div className="overlay-ModelBackround">
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>{viewMode ? "Order summary" : "Confirm Order Summary"}
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                sx={{
                                    position: 'absolute',
                                    top: 6,
                                    right: 13,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <div className="University__OrderDetails__Dailoge">
                                {orderDetailes?.map((data: any, i: any) => {
                                    return (
                                        <div className="contant__wapper" key={i}>
                                            <div className="left__contant">
                                                {isDateArray(data?.dates) ? (
                                                    <div className="date">{moment(data?.dates[0]).format("DD/MM/YYYY")} - {moment(data?.dates[data?.dates?.length - 1]).format("DD/MM/YYYY")}</div>
                                                ) : (
                                                    <div className="date">{formatDatesWithSpaces(data?.dates)}</div>
                                                )}
                                                <div className="course">{data.title}</div>
                                                <div className="session__count">No. of sessions : {data?.dates?.length}</div>
                                            </div>
                                            <div className="right__contant">
                                                {moment(data?.start_time, 'HH:mm:ss').format("h:mm a")} to {moment(data?.end_time, 'HH:mm:ss').format("h:mm a")}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="bottom__btn">
                                {viewMode ? (
                                    <button className="backBtn" onClick={handleClose}>Back</button>
                                ) : (
                                    <button className="paymentBtn" onClick={slotBookingApi}>Proceed to payment</button>
                                )}
                            </div>

                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </>
    )
}

export default UniversityCheckOut;