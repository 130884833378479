import { MenuItem, Select, Tab, Tabs } from "@mui/material";
import "./AddProfile.scss";
import {
  ACCOUNTS,
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  S3DataGetApi,
} from "../../Constants/Constants";
import { useEffect, useRef, useState } from "react";

/**Assets */
import defaultProfile from "../../Assets/Authentication/defaultProfile.svg";
import uploadArrow from "../../Assets/Authentication/uploadArrow.svg";
import edit from "../../Assets/Header/edit.svg";
import {
  GetSessionStorageData,
  nullUndefinedEmptyCheck,
  uploadFileToS3,
} from "../../Utilities/Helpers/Helper";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";
import ProfileAvatarsDialog from "../ProfileAvatarsDialog/ProfileAvatarsDialog";
import moment from "moment";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AddProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const DataKeys: any = useSelector((state: any) => state.s3DataKeyReducer.datakey);

  /**Initial states */
  const [mobileEmailTab, setMobileEmailTab] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT
  );
  const [profilePicture, setProfilePicture] = useState("");
  const [AvatarPicture, setAvatarPicture] = useState("");
  const [ParentDate, setParentDate] = useState<any>("");
  const profileInput = useRef<HTMLInputElement>(null);
  const personalDetailsRef = useRef<any>();
  const [openProfileAvatars, setOpenProfileAvatars] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [isSubmitted, setIsSubmitted] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  /**Personal details form initial values */
  const personalDetailsValue = {
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME]: "",
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME]: "",
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL]: "",
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE]: "",
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]: "",
  };
  /**Personal details validation schema */
  const getPersonalDetailsValidationSchema: any = Yup.object().shape<any>({
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME]: Yup.string()
      .trim(AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.SPACE)
      .strict(true)
      .required(
        ACCOUNTS.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.FIRST_NAME_REQUIRED
      ),
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME]: Yup.string()
      .trim(AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.SPACE)
      .strict(true)
      .required(
        ACCOUNTS.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.LAST_NAME_REQUIRED
      ),
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE]: Yup.string().required(
      ACCOUNTS.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.TYPE_REQUIRED
    ),
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL]: Yup.string().when(
      ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE,
      {
        is: (type: any) => type === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT,
        then: () => {
          Yup.string()
            .required(
              ACCOUNTS.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.EMAIL_REQUIRED
            )
            .matches(
              GLOBAL_CONSTANTS.REGEX.EMAIL,
              ACCOUNTS.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.INVALID_EMAIL
            )
            .trim();
        },
        otherwise: () => {
          Yup.string();
        },
      } as any
    ) as any,
    [ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]: Yup.string().required(
      ACCOUNTS.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.DATE_OF_BIRTH_REQUIRED
    ),
  });

  /**Handle tab change */
  const handleProfileSwitch = (
    event: React.SyntheticEvent,
    tabIndex: number
  ) => {
    // if (AvatarPicture !== "") {
    //   setOpenProfileAvatars(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
    // }
    setMobileEmailTab(tabIndex);
  };

  /**Handle upload profile picture on clicking other buttons */
  const handleUploadProfilePicture = () => {
    if (profileInput.current) {
      profileInput.current.value = "";
      profileInput.current?.click();
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      profilePictureCheck();
    }
  }, [profilePicture, AvatarPicture]);

  useEffect(() => {
    if (nullUndefinedEmptyCheck(GetSessionStorageData(AUTHENTICATION.SHARED.PARENT_DOB))) {
      const SessionDate: any = GetSessionStorageData(AUTHENTICATION.SHARED.PARENT_DOB);
      const minDate = new Date(SessionDate);
      setParentDate(minDate);
    }
  }, [])

  /**Check for empty profile picture */
  const profilePictureCheck = () => {
    profilePicture === "" && AvatarPicture === ""
      ? setIsSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE)
      : setIsSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  };

  /**Handle profile change */
  const handleChange = async (event: any) => {
    if (event.target.files[0].type.split("/")[0] === "image") {
      try {
        const uploadedImage: any = await S3DataGetApi(event);
        setProfilePicture(uploadedImage);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      toast.error('Please choose file Image format', {
        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
      });
    }
  };

  useEffect(() => {
    personalDetailsRef.current.setFieldError(
      ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL,
      ""
    );
  }, [personalDetailsRef]);

  /**Handle close profile avatar dialog */
  const handleCloseProfileAvatarDialog = (avatarImage: any) => {
    setOpenProfileAvatars(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    if (nullUndefinedEmptyCheck(avatarImage)) {
      setMobileEmailTab(GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
      setAvatarPicture(avatarImage);
    } else {
      setMobileEmailTab(GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);
    }
  };

  /**child payload */
  const childPayload = (values: any) => {
    return {
      first_name: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME],
      last_name: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME],
      profile_img: mobileEmailTab === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT ? profilePicture ? profilePicture : null : AvatarPicture ? AvatarPicture : null,
      account_type: values[ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE],
      roles: ACCOUNTS.ADD_PROFILE_ROLE.CHILD,
      dob: moment(
        values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]
      ).format(AUTHENTICATION.DATE_FORMAT.DATE_OF_BIRTH),
    };
  };

  /**parent payyload */
  const parentPayload = (values: any) => {
    return {
      first_name: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME],
      last_name: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME],
      profile_img: mobileEmailTab === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT ? profilePicture : AvatarPicture,
      account_type: values[ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE],
      email: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL],
      roles: ACCOUNTS.ADD_PROFILE_ROLE.PARENT,
      dob: moment(
        values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]
      ).format(AUTHENTICATION.DATE_FORMAT.DATE_OF_BIRTH),
    };
  };

  /**Create profile */
  const createProfile = (values: any, actions: any) => {
    if (values[ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE] === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
      if (mobileEmailTab === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT) {
        if (profilePicture === "") {
          toast.error('Please choose profilePicture', {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
          });
        } else {
          createProfileApi(values, actions);
        }
      } else {
        if (AvatarPicture === "") {
          toast.error('Please choose AvatarPicture', {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
          });
        } else {
          createProfileApi(values, actions);
        }
      }
    } else {
      createProfileApi(values, actions);
    }
  };

  /**Create profile api call */
  const createProfileApi = (values: any, actions: any) => {
    axiosInstance
      .post(
        `${API_END_POINTS.ADD_PROFILE}`,
        values[ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE] ===
          AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT
          ? parentPayload(values)
          : childPayload(values)
      )
      .then((createProfileResponse: any) => {
        if (
          createProfileResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(createProfileResponse.data.message);
          setProfilePicture("");
          setAvatarPicture("");
          actions.resetForm();
          navigate(-1);
        }
      })
      .catch(() => { });
  }

  return (
    <>
      <div className="profile_pic_fields_wrapper">
        <div className="profile_pic_tabs_container">
          <div className="profile_pic_upload_container">
            <input
              ref={profileInput}
              className="file_input"
              type="file"
              accept="image/png,image/jpg,image/jpeg,image/svg"
              onChange={handleChange}
            />
            <div
              onClick={() => {
                mobileEmailTab === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT
                  ? handleUploadProfilePicture()
                  : setOpenProfileAvatars(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
              }}
              className="profile_upload_container"
            >{mobileEmailTab === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT ? (
              <>
                <img
                  src={profilePicture !== "" ? profilePicture : defaultProfile}
                  alt="Learn and Play"
                  className="profile_pic"
                />
                <div className="upload_arrow_wrapper">
                  <div className="upload_arrow_container">
                    <img
                      className="upload_arrow"
                      src={profilePicture !== "" ? edit : uploadArrow}
                      alt="Learn and Play"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <img
                  src={AvatarPicture !== "" ? AvatarPicture : defaultProfile}
                  alt="Learn and Play"
                  className="profile_pic"
                />
                <div className="upload_arrow_wrapper">
                  <div className="upload_arrow_container">
                    <img
                      className="upload_arrow"
                      src={AvatarPicture !== "" ? edit : uploadArrow}
                      alt="Learn and Play"
                    />
                  </div>
                </div>
              </>
            )}
            </div>
          </div>
          {isSubmitted && (
            <div className="errorMessage">
              {t("Please Upload a profile picture or select an avatar")}
            </div>
          )}
          {mobileEmailTab === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT ? (
            <>
              {profilePicture !== "" && (
                <div className="remove_arrow_wrapper" title="Remove Profile" onClick={() => setProfilePicture("")}>
                  <div className="remove_arrow_text">{t("Remove Profile Image")}</div>
                  {/* <CancelIcon className="remove_arrow_icon" /> */}
                </div>
              )}
            </>
          ) : (
            <>
              {AvatarPicture !== "" && (
                <div className="remove_arrow_wrapper" title="Remove Avatar" onClick={() => setAvatarPicture("")}>
                  <div className="remove_arrow_text">{t("Remove Avatar Image")}</div>
                  {/* <CancelIcon className="remove_arrow_icon" /> */}
                </div>
              )}
            </>
          )}
          <Tabs value={mobileEmailTab} onChange={handleProfileSwitch}>
            <Tab label="Profile Image" />
            <Tab label="Avatar" />
          </Tabs>
          <ProfileAvatarsDialog
            openProfileAvatars={openProfileAvatars}
            profilePicture={profilePicture}
            handleCloseProfileAvatarDialog={handleCloseProfileAvatarDialog}
          />
        </div>
        <div className="profile_fields_wrapper">
          <Formik
            initialValues={personalDetailsValue}
            validationSchema={getPersonalDetailsValidationSchema}
            onSubmit={(values, actions) => {
              createProfile(values, actions);
            }}
            enableReinitialize={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
            innerRef={personalDetailsRef}
          >
            {(emailFormikProps: any) => {
              return (
                <Form className="email_login_form_wrapper">
                  <div className="accounts_label_field_error_messages_container label_field_error_messages_container">
                    <label className="field_label">{t("Account Type")}</label>
                    <Select
                      value={
                        emailFormikProps.values[
                        ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE
                        ]
                      }
                      label="Account Type"
                      className="Account__drpdown"
                      onChange={(event: any) => {
                        emailFormikProps.setFieldValue(
                          ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE,
                          event.target.value
                        );
                      }}
                    >
                      <MenuItem
                        value={AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT}
                      >
                        {t("Parent")}
                      </MenuItem>
                      <MenuItem
                        value={AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD}
                      >
                        {t("Kid")}
                      </MenuItem>
                    </Select>
                    {emailFormikProps.touched[
                      ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE
                    ] &&
                      emailFormikProps.errors[
                      ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE
                      ] ? (
                      <div className="errorMessage">
                        {
                          emailFormikProps.errors[
                          ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE
                          ]
                        }
                      </div>
                    ) : null}
                  </div>
                  {emailFormikProps.values[
                    ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE
                  ] === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT && (
                      <div className="accounts_label_field_error_messages_container label_field_error_messages_container">
                        <label className="field_label">{t("Email")}</label>
                        <Field
                          placeholder={t("Enter your Email")}
                          name={ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL}
                        />
                        {emailFormikProps.touched[
                          ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL
                        ] &&
                          emailFormikProps.errors[
                          ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL
                          ] ? (
                          <div className="errorMessage">
                            {
                              emailFormikProps.errors[
                              ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL
                              ]
                            }
                          </div>
                        ) : null}
                      </div>
                    )}
                  <div className="accounts_label_field_error_messages_container label_field_error_messages_container">
                    <label className="field_label">{t("First name")}</label>
                    <Field
                      placeholder={t("Enter your first name")}
                      name={ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME}
                    />
                    {emailFormikProps.touched[
                      ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME
                    ] &&
                      emailFormikProps.errors[
                      ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME
                      ] ? (
                      <div className="errorMessage">
                        {
                          emailFormikProps?.errors[
                          ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME
                          ]
                        }
                      </div>
                    ) : null}
                  </div>
                  <div className="accounts_label_field_error_messages_container label_field_error_messages_container">
                    <label className="field_label">{t("Last name")}</label>
                    <Field
                      placeholder={t("Enter your last name")}
                      name={ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME}
                    />
                    {emailFormikProps.touched[
                      ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME
                    ] &&
                      emailFormikProps.errors[
                      ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME
                      ] ? (
                      <div className="errorMessage">
                        {
                          emailFormikProps.errors[
                          ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME
                          ]
                        }
                      </div>
                    ) : null}
                  </div>
                  <div className="label_date_picker_field_error_messages_container accounts_label_date_picker_field_error_messages_container">
                    <label className="field_label">{t("Date of birth")}</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className=""
                        disableFuture
                        minDate={
                          emailFormikProps.values[ACCOUNTS.PERSONAL_DETAILS_FIELDS.TYPE] === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ?
                            null : ParentDate
                        }
                        format="dd-MM-yyyy"
                        value={
                          new Date(
                            emailFormikProps.values[
                            ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                            ]
                          )
                        }
                        onChange={(date) => {
                          const formattedDate = date
                            ? format(date, "yyyy-MM-dd")
                            : "";
                          emailFormikProps.setFieldValue(
                            ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH,
                            new Date(formattedDate)
                          );
                        }}
                      />
                    </LocalizationProvider>
                    {emailFormikProps.touched[
                      ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                    ] &&
                      emailFormikProps.errors[
                      ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                      ] ? (
                      <div className="errorMessage">
                        {
                          emailFormikProps.errors[
                          ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                          ]
                        }
                      </div>
                    ) : null}
                  </div>
                  <div className="action_buttons_wrapper">
                    <button type="button" className="cancel_button" onClick={() => navigate(-1)}>
                      {t("Cancel")}
                    </button>
                    <button
                      // onClick={profilePictureCheck}
                      type="submit"
                      className="add_profile_sign_up_button"
                    >
                      {t("Apply")}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddProfile;
