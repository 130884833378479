import { useEffect, useState } from "react";
import "./UniversityDashboardLayout.scss";
import search from "../../Assets/Tutor/searchIconGray.svg";
import universityIcon from "../../Assets/University/universityIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
  onInputChangeGoogleApi,
} from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { store } from "../../Redux/Store/Store";
import { setLoader, setLoginSignUpOpen, setUniversityFilter } from "../../Redux/Reducer/Reducer";
import {
  GetSessionStorageData,
  encryptPassData,
  getCurrentLocation,
  isObjectEmptyValue,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import ExpandMap from "../../Assets/Activities/ExpandMap.svg";
import UniversityMaps from "../../Components/UniversityMaps/UniversityMaps";
import universityImg from "../../Assets/University/universityImg.png";
import UniversityLikeIcon from "../../Assets/University/UniversityLikeIcon.svg";
import universityShareIcon from "../../Assets/University/universityShareIcon.svg";
import locationIcon from "../../Assets/Tutor/location.svg";
import toast from "react-hot-toast";
import UniversityFill from "../../Assets/HeartFill/UniversityFill.svg";
import { useDispatch, useSelector } from "react-redux";
import spacemen from "../../Assets/Global/spacemen.svg";
import WebShareComponent from "../../Components/WebShare/WebShare";
import AppFilter from "../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";

// Render the  Map view Page size
const containerStyle = {
  width: "100%",
  height: "100%",
};

const UniversityDashboardLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [tutorList, setTutorList] = useState([]);
  const [searchTextList, setSearchTextList] = useState([]);
  const [currentLat, setCurrentLat] = useState<any>("");
  const [currentLang, setCurrentLang] = useState<any>("");
  const [IsTrigger, setIsTrigger] = useState<any>(null);
  const [nearSearchList, setNearSearchList] = useState<any>([]);

  const getUniversityFilter: any = useSelector((state: any) => {
    return state.filterReducer?.universityfilter
  })
  // Filter
  const init_filter = {
    programs: [],
    price: [0, 0],
    qs_ranking: [0, 0],
    the_ranking: [0, 0],
    sh_ranking: [0, 0],
    language_instruction: [],
    radius: 10,
  }
  const [FilterPopOpen, setFilterPopOpen] = useState(false);
  const [FilterData, setFilterData] = useState<any>([]);
  const [initFilter, setInitFilter] = useState<any>(init_filter);
  const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
  const [initSort, setSort] = useState<any>({
    price: '',
    rating: ''
  });

  const onOpenFilterPop = () => {
    setFilterPopOpen(true);
  };

  const onCloseFilterPop = () => {
    setFilterPopOpen(false);
  };


  // Route to the Map view Page
  const handleRouteMap = () => {

    navigate(ROUTES.UNIVERSITY_MAPS_VIEW, {
      state: {
        tutor_list: tutorList,
        search_text: searchValue,
        redius: FilterApplyData.radius,
        lat: currentLat,
        lang: currentLang,
        filter: FilterApplyData,
        filterBoolean: IsTrigger,
      },
    });
  };

  /**Sync card index on slide */
  const navigateTutorDetailedView = (value: any) => {
    navigate(ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(value?.id), {
      state: { tutor_Detailes: value },
    });
  };

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng)) {
      setCurrentLat(HeaderLoction?.lat);
      setCurrentLang(HeaderLoction?.lng);
      if (getUniversityFilter && isObjectEmptyValue(getUniversityFilter)) {
        TutorListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, getUniversityFilter);
      } else {
        TutorListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
      }
    }
  }, [HeaderLoction, FilterApplyData]);

  useEffect(() => {
    getFilterData();
  }, []);

  const payload = (latitude: any, longitude: any, value: string, filter: any) => {
    var payload;
    var qsValue: any = [];
    var shValue: any = [];
    var theValue: any = [];
    var priceValue: any = [];

    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }
    if (initFilter.qs_ranking[0] === filter.qs_ranking[0] && initFilter.qs_ranking[1] === filter.qs_ranking[1]) {
      qsValue = []
    } else {
      qsValue = {
        min: filter.qs_ranking[0],
        max: filter.qs_ranking[1],
      }
    }
    if (initFilter.sh_ranking[0] === filter.sh_ranking[0] && initFilter.sh_ranking[1] === filter.sh_ranking[1]) {
      shValue = []
    } else {
      shValue = {
        min: filter.sh_ranking[0],
        max: filter.sh_ranking[1],
      }
    }
    if (initFilter.the_ranking[0] === filter.the_ranking[0] && initFilter.the_ranking[1] === filter.the_ranking[1]) {
      theValue = []
    } else {
      theValue = {
        min: filter.the_ranking[0],
        max: filter.the_ranking[1],
      }
    }
    return payload = {
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      radius: filter.radius,
      search: value ? value : "",
      price: priceValue,
      qs_ranking: qsValue,
      sh_ranking: shValue,
      the_ranking: theValue,
      programs: filter.programs,
      language_instruction: filter.language_instruction
    };

  };

  /**Tutor get List Api*/
  const TutorListApi = (latitude: any, longitude: any, value: string, filter: any) => {
    var pay = payload(latitude, longitude, value, filter);
    axiosInstance
      .post(`${API_END_POINTS.UNIVERSITYLIST}`, pay)
      .then((Response: any) => {
        setTutorList(Response?.data?.result?.data);
      })
      .catch(() => { });
  };

  /**Tutor Search Keyword Api*/
  const searchTutorKeyword = (search: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    var payload = {
      search_text: search.trim(),
    };
    axiosInstance
      .post(`${API_END_POINTS.UNIVERSITY_KEY_WORD_SEARCH}`, payload)
      .then((Response: any) => {
        setSearchTextList(Response?.data.result?.data);
      })
      .catch(() => { });
  };

  /**Get Filter Categories List*/
  const getFilterData = () => {
    axiosInstance
      .get(`${API_END_POINTS.UNIVERSITYFILTERLIST}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          let data = Response?.data?.result?.data;
          setFilterData(data);
          if (data) {
            let init = {
              programs: [],
              price: [data.price?.min, data.price?.max],
              qs_ranking: [0, 0],
              the_ranking: [0, 0],
              sh_ranking: [0, 0],
              // qs_ranking: [data.qs_ranking?.min, data.qs_ranking?.max],
              // the_ranking: [data.the_ranking?.min, data.the_ranking?.max],
              // sh_ranking: [data.sh_ranking?.min, data.sh_ranking?.max],
              language_instruction: [],
              radius: 10,
            }
            setInitFilter(init);
            if (getUniversityFilter && isObjectEmptyValue(getUniversityFilter)) {
              setFilterApplyData(getUniversityFilter);
            } else {
              setFilterApplyData(init);
            }
          }

        }
      })
      .catch(() => { });
  };

  /**Filter Apply api call Function*/
  const FilterApply = (filter: any) => {
    setIsTrigger(true)
    store.dispatch(setUniversityFilter(filter));
    setFilterApplyData(filter)
    TutorListApi(currentLat, currentLang, searchValue, filter);
  };

  /**AutoComplete Onchange Function*/
  const onInputChange = (value: any) => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    if (nullUndefinedEmptyCheck(value) && value?.length > 2) {
      setSearchValue(value);
      searchTutorKeyword(value);
    } else if (value?.length == 0) {
      TutorListApi(currentLat, currentLang, searchValue, FilterApplyData);
    }
  };

  /**AutoComplete submit Function*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //  prevent page refresh
    event.preventDefault();
    TutorListApi(currentLat, currentLang, searchValue, FilterApplyData);
  };

  /**onInputChangeGoogle api Function*/
  const onInputChangeGoogle = async (value: any) => {
    var response = await onInputChangeGoogleApi(value);
    setNearSearchList(response?.data?.results);
  };

  /**Change the location api Function*/
  const NearPacleApi = (data: any) => {
    setCurrentLat(data?.geometry?.location?.lat);
    setCurrentLang(data?.geometry?.location?.lng);
    TutorListApi(
      data?.geometry?.location?.lat,
      data?.geometry?.location?.lng,
      searchValue,
      FilterApplyData
    );
  };

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            TutorListApi(currentLat, currentLang, searchValue, FilterApplyData);
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  /**Loader state*/
  const Loader: any = useSelector((state: any) => {
    return state.loaderReducer.loader;
  });

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const Ssearch_Button = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ssearch_button_english" : "ssearch_button_arab";
  const RowOverRideSchool = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "row-override-school" : "row-override-school_arab";



  const handlePassListPage = (value: any) => {
    if (nullUndefinedEmptyCheck(value)) {
      if (value?.includes("near me")) {
        navigate(ROUTES.UNIVERSITY_LIST_VIEW, {
          state: {
            tutor_list: tutorList,
            search_text: value,
            redius: FilterApplyData.radius,
            lat: currentLat,
            lang: currentLang,
            map_show: true,
            filter: FilterApplyData,
            filterBoolean: IsTrigger,
          },
        });
      } else {
        navigate(ROUTES.UNIVERSITY_LIST_VIEW, {
          state: {
            tutor_list: tutorList,
            search_text: value,
            redius: FilterApplyData.radius,
            lat: currentLat,
            lang: currentLang,
            map_show: false,
            filter: FilterApplyData,
            filterBoolean: IsTrigger,
          },
        });
      }
    } else {
      TutorListApi(currentLat, currentLang, value, FilterApplyData);
    }
  };

  const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
    if (checkedPrice == 'high') {
      const sortedData = [...tutorList].sort((a: any, b: any) => b?.least_price - a?.least_price);
      setTutorList(sortedData);
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedPrice == 'low') {
      const sortedData = [...tutorList].sort((a: any, b: any) => a?.least_price - b?.least_price);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'high') {
      const sortedData = [...tutorList].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'low') {
      const sortedData = [...tutorList].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
      setTutorList(sortedData)
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    }
  }
  /**get selected or current locationName from redux */
  const HeaderLocationName: any = useSelector((state: any) => {
    return state.locationNameReducer.locationName;
  });

  return (
    <>

      <div className="University__dashboard__container">
        <div className="row">
          <div className="col-12 title_container">
            <div className="Dashboard__UniversityCard_Img_wapper">
              <img
                draggable="false"
                src={universityIcon}
                alt="TutorImg"
                className=""
              />
              <div className="text">{t("Universities")}</div>
            </div>
          </div>

          {/* <div className="row  mb-2 row-override-school"> */}
          <div className={`row  mb-2 ${RowOverRideSchool}`}>
            <div className="col-lg-3 col-md-12 input-style-phone">
              <div className="search_field_wrapper">
                <div className="search__autocomplete">
                  <form onSubmit={handleSubmit}>
                    <Autocomplete
                      freeSolo
                      popupIcon
                      id="free-solo-2-demo"
                      disableClearable={false}
                      clearIcon={false}
                      onChange={(e, value) => {
                        setSearchValue(value);
                        handlePassListPage(value);
                      }}
                      options={searchTextList?.map(
                        (option: any) => option?.university_name
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("Search for University")}
                          onChange={(inputChangeEvent: any) => {
                            onInputChange(inputChangeEvent.target.value.trim());
                          }}
                          onKeyDown={(e: any) => {
                            if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                              if (searchValue.trim() === "") {
                                e.preventDefault();
                              } else {
                                handlePassListPage(searchValue);
                                e.preventDefault();
                              }
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: "text",
                          }}
                        />
                      )}
                    />
                    <img
                      draggable="false"
                      src={search}
                      alt="search"
                      // className="search_button"
                      className={`${Ssearch_Button} search_button`}
                      onClick={() => handlePassListPage(searchValue)}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12">
              <AppFilter
                FilterPopOpen={FilterPopOpen}
                onOpenPop={onOpenFilterPop}
                onClosePop={onCloseFilterPop}
                FilterData={FilterData}
                FilterApply={FilterApply}
                FilterApplyData={FilterApplyData}
                initFilter={initFilter}
                initSort={initSort}
                handleApplySort={handleProceedSort}
                type="university"
                color="#12CC92" />
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">

            <div className="University_map_view">
              <div className="University__viewMap" onClick={handleRouteMap}>
                <img src={ExpandMap} alt="ExpandMap" />
                <span>{t("View on Map")}</span>
              </div>
              <UniversityMaps
                zoom={12}
                radius={FilterApplyData.radius * 1000}
                lat={currentLat}
                lang={currentLang}
                containerStyle={containerStyle}
                disable={true}
                zoomControl={false}
                clickableIcons={false}
                draggable={false}
                UniversityList={tutorList}
                initSort={initSort}
                getOneTutor={[]}
                filter={FilterApplyData}
                initFilter={initFilter}
                searchValue={searchValue}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 university__list__container">
            {tutorList?.map((item: any, i: any) => {
              return (
                <>
                  <div className="university__list__wapper" key={i} onClick={() => navigateTutorDetailedView(item)}>
                    <div className="university__leftside">
                      <img
                        src={
                          nullUndefinedEmptyCheck(item?.images)
                            ? item?.images
                            : universityImg
                        }
                        alt=""
                        onError={(e: any) => {
                          e.target.src = universityImg;
                        }}
                      />
                    </div>
                    <div className="university__rightside">
                      <div className="university__name">
                        <span className="name">
                          {item?.university_name}
                        </span>
                        <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                          <WebShareComponent
                            params={
                              ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(item?.id)
                            }
                          >
                            <img
                              src={universityShareIcon}
                              alt=""
                              className="mb-1"
                            />
                          </WebShareComponent>
                          <img
                            src={
                              item?.is_favourite
                                ? UniversityFill
                                : UniversityLikeIcon
                            }
                            alt=""
                            onClick={() =>
                              handleChangeFavourites(
                                item.is_favourite,
                                item?.user
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="university__location">
                        <img src={locationIcon} alt="" />
                        <span className="location" title={item?.city ?? item.address}>
                          {item?.city ?? item.address}
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-xxl-4 col-lg-4 col-md-4 university__ranking">
                          <div className="text__black">{t("QS")} :</div>
                          <div className="text__green">{item?.QS_ranking ? item?.QS_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-xxl-4 col-lg-4 col-md-4 university__ranking">
                          <div className="text__black">{t("THE")} : </div>
                          <div className="text__green">{item?.THE_ranking ? item?.THE_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-xxl-4 col-lg-4 col-md-4 university__ranking">
                          <div className="text__black">{t("SH")} : </div>
                          <div className="text__green">{item?.SH_ranking ? item?.SH_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-xxl-6 col-lg-6 col-md-6 marginTop university__ranking">
                          <div className="text__black">{t("No. of students")} :</div>
                          <div className="text__green">{item?.students_count ? item?.students_count : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="col-xxl-6 col-lg-6 col-md-6 marginTop university__ranking">
                          <div className="text__black">{t("Mediums")} :</div>
                          <div className="text__green">{item?.available_mediums === 'Both' ? t('Online & On-premises') : item?.available_mediums}</div>
                        </div>
                      </div>
                      <div className="university__rating">
                        <div className="year">
                          {t("Founded")} : {item?.founded_year ? item?.founded_year : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                        </div>
                        {/* <div className="d-flex flex-row">
                          <StarIcon className="StarIcon me-2" />
                          <span className="points">{item?.rating}</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {tutorList?.length === 0 && !Loader && (
              <>
                <div className="No__data__contant">
                  <img src={spacemen} alt="" />
                  <div className="text">{t("No Data Found")}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default UniversityDashboardLayout;
