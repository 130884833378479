import "./ServicesNavBar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";

/**Assets */
import home from "../../Assets/SideBar/home.svg";
import school from "../../Assets/SideBar/school.svg";
import university from "../../Assets/SideBar/university.svg";
import activities from "../../Assets/SideBar/activities.svg";
import activitiesActive from "../../Assets/SideBar/activitiesActive.svg";
import UniversityActive from "../../Assets/SideBar/UniversityActive.svg";
import schoolActive from "../../Assets/SideBar/SchoolActive.svg";
import tutor from "../../Assets/SideBar/tutor.svg";
import tutorActive from "../../Assets/SideBar/tutorActive.svg";
import themePark from "../../Assets/SideBar/themePark.svg";
import themeParkActive from "../../Assets/SideBar/themeParkActive.svg";
import calender from "../../Assets/SideBar/calender.svg";
import { GetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { setLoginSignUpOpen } from "../../Redux/Reducer/Reducer";
import { useDispatch, useSelector } from "react-redux";

const ServicesNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateCalender = () => {
    const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
    if (nullUndefinedEmptyCheck(accessToken)) {
      navigate(ROUTES.CHILDCALENDER)
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const sidebar_wrapper_Service_lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "sidebar_wrapper_Service_english" : "sidebar_wrapper_Service_arabic";

  return (
    <>
      <div className={`sidebar_wrapper_Service ${sidebar_wrapper_Service_lang}`}>
        <div className="sidebar_nav_container">
          {location.pathname === ROUTES.HOMEPAGE && (
            <div className="active_indicator"></div>
          )}
          <div
            className={
              location.pathname.includes(ROUTES.HOMEPAGE)
                ? "tutor_active"
                : "sidebar_logo_container ms-1"
            }
            onClick={() => {
              navigate(ROUTES.HOMEPAGE);
            }}
          >
            <img src={home} alt="Learn and Play" />
          </div>
        </div>
        {/* <div className="sidebar_logo_container">
          <img src={school} alt="Learn and Play" />
        </div> */}
        <div className="sidebar_nav_container" onClick={() => { navigate(ROUTES.SCHOOLLAYOUT) }}>
          {location.pathname.includes(ROUTES.SCHOOLLAYOUT) && (
            <div className="active_indicator school_indicator"></div>
          )}
          <div
            className={
              location.pathname.includes(ROUTES.SCHOOLLAYOUT)
                ? "school_active"
                : "sidebar_logo_container"
            }
          >
            <img
              src={
                location.pathname.includes(ROUTES.SCHOOLLAYOUT) ? schoolActive : school
              }
              alt="Learn and Play"
            />
          </div>
        </div>
        <div className="sidebar_nav_container" onClick={() => { navigate(ROUTES.TUTORS) }}>
          {location.pathname.includes(ROUTES.TUTORS) && (
            <div className="active_indicator tutor_indicator"></div>
          )}
          <div
            className={
              location.pathname.includes(ROUTES.TUTORS)
                ? "tutor_active"
                : "sidebar_logo_container"
            }
          >
            <img
              src={
                location.pathname.includes(ROUTES.TUTORS) ? tutorActive : tutor
              }
              alt="Learn and Play"
            />
          </div>
        </div>
        <div className="sidebar_nav_container" onClick={() => { navigate(ROUTES.ACTIVITYLAYOUT) }}>
          {location.pathname.includes(ROUTES.ACTIVITYLAYOUT) && (
            <div className="active_indicator activity_indicator"></div>
          )}
          <div
            className={
              location.pathname.includes(ROUTES.ACTIVITYLAYOUT)
                ? "activities_active"
                : "sidebar_logo_container"
            }
          >
            <img
              src={
                location.pathname.includes(ROUTES.ACTIVITYLAYOUT) ? activitiesActive : activities
              }
              alt="Learn and Play"
            />
          </div>
        </div>
        <div className="sidebar_nav_container" onClick={() => { navigate(ROUTES.THEMEPARK__HOMEPAGE) }}>
          {location.pathname.includes(ROUTES.THEMEPARK_LAYOUT) && (
            <div className="active_indicator themePark_indicator"></div>
          )}

          <div
            className={
              location.pathname.includes(ROUTES.THEMEPARK_LAYOUT)
                ? "themePark_active"
                : "sidebar_logo_container"
            }
          >
            <img
              src={
                location.pathname.includes(ROUTES.THEMEPARK_LAYOUT) ? themeParkActive : themePark
              }
              alt="Learn and Play"
            />
          </div>
        </div>
        <div className="sidebar_nav_container" onClick={() => { navigate(ROUTES.UNIVERSITYLAYOUT) }}>
          {location.pathname.includes(ROUTES.UNIVERSITYLAYOUT) && (
            <div className="active_indicator university_indicator"></div>
          )}
          <div
            className={
              location.pathname.includes(ROUTES.UNIVERSITYLAYOUT)
                ? "university_active"
                : "sidebar_logo_container"
            }
          >
            <img
              src={
                location.pathname.includes(ROUTES.UNIVERSITYLAYOUT) ? UniversityActive : university
              }
              alt="Learn and Play"
            />
          </div>
        </div>

        {/* <div className="sidebar_logo_container">
          <img src={themePark} alt="Learn and Play" />
        </div> */}
        <div className="sidebar_logo_container">
          <img src={calender} alt="Learn and Play" className="ms-1" onClick={navigateCalender} />
        </div>
      </div>
    </>
  );
};

export default ServicesNavBar;
