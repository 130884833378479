import "./PaymentHistory.scss";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, GLOBAL_CONSTANTS } from "../../Constants/Constants";
import tutor_img from "../../Assets/ProfileSettings/paymentHistoryTutor.svg";
import Activity from "../../Assets/ProfileSettings/paymentHistoryActivity.svg";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import moment from 'moment';
import spacemen from "../../Assets/Global/spacemen.svg";
import { useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';
import { useTranslation } from "react-i18next";

interface PaymentHistoryItem {
    service_provider_type: string;
    title: string;
    session_count: number;
    created_on: string;
    transaction_id: string;
    payment_status: string;
    total_amount: number;
}

const PaymentHistory = () => {
    const { t } = useTranslation();
    const [sortValue, setSortValue] = useState<string>(GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL);
    const [sortTime, setSortTime] = useState<number>(GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);
    const [PaymentHistoryList, setPaymentHistoryList] = useState<PaymentHistoryItem[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);

    /**Loader state*/
    const Loader: boolean = useSelector((state: any) => state.loaderReducer.loader);

    useEffect(() => {
        getPaymentHistory("", "");
    }, [page]);

    /**Get Payment History List*/
    const getPaymentHistory = (Value: string, period: string) => {
        const payload = {
            page: page,
            limit: 10,
            filter_text: Value,
            period: period
        };

        axiosInstance
            .post(`${API_END_POINTS.USER_PAYMENT_HISTORY}`, payload)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setPaymentHistoryList(Response?.data?.result?.data?.reverse());
                    setPageCount(Response?.data?.result?.total_count);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setSortValue(value);
        getPaymentHistory(value === GLOBAL_CONSTANTS.INITIAL_VALUES.STRING_ALL ? "" : value, sortTime.toString());
    };

    const handleChangeTime = (event: SelectChangeEvent<number>) => {
        const value: any = event.target.value;
        setSortTime(value);
        getPaymentHistory(sortValue, value === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT ? "" : value.toString());
    };

    /**get selected or current language from redux */
    const language: string = useSelector((state: any) => state.languageReducer.language);

    /*Below class name based on English Arabic*/
    const PaginationPaginationLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "PaginationContainerLang_Eng" : "PaginationContainerLang-Arab";

    return (
        <div className="PaymentHistory__container">
            <div className="row">
                <div className="Card__wapper col-xl-12 col-lg-12 col-md-12 col-12 row">
                    {PaymentHistoryList?.length > 0 && PaymentHistoryList?.map((data: any, i) => (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12" key={data?.id || i}>
                            <div className="card__container">
                                <div className="top__contant">
                                    <div className='img__wapper'>
                                        <img src={data.service_provider_type === 'tutor' ? tutor_img : Activity} alt="" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="name__text">{data.service_provider_type}</div>
                                        <div className="Black__text" title={data.title}>{data.title}</div>
                                    </div>
                                </div>
                                <div className="row__contant">
                                    <div className="left__light__text">{t("No. of Sessions")}</div>
                                    <div className="right__black__text">{data.session_count}</div>
                                </div>
                                <div className="row__contant">
                                    <div className="left__light__text">{t("Date")}</div>
                                    <div className="right__black__text">{moment(data.created_on).format("DD/MM/YYYY")}</div>
                                </div>
                                <div className="row__contant">
                                    <div className="left__light__text">{t("Time")}</div>
                                    <div className="right__black__text">{moment(data.created_on).format("h:mm a")}</div>
                                </div>
                                <div className="row__contant">
                                    <div className="left__light__text">{t("Transaction ID")}</div>
                                    <div className="right__black__text">{data.transaction_id}</div>
                                </div>
                                <div className="bottom__contant">
                                    <div className="left_side__payment_wapper">
                                        <CheckCircleRoundedIcon className="icon" />
                                        <div className="text">{data.payment_status}</div>
                                    </div>
                                    <div className="amount">{t("AED")} {data.total_amount}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {PaymentHistoryList?.length === 0 && !Loader && (
                        <div className="No__data__contant">
                            <img src={spacemen} alt="" />
                            <div className="text">{t("No Data Found")}</div>
                        </div>
                    )}
                    {PaymentHistoryList?.length > 0 && (
                        <div translate='no' className="pagination__container">
                            <Pagination
                                count={Math.ceil(pageCount / 10)}
                                shape="rounded"
                                page={page}
                                onChange={(event, value) => {
                                    if (value !== page) {
                                        handleChangePage(event, value);
                                    }
                                }}
                                className={`${PaginationPaginationLang} custom_pagination`}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PaymentHistory;
