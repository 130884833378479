import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { NESTEDMENUITEM } from "../../Constants/Constants";

const NestedMenuItem = React.forwardRef((props: any, ref: any) => {
    const {
        parentMenuOpen,
        label,
        rightIcon = <ArrowRight style={{ fontSize: 16 }} />,
        keepOpen,
        children,
        customTheme,
        className,
        customClass,
        tabIndex: tabIndexProp,
        ContainerProps: ContainerPropsProp = {},
        rightAnchored,
        ...MenuItemProps
    }: any = props;

    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

    const menuItemRef = React.useRef<any>(null);
    React.useImperativeHandle(ref, () => menuItemRef.current);

    const containerRef = React.useRef<any>(null);
    React.useImperativeHandle(containerRefProp, () => containerRef.current);

    const menuContainerRef = React.useRef<any>(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = React.useState<any>(false);

    const handleMouseEnter = (event: any) => {
        setIsSubMenuOpen(true);

        if (ContainerProps?.onMouseEnter) {
            ContainerProps.onMouseEnter(event);
        }
    };

    const handleMouseLeave = (event: any) => {
        setIsSubMenuOpen(false);

        if (ContainerProps?.onMouseLeave) {
            ContainerProps.onMouseLeave(event);
        }
    };

    const isSubmenuFocused = () => {
        const active = containerRef.current?.ownerDocument?.activeElement;

        for (const child of menuContainerRef.current?.children ?? []) {
            if (child === active) {
                return true;
            }
        }
        return false;
    };

    const handleFocus = (event: any) => {
        if (event.target === containerRef.current) {
            setIsSubMenuOpen(true);
        }

        if (ContainerProps?.onFocus) {
            ContainerProps.onFocus(event);
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === NESTEDMENUITEM.ESCAPE) {
            return;
        }

        if (isSubmenuFocused()) {
            event.stopPropagation();
        }

        const active: any = containerRef.current?.ownerDocument?.activeElement;

        if (event.key === NESTEDMENUITEM.ARROWLEFT && isSubmenuFocused()) {
            containerRef.current?.focus();
        }

        if (
            event.key === NESTEDMENUITEM.ARROWRIGHT &&
            event.target === containerRef.current &&
            event.target === active
        ) {
            const firstChild = menuContainerRef.current?.children[0];
            firstChild?.focus();
        }
    };

    const open = isSubMenuOpen && parentMenuOpen;

    let tabIndex;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    return (
        <div
            {...ContainerProps}
            ref={containerRef}
            onFocus={handleFocus}
            tabIndex={tabIndex}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onKeyDown={handleKeyDown}
            className={customClass}
        >
            <MenuItem
                {...MenuItemProps}
                data-open={!!open || undefined}
                className={className}
                ref={menuItemRef}
                keepOpen={keepOpen}
            >
                {label}
                <div style={{ flexGrow: 1 }} />
                {rightIcon}
            </MenuItem>
            <Menu
                hideBackdrop
                style={{ pointerEvents: "none" }}
                anchorEl={menuItemRef.current}
                anchorOrigin={{
                    vertical: NESTEDMENUITEM.TOP,
                    horizontal: rightAnchored ? NESTEDMENUITEM.LEFT : NESTEDMENUITEM.RIGHT
                }}
                transformOrigin={{
                    vertical: NESTEDMENUITEM.TOP,
                    horizontal: rightAnchored ? NESTEDMENUITEM.RIGHT : NESTEDMENUITEM.LEFT
                }}
                // css={customTheme}
                open={!!open}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                onClose={() => {
                    setIsSubMenuOpen(false);
                }}
            >
                <div ref={menuContainerRef} style={{ pointerEvents: "auto" }}>
                    {children}
                </div>
            </Menu>
        </div>
    );
});

export default NestedMenuItem;
